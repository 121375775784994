import React, { forwardRef, Fragment, useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import moment from "moment";
import styled from "styled-components";

import { numberWithCommas } from "../../common";
import SelectDate from "../../selectDate";
import { getPayAllList, merchantId_api } from "../../../lib/api";
import Arrow from "../../../assets/images/dashboard/arrow.svg";
import Schedule from "../../../assets/images/dashboard/schedule.svg";
import { DashboardCard } from "../../commonStyle";

const StyleComponent = styled(DashboardCard)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .topContainer {
    width: 100%;
    > div > p {
      &:first-child {
        margin-bottom: 0;
        font-weight: 700;
        line-height: 1.5;
        font-size: var(--dashboard-main);
      }
    }
    > p {
      font-size: var(--dashboard-count);
      color: var(--dashboard-default);
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .margin-top {
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 15vw);
  }

  #circlechart {
    width: 14vw;
    margin-right: 1vw;
  }

  @media screen and (max-width: 805px) {
    .topContainer {
      > div > p {
        &:first-child {
          font-size: 5.42vw;
        }
      }
      > p {
        font-size: 8.88vw;
        margin: 0;
      }
    }
    .flex {
      flex-direction: column;
    }
    .margin-top {
      margin-top: 0;
    }
    #circlechart {
      width: 100%;
      margin-right: 0;
    }

    .flex-col {
      width: 100%;
    }
  }
`;

export const DatePickerStyle = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  margin: 0 10px;
  h5 {
    margin: 0 8px;
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 5px 0 0;
    > div > div > button {
      padding: 6px 10px;
      font-size: 13px;
      > svg {
        width: 17px;
      }
    }
    select {
      padding: 9px 5px;
    }
  }
`;

export const DatePickerRenewal = styled.div`
  display: flex;
  border: 1px solid #dde4eb;
  background-color: #ffffff !important;
  border-radius: 0.41vw;
  font-size: 0.83vw;
  align-items: center;
  .react-datepicker__input-container {
    button {
      background: none !important;
      color: #686c71;
      border: none;
      font-size: 0.83vw;
      :focus {
        box-shadow: none;
      }
      :active {
        box-shadow: none !important;
        color: #686c71 !important ;
        border: none;
      }
    }
  }

  h5 {
    margin-bottom: 0;
  }
  select {
    padding: 0 1.3vw 0 12px;
    -webkit-appearance: none; /* for chrome */
    -moz-appearance: none; /*for firefox*/
    appearance: none;
    border: none;
    border-right: 1px solid #dde4eb;
    color: #686c71;
    background: url(${Arrow}) no-repeat no-repeat 90% 50%;
  }
  > img {
    padding-left: 0.3vw;
  }
  @media screen and (max-width: 805px) {
    height: 11.11vw;
    border-radius: 2.22vw;
    padding: 2.22vw 3.55vw;
    margin: 4.44vw 0;
    .react-datepicker-wrapper {
      display: flex !important;
      align-items: center;
    }
    .react-datepicker__input-container {
      display: flex;
      justify-content: center;
      button {
        font-size: 4.44vw;
        padding: 0;
      }
    }
    h5 {
      margin-top: 0;
    }
    img {
      width: 5vw;
    }
  }
`;

const SaleData = styled.div`
  display: flex;
  font-size: 0.83vw;
  background: ${(props) => `${props.color}10`};
  border-radius: 100px;
  height: 2.13vw;
  .title {
    width: 5vw;
    display: flex;
    align-items: center;
    background: ${(props) => props.color};
    justify-content: center;
    border-radius: 100px;
    padding: 0 0.625vw;
    > p {
      color: #ffffff;
      font-size: 0.83vw;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .data {
    display: flex;
    width: 100%;
    align-items: center;
    p {
      width: calc(100% / 3);
      height: 60%;
      text-align: center;
      margin-bottom: 0;
      font-size: 0.83vw;
      font-weight: 600;
      color: #686c71;
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #dde4eb;
      }
    }
  }
`;

const SaleDataMobile = styled.div`
  font-size: 3.33vw;
  .title {
    display: flex;
    align-items: center;

    span {
      display: block;
      width: 2.22vw;
      height: 2.22vw;
      border-radius: 100vw;
      background: ${(props) => `${props.color}`};
    }
    p {
      margin-left: 2.22vw;
      margin-bottom: 0;
      font-weight: 600;
    }
  }
  .data {
    background: ${(props) => `${props.color}10`};
    border-radius: 100px;
    height: 11.11vw;
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
    margin: 2.22vw 0;
    p {
      height: 60%;
      text-align: center;
      margin-bottom: 0;
      font-weight: 600;
      color: #686c71;
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #dde4eb;
      }
    }
  }

  &:last-child {
    .data {
      margin-bottom: 0;
    }
  }
`;

const DayButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.22vw;
  margin-bottom: 6.66vw;
  button {
    background-color: #ffffff;
    border-radius: 2.22vw;
    color: #91979e;
    border: 1px solid #dde4eb;
    font-weight: 500;
    font-size: 4.44vw;
    padding: 2.22vw 0;
    &.check {
      background-color: var(--dashboard-default);
      color: #ffffff;
    }
  }
`;

let merchantId = "";

const SalesChart = ({
  paddingStyle,
  isMobile,
  setIsLoading,
  mid,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [salesList, setSalesList] = useState([]);
  // const [startDate, setStartDate] = useState(
  //   moment(new Date()).startOf("month")._d
  // );
  // const [endDate, setEndDate] = useState(new Date());
  const dateArray = [
    "어제",
    "이번 주",
    "이번 달",
    "이번 년",
    "저번 주",
    "저번 달",
  ];
  const [selectDate, setSelectDate] = useState("이번 달");
  useEffect(() => {
    getPaylogAllListApi(mid);
  }, [mid]);

  const getPaylogAllListApi = async (merchantId) => {
    if (merchantId) {
      setIsLoading(true);
      const { data } = await getPayAllList(
        merchantId,
        moment(startDate).format("YYYY-MM-DD 00:00:00"),
        moment(endDate).format("YYYY-MM-DD 23:59:59")
      );

      if (data) {
        setSalesList(data);
        setIsLoading(false);
      }
    }
  };

  let credit = 0;
  let kakaopay = 0;
  let cash = 0;
  let virtual = 0;
  let webPayment = 0;
  let creditPrice = 0;
  let kakaopayPrice = 0;
  let cashPrice = 0;
  let virtualPrice = 0;
  let webPaymentPrice = 0;
  let credit_cancel = 0;
  let kakaopay_cancel = 0;
  let cash_cancel = 0;
  let virtual_cancel = 0;
  let webPayment_cancel = 0;
  let creditPrice_cancel = 0;
  let kakaopayPrice_cancel = 0;
  let cashPrice_cancel = 0;
  let virtualPrice_cancel = 0;
  let webPaymentPrice_cancel = 0;

  for (let i = 0; i < salesList.length; i++) {
    if (
      salesList[i].paymentType === "credit" ||
      salesList[i].paymentType.paymentType === "applepay"
    ) {
      credit += 1;
      creditPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === "credit-cancel" ||
      salesList[i].paymentType.paymentType === "applepay-cancel"
    ) {
      credit_cancel += 1;
      creditPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === "cash") {
      cash += 1;
      cashPrice += salesList[i].amount;
    } else if (salesList[i].paymentType === "cash-cancel") {
      cash_cancel += 1;
      cashPrice_cancel += salesList[i].amount;
    } else if (
      salesList[i].paymentType === "kakaopay" ||
      salesList[i].paymentType === "naverpay" ||
      salesList[i].paymentType === "payco"
    ) {
      kakaopay += 1;
      kakaopayPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === "kakaopay-cancel" ||
      salesList[i].paymentType === "naverpay-cancel" ||
      salesList[i].paymentType === "payco-cancel"
    ) {
      kakaopay_cancel += 1;
      kakaopayPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === "virtual") {
      virtual += 1;
      virtualPrice += salesList[i].amount;
    } else if (salesList[i].paymentType === "virtual-cancel") {
      virtual_cancel += 1;
      virtualPrice_cancel += salesList[i].amount;
    } else if (
      salesList[i].paymentType === "toss" ||
      salesList[i].paymentType === "hecto"
    ) {
      webPayment += 1;
      webPaymentPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === "toss-cancel" ||
      salesList[i].paymentType === "hecto-cancel"
    ) {
      webPayment_cancel += 1;
      webPaymentPrice_cancel += salesList[i].amount;
    } else {
    }
  }

  const totalPayDataCount =
    +credit + +cash + +kakaopay + +virtual + +webPayment;
  const totalPayCancelCount =
    +credit_cancel +
    +cash_cancel +
    +kakaopay_cancel +
    +virtual_cancel +
    +webPayment_cancel;
  const totalPayDataPrice =
    +creditPrice +
    +cashPrice +
    +kakaopayPrice +
    +virtualPrice +
    +webPaymentPrice;
  const totalPayCancelDataPrice =
    +creditPrice_cancel +
    +cashPrice_cancel +
    +kakaopayPrice_cancel +
    +virtualPrice_cancel +
    +webPaymentPrice_cancel;
  const credit1 =
    credit > 0
      ? Math.round(
          ((credit - credit_cancel) /
            (totalPayDataCount - totalPayCancelCount)) *
            100
        )
      : 0;
  const cash1 =
    cash > 0
      ? Math.round(
          ((cash - cash_cancel) / (totalPayDataCount - totalPayCancelCount)) *
            100
        )
      : 0;
  const kakaopay1 =
    kakaopay > 0
      ? Math.round(
          ((kakaopay - kakaopay_cancel) /
            (totalPayDataCount - totalPayCancelCount)) *
            100
        )
      : 0;
  const virtual1 =
    virtual > 0
      ? Math.round(
          ((virtual - virtual_cancel) /
            (totalPayDataCount - totalPayCancelCount)) *
            100
        )
      : 0;
  const webPayment1 =
    webPayment > 0
      ? Math.round(
          ((webPayment - webPayment_cancel) /
            (totalPayDataCount - totalPayCancelCount)) *
            100
        )
      : 0;

  // sale chart

  const apexRadialBarChart = {
    series: [credit1, virtual1, cash1, kakaopay1, webPayment1],
    options: {
      chart: {
        height: isMobile ? 300 : 320,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            show: false,
            name: {
              show: true,
              fontSize: "22px",
            },
            value: {
              show: true,
              fontSize: "16px",
              formatter: function(val) {
                return val + "회";
              },
            },
            total: {
              show: true,
              label: "결제 총 횟수",
              formatter: function(w) {
                return (
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0) + "회"
                );
              },
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["카드", "가상결제", "현금", "간편결제", "웹결제"],
      colors: ["#00CFE2", "#F2A354", "#EC6E85", "#6EAD69", "#8E78AA"],
    },
  };

  const handleDate = (value) => {
    setSelectDate(value);
    if (value === "어제") {
      setStartDate(moment(new Date()).subtract(1, "d")._d);
      setEndDate(moment(new Date()).subtract(1, "d")._d);
    } else if (value === "이번 주") {
      setStartDate(moment(new Date()).startOf("isoweek")._d);
      setEndDate(moment(new Date()).endOf("isoweek")._d);
    } else if (value === "이번 달") {
      setStartDate(moment(new Date()).startOf("month")._d);
      setEndDate(new Date());
    } else if (value === "저번 달") {
      setStartDate(
        moment(new Date())
          .subtract(1, "M")
          .startOf("month")._d
      );
      setEndDate(
        moment(new Date())
          .startOf("month")
          .subtract(1, "d")._d
      );
    } else if (value === "이번 년") {
      setStartDate(moment(new Date()).subtract(1, "y")._d);
      setEndDate(new Date());
    } else if (value === "저번 주") {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };

  useEffect(() => {
    getPaylogAllListApi(mid);
  }, [startDate, endDate]);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn-primary btn-sm" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <Fragment>
      <StyleComponent>
        <div className="topContainer">
          <div className="flex">
            <p>결제 유형별 매출 현황</p>
            <div className="topbar">
              <DatePickerRenewal>
                {!isMobile && (
                  <SelectDate
                    nowTime={"이번 달"}
                    setStartDate={(val) => setStartDate(val)}
                    setEndDate={(val) => setEndDate(val)}
                  />
                )}

                <img src={Schedule} alt="s" />
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  selectsStart
                  locale={ko}
                  startDate={startDate}
                  maxDate={endDate}
                  endDate={endDate}
                  customInput={<ExampleCustomInput />}
                />
                <h5>-</h5>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  locale={ko}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
                />
              </DatePickerRenewal>
              {isMobile && (
                <DayButtons>
                  {dateArray.map((item) => (
                    <button
                      className={selectDate === item ? "check" : ""}
                      onClick={() => handleDate(item)}
                    >
                      {item}
                    </button>
                  ))}
                </DayButtons>
              )}
            </div>
          </div>
          <p>
            {numberWithCommas(totalPayDataPrice - totalPayCancelDataPrice)} 원
          </p>
        </div>
        <div className="flex margin-top">
          <div id="circlechart">
            <Chart
              options={apexRadialBarChart.options}
              series={apexRadialBarChart.series}
              type="radialBar"
              height={isMobile ? 270 : 290}
            />
          </div>
          <div className="flex-col">
            {isMobile && (
              <>
                <SaleDataMobile color="#00CFE2">
                  <div className="title">
                    <span />
                    <p>카드</p>
                  </div>
                  <div className="data">
                    <p>{credit1}%</p>
                    <p>
                      {numberWithCommas(creditPrice - creditPrice_cancel)} 원
                    </p>
                    <p>{credit - credit_cancel} 회</p>
                  </div>
                </SaleDataMobile>
                <SaleDataMobile color="#F2A354">
                  <div className="title">
                    <span />
                    <p>가상결제</p>
                  </div>
                  <div className="data">
                    <p>{virtual1} %</p>
                    <p>
                      {numberWithCommas(virtualPrice - virtualPrice_cancel)} 원
                    </p>
                    <p>{virtual - virtual_cancel} 회</p>
                  </div>
                </SaleDataMobile>
                <SaleDataMobile color="#EC6E85">
                  <div className="title">
                    <span />
                    <p>현금</p>
                  </div>
                  <div className="data">
                    <p>{cash1}%</p>
                    <p>{numberWithCommas(cashPrice - cashPrice_cancel)} 원</p>
                    <p>{cash - cash_cancel} 회</p>
                  </div>
                </SaleDataMobile>
                <SaleDataMobile color="#6EAD69">
                  <div className="title">
                    <span />
                    <p>간편결제</p>
                  </div>
                  <div className="data">
                    <p>{kakaopay1} %</p>
                    <p>
                      {numberWithCommas(kakaopayPrice - kakaopayPrice_cancel)}{" "}
                      원
                    </p>
                    <p>{kakaopay - kakaopay_cancel} 회</p>
                  </div>
                </SaleDataMobile>
                <SaleDataMobile color="#8E78AA">
                  <div className="title">
                    <span />
                    <p>웹결제</p>
                  </div>
                  <div className="data">
                    <p>{webPayment1} %</p>
                    <p>
                      {numberWithCommas(
                        webPaymentPrice - webPaymentPrice_cancel
                      )}{" "}
                      원
                    </p>
                    <p>{webPayment - webPayment_cancel} 회</p>
                  </div>
                </SaleDataMobile>
              </>
            )}
            {!isMobile && (
              <>
                {" "}
                <SaleData color="#00CFE2">
                  <div className="title">
                    <p>카드</p>
                  </div>
                  <div className="data">
                    <p>{credit1}%</p>
                    <p>
                      {numberWithCommas(creditPrice - creditPrice_cancel)} 원
                    </p>
                    <p>{credit - credit_cancel} 회</p>
                  </div>
                </SaleData>
                <SaleData color="#F2A354">
                  <div className="title">
                    <p>가상결제</p>
                  </div>
                  <div className="data">
                    <p>{virtual1}%</p>
                    <p>
                      {numberWithCommas(virtualPrice - virtualPrice_cancel)} 원
                    </p>
                    <p>{virtual - virtual_cancel} 회</p>
                  </div>
                </SaleData>
                <SaleData color="#EC6E85">
                  <div className="title">
                    <p>현금</p>
                  </div>
                  <div className="data">
                    <p>{cash1}%</p>
                    <p>{numberWithCommas(cashPrice - cashPrice_cancel)} 원</p>
                    <p>{cash - cash_cancel} 회</p>
                  </div>
                </SaleData>
                <SaleData color="#6EAD69">
                  <div className="title">
                    <p>간편결제</p>
                  </div>
                  <div className="data">
                    <p>{kakaopay1}%</p>
                    <p>
                      {numberWithCommas(kakaopayPrice - kakaopayPrice_cancel)}{" "}
                      원
                    </p>
                    <p>{kakaopay - kakaopay_cancel} 회</p>
                  </div>
                </SaleData>
                <SaleData color="#8E78AA">
                  <div className="title">
                    <p>웹결제</p>
                  </div>
                  <div className="data">
                    <p>{webPayment1}%</p>
                    <p>
                      {numberWithCommas(
                        webPaymentPrice - webPaymentPrice_cancel
                      )}{" "}
                      원
                    </p>
                    <p>{webPayment - webPayment_cancel} 회</p>
                  </div>
                </SaleData>
              </>
            )}
          </div>
        </div>
      </StyleComponent>
    </Fragment>
  );
};
export default SalesChart;
