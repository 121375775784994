import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import IconMapviewer from "../../../assets/images/dashboard/mapviewer.svg";
import IconTicket from "../../../assets/images/dashboard/search_ticket.svg";
import IconInAndOut from "../../../assets/images/dashboard/search_inandout.svg";
import IconMember from "../../../assets/images/dashboard/search_member.svg";
import IconOpenDoor from "../../../assets/images/dashboard/remote_opendoor.svg";
import IconHelp from "../../../assets/images/dashboard/help.svg";
import { useHistory } from "react-router-dom";
import { DashboardCard } from "../../commonStyle";
import { merchantId_api } from "../../../lib/api";
import { openDoor } from "../../../lib/socket";
import OpenDoorModal from "../../modal/openDoorModal";
import Swal from "sweetalert2";

const StyleComponent = styled(DashboardCard)`
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5vw;
  justify-items: center;
  @media screen and (max-width: 805px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.22vw;
  }
`;

const QuickBtn = styled.button`
  width: 4.16vw;
  height: 4.16vw;
  border-radius: 0.41vw;
  background-color: var(--dashboard-default);
  border: none;
  color: #ffffff;
  font-size: 0.62vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.41vw 0.2vw;
  img {
    width: 2.5vw;
  }
  &:hover {
    background-color: #2f6d84;
  }

  @media screen and (max-width: 805px) {
    width: 33.88vw;
    height: 13.33vw;
    font-size: 4.44vw;
    background-color: #3c5169;
    border-radius: 2.22vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const QuickMenu = ({ isMobile, isMulti, mid }) => {
  const history = useHistory();
  const menuList = [
    { name: "맵 뷰어", img: IconMapviewer, path: "/seat/list" },
    { name: "입장권 조회", img: IconTicket, path: "/ticket/ticket-list" },
    { name: "지점 출입 조회", img: IconInAndOut, path: "/exit-data" },
    { name: "회원 정보 조회", img: IconMember, path: "/user/userlist" },
    { name: "원격 문열기", img: IconOpenDoor, path: "door" },
    { name: "가이드", img: IconHelp, path: "guide" },
  ];
  const [isDoorModal, setIsDoorModal] = useState(false);

  const goPage = (path) => {
    if (path === "door") {
      if (isMulti) {
        console.log(isMulti);
        setIsDoorModal(true);
      } else {
        openDoor(mid);
        console.log(mid);
      }
    } else if (path === "guide") {
      Swal.fire({
        text: "현재 준비중입니다.",
        timer: 5000,
      });
    } else {
      history.push(path);
    }
  };

  return (
    <Fragment>
      {isDoorModal && isMulti && (
        <OpenDoorModal
          show={isDoorModal}
          onClose={setIsDoorModal}
          data={isMulti}
          id={mid}
        />
      )}
      <StyleComponent>
        {menuList.map((item) =>
          isMobile &&
          (item.path === "/user/userlist" || item.path === "guide") ? (
            <></>
          ) : (
            <QuickBtn onClick={() => goPage(item.path)}>
              {!isMobile && <img src={item.img} alt="icon" />}
              {item.name}
            </QuickBtn>
          )
        )}
      </StyleComponent>
    </Fragment>
  );
};

export default QuickMenu;
