import React from "react";
import moment from "moment";

const SelectDate = ({ setStartDate, setEndDate, nowTime, page }) => {
  const month =
    page === "ticket"
      ? [
          "어제",
          "이번 주",
          "이번 달",
          "저번 달",
          "1년",
          // "전체"
        ]
      : [
          "어제",
          "이번 주",
          "이번 달",
          "저번 달",
          "1년 전",
          // "전체"
        ];
  const handleDate = ({ target }) => {
    if (target.value === "어제") {
      setStartDate(moment(new Date()).subtract(1, "d")._d);
      setEndDate(moment(new Date()).subtract(1, "d")._d);
    } else if (target.value === "이번 주") {
      setStartDate(moment(new Date()).startOf("isoWeek")._d);
      setEndDate(moment(new Date()).endOf("isoWeek")._d);
    } else if (target.value === "이번 달") {
      setStartDate(moment(new Date()).startOf("month")._d);
      setEndDate(new Date());
    } else if (target.value === "저번 달") {
      setStartDate(
        moment(new Date())
          .subtract(1, "M")
          .startOf("month")._d
      );
      setEndDate(
        moment(new Date())
          .startOf("month")
          .subtract(1, "d")._d
      );
    } else if (target.value === "1년 전") {
      setStartDate(moment(new Date()).subtract(1, "y")._d);
      setEndDate(new Date());
    } else if (target.value === "오늘") {
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (target.value === "1년") {
      setStartDate(moment(new Date()).subtract(1, "y")._d);
      setEndDate(moment(new Date()).add(1, "y")._d);
    }
  };

  return (
    <select onChange={handleDate} defaultValue={nowTime ? nowTime : "오늘"}>
      {!nowTime && (
        <>
          <option value="오늘" defaultChecked>
            오늘
          </option>
          {month.map((li) => (
            <option key={li} value={li}>
              {li}
            </option>
          ))}
        </>
      )}
      {nowTime && (
        <>
          <option value="오늘">오늘</option>
          {month.map((li) => (
            <>
              {nowTime == li ? (
                <option defaultChecked key={li} value={li}>
                  {li}
                </option>
              ) : (
                <option key={li} value={li}>
                  {li}
                </option>
              )}
            </>
          ))}
        </>
      )}
    </select>
  );
};
export default SelectDate;
