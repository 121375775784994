import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";

import configDB from "../../data/customizer/config";

import {
  Seat,
  SeatArrayS,
  SeatView,
  TabButton,
  Text,
  Wall,
} from "../../pages/seatList";
import { getMapViewList, getMerchantInfo } from "../../lib/api";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const Container = styled.div`
  width: 100%;
  overflow: scroll;
  height: 75vh;
`;

export const SelectBox = styled.div`
  width: 100%;
  position: relative;
  select option {
    padding: 4vw 1vw;
  }
  img {
    width: 1vw;
    position: absolute;
    transform: rotate(-90deg);
    top: 44%;
    right: 3.8vw;
  }
`;

const SeatMapviewModal = ({
  onClose,
  show,
  merchantId,
  selected,
  setChoiceSeatId,
  setChoiceSeatNum,
}) => {
  const [lists, setLists] = useState([]);
  const [walls, setWalls] = useState([]);
  const [texts, setTexts] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [floorNum, setFloorNum] = useState(null);
  const [multiNum, setMultiNum] = useState(null);

  const getSeatListApi = async () => {
    const res = await getMapViewList(merchantId);
    const { data } = await getMerchantInfo(merchantId);
    if (res.status === 200) {
      if (data[0].multiKioskFloor) {
        setFloorNum(1);
        setMultiNum(data[0].multiKioskFloor);
        setDefaults(res.data);
        setLists(
          res.data.filter(
            (item) =>
              item.type === "seat" &&
              selected?.T_level >= item?.level &&
              item.seatType === 0 &&
              item.floor === 1
          )
        );
        setWalls(res.data.filter((li) => li.type === "wall" && li.floor === 1));
        setTexts(res.data.filter((li) => li.type === "text" && li.floor === 1));
      } else {
        setLists(
          res.data.filter(
            (item) =>
              item.type === "seat" &&
              selected?.T_level >= item?.level &&
              item.seatType === 0
          )
        );
        setWalls(res.data.filter((li) => li.type === "wall"));
        setTexts(res.data.filter((li) => li.type === "text"));
      }
    }
  };
  useEffect(() => {
    getSeatListApi();
  }, []);

  const handleClose = () => {
    onClose();
    localStorage.removeItem("seat");
  };
  const handleSelectSeat = (seat) => {
    setChoiceSeatId(seat.id);
    setChoiceSeatNum(seat.number);
    console.log(seat);
    onClose();
  };

  const changefloor = (floorData) => {
    if (floorNum) {
      setLists(
        defaults.filter(
          (item) =>
            item.type === "seat" &&
            selected?.T_level >= item?.level &&
            item.seatType === 0 &&
            item.floor === floorData
        )
      );
      setWalls(
        defaults.filter((li) => li.type === "wall" && li.floor === floorData)
      );
      setTexts(
        defaults.filter((li) => li.type === "text" && li.floor === floorData)
      );
    }
  };
  const ratio = (920 - window.innerWidth) * 0.7 * 0.001;
  const isMinimal = window.innerWidth < 480;
  const isFold = window.innerWidth < 768;
  useEffect(() => {
    if (floorNum !== null) {
      changefloor(floorNum);
    }
  }, [floorNum]);
  return (
    <Modal
      centered
      isOpen={show}
      toggle={handleClose}
      style={{ maxWidth: isFold ? "90vw" : "70vw", margin: "2vw auto" }}
    >
      <ModalHeader className="modal-title" toggle={handleClose}>
        좌석 선택
      </ModalHeader>
      <ModalBody style={{ padding: "5px" }}>
        {multiNum && (
          <div className="floorBtn">
            {[...Array(multiNum)].map((item, index) => (
              <TabButton
                selected={floorNum === index + 1}
                onClick={() => setFloorNum(index + 1)}
                value={index + 1}
              >
                {index + 1}층
              </TabButton>
            ))}
          </div>
        )}
        <Container>
          <SeatView ratio={ratio} isMin={isMinimal}>
            <SeatArrayS ratio={ratio} isMin={isMinimal}>
              {lists?.map((seat) => (
                <React.Fragment key={seat.id}>
                  {(seat.status === "block" || seat.status === 1) && (
                    <Seat
                      ratio={ratio}
                      isMin={isMinimal}
                      used="#979797"
                      top={seat.pos_top}
                      left={seat.pos_left}
                    >
                      <p>{seat.number}</p>
                    </Seat>
                  )}
                  {seat.status === "idle" && (
                    <Seat
                      ratio={ratio}
                      isMin={isMinimal}
                      className="seat"
                      top={seat.pos_top}
                      left={seat.pos_left}
                      onClick={() => handleSelectSeat(seat)}
                    >
                      <p>{seat.number}</p>
                    </Seat>
                  )}
                  {seat.status === "using" && seat.seatType === 1 && (
                    <Seat
                      ratio={ratio}
                      isMin={isMinimal}
                      className="seat"
                      used="#1BBABC"
                      top={seat.pos_top}
                      left={seat.pos_left}
                    >
                      <p>{seat.number}</p>
                    </Seat>
                  )}
                  {seat.status === "using" && seat.seatType === 0 && (
                    <Seat
                      ratio={ratio}
                      isMin={isMinimal}
                      className="seat"
                      top={seat.pos_top}
                      left={seat.pos_left}
                      used={
                        seat.ticketType === 1
                          ? "#616161"
                          : seat.ticketType === 2
                          ? "#FF8F00"
                          : "#00838F"
                      }
                    >
                      <p>{seat.number}</p>
                    </Seat>
                  )}
                  {seat.status === "rest" && seat.seatType === 1 && (
                    <Seat
                      ratio={ratio}
                      isMin={isMinimal}
                      className="seat"
                      top={seat.pos_top}
                      left={seat.pos_left}
                      used="#7bb4ff"
                    >
                      <p>{seat.number}</p>
                    </Seat>
                  )}
                  {seat.status === "rest" && seat.seatType === 0 && (
                    <Seat
                      ratio={ratio}
                      isMin={isMinimal}
                      className="seat"
                      top={seat.pos_top}
                      left={seat.pos_left}
                      used={
                        seat.ticketType === 1
                          ? "#B0BEC5"
                          : seat.ticketType === 2
                          ? "#FFAB91"
                          : "#80DEEA"
                      }
                    >
                      <p>{seat.number}</p>
                    </Seat>
                  )}
                  {seat.status === "using" && seat.type === "seminar" && (
                    <Seat
                      ratio={ratio}
                      isMin={isMinimal}
                      used="#616161"
                      top={seat.pos_top}
                      left={seat.pos_left}
                    >
                      <p>{seat.number}</p>
                    </Seat>
                  )}
                </React.Fragment>
              ))}
              {walls?.map((wall) => (
                <React.Fragment key={wall.id}>
                  <Wall
                    ratio={ratio}
                    isMin={isMinimal}
                    width={wall.pos_width}
                    height={wall.pos_height}
                    top={wall.pos_top}
                    left={wall.pos_left}
                  />
                </React.Fragment>
              ))}
              {texts?.map((text) => (
                <React.Fragment key={text.id}>
                  <Text
                    ratio={ratio}
                    isMin={isMinimal}
                    size={text.font_size}
                    top={text.pos_top}
                    left={text.pos_left}
                  >
                    {text?.name}
                  </Text>
                </React.Fragment>
              ))}
            </SeatArrayS>
          </SeatView>
        </Container>
      </ModalBody>
    </Modal>
  );
};
export default SeatMapviewModal;
