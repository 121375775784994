import React, { Fragment, useEffect, useState } from "react";
import Loader from "../layout/loader";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from "react-toastify";
import { levelDecode } from "./common";
import { getMerchantInfo, getUserData, merchantId_api } from "../lib/api";
import { Cookies } from "react-cookie";
import RouteChangeTracker from "./routeChangeTracker";
import styled from "styled-components";
import { BackgroundLoading } from "./Loading";
import { SideBar2 } from "../layout/sidebar/index";
const cookies = new Cookies();

const App = ({ children, isLoading }) => {
  console.warn = () => {};
  const [merchantData, setMerchantData] = useState({});
  const [mid, setMid] = useState({});
  const [isShowSide, setIsShowSide] = useState(false);

  useEffect(() => {
    getMerchantId();
  }, []);

  let level = levelDecode(localStorage.getItem("Token"));

  const getMerchantId = async () => {
    try {
      const htmlTitle = document.querySelector("title");
      if (level && +level > 1) {
        // 지점 및 관리자 로그인
        const { data } = await merchantId_api();
        setMid(data.id);
        if (data) {
          const res = await getMerchantInfo(data.id);
          if (res.status === 200) {
            setMerchantData(res.data[0]);

            if (level && +level === 50) {
              htmlTitle.innerText = `렛미업 스터디카페 키오스크 - ${res.data[0].name} 관리페이지`;
            }
          }
        }
      } else {
        // 회원 로그인
        const res = await getUserData();

        setMerchantData(res.data[0]);
        htmlTitle.innerText = `렛미업 스터디카페 키오스크 - ${res.data[0].name}`;
      }
    } catch (err) {
      console.log(err);
    }
  };

  RouteChangeTracker();

  return (
    <Fragment>
      <Loader />
      {isLoading && <BackgroundLoading />}
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header
          data={merchantData}
          mid={mid}
          isShowSide={isShowSide}
          setIsShowSide={setIsShowSide}
        />
        {}
        <div
          onClick={() => {
            if (localStorage.getItem("isClick")) {
              document
                .querySelector(".onhover-show-div")
                .classList.remove("showing");
              localStorage.setItem("isClick", false);
            }
          }}
          className="page-body-wrapper sidebar-icon"
        >
          {/* <Sidebar/> */}
          <SideBar2 isShowSide={isShowSide} setIsShowSide={setIsShowSide} />
          <div className="page-body" isLoading merchantData={merchantData}>
            {children}
          </div>
          <Footer data={merchantData} />
          {/* <ThemeCustomize /> */}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default App;
