import axios from "axios";
import { useState } from "react";

import { Cookies } from "react-cookie";
import { io } from "socket.io-client";
import { idDecode, levelDecode } from "../components/common";

const cookies = new Cookies();

const getCookie = (name) => {
  return localStorage.getItem(name);
};

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
    Authorization: "bearer " + getCookie("Token"),
  },
};

let config2 = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
    Authorization: "bearer " + getCookie("adminToken"),
  },
};
const getSiteLink = window.location.href;

const SOCKET_URL = getSiteLink?.includes("localhost")
  ? process.env.REACT_APP_SOCKET_URL_DEV
  : process.env.REACT_APP_SOCKET_URL;
const API_URL = process.env.REACT_APP_API_URL;
const API_URL_BOT_ALERT = process.env.REACT_APP_API_URL_BOT_ALERT;
const API_URL_BOT_EXIT = process.env.REACT_APP_API_URL_BOT_EXIT;
const API_IMG_URL = process.env.REACT_APP_API_IMG_URL;

//로그인 한 유저의 id
// admin:최고관리자
// 6자리: 점주
// 11자리: 유저
let loginId;

const getLoginId = () => {
  loginId = getCookie("adminToken")
    ? "admin"
    : getCookie("Token")
    ? idDecode(getCookie("Token"))
    : "letmeup";
};

//api 미들웨어
const frontReqLog = async (apiName, merchant, data, api, notLogin) => {
  if (!loginId && !notLogin) {
    getLoginId();
  }
  const isLogData = await axios.post(
    `${API_URL}/frontReqLog/:${apiName}/backoffice`,
    {
      merchantId: merchant,
      loginUser: notLogin ? notLogin : loginId,
      data: data ? data : "",
    }
  );
  if (isLogData.status === 200) return await api;
  else return isLogData;
};

// login
export const Login_api = async (id, pw) => {
  let result = await frontReqLog(
    "login",
    "letmeup",
    { id },
    axios.post(`${API_URL}/v1/auth/login`, {
      phoneNum: id,
      password: pw,
    }),
    id
  );
  if (result.status === 200 && +levelDecode(result.data.access.token) === 99) {
    // 로그인 아이디 저장(최고관리자)
    loginId = "admin";
    localStorage.setItem("adminToken", result.data?.access.token, {
      path: "/",
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    localStorage.setItem("adminRefreshToken", result.data?.refresh.token, {
      path: "/",
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    return result;
  } else if (
    result.status === 200 &&
    +levelDecode(result.data.access.token) <= 50
  ) {
    // 로그인 아이디 저장
    loginId = id;
    localStorage.setItem("Token", result.data?.access.token, {
      path: "/",
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    localStorage.setItem("refreshToken", result.data?.refresh.token, {
      path: "/",
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    return result;
  } else {
    return result;
  }
};

export const Logout_api = async (merchantId, phoneNum) => {
  if (getCookie("adminToken")) {
    const res = await frontReqLog(
      "logout",
      merchantId,
      { refreshToken: getCookie("adminRefreshToken") },
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/auth/logout`,
        { refreshToken: getCookie("adminRefreshToken") },
        config2
      )
    );
    console.log(res, "res");
    localStorage.clear();
  } else {
    const res1 = await frontReqLog(
      "logout",
      merchantId,
      { refreshToken: getCookie("refreshToken") },
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/auth/logout`,
        { refreshToken: getCookie("refreshToken") },
        config
      )
    );
    console.log(res1, "res1");
  }
  localStorage.removeItem("Token", {
    path: "/",
  });
  localStorage.removeItem("refreshToken", {
    path: "/",
  });
  localStorage.removeItem("adminToken", {
    path: "/",
  });
  localStorage.removeItem("adminRefreshToken", {
    path: "/",
  });
  localStorage.removeItem("number");
  localStorage.clear();
  return window.location.replace(`${process.env.PUBLIC_URL}/auth/login`);
};

export const merchantId_api = async () => {
  try {
    const res = await frontReqLog(
      "merchantUser",
      "merchant",

      {
        token: getCookie("Token"),
      },
      axios.post(
        `${process.env.REACT_APP_API_URL}/auth/merchantUser/`,
        { token: getCookie("Token") },
        config
      )
    );

    return res;
  } catch (e) {
    try {
      const result = await frontReqLog(
        "refresh-tokens",
        "merchant",

        {
          token: getCookie("refreshToken"),
        },
        axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/refresh-tokens`, {
          refreshToken: getCookie("refreshToken"),
        })
      );

      localStorage.setItem("Token", result.data.access.token, {
        path: "/",
        expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      });
      localStorage.setItem("refreshToken", result.data.refresh.token, {
        path: "/",
        expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      });
      window.location.reload();
    } catch (e) {
      localStorage.removeItem("Token", {
        path: "/",
      });
      localStorage.removeItem("refreshToken", {
        path: "/",
      });
      window.location.reload();
    }
  }
};

// merchant
export const postTicketCreate = async (data) =>
  await frontReqLog(
    "ticketCreate",
    data.merchantId,
    data,
    axios.post(`${API_URL}/ticket/ticketCreate`, data, config)
  );

export const getTicketList = async (merchantId) =>
  axios.get(`${API_URL}/ticket/ticketList/${merchantId}`);

// auth
export const msgSend = async (data) =>
  await frontReqLog(
    "backUserCheck",
    "letmeup",
    {
      phoneNum: data,
    },
    axios.post(`${API_URL}/auth/backUserCheck`, {
      phoneNum: data,
    })
  );

export const msgAuth = async (number, cert) =>
  await frontReqLog(
    "backUserMsgAuth",
    "letmeup",
    {
      phoneNum: number,
      certNumber: cert,
    },
    axios.post(`${API_URL}/auth/backUserMsgAuth`, {
      phoneNum: number,
      certNumber: cert,
    })
  );

//안쓰는 api
export const getMerchantList = async () =>
  axios.get(`${API_URL}/user/merchantList`);

export const signUpSite = async (data) =>
  await frontReqLog(
    "signupBack",
    "letmeup",
    data,
    axios.post(`${API_URL}/user/signupBack`, data, config)
  );

export const signUp = async (data) =>
  await frontReqLog(
    "signup",
    data.merchantId,

    data,
    axios.post(`${API_URL}/user/signup`, data)
  );
// Ticket
export const ticketUpdate = async (data) =>
  await frontReqLog(
    "ticketUpdate",
    data[0].merchantId,

    data,
    axios.put(`${API_URL}/ticket/ticketUpdate`, data, config)
  );

export const ticketDelete = async (id, merchantId, type) =>
  await frontReqLog(
    "ticketDelete",
    merchantId,
    { id, merchantId, type },
    axios.delete(
      `${API_URL}/ticket/ticketDelete/${id}/${merchantId}/${type}`,
      config
    )
  );

export const getUserTicketList = async (
  merchantId,
  start,
  end,
  page,
  limit,
  search
) =>
  axios.get(
    `${API_URL}/ticket/userTicketList/${merchantId}/${start}/${end}/${page}/${limit}/${search}`,
    config
  );

export const ticketExit = async (data) =>
  await frontReqLog(
    "ticketExit",
    data.merchantId,
    data,
    axios.put(`${API_URL}/ticket/ticketExit`, data, config)
  );

export const ticketUsing = async (userId, merchantId) =>
  axios.get(`${API_URL}/ticket/ticketUsing/${userId}/${merchantId}`);

export const allTicketList = async (merchantId, start, end) =>
  axios.get(
    `${API_URL}/ticket/allTicketList/${merchantId}/${start}/${end}`,
    config
  );

//관리자 강제 퇴장

export const ticketForceExit = async (data) =>
  await frontReqLog(
    "ticketForceExit",
    data.merchantId,
    data,
    axios.put(`${API_URL}/ticket/ticketForceExit`, data, config)
  );

export const userTicketEnroll = async (data) =>
  await frontReqLog(
    "userTicketEnroll",
    data.merchantId,
    data,
    axios.post(`${API_URL}/ticket/userTicketEnroll`, data, config)
  );
//안쓰임
export const ticketEnroll = async (data) =>
  await frontReqLog(
    "ticketEnroll",
    data.merchantId,
    data,
    axios.post(`${API_URL}/ticket/ticketEnroll`, data, config)
  );
// Seat
export const getSeatList = async (merchantId) =>
  axios.get(`${API_URL}/seat/seatListBack/${merchantId}/seat`, config);

export const getLockerInfoList = async (merchantId) =>
  axios.get(`${API_URL}/seat/seatListBack/${merchantId}/locker`, config);
export const getSeatLogList = async (merchantId, start, end) =>
  axios.get(`${API_URL}/seatlogList/${merchantId}/${start}/${end}`, config);

export const changeSeat = async (data) =>
  await frontReqLog(
    "seatMove",
    data.merchantId,
    data,
    axios.put(`${API_URL}/seat/seatMove`, data)
  );

export const postCreateSeat = async (data) =>
  await frontReqLog(
    "seatManage",
    data.merchantId,
    data,
    axios.post(`${API_URL}/seat/seatManage`, data, config)
  );

export const deleteSeat = async (id, merchantId) =>
  await frontReqLog(
    "seatManage",
    merchantId,
    { id, merchantId },
    axios.delete(`${API_URL}/seat/seatDelete/${id}/${merchantId}`, config)
  );

export const getLockerList = async (merchantId) =>
  axios.get(`${API_URL}/seat/seatList/${merchantId}/locker`);

export const getMapViewList = async (merchantId) =>
  axios.get(`${API_URL}/seat/seatMapView/${merchantId}`, config);

// Sales
export const getPaylogList = async (
  merchantId,
  start,
  end,
  page,
  limit,
  search
) =>
  axios.get(
    `${API_URL}/paylogList/${merchantId}/${start}/${end}/${page}/${limit}/${search}`,
    config
  );

// 결제로그 불러오기(마일리지 적립 및 차감 포함)
export const PaylogDataList = async (merchantId, start, end, page, search) =>
  axios.get(
    `${API_URL}/paylogDataList/${merchantId}?pageNum=${page}&start=${start}&end=${end}&phoneNum=${search}`,
    config
  );

export const getPayAllList = async (merchantId, start, end) =>
  axios.get(`${API_URL}/paylogAllList/${merchantId}/${start}/${end}`, config);

export const getSeatAllList = async (merchantId, start, end) =>
  axios.get(`${API_URL}/seatlogAllList/${merchantId}/${start}/${end}`, config);

// Message
export const postSendOneMessage = async (info) =>
  await frontReqLog(
    "sendOneMessage",
    info.mId,
    { info },
    axios.post(`${API_URL}/sendOneMessage`, info, config)
  );

export const postSendManyMessage = async (info) =>
  await frontReqLog(
    "sendManyMessage",
    info.mId,
    { info },
    axios.post(`${API_URL}/sendManyMessage`, info, config)
  );

export const getMessageLog = async (
  merchantId,
  start,
  end,
  page,
  limit,
  search
) =>
  axios.get(
    `${API_URL}/msgLog/${merchantId}/${start}/${end}/${page}/${limit}/${search}`,
    config
  );

// Setting
export const getMerchantInfo = async (merchantId) =>
  axios.get(`${API_URL}/user/merchantInfo/${merchantId}`, config);

export const putSetting = async (info) =>
  await frontReqLog(
    "merchantStaticUpdate",
    info?.merchantId,
    info,
    axios.put(`${API_URL}/user/merchantStaticUpdate`, info, config)
  );

export const putMerchantSetting = async (info) =>
  await frontReqLog(
    "merchantKioskUpdate",
    info?.merchantId,
    info,
    axios.put(`${API_URL}/user/merchantKioskUpdate`, info, config)
  );
export const putPinUpdate = async (info) =>
  await frontReqLog(
    "merchantPinUpdate",
    info?.merchant_uuid,
    "",
    axios.put(`${API_URL}/user/merchantPinUpdate`, info, config)
  );

// user
export const userList = async (merchantId, phoneNum) =>
  axios.get(
    `${API_URL}/user/merchantUserinfo/${merchantId}/${phoneNum}`,
    config
  );

export const userLog = async (
  merchantId,
  start,
  end,
  page,
  limit,
  search,
  count
) =>
  axios.get(
    `${API_URL}/userLog/${merchantId}/${start}/${end}/${page}/${limit}/${search}/?count=${count}`,
    config
  );

export const userTicketAccess = async (data) =>
  await frontReqLog(
    "userTicketAccess",
    data?.merchantId,
    data,
    axios.put(`${API_URL}/user/userTicketAccess`, data, config)
  );

export const TicketOverTime = async (data) =>
  await frontReqLog(
    "ticketOverTimeMg",
    data?.merchantId,
    data,
    axios.put(`${API_URL}/ticket/ticketOverTimeMg`, data, config)
  );

export const userTicketUpdate = async (data) =>
  await frontReqLog(
    "userTicketUpdate",
    data?.merchantId,
    data,
    axios.put(`${API_URL}/ticket/userTicketUpdate`, data, config)
  );

export const userTicketBlock = async (data) =>
  await frontReqLog(
    "userTicketBlock",
    data?.merchantId,
    data,
    axios.put(`${API_URL}/ticket/userTicketBlock`, data, config)
  );

export const userBlock = async (data) =>
  await frontReqLog(
    "userBlock",
    data?.merchantId,
    data,
    axios.put(`${API_URL}/user/userBlock`, data, config)
  );

export const userInfoUpdate = async (data) =>
  await frontReqLog(
    "userInfoUpdate",
    data?.merchantId || data?.merchantIdChange,
    data,
    axios.put(`${API_URL}/user/userInfoUpdate`, data, config)
  );

//seminar

export const getSeminarInfoList = async (merchantId) =>
  axios.get(`${API_URL}/seat/seatListBack/${merchantId}/seminar`, config);

export const getSeminarItemList = async (merchantId, start, end) =>
  axios.get(
    `${API_URL}/ticket/seminarList/${merchantId}/${start}/${end}`,
    config
  );

//mileage
export const getMileageLog = async (
  merchantId,
  start,
  end,
  page,
  limit,
  search
) =>
  axios.get(
    `${API_URL}/mileageLogBack/${merchantId}/${start}/${end}/${page}/${limit}/${search}`,
    config
  );
export const postMileageAdd = async (data) =>
  await frontReqLog(
    "mileageAdd",
    data?.merchantId,
    data,
    axios.post(`${API_URL}/mileageAdd`, data)
  );

//coupon

export const couponEnroll = async (merchantId, data) =>
  await frontReqLog(
    "createCoupon",
    merchantId,
    data,
    axios.post(`${API_URL}/coupon/createCoupon/${merchantId}`, data, config)
  );

export const getCouponData = async (
  merchantId,
  start,
  end,
  phoneNum,
  page,
  limit,
  isUsed
) =>
  axios.get(
    `${API_URL}/coupon/showCoupons/${merchantId}/?createdEnd=${end}&createdStart=${start}&phoneNum=${phoneNum}&isUsed=${isUsed}&page=${page}&limit=${limit}`,
    config
  );

export const getCouponIssuedData = async (
  merchantId,
  start,
  end,
  phoneNum,
  page,
  limit
) =>
  axios.get(
    `${API_URL}/coupon/showCoupons/${merchantId}/?createdEnd=${end}&createdStart=${start}&phoneNum=${phoneNum}&page=${page}&limit=${limit}`,
    config
  );

export const deleteCoupon = async (merchantId, couponId) =>
  await frontReqLog(
    "deleteCoupon",
    merchantId,
    { couponId },
    axios.delete(
      `${API_URL}/coupon/deleteCoupon/${merchantId}/${couponId}`,
      config
    )
  );
//vending:자판기
export const getVendingItemList = async (merchantId) =>
  axios.get(`${API_URL}/vending/vendingItemList/${merchantId}`);

export const updateVendingItemList = async (data) =>
  await frontReqLog(
    "vendingItemUpdate",
    data?.merchantId,
    data,
    axios.put(`${API_URL}/vending/vendingItemUpdate`, data, config)
  );

export const vendingItemDelete = async (merchantId, id) =>
  await frontReqLog(
    "vendingItemDelete",
    merchantId,
    { deletevendingId: id },
    axios.delete(
      `${API_URL}/vending/vendingItemDelete/${merchantId}/${id}`,
      config
    )
  );
export const vendingItemEnroll = async (data) =>
  await frontReqLog(
    "vendingItemEnroll",
    data?.merchantId,
    data,
    axios.post(`${API_URL}/vending/vendingItemEnroll/`, data, config)
  );
//최고관리자
export const merchantLogin = async (data) =>
  await frontReqLog(
    "merchantLogin",
    data.merchantId,
    data,
    axios.post(`${API_URL}/v1/auth/merchantLogin`, data, config2)
  );

export const merchantCreate = (data) =>
  axios.post(`${API_URL}/admin/merchantCreate`, data, config2); // 사용x

export const franchiseCalculate = async (start, end) =>
  axios.get(
    `https://api-franchisefee.letmeup.co.kr/franchiseCalculate/${start}/${end}`,
    config2
  );

export const merchantMsgAdd = async (data) =>
  await frontReqLog(
    "merchantMsgAdd",
    data.merchantId,
    data,
    axios.post(`${API_URL}/admin/merchantMsgAdd`, data, config2)
  );
export const getAdminMerchantList = async () =>
  axios.get(`${API_URL}/admin/merchantListAdmin`, config2);

//payLog
export const postPayLog = async (info) =>
  await frontReqLog(
    "paylog",
    info.merchantId,
    info,
    axios.post(`${API_URL}/paylog`, info)
  );

export const putRefundLog = async (data) =>
  await frontReqLog(
    "refundlog",
    data.merchantId,
    data,
    axios.put(`${API_URL}/refundlog `, data)
  );

//password변경
export const userPassReset = async (data) => {
  if (getCookie("adminToken")) {
    const result = await frontReqLog(
      "userPassReset",
      data.merchantId || "letmeup",
      data,
      axios.put(`${API_URL}/auth/userPassReset`, data, config2)
    );
    return result;
  } else {
    const result = await frontReqLog(
      "userPassReset",
      data.merchantId || "letmeup",
      data,
      axios.put(`${API_URL}/auth/userPassReset`, data)
    );
    return result;
  }
};
// memo
export const createMemo = async (data) =>
  await frontReqLog(
    "memoCreate",
    data.merchantId,
    data,
    axios.post(`${API_URL}/memo/memoCreate`, data, config)
  );

export const getMemo = async (merchantId, phoneNum) =>
  await frontReqLog(
    "memoList",
    merchantId,
    { phoneNum },
    axios.get(`${API_URL}/memo/memoList/${merchantId}/${phoneNum}`, config)
  );

export const deleteMemo = async (merchantId, phoneNum) =>
  await frontReqLog(
    "memoDel",
    merchantId,
    { phoneNum },
    axios.delete(`${API_URL}/memo/memoDel/${merchantId}/${phoneNum}`, config)
  );

// 사용자 api
export const getUserData = async () => {
  try {
    const { data } = await merchantId_api();
    const merchantId = data.id;
    const res = await frontReqLog(
      "userInfo",
      merchantId,
      "",
      axios.post(
        `${API_URL}/user/userInfo`,
        { merchantId, token: getCookie("Token") },
        config
      )
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getSaleData = async (merchantId) =>
  axios.get(`${API_URL}/user/merchantInfoUser/${merchantId}`, config);

export const getWebPayRequest = async (data) =>
  await frontReqLog(
    "webpayRequest",
    data.merchantId,
    data,
    axios.post(`${API_URL}/webPayment/webpayRequest`, data, config)
  );

export const getUserQrCode = async (phoneNum, merchantId) =>
  axios.get(`${API_URL}/user/userQrcode/${phoneNum}/${merchantId}`, config);

//입퇴장로그(비상출입)
export const EntryExitLog = async (mid, start, end) =>
  axios.get(`${API_URL}/EntryExitLog/${mid}/${start}/${end}`, config);

//백오피스 결제 취소
export const webpayRefund = async (data) =>
  await frontReqLog(
    "webpayRefund",
    data.merchantId,
    data,
    axios.post(`${API_URL}/webPayment/webpayRefund`, data, config)
  );
//백오피스 비밀번호 reset
export const backUserCheckReset = async (data) =>
  await frontReqLog(
    "backUserCheckReset",
    "letmeup",
    data,
    axios.post(`${API_URL}/auth/backUserCheckReset`, data)
  );

//백오피스 비밀번호 reset 메세지 인증
export const backUserMsgAuth = async (data) =>
  await frontReqLog(
    "backUserCheckResetAuth",
    "letmeup",
    data,
    axios.post(`${API_URL}/auth/backUserCheckResetAuth`, data)
  );
export const getUserMerchantList = async (merchantId, search) =>
  axios.get(`${API_URL}/user/userMerchantList/${merchantId}/${search}`, config);

// health-check

export const getHealthCheck = async () => axios.get(`${API_URL}/health-check`);
export const getHealthCheckDB = async () =>
  axios.get(`${API_URL}/health-check-db`);
export const getHealthCheckSocket = async () =>
  axios.get(`${SOCKET_URL}/health-check-socket`);

export const getHealthCheckAlertBot = async () =>
  axios.get(`${API_URL_BOT_ALERT}/health-check-bot-alert`);

export const getHealthCheckExitBot = async () =>
  axios.get(`${API_URL_BOT_EXIT}/health-check-bot-exit`);

// image
export const postBannerImgAdmin = async (data) =>
  await frontReqLog(
    "image-banners-admin",
    "letmeup",
    data,
    axios.post(`${API_IMG_URL}/image/banners/admin`, data, config2)
  );
export const postAdImgsAdmin = async (data) =>
  await frontReqLog(
    "image-ads-admin",
    "letmeup",
    data,
    axios.post(`${API_IMG_URL}/image/ads/admin`, data, config2)
  );

export const postBannerImg = async (data) =>
  await frontReqLog(
    "image-banners-merchant",
    "letmeup",
    data,
    axios.post(`${API_IMG_URL}/image/banners/merchant`, data, config)
  );
export const postAdImgs = async (data) =>
  await frontReqLog(
    "image-ads-merchant",
    "letmeup",
    data,
    axios.post(`${API_IMG_URL}/image/ads/merchant`, data, config)
  );

export const getImages = async (merchantId) =>
  axios.get(`${API_IMG_URL}/image/merchant/${merchantId}`, config);

export const getImagesAdmin = async () =>
  axios.get(`${API_IMG_URL}/image/admin`, config2);

export const deleteImage = async (merchantId, target) =>
  await frontReqLog(
    "image-ads",
    merchantId,
    target,
    axios.delete(
      `${API_IMG_URL}/image/ads/${merchantId}/${target}`,
      merchantId === null ? config2 : config
    )
  );

//공지사항
// 1. 생성
export const announcementCreate = async (data) =>
  await frontReqLog(
    "announcementCreate",
    "letmeup",
    data,
    axios.post(`${API_URL}/announcement/announcementCreate`, data, config2)
  );
// 2. 출력
export const announcementRead = async (data) =>
  axios.get(
    `${API_URL}/announcement/announcementRead`,
    data === "admin" ? config2 : config
  );
// 3. 삭제
export const announcementDelete = async (id) =>
  await frontReqLog(
    "announcementDelete",
    "letmeup",
    "",
    axios.delete(`${API_URL}/announcement/announcementDelete/${id}`, config2)
  ); // 4. 수정
export const announcementUpdate = async (data) =>
  await frontReqLog(
    "announcementUpdate",
    "letmeup",
    data,
    axios.put(`${API_URL}/announcement/announcementUpdate`, data, config2)
  );
//이용약관
export const getTerms = async () =>
  axios.get(` https://kiosk-api-server.letmeup.co.kr/kioskTerms/0`);

//메세지 아이템 추가
export const getMsgItemList = async () =>
  axios.get(`${API_URL}/getMsgItemList`);

// 클라이언트키 가져오기
export const msgPointWebpay = async (data) =>
  axios.post(`${API_URL}/webPayment/msgPointWebpay`, data, config);

// 메세지 충전 로그 가져오기
export const getMsgChargeLog = async (startDate, endDate) =>
  axios.get(
    `${API_URL}/getMsgChargeLog?start=${startDate}&end=${endDate}`,
    config2
  );

export const postMerchantCoupon = async (data) =>
  await frontReqLog(
    "merchantCoupon",
    "letmeup",
    data,
    axios.post(`${API_URL}/admin/merchantCoupon`, data, config2)
  );

//메세지 보내기(여러명)
export const postSendEveryMessage = async (info) =>
  await frontReqLog(
    "sendEveryMessage",
    info.mId,
    { info },
    axios.post(`${API_URL}/sendEveryMessage`, info, config)
  );

//지점 프랜차이즈 정산
export const franchiseCalculateFunc = async (start, end) =>
  await frontReqLog(
    "franchiseCalculate",
    "admin",
    "",
    axios.get(
      `https://api-franchisefee.letmeup.co.kr/franchiseCalculateMail/${start}/${end}`,
      config2
    )
  );

export const franchiseCalculateMailSend = async (data) =>
  await frontReqLog(
    "franchiseCalculateMailSend",
    "admin",
    "",
    axios.post(
      `https://api-franchisefee.letmeup.co.kr/franchiseCalculateMailSend`,
      data,
      config2
    )
  );

// 지점 간단 매출 현황
export const paySimpleLogList = async (merchantId) =>
  axios.get(`${API_URL}/paySimplelogList/${merchantId}`, config);

//hecto 결제시 데이터 가져오는 api(샘랩-메세지)
export const msgPointWebpayData = async (data) =>
  await frontReqLog(
    "msgPointWebpayData",
    data.merchantId,
    "",
    axios.post(`${API_URL}/webPayment/msgPointWebpayData`, data, config)
  );

//환불 test용 api
export const msgPointWebpayRefund = async (data) =>
  axios.post(`${API_URL}/webPayment/msgPointWebpayRefund`, data, config2);

//결제 데이터
export const webpayData = async (data) =>
  await frontReqLog(
    "webpayData",
    data.merchantId,
    "",
    axios.post(`${API_URL}/webPayment/webpayData`, data, config)
  );

// ticket 현황및 결제 금액 가져오기
export const ticketSales = async (merchantId, startDate, endDate) =>
  axios.get(
    `${API_URL}/ticketSales/${merchantId}/${startDate}/${endDate}`,
    config
  );

// ticket 현황및 결제 금액 가져오기
export const ticketSalesAmount = async (merchantId, startDate, endDate) =>
  axios.get(
    `${API_URL}/ticketSalesAmount/${merchantId}/${startDate}/${endDate}`,
    config
  );
