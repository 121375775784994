import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { announcementRead } from "../../../lib/api";
import { DashboardCard } from "../../commonStyle";
import NoticeModal from "../../modal/noticeModal";
import PaginationComponent from "../../pagination";

const NoticeContainer = styled(DashboardCard)`
  p:first-child {
    margin-bottom: 0;
    padding-bottom: 0.62vw;
    font-weight: 700;
    line-height: 1.5;
    font-size: var(--dashboard-main);
    width: 100%;
    border-bottom: 2px solid #dde4eb;
  }
  .tableDiv {
    min-height: 17.9vw;
    max-height: 17.9vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (max-width: 805px) {
    p:first-child {
      font-size: 5.42vw;
    }
    .tableDiv {
      min-height: 93.27vw;
      max-height: 93.27vw;
    }
  }
`;

const NoticeTable = styled.table`
  color: #686c71;
  font-size: 0.83vw;

  th {
    text-align: center;
    color: #3e4144;
    font-weight: 600;
    padding: 0.62vw 1.04vw;
    &:nth-child(2) {
      padding: 0.62vw;
    }
    &:last-child {
      padding: 0.62vw 1.04vw 0.62vw 0;
    }
  }
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    &:first-child {
      max-width: 3.12vw;
      padding: 0.62vw 1.04vw;
      border-bottom-left-radius: 100vw;
      border-top-left-radius: 100vw;
    }
    &:nth-child(2) {
      max-width: 12.23vw;
      padding: 0.62vw;
    }
    &:last-child {
      max-width: 6.25vw;
      padding: 0.62vw 1.04vw 0.62vw 0;
      border-bottom-right-radius: 100vw;
      border-top-right-radius: 100vw;
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:nth-child(2n-1) {
        background-color: #f1f3f6;
      }
      &:hover {
        background-color: #dde4eb;
      }
    }
  }

  @media screen and (max-width: 805px) {
    font-size: 4.44vw;
    th {
      padding: 3.33vw 0 3.33vw 0;
      &:nth-child(2) {
        padding: 0 5.55vw 0 5.55vw;
      }
    }
    td {
      &:first-child {
        padding: 3.33vw 0 3.33vw 0;
      }
      &:nth-child(2) {
        padding: 0 5.55vw 0 5.55vw;
      }
    }
  }
`;

const NoticeListTable = ({ isMobile }) => {
  const [isShowText, setIsShowText] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const [merchantData, setMerchantData] = useState({});

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const getNoticeData = async () => {
    let getData = await announcementRead();

    let reverseData = getData.data.reverse();

    setNoticeList(
      reverseData,
      reverseData.map((item, index) => {
        item.create = moment(item.createdAt).format("YYYY-MM-DD");
        item.titleData = <span>{item.title}</span>;
      })
    );
  };

  const showData = (item) => {
    setIsShowText(true);
    setDetailData(item);
  };

  const closeData = () => {
    setIsShowText(false);
  };

  useEffect(() => {
    getNoticeData();
  }, []);
  return (
    <>
      {isShowText && (
        <NoticeModal
          show={showData}
          onClose={closeData}
          selected={detailData}
        />
      )}
      <Fragment>
        <NoticeContainer>
          <p>공지사항</p>

          <div className="tableDiv">
            <NoticeTable>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>제목</th>
                  {!isMobile && <th>일자</th>}
                </tr>
              </thead>
              <tbody>
                {currentPosts(noticeList).map((item) => (
                  <tr onClick={() => showData(item)}>
                    <td>{item.id}</td>
                    <td>{item.titleData}</td>
                    {!isMobile && <td>{item.create}</td>}
                  </tr>
                ))}
              </tbody>
            </NoticeTable>

            {noticeList.length > 0 && (
              <PaginationComponent
                setCurrentPage={setCurrentPage}
                totalLength={noticeList.length}
                postsPerPage={postsPerPage}
                currentPage={currentPage}
                padding={0}
              />
            )}
          </div>
        </NoticeContainer>
      </Fragment>
    </>
  );
};

export default NoticeListTable;
