import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import configDB from "../../data/customizer/config";
import { MainTextContainer } from "../../pages/noticeBoard";
import { ModalBackground } from "./choiceModal";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const NoticeModal = ({ selected, onClose, show }) => {
  const [textData, setTextData] = useState(
    selected.text
      ? selected.text
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
          .replace(/&#39;/g, "'")
      : selected.text
  );

  return (
    <>
      <Modal
        style={{ maxWidth: window.innerWidth < 480 ? "100%" : "65%" }}
        centered
        isOpen={show}
        toggle={onClose}
      >
        <ModalHeader className="modal-title" toggle={onClose}>
          공지사항
        </ModalHeader>
        <ModalBody>
          <MainTextContainer className="mainText">
            <div className="title">
              <p>{selected.title}</p>
              <p>
                <span>작성일 | {selected.create}</span>
                <span>작성자 | 샘랩</span>
              </p>
            </div>
            <div
              style={{
                minHeight: "400px",
                overflow: "scroll",
                maxHeight: "400px",
              }}
            >
              {selected.text && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: textData,
                  }}
                ></div>
              )}
            </div>
          </MainTextContainer>
          <div className="btns" style={{ float: "right", marginRight: "30px" }}>
            <button
              className="btn-success btn-sm"
              onClick={() => {
                onClose();
              }}
            >
              닫기
            </button>
          </div>
        </ModalBody>
      </Modal>

      <ModalBackground />
    </>
  );
};
export default NoticeModal;
