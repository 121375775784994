import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
} from "reactstrap";
import styled from "styled-components";
import {
  deleteSeat,
  getLockerList,
  getMerchantInfo,
  merchantId_api,
  postCreateSeat,
} from "../lib/api";
import "moment/locale/ko";
import Swal from "sweetalert2";
import configDB from "../data/customizer/config";
import { TabButton, ToggleBtn } from "./seatList";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const CardContainer = styled.div`
  .cardBody {
    display: flex;
  }
  .date-pickers {
    display: flex;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      i {
        position: absolute;
        top: 0.8vw;
        right: 0.5vw;
      }
      input {
        width: 100%;
      }
    }
  }
  .floorBtn {
    display: "flex";
    flex-direction: "column";
  }
  @media screen and (max-width: 768px) {
    .floorBtn {
      flex-direction: row;
    }
  }
  @media screen and (max-width: 480px) {
    .topbar {
      align-items: baseline;
      flex-direction: column;
      .m-0 {
        margin: 3vw 0 !important;
      }
    }
    button {
      margin: 1vw 0 5vw;
    }
    input {
      width: 80% !important;
    }
    .cardBody {
      flex-direction: column;
    }
  }
`;

export const LockerView = styled.div`
  width: 981px;
  width: 100%;
  height: 60vh;
  border: 3px solid #222;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.6vw;
    height: 0.6vw;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4cbd6;
    border-radius: 1vw;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 1vw;
  }
`;
export const LockersArray = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const LockerS = styled.div`
  width: 71px;
  height: 71px;
  background-color: ${(props) => (props.isUse ? "#888EA5" : "#fff")};
  border: 1px solid #546382;
  color: ${(props) => (props.isUse ? "#ccc" : "#222")};
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  font-size: 2.3vw;
  font-weight: 600;
  top: ${(props) => `${props.top}vw`};
  left: ${(props) => `${props.left}vw`};
  padding: 5px;

  p {
    margin-bottom: 0;
    /* text-align: center; */
    font-size: 11px;
    line-height: 1.2;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  border: none;
  background-color: ${(props) => props.color};
  border: 1px solid
    ${(props) => (props.color === "#fff" ? "#ccc" : props.color)};
  color: ${(props) => (props.color === "#fff" ? "#222" : "#fff")};
`;

export const Btns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 12%;
  margin-left: 0.6vw;

  > button {
    background-color: ${primary};

    :last-child {
      background-color: #f53c14;
      margin-top: 0.6vw;
    }
  }
  @media screen and (max-width: 768px) {
    width: 20%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: row;
    width: 100%;
    margin-left: 0vw;
    > button {
      :last-child {
        margin-top: 1vw;
      }
    }
  }
`;

export const AddBtn = styled(Button)`
  width: 12%;
  margin-right: 0.6vw;
  color: ${primary};
  border: 1px solid ${primary};
  background-color: transparent;
  :active,
  :hover {
    background-color: ${primary};
    color: #fff;
  }
  transition: all 0.1s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 20%;
  }
  @media screen and (max-width: 480px) {
    /* flex-direction: row; */
    font-size: 12px;
    width: 40%;
  }
`;
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  font-weight: 500;
  margin-bottom: 1vw;
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;
export const Label1 = styled.label`
  width: fit-content;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  h5 {
    width: fit-content;
    font-size: 14px;
    strong {
      color: red;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #555;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
  input,
  select {
    margin-top: 5px;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 6px 13px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    h5 {
      font-size: 13px;
    }
    input,
    select {
      padding: 5px 13px;
      font-size: 13px;
    }
  }
`;

let merchantId = "";

const LockerChange = ({ setIsLoading }) => {
  const [lists, setLists] = useState([]);
  const [tagModal, setTagModal] = useState(false);
  const [selected, setSelected] = useState();
  const [changeData, setChangeData] = useState([]);
  const [multiNum, setMultiNum] = useState(null);
  const [floorNum, setFloorNum] = useState(0);
  const [floorList, setFloorList] = useState([]);

  useEffect(() => {
    let getId = getMerchantId();
    return () => clearTimeout(getId);
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      const multiNumData = await getMerchantInfo(data.id);
      if (
        multiNumData &&
        multiNumData.status === 200 &&
        multiNumData.data[0].multiKioskFloor > 0
      ) {
        setMultiNum(multiNumData.data[0].multiKioskFloor);
        setFloorNum(1);
        getLockerListApi(data.id, 1);
      } else {
        getLockerListApi(data.id, null);
      }
    }
  };

  const getLockerListApi = async (merchantId, floorData) => {
    setIsLoading(true);
    if (floorNum && lists.length !== 0) {
      setFloorList(lists.filter((item) => item.floor === floorData));
    } else {
      const res = await getLockerList(merchantId);

      if (res.status === 200) {
        if (floorData) {
          setFloorList(res.data.filter((item) => item.floor === floorNum));
          setLists(res.data);
        } else {
          setLists(res.data);
        }
      }
    }
    setIsLoading(false);
  };

  const handleLockerOnClick = (locker) => {
    tagToggle();
    setSelected(locker);
  };
  const tagToggle = () => {
    setTagModal(!tagModal);
  };
  const handleError = (msg) => {
    Swal.fire({
      icon: "error",
      timer: 2000,
      title: "현재 사용중인 사물함은 변경이 불가능합니다.",
    });
  };
  const handleAddLocker = () => {
    const fakeId = new Date().getTime();

    const newObj = {
      fakeId,
      number: lists.length + 1,
      name: "",
      status: "idle",
      type: "locker",
      level: 1,
      pos_top: 0,
      pos_left: 0,
      id: 0,
      floor: floorNum,
      seatType: 0,
    };
    setFloorList([...floorList, newObj]);
    setLists([...lists, newObj]);
    setChangeData([...changeData, newObj]);
  };

  const handleOnchange = ({ target }) => {
    if (target.name === "number") {
      setSelected({ ...selected, [target.name]: +target.value });
    } else if (target.name === "status") {
      if (target.value === "idle") {
        setSelected({ ...selected, status: 1 });
      } else {
        setSelected({ ...selected, status: "idle" });
      }
    } else if (target.name === "floor") {
      setSelected({ ...selected, floor: +target.value });
    } else {
      setSelected({ ...selected, [target.name]: target.value });
    }
  };

  const lockerInfoChange = () => {
    let copyData = [...lists];

    let filterChangeData = copyData.map((item) =>
      item.id === selected.id && item?.fakeId === selected?.fakeId
        ? selected
        : item
    );
    if (floorNum) {
      let floorData = floorList
        .map((item) =>
          item.id === selected.id && item?.fakeId === selected?.fakeId
            ? selected
            : item
        )
        .filter((item) => item.floor === floorNum);
      setFloorList(floorData);
    }
    setLists(filterChangeData);

    if (!changeData.map((li) => li.id).includes(selected.id)) {
      setChangeData((pre) => pre.concat(selected));
    } else {
      setChangeData((pre) =>
        pre.map((li) =>
          li.id === selected.id && li?.fakeId === selected?.fakeId
            ? {
                ...li,
                fakeId: selected?.fakeId,
                number: selected.number,
                name: selected.name,
                status: selected.status,
                level: selected.level,
                seatType: selected.seatType,
                floor: selected.floor,
              }
            : { ...li }
        )
      );
    }
    tagToggle();
    setSelected();
  };

  const seatChangeCancel = () => {
    tagToggle();
    setSelected();
  };

  const seatDelete = () => {
    if (selected.id === 0) {
      let copyData = [...lists];
      let copyChangeData = [...changeData];
      let copyFloorData = [...floorList];
      let changeList = copyData.filter(
        (item) => item?.fakeId !== selected?.fakeId
      );
      let changeList1 = copyChangeData.filter(
        (item) => item?.fakeId !== selected?.fakeId
      );

      let changeList2 = copyFloorData.filter(
        (item) => item?.fakeId !== selected?.fakeId
      );
      setLists(changeList);
      setChangeData(changeList1);
      setFloorList(changeList2);
      tagToggle();
      setSelected();
    } else {
      Swal.fire({
        icon: "warning",
        title: "삭제 시 저장하지 않은 데이터는 사라집니다.",
        text: "그래도 지우시겠습니까?",
        confirmButtonColor: "#F53C14",
        cancelButtonColor: "#04c9db",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = await deleteSeat(selected.id, selected.merchantId);

          if (data.status === 200) {
            Swal.fire({
              icon: "success",
              title: "삭제가 완료되었습니다.",
              timer: 2000,
            }).then(() => {
              window.location.reload();
              tagToggle();
            });
          }
        }
      });
    }
  };

  const handleOnSubmit = async () => {
    let copyData = [...changeData];
    let filterArr = copyData.map((item) =>
      item.fakeId
        ? {
            number: item.number,
            name: item.name,
            status: item.status,
            type: "locker",
            level: item.level,
            pos_top: item.pos_top,
            pos_left: item.pos_left,
            id: item.id,
            seatType: item.seatType,
            status: item.status,
            floor: item.floor,
          }
        : item
    );
    const res = await postCreateSeat({ seat: filterArr, merchantId });
    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "변경이 완료됐습니다.",
        timer: 3000,
      }).then(() => window.location.reload());
    } else {
      Swal.fire({
        icon: "error",
        title: "변경에 실패했습니다. 다시 시도해주세요.",
        timer: 2000,
      });
    }
  };
  const handleReset = () => {
    Swal.fire({
      title: "저장하지 않은 데이터는 사라집니다.",
      text: "변경을 취소하시겠습니까?",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: "#04c9db",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(() => window.location.reload());
  };

  useEffect(() => {
    if (merchantId) {
      getLockerListApi(merchantId, floorNum);
    }
  }, [floorNum]);

  return (
    <Fragment>
      {tagModal && (
        <Modal centered isOpen={tagModal} toggle={tagToggle}>
          <ModalHeader className="modal-title" toggle={tagToggle}>
            사물함 정보 수정
          </ModalHeader>
          <ModalBody>
            <Label1>
              사물함 번호
              <input
                type="text"
                name="number"
                value={selected.number || 0}
                onChange={handleOnchange}
                placeholder="사물함 번호를 입력해주세요."
              />
            </Label1>
            <Label1>
              사물함 이름
              <input
                type="text"
                name="name"
                value={selected.name || ""}
                onChange={handleOnchange}
                placeholder="사물함 이름을 입력해주세요."
              />
            </Label1>
            {floorNum && (
              <Label1>
                층 설정
                <Input
                  type="text"
                  name="floor"
                  value={selected?.floor || ""}
                  onChange={handleOnchange}
                />
              </Label1>
            )}
            <ToggleContainer>
              사물함 사용불가 설정
              <ToggleBtn>
                <input
                  type="checkbox"
                  name="status"
                  onChange={handleOnchange}
                  value={selected.status || ""}
                  checked={
                    selected.status === 1 || selected?.status === "block"
                  }
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "10px",
              }}
            >
              <Button color="#fff" onClick={seatChangeCancel}>
                취소
              </Button>
              <Button color={primary} onClick={lockerInfoChange}>
                확인
              </Button>
              <Button color="#F53C14" onClick={seatDelete}>
                삭제
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <Breadcrumb parent="좌석 및 사물함" title="사물함 배치 변경" />
      <Container fluid={true}>
        <CardContainer>
          <Row>
            <Col sm="12" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <Card>
                <CardBody className="cardBody">
                  <div>
                    <AddBtn
                      style={{
                        width: window.innerWidth > 480 ? "80%" : "100%",
                      }}
                      onClick={handleAddLocker}
                    >
                      사물함 추가
                    </AddBtn>
                    {multiNum && (
                      <div className="floorBtn">
                        {[...Array(multiNum)].map((item, index) => (
                          <TabButton
                            selected={floorNum === index + 1}
                            onClick={() => setFloorNum(index + 1)}
                            value={index + 1}
                          >
                            {index + 1}층
                          </TabButton>
                        ))}
                      </div>
                    )}
                  </div>
                  <LockerView>
                    <LockersArray>
                      {(floorNum ? floorList : lists)?.map((seat, index) => (
                        <React.Fragment
                          key={seat.id === 0 ? seat?.fakeId : seat.id}
                        >
                          {(seat.status === "block" || seat.status === 1) && (
                            <LockerS
                              style={{
                                width: 69,
                                height: 69,
                                backgroundColor: "#979797",
                              }}
                              onClick={() => handleLockerOnClick(seat)}
                              isUse
                            >
                              <p>
                                {seat.number}.{seat.name}
                              </p>
                            </LockerS>
                          )}
                          {seat.status === "idle" && (
                            <LockerS
                              style={{ width: 69, height: 69 }}
                              onClick={() => handleLockerOnClick(seat)}
                            >
                              <p>
                                {seat.number}.{seat.name}
                              </p>
                            </LockerS>
                          )}
                          {seat.status === "using" && (
                            <LockerS
                              style={{ width: 69, height: 69 }}
                              onClick={handleError}
                              isUse
                            >
                              <p>
                                {seat.number}.{seat.name}
                              </p>
                            </LockerS>
                          )}
                        </React.Fragment>
                      ))}
                    </LockersArray>
                  </LockerView>
                  <Btns>
                    <Button onClick={handleOnSubmit}>변경하기</Button>
                    <Button onClick={handleReset}>취소하기</Button>
                  </Btns>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </Fragment>
  );
};

export default LockerChange;
