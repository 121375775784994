import React, { useEffect } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";

const MessageFail = () => {
  const handleFail = () => {
    Swal.fire({
      icon: "error",
      title: "결제가 실패했습니다.",
      text: "자동으로 메세지 충전 페이지로 이동됩니다.",
      timer: 2500,
      showCancelButton: false,
      showConfirmButton: false,
    }).then(() => {
      window.location.href = `${process.env.PUBLIC_URL}${"/message/charge"}`;
    });
  };

  useEffect(() => {
    handleFail();
  }, []);
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Card>
              <CardHeader>결제가 실패했습니다.</CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default MessageFail;
