import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { decryptFuncCode } from "../components/common";

const BarcodeStyle = styled.div`
  img {
    width: 50%;
  }
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageBarcode = () => {
  let { usercode } = useParams();
  const [barcodeData, setBarcode] = useState();

  const getCode = () => {
    let codedata = usercode;
    let replaceData = codedata.replace(/samlet/gi, "/");
    decrypt(replaceData);
  };

  const decrypt = (codedata) => {
    let data = decryptFuncCode(codedata);

    if (!data) {
      Swal.fire({
        text: "잘못된 접근입니다. 로그인 화면으로 이동합니다.",
        timer: 3000,
      }).then((window.location.href = `${process.env.PUBLIC_URL}/`));
    } else {
      setBarcode(data);
    }
  };

  useEffect(() => {
    getCode();
  }, []);
  return (
    <>
      {usercode && (
        <BarcodeStyle>
          <Barcode
            className="barcode"
            displayValue={false}
            width={1}
            fontSize={10}
            value={barcodeData}
          />
          <p>{barcodeData && barcodeData.replace('"', "").split("-")[0]}</p>
          <img
            alt="logo"
            src="https://user-images.githubusercontent.com/82793902/171102074-52e90618-4d38-4734-bd99-84f97a8c4a32.png"
          />
        </BarcodeStyle>
      )}
    </>
  );
};
export default PageBarcode;
