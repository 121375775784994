import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import "./index.scss";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes, termsRoute } from "./route";
import ConfigDB from "./data/customizer/config";
// Authentication
import Signin from "./auth/signin";
import SignUp from "./auth/signup";

import { Cookies, CookiesProvider } from "react-cookie";
import MerchantList from "./pages/merchantList";
import { levelDecode } from "./components/common";
import FindPassword from "./auth/password";
import ReactGA from "react-ga";
import PageBarcode from "./pages/barcode";
import { BackgroundLoading } from "./components/Loading";

const Root = (props) => {
  const [anim, setAnim] = useState("");
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();

  const cookies = new Cookies();
  const jwt_token = localStorage.getItem("Token");
  let level = levelDecode(localStorage.getItem("Token"));
  const TRACKING_ID = process.env.React_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    setAnim(animation);
    localStorage.setItem("isClick", false);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);
  //로딩componernt
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL}/auth/login`}
              component={Signin}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/auth/join`}
              component={SignUp}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/auth/password`}
              component={FindPassword}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/barcode/:usercode`}
              component={PageBarcode}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/merchant-list`}
              component={MerchantList}
            ></Route>

            {//이용약관 크롤링용
            termsRoute.map(({ path, Component }) => (
              <Route
                key={path}
                exact
                path={`${process.env.PUBLIC_URL}${path}`}
                component={Component}
              ></Route>
            ))}

            {jwt_token ? (
              <>
                <App isLoading={isLoading}>
                  {+level === 50 && (
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      render={() => {
                        return (
                          <Redirect
                            to={`${process.env.PUBLIC_URL}/admin/dashboard`}
                          />
                        );
                      }}
                    />
                  )}
                  {+level === 1 && (
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      render={() => {
                        return (
                          <Redirect
                            to={`${process.env.PUBLIC_URL}/dashboard`}
                          />
                        );
                      }}
                    />
                  )}

                  <TransitionGroup>
                    {routes.map(({ path, Component }) => (
                      <Route
                        key={path}
                        exact
                        path={`${process.env.PUBLIC_URL}${path}`}
                      >
                        {({ match }) => (
                          <CSSTransition
                            in={match != null}
                            timeout={100}
                            classNames={anim}
                            unmountOnExit
                          >
                            <div>
                              <Component setIsLoading={setIsLoading} />
                            </div>
                          </CSSTransition>
                        )}
                      </Route>
                    ))}
                  </TransitionGroup>
                </App>
              </>
            ) : (
              <Redirect to={`${process.env.PUBLIC_URL}/auth/login`} />
            )}
          </Switch>
        </BrowserRouter>
      </Provider>
    </Fragment>
  );
};
ReactDOM.render(
  <RecoilRoot>
    <CookiesProvider>
      <Root />
    </CookiesProvider>
  </RecoilRoot>,
  document.getElementById("root")
);

serviceWorker.unregister();
