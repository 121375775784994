// import { Chart } from "chart.js";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import IconUser from "../../../assets/images/dashboard/people.svg";
import { PieChart } from "react-minimal-pie-chart";
import {
  getLockerList,
  getMerchantInfo,
  getSeatList,
  merchantId_api,
} from "../../../lib/api";
import { numberWithCommas } from "../../common";
import { DashboardCard } from "../../commonStyle";
/** 좌석,사물함, 메세지 사용량 component */

const UserChartData = styled.div`
  display: flex;
  width: 100%;
  .titleImg {
    border: 1px solid #dde4eb;
    width: 4.16vw;
    height: 4.16vw;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    img {
      width: 2.08vw;
    }
  }
  .text {
    margin-left: 1vw;
    div {
      display: flex;
      align-items: center;
      > button {
        font-size: 0.62vw;
        line-height: 18px;
        color: #91979e;
        background: none;
        border: 1px solid #dde4eb;
        border-radius: 100px;
      }
      > p {
        margin-bottom: 0;
        font-weight: 700;
        line-height: 1.5;
        font-size: var(--dashboard-main);
      }
    }
    > p {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 1.5;

      font-size: var(--dashboard-count);
      color: ${(props) =>
        props.color === "red"
          ? " #D31F40"
          : props.color === "orange"
          ? "#D75E1B"
          : "var(--dashboard-default)"};
    }
  }

  @media screen and (max-width: 805px) {
    margin-bottom: 4.44vw;
    .titleImg {
      width: 22.22vw;
      height: 22.22vw;

      img {
        width: 11.11vw;
      }
    }
    .text {
      margin-left: 4.44vw;
      div {
        > button {
          font-size: 3.33vw;
        }
        > p {
          font-size: 5.55vw;
        }
      }
      > p {
        font-size: 8.88vw;
      }
    }
  }
`;

const StyleComponent = styled(DashboardCard)`
  display: flex;
  height: 10vw;
  justify-content: space-between;
  align-items: center;

  .totalText {
    display: flex;
    p {
      font-size: 0.83vw;
      margin-bottom: 0;
      &:first-child {
        font-weight: 600;
      }
    }
  }
  .chartData {
    display: flex;
    width: 50%;
    justify-content: space-between;
    > div {
      width: 3.33vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      > p {
        font-size: 0.83vw;
        font-weight: 600;
        margin-bottom: 0;
        color: var(--dashboard-default);
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  @media screen and (max-width: 805px) {
    height: fit-content;
    flex-direction: column;
    .flex {
      width: 100%;
    }
    .chartData {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 71.66vw;
      justify-items: center;
      margin-top: 11.11vw;
      grid-row-gap: 7.77vw;
      > div {
        width: 26.66vw;
        > p {
          font-size: 4.44vw;
          margin-top: 2.22vw;
        }
      }
    }
  }
`;

const LabelData = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.82vw;
  > div {
    display: flex;
    align-items: center;
    span {
      &:first-child {
        width: 36px;
        border-radius: 50px;
        height: 6px;
        margin-right: 0.41vw;
      }
      &:last-child {
        color: #686c71;
        font-size: 0.72vw;
        line-height: 1.5;
      }
    }
  }
  @media screen and (max-width: 805px) {
    width: fit-content;
    margin: 0 auto;
    grid-column-gap: 4.44vw;

    > div {
      span {
        &:first-child {
          width: 5.55vw;
        }
        &:last-child {
          font-size: 3.88vw;
          margin-left: 2.22vw;
        }
      }
    }
  }
`;
const TicketUserComponent = ({ isMobile }) => {
  const [seatLength, setSeatLength] = useState(0);
  const [usingData, setUsingData] = useState([]);
  const getMerchantId = async () => {
    const data = await merchantId_api();

    if (data.status === 200) {
      seatData(data.data.id);
    }
  };

  const seatData = async (e) => {
    let data = await getSeatList(e);
    if (data.status === 200) {
      let usingFilterData = data.data.filter((item) => item.isUsing === 1);
      setSeatLength(data.data.length);
      let usingSeatData = {
        oneTicket: 0,
        dayTicket: 0,
        timeTicket: 0,
      };
      for (let i = 0; i < usingFilterData.length; i++) {
        if (usingFilterData[i].ticketType === 1) {
          usingSeatData.oneTicket += 1;
        } else if (usingFilterData[i].ticketType === 2) {
          usingSeatData.dayTicket += 1;
        } else if (usingFilterData[i].ticketType === 3) {
          usingSeatData.timeTicket += 1;
        }
      }
      setUsingData(usingSeatData);
    }
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  return (
    <Fragment>
      <StyleComponent>
        <div className="flex">
          <UserChartData>
            <div className="titleImg">
              <img src={IconUser} alt="icon" />
            </div>
            <div className="text">
              <div>
                <p>입장권별 회원 수</p>
              </div>

              <p>
                {usingData.oneTicket +
                  usingData.dayTicket +
                  usingData.timeTicket || 0}
                명
              </p>
            </div>
          </UserChartData>
          <LabelData>
            <div>
              <span style={{ background: "#6ead69" }} /> <span>기간권</span>
            </div>
            <div>
              <span style={{ background: "#EC6E85" }} /> <span>일회권</span>
            </div>
            <div>
              <span style={{ background: "#8E78AA" }} />{" "}
              <span>시간 정액권</span>
            </div>
          </LabelData>
        </div>
        <div className="chartData">
          <div>
            <PieChart
              data={[
                {
                  title: "",
                  value:
                    Math.floor((usingData.dayTicket / seatLength) * 100) || 0,
                  color: "#6EAD69",
                },
              ]}
              reveal={Math.floor((usingData.dayTicket / seatLength) * 100) || 0}
              rounded
              animate
              startAngle={270}
              lineWidth={isMobile ? 26.66 : 20}
              background={"#F1F3F6"}
              label={({ dataEntry }) => dataEntry.value + "%"}
              labelStyle={{
                fontSize: isMobile ? "4.44vw" : "1.35vw",
                fill: "#2D7028",
                fontWeight: 600,
              }}
              labelPosition={0}
            />
            <p>{usingData.dayTicket || 0}명</p>
          </div>
          <div>
            <PieChart
              data={[
                {
                  title: "",
                  value:
                    Math.floor((usingData.timeTicket / seatLength) * 100) || 0,
                  color: "#8E78AA",
                },
              ]}
              reveal={
                Math.floor((usingData.timeTicket / seatLength) * 100) || 0
              }
              rounded
              animate
              startAngle={270}
              lineWidth={isMobile ? 26.66 : 20}
              background={"#F1F3F6"}
              label={({ dataEntry }) => dataEntry.value + "%"}
              labelStyle={{
                fontSize: isMobile ? "4.44vw" : "1.35vw",
                fill: "#543083",
                fontWeight: 600,
              }}
              labelPosition={0}
            />
            <p>{usingData.timeTicket || 0}명</p>
          </div>
          <div>
            <PieChart
              data={[
                {
                  title: "",
                  value:
                    Math.floor((usingData.oneTicket / seatLength) * 100) || 0,
                  color: "#EC6E85",
                },
              ]}
              reveal={Math.floor((usingData.oneTicket / seatLength) * 100) || 0}
              rounded
              animate
              startAngle={270}
              lineWidth={isMobile ? 26.66 : 20}
              background={"#F1F3F6"}
              label={({ dataEntry }) => dataEntry.value + "%"}
              labelStyle={{
                fontSize: isMobile ? "4.44vw" : "1.35vw",
                fill: "#D31F40",
                fontWeight: 600,
              }}
              labelPosition={0}
            />
            <p>{usingData.oneTicket || 0}명</p>
          </div>
        </div>
      </StyleComponent>
    </Fragment>
  );
};

export default TicketUserComponent;
