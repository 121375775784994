import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DatePickerStyle } from "../../pages/paylogChart";
import SelectDate from "../selectDate";
import DatePicker from "react-datepicker";
import { ExampleCustomInput } from "../../pages/ticketCustom";
import { ko } from "date-fns/esm/locale";
import { numberWithCommas } from "../common";
import PaginationComponent from "../pagination";
import { getMsgChargeLog, msgPointWebpayRefund } from "../../lib/api";
import moment from "moment";
import Swal from "sweetalert2";

const MessageChargeContainer = styled.div`
  border: 1px solid #e1e1e1;
  padding: 2vw 1vw;
  table {
    width: 80%;
    margin: 2vw auto;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    tr {
      border-bottom: 1px solid #e0e0e0;
    }
    tr:nth-last-child(1) {
      border-bottom: none;
    }
    td,
    th {
      padding: 1vw 2vw;
    }
    th {
      font-size: 13px;
      font-weight: 600;
    }

    td {
      font-size: 13px;
      font-weight: 400;
    }
  }

  .topbar .selectbox {
    width: inherit;
  }
`;

const MessageChargeInfo = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [infoData, setInfoData] = useState([]);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const getChargeLog = async () => {
    const start = moment(startDate).format("YYYY-MM-DD 00:00:00");
    const end = moment(endDate).format("YYYY-MM-DD 23:59:59");
    try {
      const logData = await getMsgChargeLog(start, end);
      if (logData.status === 200) {
        const messageLogData = [
          logData.data,
          logData.data.map((item, idx) => {
            // 충전일시
            item.chargeDate = moment(item.createdAt).format(
              "YYYY.MM.DD hh:mm:ss"
            );
            //충전포인트
            item.chargePoint = item.message.split(" ")[2].replace("충전", "");
            //충전방식
            item.chargeType = item.subtype.includes("merchant")
              ? "지점 결제"
              : "관리자 추가";
            // 충전지점
            //환불버튼(test 용 버튼임. 나중에 지워질 예정)
            item.refundBtn = (
              <button onClick={() => testRefund(item.id)}>test</button>
            );
          }),
        ];

        setInfoData(messageLogData[0]);
      } else {
        Swal.fire({
          text: "충전 내역을 불러오는데 실패하였습니다. 다시 시도해주세요",
          timer: 3500,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        text: "충전 내역을 불러오는데 실패하였습니다. 다시 시도해주세요",
        timer: 3500,
      });
    }
  };

  const testRefund = async (id) => {
    const refundApi = await msgPointWebpayRefund({
      transactionId: 1166367,
      merchantId: 2,
    });
    console.log(refundApi);
  };
  // tableHeader
  const tableHeader = [
    // "구분",
    "충전포인트",
    "결제타입",
    "결제일시",
    "충전지점",
    "test 버튼",
  ];

  useEffect(() => {
    getChargeLog();
  }, [startDate, endDate]);

  return (
    <MessageChargeContainer>
      <div className="topbar">
        <DatePickerStyle className="selectbox">
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            selectsStart
            locale={ko}
            startDate={startDate}
            maxDate={endDate}
            endDate={endDate}
            customInput={<ExampleCustomInput />}
          />
          <h5> - </h5>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            locale={ko}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            customInput={<ExampleCustomInput />}
          />
          <SelectDate
            setStartDate={(val) => setStartDate(val)}
            setEndDate={(val) => setEndDate(val)}
          />
        </DatePickerStyle>
      </div>
      <table>
        <tr>
          {tableHeader &&
            tableHeader.map((item, index) => (
              <th className="headerItem" key={"hea" + index}>
                {item}
              </th>
            ))}
        </tr>
        {infoData &&
          currentPosts(infoData).map((info, index) => (
            <tr key={"infoTable" + index}>
              <td>{numberWithCommas(info.chargePoint)}p</td>
              <td>{info.chargeType}</td>

              <td>{moment(info.chargeDate).format("YYYY-MM-DD HH:mm:ss")}</td>
              <td>{info.merchantId}</td>
              <td>{info.refundBtn}</td>
            </tr>
          ))}
      </table>
      <PaginationComponent
        setCurrentPage={setCurrentPage}
        totalLength={infoData.length}
        postsPerPage={postsPerPage}
        currentPage={currentPage}
        padding={0}
      />
    </MessageChargeContainer>
  );
};
export default MessageChargeInfo;
