import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { CheckBoxRound } from "../auth/signup";

import {
  getUUID,
  HectoPay,
  numberWithCommas,
  tossPayMessage,
} from "../components/common";
import {
  getMerchantInfo,
  getMsgItemList,
  getUserData,
  merchantId_api,
  msgPointWebpay,
} from "../lib/api";
import SelectPaymentType from "../components/modal/selectPaymentType";

const CardContainer = styled.div`
  .boxContainer {
    width: 80%;
    margin: 1vw auto;
    border: 1px solid #e1e1e1;
    padding: 3vw;
    border-radius: 5px;
    .possessionData {
      width: 100%;
      background: rgba(225, 225, 225, 0.52);
      border-radius: 4px;
      margin-bottom: 3vw;
      p {
        font-weight: 700;
        text-align: center;
        padding: 1vw;
      }
    }
    p {
      font-size: 16px;
    }
    .pointChargeTable {
      width: 100%;
      border: 1px solid #e1e1e1;
      tr {
        border-bottom: 1px solid #e1e1e1;
      }

      th {
        font-size: 13px;
        color: #898a8d;
        font-weight: 400;
      }

      th,
      td {
        text-align: center;
        padding: 1vw;

        &:nth-child(1) {
          padding: 1vw 0 1vw 5vw;
          width: 12vw;
        }
      }
      .point {
        font-weight: 400;
      }
      .price {
        color: #898a8d;
      }
      .active {
        .point {
          font-weight: 700;
        }
      }
    }
    .paymentPrice {
      display: flex;
      width: 33%;
      justify-content: space-between;
      margin-top: 3vw;
    }
    .bold {
      font-weight: 700;
    }
  }

  .submit {
    display: block;
    margin: 2vw auto 0;
  }
  .title {
    color: #222222;
    font-size: 1.2vw;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 2vw;
    }
    p {
      font-size: 1.5vw;
    }
  }
  @media screen and (max-width: 480px) {
    .paymentPrice {
      width: 70% !important;
      margin: 12vw auto 0;
    }
  }
`;

const MessageCharge = () => {
  const [choicePoint, setChoicePoint] = useState(0);
  const [merchantDefault, setMerchantDefault] = useState({});
  const [chergeItemData, setChergeItemData] = useState([]);
  const [clientKey, setclientKey] = useState([]);
  const [isSelectPaymentModal, setIsSelectPaymentModal] = useState(false);
  const [payData, setPayData] = useState({ id: "", points: "" });
  const [merchantId, setMerchantId] = useState();
  const getDefaultData = async () => {
    const { data } = await merchantId_api();
    const itemData = await getMsgItemList();

    if (data) {
      setMerchantId(data.id);
      const res = await getMerchantInfo(data.id);
      if (res.status === 200) {
        setclientKey(itemData.data.key);
        setMerchantDefault(res.data[0]);
        setChergeItemData(itemData.data);
      }
    }
  };

  // checkbox Event
  const changeBtn = (index) => {
    setChoicePoint(+index);
  };

  // payment Func
  const buyMessagePoint = () => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: "#04c9db",
      cancelButtonColor: "#F53C14",
      confirmButtonText: "확인",
      cancelButtonText: "취소",
      reverseButtons: true,
      title: "구매 정보 확인",
      html: ` 충전 포인트: <span style="font-weight:800; font-size:20px">${numberWithCommas(
        chergeItemData[choicePoint].points
      )}</span>p
      <br> 결제 금액: <span style="font-weight:800; font-size:20px">${numberWithCommas(
        chergeItemData[choicePoint].amount
      )}</span>원 <br> 맞으실 경우 확인을 눌러주세요. `,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsSelectPaymentModal(true);
        setPayData({
          id: chergeItemData[choicePoint].id,
          points: chergeItemData[choicePoint].points,
          mid: merchantId,
          amount: chergeItemData[choicePoint].amount,
          //결제 수단 판별을 위한 type
          type: "message",
        });
      }
    });
  };
  useEffect(() => {
    getDefaultData();
  }, []);
  return (
    <>
      {isSelectPaymentModal && (
        <SelectPaymentType
          show={isSelectPaymentModal}
          onClose={() => setIsSelectPaymentModal(false)}
          data={merchantDefault}
          payData={payData}
        />
      )}
      <Fragment>
        {/* <Breadcrumb parent="메세지 관리" title="메세지 충전" /> */}
        <CardContainer style={{ padding: "0px 5px" }}>
          <Card>
            <CardBody>
              <h5>메세지 포인트 충전</h5>
              <div className="boxContainer">
                <p className="title">보유 포인트</p>
                <div className="possessionData">
                  <p className="defaultPoint">
                    {numberWithCommas(
                      merchantDefault?.maxSendMessage -
                        merchantDefault?.sendMessageUseCount
                    )}
                  </p>
                </div>
                <p className="title">충전할 포인트</p>
                <div>
                  <table className="pointChargeTable">
                    <tr>
                      <th className="choiceData"></th>
                      <th>충전 포인트</th>
                      <th>결제 금액</th>
                    </tr>
                    {chergeItemData &&
                      chergeItemData.map((item, index) => (
                        <tr
                          key={`index${index}`}
                          className={choicePoint === index ? "active" : ""}
                        >
                          <td>
                            <CheckBoxRound>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="checkbox"
                                  name="smsDeny"
                                  onChange={() => changeBtn(index)}
                                  value={index}
                                  checked={choicePoint === index}
                                />
                                <span className="checkbox_icon" />
                              </div>
                            </CheckBoxRound>
                          </td>
                          <td className="point">
                            <p>{numberWithCommas(item?.points)}p</p>
                          </td>
                          <td className="price">
                            <p>{numberWithCommas(item?.amount)}원</p>
                          </td>
                        </tr>
                      ))}
                  </table>
                  <div className="paymentPrice">
                    <p className="title">결제금액</p>
                    <p className="title bold">
                      {numberWithCommas(chergeItemData[choicePoint]?.amount) ||
                        0}{" "}
                      원
                    </p>
                  </div>
                </div>
                <button
                  className="btn btn-primary submit"
                  onClick={buyMessagePoint}
                >
                  결제하기
                </button>
              </div>
            </CardBody>
          </Card>
        </CardContainer>
      </Fragment>
    </>
  );
};

export default MessageCharge;
