import React, { Fragment, useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { MENUITEMS, UserMenuItems } from "./menu";
import { ArrowRight, ArrowLeft, Grid, BarChart2 } from "react-feather";
import { Link, useHistory, useLocation } from "react-router-dom";
import { translate } from "react-switch-lang";
import configDB from "../../data/customizer/config";
import { DefaultLayout } from "../theme-customizer";
import { getUserPayData, levelDecode } from "../../components/common";
import styled from "styled-components";
import { getSaleData, getUserData, merchantId_api } from "../../lib/api";
import { Cookies } from "react-cookie";
import "dotenv/config";
import LogoLetmeup from "../../assets/images/Logo_letmeup.svg";
import ArrowRight1 from "../../assets/images/arrow_right.svg";
import Menu from "../../assets/images/menu.svg";
import { set } from "react-ga";

const cookies = new Cookies();

const UlContainer = styled.ul`
  > li:nth-child(${(props) => props.active + 1}) {
    > a {
      margin-bottom: 10px;
    }
  }
`;

// export const Sidebar = (props) => {
//   const id = "Seoul";
//   let list =
//     +levelDecode(localStorage.getItem("Token")) === 1
//       ? UserMenuItems
//       : MENUITEMS;
//   const defaultLayout = Object.keys(DefaultLayout);
//   const layout = id ? id : defaultLayout;
//   const [mainmenu, setMainMenu] = useState(list);
//   const [margin, setMargin] = useState(0);
//   const [width, setWidth] = useState(0);
//   const [sidebartoogle, setSidebartoogle] = useState(true);
//   const [title, setTitle] = useState("대시보드");

//   var isMobile = /Mobi/i.test(window.navigator.userAgent);
//   // const ref = useRef()
//   const [active, setActive] = useState(-1);
//   // let level = levelDecode(localStorage.getItem("Token"));
//   const [isPayment, setIsPayment] = useState(false);
//   const getMerchantId = async () => {
//     try {
//       if (+levelDecode(localStorage.getItem("Token")) === 1) {
//         const res = await getUserData();
//         const data = await getSaleData(res.data[0]?.id);
//         if (data.data[0].toss_client_key) {
//           setIsPayment(true);
//         }
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   useEffect(() => {
//     getMerchantId();
//   }, []);
//   const wrapper =
//     useSelector((content) => content.Customizer.sidebar_types.type) ||
//     configDB.data.settings.sidebar.type;
//   const handleScroll = () => {
//     if (window.scrollY > 400) {
//       if (
//         configDB.data.settings.sidebar.type.split(" ").pop() ===
//           "material-type" ||
//         configDB.data.settings.sidebar.type.split(" ").pop() ===
//           "advance-layout"
//       )
//         document.querySelector(".sidebar-main").className =
//           "sidebar-main hovered";
//     } else {
//       document.querySelector(".sidebar-main").className = "sidebar-main";
//     }
//   };
//   useEffect(() => {
//     // document.querySelector(".left-arrow").classList.add("d-none");
//     window.addEventListener("resize", handleResize);
//     handleResize();
//     const currentUrl = window.location.pathname;

//     list.map((items) => {
//       items.Items.filter((Items) => {
//         if (Items.path === currentUrl) setNavActive(Items);
//         if (!Items.children) return false;
//         Items.children.filter((subItems) => {
//           if (subItems.path === currentUrl) setNavActive(subItems);
//           if (!subItems.children) return false;
//           subItems.children.filter((subSubItems) => {
//             if (subSubItems.path === currentUrl) {
//               setNavActive(subSubItems);
//               return true;
//             } else {
//               return false;
//             }
//           });
//           return subItems;
//         });
//         return Items;
//       });
//       return items;
//     });
//     window.addEventListener("scroll", handleScroll);
//     handleScroll();

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//       window.removeEventListener("resize", handleResize);
//     };

//     // eslint-disable-next-line
//   }, [layout]);

//   const handleResize = () => {
//     setWidth(window.innerWidth - 500);
//   };

//   const setNavActive = (item) => {
//     setTitle(item.title);
//     list.map((menuItems) => {
//       menuItems.Items.filter((Items) => {
//         if (Items !== item) {
//           Items.active = false;
//           document.querySelector(".bg-overlay1").classList.remove("active");
//         }
//         if (Items.children && Items.children.includes(item)) {
//           Items.active = true;

//           document.querySelector(".sidebar-link").classList.add("active");
//         }
//         if (Items.children) {
//           Items.children.filter((submenuItems) => {
//             if (submenuItems.children && submenuItems.children.includes(item)) {
//               Items.active = true;
//               submenuItems.active = true;
//               return true;
//             } else {
//               return false;
//             }
//           });
//         }
//         return Items;
//       });
//       return menuItems;
//     });
//     item.active = !item.active;
//     setMainMenu({ mainmenu: list });
//   };

//   const toggletNavActive = (item) => {
//     if (window.innerWidth <= 991) {
//       document.querySelector(".page-header").className =
//         "page-header close_icon";
//       document.querySelector(".sidebar-wrapper").className =
//         "sidebar-wrapper close_icon ";
//       // document
//       //   .querySelector(".mega-menu-container")
//       //   .classList.remove("d-block");
//       if (item.type === "sub") {
//         document.querySelector(".page-header").className = "page-header ";
//         document.querySelector(".sidebar-wrapper").className =
//           "sidebar-wrapper ";
//       }
//     }

//     if (!item.active) {
//       list.map((a) => {
//         a.Items.filter((Items) => {
//           if (a.Items.includes(item)) Items.active = false;
//           if (!Items.children) return false;
//           Items.children.forEach((b) => {
//             if (Items.children.includes(item)) {
//               b.active = false;
//             }
//             if (!b.children) return false;
//             b.children.forEach((c) => {
//               if (b.children.includes(item)) {
//                 c.active = false;
//               }
//             });
//           });
//           return Items;
//         });
//         return a;
//       });
//     }
//     item.active = !item.active;
//     setMainMenu({ mainmenu: list });
//   };

//   const scrollToRight = () => {
//     if (margin <= -2598 || margin <= -2034) {
//       if (width === 492) {
//         setMargin(-3570);
//       } else {
//         setMargin(-3464);
//       }
//       document.querySelector(".right-arrow").classList.add("d-none");
//       document.querySelector(".left-arrow").classList.remove("d-none");
//     } else {
//       setMargin((margin) => (margin += -width));
//       document.querySelector(".left-arrow").classList.remove("d-none");
//     }
//   };

//   const scrollToLeft = () => {
//     if (margin >= -width) {
//       setMargin(0);
//       document.querySelector(".left-arrow").classList.add("d-none");
//       document.querySelector(".right-arrow").classList.remove("d-none");
//     } else {
//       setMargin((margin) => (margin += width));
//       document.querySelector(".right-arrow").classList.remove("d-none");
//     }
//   };

//   const closeOverlay = () => {
//     document.querySelector(".bg-overlay1").classList.remove("active");
//     document.querySelector(".sidebar-link").classList.remove("active");
//   };

//   const activeClass = () => {
//     document.querySelector(".sidebar-link").classList.add("active");
//     document.querySelector(".bg-overlay1").classList.add("active");
//   };

//   const openCloseSidebar = (toggle) => {
//     if (toggle) {
//       setSidebartoogle(!toggle);
//       document.querySelector(".page-header").className =
//         "page-header close_icon";
//       document.querySelector(".sidebar-wrapper").className =
//         "sidebar-wrapper close_icon ";
//     } else {
//       setSidebartoogle(!toggle);
//       document.querySelector(".page-header").className = "page-header";
//       document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
//     }
//   };

//   const responsiveSidebar = () => {
//     document.querySelector(".page-header").className = "page-header close_icon";
//     document.querySelector(".sidebar-wrapper").className =
//       "sidebar-wrapper close_icon";
//   };

//   return (
//     <Fragment>
//       <div
//         className={`bg-overlay1`}
//         onClick={() => {
//           closeOverlay();
//         }}
//       ></div>
//       <div className="sidebar-wrapper">
//         <div className="logo-wrapper">
//           <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
//             <img
//               style={{ width: 140, maxHeight: 35, objectFit: "cover" }}
//               className="img-fluid for-light"
//               src={require("../../assets/images/logo/logo.svg")}
//               alt=""
//             />
//             <img
//               style={{ width: 140, maxHeight: 35, objectFit: "cover" }}
//               className="img-fluid for-dark"
//               src={require("../../assets/images/logo/logo_dark.png")}
//               alt=""
//             />
//           </Link>
//           <div className="back-btn" onClick={() => responsiveSidebar()}>
//             <i className="fa fa-angle-left"></i>
//           </div>
//           <div
//             className="toggle-sidebar"
//             onClick={() => openCloseSidebar(sidebartoogle)}
//           >
//             <Grid className="status_toggle middle sidebar-toggle" />
//           </div>
//         </div>
//         <div className="logo-icon-wrapper" style={{ display: "none" }}>
//           <Link to={`${process.env.PUBLIC_URL}/dashboard/`}>
//             <img
//               className="img-fluid"
//               src={require("../../assets/images/logo/logo-icon.png")}
//               alt=""
//             />
//           </Link>
//         </div>
//         <nav className="sidebar-main">
//           <div className="left-arrow" onClick={scrollToLeft}>
//             <ArrowLeft />
//           </div>
//           <div
//             id="sidebar-menu"
//             style={
//               wrapper.split(" ").includes("horizontal-wrapper")
//                 ? { marginLeft: margin + "px" }
//                 : { margin: "0px" }
//             }
//           >
//             <UlContainer
//               active={active}
//               className="sidebar-links custom-scrollbar"
//             >
//               <li className="back-btn">
//                 <div className="mobile-back text-right">
//                   <span>{"Back"}</span>
//                   <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
//                 </div>
//               </li>

//               {list?.map((Item, idx) => (
//                 <Fragment key={idx}>
//                   {/* <li className="sidebar-main-title">
//                   <div>
//                     <h6 className="lan-1">{props.t(Item.menutitle)}</h6>
//                     <p className="lan-2">{props.t(Item.menucontent)}</p>
//                   </div>
//                 </li> */}
//                   {Item?.Items.map((menuItem, i) => (
//                     <li className="sidebar-list" key={i}>
//                       {menuItem.type === "sub" ? (
//                         <a
//                           href="javascript"
//                           className={`sidebar-link sidebar-title ${
//                             title === menuItem.title ? "active" : ""
//                           }`}
//                           onClick={(event) => {
//                             event.preventDefault();
//                             setNavActive(menuItem);
//                             setActive(idx + 1);
//                           }}
//                         >
//                           {!menuItem?.icon.render &&
//                           menuItem?.icon.includes("seat") ? (
//                             <img
//                               src={menuItem?.icon}
//                               style={{
//                                 color: "#2c323f",
//                                 marginRight: "12px",
//                                 marginLeft: "2px",
//                                 width: "14px",
//                               }}
//                             />
//                           ) : !menuItem?.icon.render &&
//                             menuItem?.icon.includes("ticket") ? (
//                             <img
//                               src={menuItem?.icon}
//                               style={{
//                                 color: "#2c323f",
//                                 marginRight: "9px",
//                                 width: "19px",
//                               }}
//                             />
//                           ) : (
//                             <menuItem.icon />
//                           )}

//                           {/* <span>{props.t(menuItem.title)}</span> */}
//                           {menuItem.badge ? (
//                             <label className={menuItem.badge}>
//                               {menuItem.badgetxt}
//                             </label>
//                           ) : (
//                             ""
//                           )}
//                           <div className="according-menu">
//                             {menuItem.active ? (
//                               <i className="fa fa-angle-down"></i>
//                             ) : (
//                               <i className="fa fa-angle-right"></i>
//                             )}
//                           </div>
//                         </a>
//                       ) : (
//                         ""
//                       )}

//                       {menuItem.type === "link" ? (
//                         <Link
//                           to={menuItem.path}
//                           className={`sidebar-link sidebar-title link-nav ${
//                             title === menuItem.title ? "active" : ""
//                           }`}
//                           onClick={() => {
//                             setNavActive(menuItem);
//                             toggletNavActive(menuItem);
//                             setActive(-1);
//                           }}
//                         >
//                           <menuItem.icon />
//                           {/* <span>{props.t(menuItem.title)}</span> */}
//                           {menuItem.badge ? (
//                             <label className={menuItem.badge}>
//                               {menuItem.badgetxt}
//                             </label>
//                           ) : (
//                             ""
//                           )}
//                         </Link>
//                       ) : (
//                         ""
//                       )}

//                       {menuItem.children ? (
//                         <ul
//                           className="sidebar-submenu"
//                           style={
//                             menuItem.active
//                               ? sidebartoogle
//                                 ? {
//                                     opacity: 1,
//                                     transition: "opacity 500ms ease-in",
//                                   }
//                                 : { display: "block" }
//                               : { display: "none" }
//                           }
//                         >
//                           {menuItem.children.map((childrenItem, index) => {
//                             return (
//                               <li key={index}>
//                                 {isMobile &&
//                                 childrenItem.path.includes("seat/change") ? (
//                                   ""
//                                 ) : childrenItem.type === "sub" ? (
//                                   <a
//                                     href="javascript"
//                                     className={`${
//                                       childrenItem.active ? "active" : ""
//                                     }`}
//                                     onClick={(event) => {
//                                       event.preventDefault();
//                                       toggletNavActive(childrenItem);
//                                     }}
//                                   >
//                                     {/* {props.t(childrenItem.title)} */}
//                                     <span className="sub-arrow">
//                                       <i className="fa fa-chevron-right"></i>
//                                     </span>
//                                     <div className="according-menu">
//                                       {childrenItem.active ? (
//                                         <i className="fa fa-angle-down"></i>
//                                       ) : (
//                                         <i className="fa fa-angle-right"></i>
//                                       )}
//                                     </div>
//                                   </a>
//                                 ) : (
//                                   ""
//                                 )}

//                                 {(isMobile &&
//                                   childrenItem.path.includes("seat/change")) ||
//                                 (!isPayment &&
//                                   childrenItem.path.includes("ticket/buy")) ||
//                                 (!isPayment &&
//                                   childrenItem.path.includes("seminar/buy")) ? (
//                                   ""
//                                 ) : childrenItem.type === "link" ? (
//                                   <Link
//                                     to={childrenItem.path}
//                                     className={`${
//                                       childrenItem.active ? "active" : ""
//                                     }`}
//                                     onClick={() =>
//                                       toggletNavActive(childrenItem)
//                                     }
//                                   >
//                                     {/* {props.t(childrenItem.title)} */}
//                                   </Link>
//                                 ) : (
//                                   ""
//                                 )}

//                                 {isMobile &&
//                                 childrenItem.path.includes("seat/change") ? (
//                                   ""
//                                 ) : childrenItem.children ? (
//                                   <ul
//                                     className="nav-sub-childmenu submenu-content"
//                                     style={
//                                       childrenItem.active
//                                         ? { display: "block" }
//                                         : { display: "none" }
//                                     }
//                                   >
//                                     {childrenItem.children.map(
//                                       (childrenSubItem, key) => (
//                                         <li key={key}>
//                                           {childrenSubItem.type === "link" ? (
//                                             <Link
//                                               to={childrenSubItem.path}
//                                               className={`${
//                                                 childrenSubItem.active
//                                                   ? "active"
//                                                   : ""
//                                               }`}
//                                               onClick={() =>
//                                                 toggletNavActive(
//                                                   childrenSubItem
//                                                 )
//                                               }
//                                             >
//                                               {/* {props.t(childrenSubItem.title)} */}
//                                             </Link>
//                                           ) : (
//                                             ""
//                                           )}
//                                         </li>
//                                       )
//                                     )}
//                                   </ul>
//                                 ) : (
//                                   ""
//                                 )}
//                               </li>
//                             );
//                           })}
//                         </ul>
//                       ) : (
//                         ""
//                       )}
//                     </li>
//                   ))}
//                 </Fragment>
//               ))}
//             </UlContainer>
//           </div>
//           <div className="right-arrow" onClick={scrollToRight}>
//             <ArrowRight />
//           </div>
//         </nav>
//       </div>
//     </Fragment>
//   );
// };

export const SideBar2 = ({ isShowSide, setIsShowSide }) => {
  const history = useHistory();
  const [menuList, setMenuList] = useState([]);
  const location = useLocation();
  const isMobile = window.innerWidth < 806;
  const [showPay, setShowPay] = useState(true);

  useEffect(() => {
    getCurrentMenuAndSaveMenuList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentMenuAndSaveMenuList = () => {
    const currentMenu = checkUserLevelAndGetMenu();
    setMenuList(currentMenu);
    setIsShowSide(false);
  };

  const checkUserLevelAndGetMenu = () => {
    const token = localStorage.getItem("Token");
    const userLevel = levelDecode(token);
    const menu = +userLevel === 1 ? UserMenuItems : MENUITEMS;
    return JSON.parse(JSON.stringify(menu));
  };

  const onClickExtendTopMenu = (type, menuListIndex, topMenuIndex) => {
    if (type === "link") return getCurrentMenuAndSaveMenuList();
    const menuListCopy = [...menuList];

    const newArray = menuListCopy.map((item, index) =>
      index === menuListIndex
        ? {
            ...item,
            Items: [{ ...item.Items[0], active: true }],
          }
        : {
            ...item,
            Items: [{ ...item.Items[0], active: false }],
          }
    );

    // menuListCopy[menuListIndex].Items[topMenuIndex].active = !menuList[
    //   menuListIndex
    // ].Items[topMenuIndex].active;
    setMenuList(newArray);
  };

  const onClickSubMenu = (menuListIndex, topMenuIndex, subMenuIndex) => {
    const currentMenu = checkUserLevelAndGetMenu();
    setIsShowSide(false);
    currentMenu[menuListIndex].Items[topMenuIndex].active = true;
    currentMenu[menuListIndex].Items[topMenuIndex].children[
      subMenuIndex
    ].active = true;
    setMenuList(currentMenu);
  };

  const moveToDashBoard = () => {
    const path = menuList[0].Items[0].path;
    setIsShowSide(false);
    getCurrentMenuAndSaveMenuList();
    return history.push(path);
  };

  const getShowPay = async () => {
    const { data } = await merchantId_api();
    const result = await getUserPayData(data.id);
    if (result.credit_store_id === null && result.simple_store_id === null) {
      setShowPay(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("Token");
    const userLevel = levelDecode(token);
    if (+userLevel === 1) getShowPay();
  }, []);
  return (
    <SideBarContainer className={isShowSide ? "active" : ""}>
      <div>
        {isMobile && (
          <img onClick={() => setIsShowSide(false)} src={Menu} alt="menu" />
        )}
        <div onClick={() => moveToDashBoard()} className="logo-wrapper">
          <img src={LogoLetmeup} alt="LogoLetmeup" />
        </div>
      </div>
      <ul>
        {menuList.map((topMenus, index) => (
          <TopMenus
            pathname={location.pathname}
            menuItemIndex={index}
            onClickExtendTopMenu={onClickExtendTopMenu}
            topMenus={topMenus.Items}
            onClickSubMenu={onClickSubMenu}
            showPay={showPay}
          />
        ))}
      </ul>
    </SideBarContainer>
  );
};

const SideBarContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 12.5vw;
  height: 100%;
  background-color: #3c5169;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo-wrapper {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 4.16vw;
    img {
      width: 7.29vw;
    }
  }
  > ul {
    width: 100%;
    gap: 1rem;
  }
  @media screen and (max-width: 805px) {
    position: fixed;
    width: 66.66vw;
    margin-top: -15.55vw;
    height: 100vh;

    transform: translateX(-67vw);
    transition: all 0.3s;
    &.active {
      transform: translateX(0);
      transition: all 0.3s;
    }

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      > img {
        width: 6.66vw;
        height: 6.66vw;
        margin: 0 5vw;
      }
    }
    .logo-wrapper {
      width: fit-content;
      height: 15.55vw;
      img {
        width: 27.77vw;
        height: 15.55vw;
      }
    }
    > ul {
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
    }
  }
`;

const TopMenus = ({
  topMenus,
  menuItemIndex,
  onClickExtendTopMenu,
  onClickSubMenu,
  pathname,
  showPay,
}) => {
  useEffect(() => {}, [pathname]);

  return (
    <TopMenusContiner>
      {topMenus.map((topMenu, index) => (
        <>
          <TopMenuItem
            topMenu={topMenu}
            pathname={pathname}
            menuItemIndex={menuItemIndex}
            topItemIndex={index}
            onClickExtendTopMenu={onClickExtendTopMenu}
          />
          {topMenu.children && (
            <SubMenus
              pathname={pathname}
              menuItemIndex={menuItemIndex}
              topItemIndex={index}
              active={topMenu.active}
              subMuens={topMenu.children}
              onClickSubMenu={onClickSubMenu}
              showPay={showPay}
            />
          )}
        </>
      ))}
    </TopMenusContiner>
  );
};

const TopMenusContiner = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  cursor: pointer;
  :hover {
    background-color: #2f6d84;
  }
`;

const TopMenuItemContainer = styled.li`
  background-color: ${(props) => (props.active ? "#22899F" : "none")};
  > a {
    padding: 0.62vw 1.25vw;
    display: grid;
    grid-template-columns: ${(props) =>
      +props.userLevel === 1 ? "82% 5%" : "1fr 82% 5%"};
    align-items: center;

    > img {
      width: 0.93vw;
      height: 0.93vw;
    }
    > span {
      font-size: 0.93vw;
      font-weight: 600;
      color: ${(props) => (props.active ? "#ffffff" : "#cfd8e2")};
    }
  }

  @media screen and (max-width: 805px) {
    > a {
      padding: 3.33vw 6.66vw;

      > img {
        width: 5vw;
        height: 5vw;
      }
      > span {
        font-size: 5vw;
      }
    }
  }
`;

const TopMenuItem = ({
  topMenu,
  pathname,
  menuItemIndex,
  topItemIndex,
  onClickExtendTopMenu,
}) => {
  const [isHover, setIsHover] = useState(false);
  const token = localStorage.getItem("Token");
  const userLevel = levelDecode(token);
  return (
    <TopMenuItemContainer
      active={pathname.startsWith(topMenu.path) || topMenu.active}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() =>
        onClickExtendTopMenu(topMenu.type, menuItemIndex, topItemIndex)
      }
      userLevel={userLevel}
    >
      {topMenu.type === "link" ? (
        <Link to={topMenu.path}>
          {+userLevel !== 1 && (
            <img
              src={
                pathname.startsWith(topMenu.path) || topMenu.active
                  ? topMenu.icon.pressed
                  : isHover
                  ? topMenu.icon.hovered
                  : topMenu.icon.enabled
              }
            />
          )}

          <span>{topMenu.title}</span>

          {/* <img src={ArrowRight1} alt="arrow-right" /> */}
        </Link>
      ) : (
        <a>
          {+userLevel !== 1 && (
            <img
              src={
                pathname.startsWith(topMenu.path) || topMenu.active
                  ? topMenu.icon.pressed
                  : isHover
                  ? topMenu.icon.hovered
                  : topMenu.icon.enabled
              }
            />
          )}
          <span>{topMenu.title}</span>
          <img src={ArrowRight1} alt="arrow-right" />
        </a>
      )}
    </TopMenuItemContainer>
  );
};

const SubMenus = ({
  subMuens,
  active,
  pathname,
  menuItemIndex,
  topItemIndex,
  onClickSubMenu,
  showPay,
}) => {
  return (
    <SubMenuContainer active={active}>
      {subMuens.map((subMenu, index) =>
        !showPay && subMenu?.path.includes("buy") ? (
          <></>
        ) : (
          <SubMenuItem
            active={pathname.startsWith(subMenu.path)}
            onClick={() => onClickSubMenu(menuItemIndex, topItemIndex, index)}
          >
            <Link to={subMenu?.path}>
              <span></span>
              <span>{subMenu.title}</span>
              <span></span>
            </Link>
          </SubMenuItem>
        )
      )}
    </SubMenuContainer>
  );
};

const SubMenuContainer = styled.ul`
  width: 100%;
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: left;
  background-color: #2f6d84;
`;

const SubMenuItem = styled.li`
  background-color: ${(props) => (props.active ? "#22899F" : "none")};
  > a {
    padding: 0.62vw 1rem;

    display: grid;
    grid-template-columns: 1fr 84%;
    align-items: center;
    > span {
      vertical-align: middle;
      color: ${(props) => (props.active ? "#ffffff" : "#cfd8e2")};
      font-size: 0.83vw;
      font-weight: 600;
    }
  }
  :hover {
    background-color: #297b91;
  }
  @media screen and (max-width: 805px) {
    > a {
      padding: 3.33vw 0 3.33vw 13.88vw;
      grid-template-columns: 1fr;
      > span {
        font-size: 4.44vw;
      }
    }
  }
`;
