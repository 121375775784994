import moment from "moment";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import styled from "styled-components";
import { numberWithCommas } from "../components/common";
import { getPayAllList, merchantId_api } from "../lib/api";

const TableStyle = styled.div`
  .tableContainer {
    width: 100%;
    overflow: scroll;
  }
  table {
    width: 1400px;
    th,
    td {
      width: 100px;
      text-align: center;
      border: 1px solid rgba(174, 174, 174, 0.5);
      padding: 10px;
    }
    thead {
      border-bottom: 2px solid #222222;
    }
  }
  .choiceYear {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vw 0 2vw;
    p {
      font-size: 20px;
      margin: 0;
      cursor: pointer;
    }
    h5 {
      margin: 0 2vw;
    }
  }
`;

let mid;
const SalesMonth = ({ setIsLoading }) => {
  const paymentHeader = [
    "카드",
    "현금",
    "간편결제",
    "가상결제",
    "웹결제",
    "카드 환불",
    "현금 환불",
    "간편결제\n환불",
    "가상결제\n환불",
    "웹결제 환불",
  ];
  const tableHeader = [
    "월별",
    "총 매출액",
    "결제액",
    "환불액",
    "결제수단별 내역",
  ];
  const month = Array(12)
    .fill()
    .map((v, i) => ({
      creditPrice: 0,
      kakaopayPrice: 0,
      cashPrice: 0,
      virtualPrice: 0,
      webPaymentPrice: 0,
      creditPrice_cancel: 0,
      kakaopayPrice_cancel: 0,
      cashPrice_cancel: 0,
      virtualPrice_cancel: 0,
      webPaymentPrice_cancel: 0,
    }));
  const [nowYear, setNowYear] = useState(new Date().getFullYear());
  const [salesData, setSalesDate] = useState([]);

  const getPaylogAllList = async (merchantId) => {
    //1. 데이터 가져오기
    setIsLoading(true);
    if (merchantId) {
      const resultData = await getPayAllList(
        merchantId,
        moment(nowYear).format(`${nowYear}-01-01 00:00:00`),
        moment(nowYear).format(`${nowYear}-12-31 23:59:59`)
      );

      if (resultData.status === 200 && resultData.length !== 0) {
        let payData = [
          resultData.data,
          resultData.data.map((item) => {
            item.payDate = moment(item.createdAt).format(`MM`);
          }),
        ];

        if (payData) {
          for (let j = 0; j < month.length; j++) {
            for (let i = 0; i < payData[0].length; i++) {
              if (+payData[0][i].payDate === j + 1) {
                if (
                  payData[0][i].paymentType === "credit" ||
                  payData[0][i].paymentType === "applepay"
                ) {
                  // 카드, 지역화폐(credit으로 표기됨), 애플페이
                  month[j].creditPrice += payData[0][i].amount;
                } else if (
                  payData[0][i].paymentType === "kakaopay" ||
                  payData[0][i].paymentType === "naverpay" ||
                  payData[0][i].paymentType === "payco"
                ) {
                  // 간편결제(카카오페이, 페이코, 네이버페이, 제로페이(추후 추가예정)
                  month[j].kakaopayPrice += payData[0][i].amount;
                } else if (payData[0][i].paymentType === "cash") {
                  // 현금
                  month[j].cashPrice += payData[0][i].amount;
                } else if (payData[0][i].paymentType === "virtual") {
                  // 가상
                  month[j].virtualPrice += payData[0][i].amount;
                } else if (
                  payData[0][i].paymentType === "toss" ||
                  payData[0][i].paymentType === "hecto"
                ) {
                  // 웹결제
                  month[j].webPaymentPrice += payData[0][i].amount;
                } else if (
                  payData[0][i].paymentType === "credit-cancel" ||
                  payData[0][i].paymentType === "applepay-cancel"
                ) {
                  month[j].creditPrice_cancel += payData[0][i].amount;
                } else if (
                  payData[0][i].paymentType === "kakaopay-cancel" ||
                  payData[0][i].paymentType === "naverpay-cancel" ||
                  payData[0][i].paymentType === "payco-cancel"
                ) {
                  month[j].kakaopayPrice_cancel += payData[0][i].amount;
                } else if (payData[0][i].paymentType === "cash-cancel") {
                  month[j].cashPrice_cancel += payData[0][i].amount;
                } else if (payData[0][i].paymentType === "virtual-cancel") {
                  month[j].virtualPrice_cancel += payData[0][i].amount;
                } else if (
                  payData[0][i].paymentType === "toss-cancel" ||
                  payData[0][i].paymentType === "hecto-cancel"
                ) {
                  month[j].webPaymentPrice_cancel += payData[0][i].amount;
                }
              }
            }
          }

          setSalesDate(
            month,
            month.map((item) => {
              item.totalPrice =
                item.creditPrice +
                item.kakaopayPrice +
                item.cashPrice +
                item.virtualPrice +
                item.webPaymentPrice;
              item.cancelTotal =
                item.creditPrice_cancel +
                item.kakaopayPrice_cancel +
                item.cashPrice_cancel +
                item.virtualPrice_cancel +
                item.webPaymentPrice_cancel;
            })
          );
        }
      }
    }
    setIsLoading(false);
  };

  //0. merchantId 가져오기
  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      getPaylogAllList(data.id);
      mid = data.id;
    }
  };

  useEffect(() => {
    getMerchantId();
  }, []);
  useEffect(() => {
    if (mid) {
      getPaylogAllList(mid);
    }
  }, [nowYear]);

  return (
    <Fragment>
      <Container fluid={true} style={{ padding: "0 6px" }}>
        <Card>
          <CardBody>
            <TableStyle>
              <h4>월 별 매출 보기</h4>
              <div className="choiceYear">
                <p onClick={() => setNowYear(nowYear - 1)}>&lt;</p>
                <h5>{nowYear}년</h5>
                <p onClick={() => setNowYear(nowYear + 1)}>&gt;</p>
              </div>
              <div className="tableContainer">
                <table>
                  <thead>
                    <tr>
                      {tableHeader.map((item, index) => (
                        <th
                          key={index + item}
                          colSpan={item.includes("수단") ? 10 : 1}
                          rowSpan={item.includes("수단") ? 1 : 2}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                    <tr>
                      {paymentHeader.map((item, index) => (
                        <th key={index + item}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {salesData.map((sale, index) => (
                      <tr>
                        <td>{index + 1}월</td>
                        <td>
                          {numberWithCommas(
                            sale.totalPrice - sale.cancelTotal
                          ) !== "-"
                            ? `${numberWithCommas(
                                sale.totalPrice - sale.cancelTotal
                              )}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.totalPrice) !== "-"
                            ? `${numberWithCommas(sale.totalPrice)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.cancelTotal) !== "-"
                            ? `${numberWithCommas(sale.cancelTotal)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.creditPrice) !== "-"
                            ? `${numberWithCommas(sale.creditPrice)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.cashPrice) !== "-"
                            ? `${numberWithCommas(sale.cashPrice)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.kakaopayPrice) !== "-"
                            ? `${numberWithCommas(sale.kakaopayPrice)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.virtualPrice) !== "-"
                            ? `${numberWithCommas(sale.virtualPrice)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.webPaymentPrice) !== "-"
                            ? `${numberWithCommas(sale.webPaymentPrice)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.creditPrice_cancel) !== "-"
                            ? `${numberWithCommas(sale.creditPrice_cancel)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.cashPrice_cancel) !== "-"
                            ? `${numberWithCommas(sale.cashPrice_cancel)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.kakaopayPrice_cancel) !== "-"
                            ? `${numberWithCommas(sale.kakaopayPrice_cancel)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.virtualPrice_cancel) !== "-"
                            ? `${numberWithCommas(sale.virtualPrice_cancel)}원`
                            : 0}
                        </td>
                        <td>
                          {numberWithCommas(sale.webPaymentPrice_cancel) !== "-"
                            ? `${numberWithCommas(
                                sale.webPaymentPrice_cancel
                              )}원`
                            : 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TableStyle>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default SalesMonth;
