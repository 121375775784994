import React from "react";
import styled from "styled-components";

const ReceiptComponent = styled.div`
  width: 80vw;
  margin: 0 auto;

  border-radius: 0.83vw;
  padding: 2vw;
  p {
    margin-bottom: 0;
  }
  .area {
    border-bottom: 1px solid #c9d0d6;
    margin-bottom: 5vw;
    > h4 {
      margin-bottom: 0;
      font-size: 2.5vw;
      border-bottom: 1px solid black;
    }
    &:last-child {
      .flex {
        > p {
          &:last-child {
            font-size: 2vw;
            color: black;
          }
        }
      }
    }
  }

  .flex {
    display: flex;
    padding: 1vw;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    > p {
      &:first-child {
        font-size: 2vw;
        color: #989898;
      }
      &:last-child {
        font-weight: 500;
        font-size: 2vw;
        color: #989898;
      }
    }
  }
  .price {
    border-top: 1px solid #e1e1e1;
    > h5 {
      margin-bottom: 0;
      padding: 1vw 0;
      font-size: 2.3vw;
    }
    > p {
      &:last-child {
        color: #ff6e6e !important;
      }
    }
  }
`;
const Receipt = () => {
  return (
    <ReceiptComponent>
      <div className="area">
        <h4>거래정보</h4>
        <div className="flex">
          <p>카드종류</p> <p>신한</p>
        </div>
        <div className="flex">
          <p>카드번호</p> <p>4325************</p>
        </div>
        <div className="flex">
          <p>할부</p> <p>일시불</p>
        </div>
        <div className="flex">
          <p>거래유형</p> <p>승인</p>
        </div>
        <div className="flex">
          <p>승인번호</p> <p>99044231</p>
        </div>
        <div className="flex">
          <p>거래(취소)일시</p> <p>2023-03-27 12:53:18</p>
        </div>
        <div className="flex">
          <p>구매자명</p> <p> </p>
        </div>
        <div className="flex">
          <p>상품명</p> <p>15000p 충전</p>
        </div>
      </div>
      <div className="area">
        <h4>공급대가</h4>
        <div className="flex">
          <p>과세금액</p> <p>909원</p>
        </div>
        <div className="flex">
          <p>부가세</p> <p>91원</p>
        </div>
        <div className="flex">
          <p>면세금액</p> <p>0원</p>
        </div>
        <div className="flex">
          <p>봉사료</p> <p>0원</p>
        </div>
        <div className="flex price">
          <h5>총 결제금액</h5>
          <p>1,000원</p>
        </div>
      </div>
    </ReceiptComponent>
  );
};

export default Receipt;
