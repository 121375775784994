import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { vendingItemEnroll } from "../../lib/api";
import { Button, Label1 } from "../../pages/lockerChange";
import { ModalBackground } from "./choiceModal";
import configDB from "../../data/customizer/config";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const ImgContainer = styled.div`
  width: 100%;
  max-height: 260px;
  margin-bottom: 20px;
  img {
    width: 100%;
    max-height: 250px;
    object-fit: contain;
  }
`;

const AddVendingItemModal = ({
  item,
  setItem,
  close,
  merchant,
  setVendingItems,
}) => {
  const handleChange = (e) => {
    var regex = /[^0-9]/g;
    if (
      e.target.name.includes("count") ||
      e.target.name.includes("number") ||
      e.target.name.includes("price")
    ) {
      setItem({ ...item, [e.target.name]: e.target.value.replace(regex, "") });
    } else {
      setItem({ ...item, [e.target.name]: e.target.value });
    }
  };

  const enrollVendingItem = async () => {
    if (
      item.vending_count === "" ||
      item.vending_name === "" ||
      item.vending_number === "" ||
      item.vending_price === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "url을 제외한 모든 값을 입력해주세요",
        timer: 2000,
      });
    } else {
      if (merchant) {
        const result = await vendingItemEnroll({
          ...item,
          merchantId: merchant,
        });

        if (result.status === 200) {
          Swal.fire({
            icon: "success",
            title: "아이템 등록이 완료되었습니다.",
            timer: 2000,
          }).then(() => {
            setVendingItems(merchant);
            setItem({
              vending_number: "",
              vending_name: "",
              vending_count: "",
              vending_price: "",
              vending_image: "",
              merchantId: "",
            });
            close();
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "아이템 등록에 실패했습니다. 다시 시도해주세요.",
            timer: 2000,
          });
        }
      }
    }
  };
  return (
    <>
      <Modal centered isOpen={close} toggle={close}>
        <ModalHeader className="modal-title" toggle={close}>
          자판기 상품 등록하기
        </ModalHeader>

        <ModalBody>
          <ImgContainer>
            <img
              src={
                item?.vending_image
                  ? item?.vending_image
                  : require("../../assets/images/logo/logo512.png")
              }
              alt="자판기 아이템"
            />
          </ImgContainer>
          <div>
            <Label1>
              <h5>상품번호 </h5>
              <Input
                value={item?.vending_number || ""}
                name="vending_number"
                id={item?.id || ""}
                onChange={(e) => handleChange(e)}
                placeholder="상품번호를 입력해주세요"
              />
            </Label1>
            <Label1>
              <h5>상품명 </h5>
              <Input
                id={item?.id || ""}
                name="vending_name"
                value={item?.vending_name || ""}
                onChange={(e) => handleChange(e)}
                placeholder="상품명을 입력해주세요"
              />
            </Label1>
            <Label1>
              <h5>상품가격 </h5>
              <Input
                id={item?.id || ""}
                name="vending_price"
                value={item?.vending_price || ""}
                onChange={(e) => handleChange(e)}
                placeholder="상품가격을 입력해주세요"
              />
            </Label1>
            <Label1>
              <h5>상품수량 </h5>
              <Input
                id={item?.id || ""}
                name="vending_count"
                value={item?.vending_count || ""}
                onChange={(e) => handleChange(e)}
                placeholder="상품수량을 입력해주세요"
              />
            </Label1>

            <Label1>
              <h5>상품 이미지 url </h5>
              <Input
                id={item?.id || ""}
                name="vending_image"
                value={item?.vending_image || ""}
                onChange={(e) => handleChange(e)}
                placeholder="상품 이미지 url을 입력해주세요"
              />
            </Label1>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "10px",
                marginTop: "25px",
              }}
            >
              <Button
                color="#fff"
                onClick={() => {
                  close();
                  setItem({
                    vending_number: "",
                    vending_name: "",
                    vending_count: "",
                    vending_price: "",
                    vending_image: "",
                    merchantId: "",
                  });
                }}
              >
                취소하기
              </Button>
              <Button color={primary} onClick={enrollVendingItem}>
                등록하기
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default AddVendingItemModal;
