import React from "react";

import { PlusCircle, XCircle } from "react-feather";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { getMapViewList, postCreateSeat } from "../../lib/api";

const Btns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;
export const ButtonS = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.bg};
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-weight: 600;
  font-size: 15px;
  > svg {
    height: 17px;
  }
  @media screen and (max-width: 480px) {
    padding: 6px 10px;
    font-size: 13px;
    > svg {
      height: 15px;
    }
  }
`;

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 101;
`;
const SeatBlockModal = ({
  isOpen,
  onClose,
  seatData,
  setLists,
  lists,
  floorNum,
}) => {
  const changeBlock = async (blockType) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: "#04c9db",
      cancelButtonColor: "#F53C14",
      confirmButtonText: "확인",
      cancelButtonText: "취소",
      reverseButtons: true,
      title: "좌석설정을 변경하시겠습니까?",
      html: `${blockType === "unBlock" ? "좌석열기 실행" : "좌석막기 실행"}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let changeData;
        if (blockType === "unBlock") {
          changeData = [{ ...seatData, status: "idle" }];
        } else {
          changeData = [{ ...seatData, status: 1 }];
        }

        const res = await postCreateSeat({
          seat: changeData,
          merchantId: seatData.merchantId,
        });

        try {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "변경이 완료됐습니다.",
              timer: 3000,
            }).then(() => getSeatListApi());
          } else if (res) {
            Swal.fire({
              icon: "error",
              title: "변경에 실패했습니다. 다시 시도해주세요.",
              timer: 3000,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "변경에 실패했습니다. 다시 시도해주세요.",
            timer: 3000,
          });
        }
      }
    });
  };

  const getSeatListApi = async () => {
    const res = await getMapViewList(seatData.merchantId);
    if (res.status === 200) {
      if (floorNum) {
        setLists(
          res.data
            .filter((item) => item.type === "seat" || item.type === "seminar")
            .filter((list) => list.floor === +floorNum)
        );
      } else {
        setLists(
          res.data.filter(
            (item) => item.type === "seat" || item.type === "seminar"
          )
        );
      }

      onClose();
    }
  };

  return (
    <>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader className="modal-title" toggle={onClose}>
          {seatData.number}번
        </ModalHeader>
        <ModalBody>
          <h5>{seatData.phoneNum}</h5>
          <Btns>
            {seatData.status === "block" && (
              <ButtonS bg="#21D7D9" onClick={() => changeBlock("unBlock")}>
                좌석열기 <PlusCircle />
              </ButtonS>
            )}
            {seatData.status === "idle" && (
              <ButtonS bg="#F53C14" onClick={() => changeBlock("block")}>
                좌석막기 <XCircle />
              </ButtonS>
            )}
          </Btns>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default SeatBlockModal;
