import React, { useEffect, useState } from "react";
import { getUserData, getUserTicketList, ticketExit } from "../../lib/api";
import moment from "moment";
import Breadcrumb from "../../layout/breadcrumb";
import { secondToHour } from "../ticketList";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { ko } from "date-fns/esm/locale";
import DataTable from "react-data-table-component";
import { UserTicketListColumn } from "../../data/ticketList";
import PaginationComponent from "../../components/pagination";
import Swal from "sweetalert2";

const CardContainer = styled.div`
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1vh;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;
    .m-0 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      /* position: relative; */
      width: 100%;

      input {
        width: 80% !important;
      }
      .fa-search {
        position: absolute;
        right: 12px;
        top: 16.8px;
      }
    }
  }
  .addBtns {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    > button {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  .table {
    width: 100%;

    overflow: auto;
    > div {
      overflow-x: auto;
      ::-webkit-scrollbar {
        width: 0.6vw;
        height: 0.6vw;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c4cbd6;
        border-radius: 1vw;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 1vw;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .topbar {
      align-items: center;
      flex-direction: column;
      .m-0 {
        margin: 3vw 0 !important;
        input {
          width: 100% !important;
        }
      }
    }
    .btn {
      padding: 5px 10px;
    }
  }

  .rbt_TabaleRow {
    div:nth-child(2) {
      width: 30px;
    }
  }

  .ptag {
    padding: 2px 5px;
    border-radius: 5px;
  }

  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

const UserTicketInfo = () => {
  const [merchantId, setMerchantId] = useState(0);
  const [phoneNum, setPhoneNum] = useState(null);
  const [startDate, setStartDate] = useState(new Date("2020-01-01"));
  const [endDate, setEndDate] = useState(new Date("2030-01-01"));
  const [lists, setLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [total, setTotal] = useState(0);
  const [isReload, setIsReload] = useState(false);
  const getUserInfo = async () => {
    try {
      const { data } = await getUserData();

      setPhoneNum(data[0]?.phoneNum);
      setMerchantId(data[0]?.id);
      getUserTicketListApi(data[0]?.id, data[0]?.phoneNum);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserTicketListApi = async (merchantId, phoneNum) => {
    if (merchantId) {
      const data = await getUserTicketList(
        merchantId,
        moment(startDate).format("YYYYMMDD000000"),
        moment(endDate).format("YYYYMMDD235959"),
        currentPage,
        postsPerPage,
        phoneNum
      );
      if (data.status === 200) {
        setTotal(data.data.count);
        let changeList = [
          data.data.result,
          data.data.result.map((item, idx) => {
            item.typeName =
              item.type === 1 && item.seatNumber
                ? ` 일회권 (${item.seatNumber}번)`
                : item.type === 1 && !item.seatNumber
                ? " 일회권"
                : item.type === 4 && item.seatNumber
                ? ` 세미나실 (${item.seatNumber}번)`
                : item.type === 4 && !item.seatNumber
                ? " 세미나실"
                : item.type === 5 && item.seatNumber
                ? ` 사물함 (${item.seatNumber}번)`
                : item.type === 5 && !item.seatNumber
                ? " 사물함"
                : item.type === 2
                ? ` 기간권 - ${item.seatType === 0 ? "자유석" : "고정석"}`
                : "사물함";

            item.type1 =
              item.type === 1 ? (
                <p style={{ color: "#EBAF22" }}>
                  일회권
                  {item.seatNumber ? `(${item.seatNumber}번)` : ""}
                </p>
              ) : item.type === 2 ? (
                <>
                  {item.seatType === 0 ? (
                    <p style={{ color: "#E03D64" }}>기간권 - 자유석</p>
                  ) : (
                    <p style={{ color: "#1BBABC" }}>기간권 - 고정석</p>
                  )}
                </>
              ) : item.type === 3 ? (
                <p style={{ color: "#33946B" }}>시간정액권</p>
              ) : item.type === 4 ? (
                <p style={{ color: "#3E67B6" }}>
                  세미나실
                  {item.seatNumber ? `(${item.seatNumber}번)` : ""}
                </p>
              ) : (
                <p style={{ color: "#AB84FF" }}>
                  사물함
                  {item.seatNumber ? `(${item.seatNumber}번)` : ""}
                </p>
              );
            item.lastIn = item.lastIn
              ? moment(item.lastIn).format("YYYY-MM-DD HH:mm:ss")
              : "";

            item.startDate = item.startDate
              ? moment(item.startDate).format("YYYY-MM-DD HH:mm:ss")
              : "";

            const t1 = moment(item.endDate, "YYYY-MM-DD HH:mm:ss");
            const t3 = moment(new Date(), "YYYY-MM-DD HH:mm:ss");

            item.action =
              +item.type === 2 && +item.seatType === 0
                ? secondToHour(item.remainTime)
                : secondToHour(item.remainTime + item.usedTime);

            item.expireDate2 =
              (item.type === 3 || item.type === 5) &&
              Math.ceil(moment.duration(moment(t1).diff(t3)).asDays()) > 0
                ? `${Math.ceil(
                    moment.duration(moment(t1).diff(t3)).asDays()
                  )}일`
                : "-";
            item.T_remainTime =
              +item.type === 2 && +item.seatType === 0
                ? secondToHour(item.remainTime - item.usedTime)
                : secondToHour(item.remainTime);
            item.T_isUsing =
              item.isUsing === 1 ? (
                <div>사용중</div>
              ) : item.isUsing === 2 ? (
                <div style={{ color: "#F04E36" }}>시간 초과</div>
              ) : item.lastOut ? (
                <div style={{ color: "#53852A" }}>퇴장완료</div>
              ) : (
                <div>사용전</div>
              );
            item.blockKeyword = item.block === 1 ? "사용불가" : "사용가능";
            item.T_block =
              item.block === 1 ? (
                <p style={{ color: "#F04E36" }}>사용불가</p>
              ) : (
                <p>사용가능</p>
              );

            item.isUsingKeyword =
              item.isUsing === 1
                ? "강제퇴장처리"
                : item.isUsing === 2
                ? "초과결제해제"
                : "퇴장처리";
            item.last2 = item.lastOut
              ? moment(item.lastOut).format("YYYY-MM-DD HH:mm:ss")
              : "";
            const t11 = moment();
            const t22 = moment(item.endDate);
            const t23 = moment(item.startDate);
            const t33 = moment(item.lastIn);
            const t44 = moment(item.lastOut);
            const today_diff = moment.duration(t22.diff(t11)).asMilliseconds();
            //현재시간기준으로 시작시간하고의 차이
            const today_diff2 = moment.duration(t11.diff(t23)).asMilliseconds();
            const remainTimeData = moment.duration(t11.diff(t33)).asSeconds();
            const seminarNotUsing = moment.duration(t22.diff(t11)).asSeconds();
            const excessData = moment.duration(t11.diff(t44)).asSeconds();
            //잔여시간 조회
            item.remainTime2 =
            // 일회권, 기간권, 시간정액권 사용 중이면서, 퇴장 기록이 없는 경우
            item.isUsing === 1 && !item.lastOut && item.lastIn &&
            (item.type === 1 || item.type === 2 || item.type === 3) ? 
            secondToHour(item.remainTime - Math.ceil(remainTimeData)) :
            // 세미나실인 경우 (입실 처리x, 시간 초과x)
            item.type === 4 && today_diff > 0 && today_diff2 > 0 ? 
            secondToHour(Math.ceil(seminarNotUsing)) :
            // 세미나실인 경우 (입실 처리x, 시간 초과o)
            item.type === 4 && today_diff < 0 && today_diff2 > 0 ? 
            "00:00:00" :
            // 일회권, 기간권, 시간정액권 사용 중이면서 시간을 초과한 경우
            item.isUsing === 2 && item.lastOut ? 
            `${secondToHour(+Math.ceil(excessData))} 초과` :
            // 그외에 조건
            item.type === 2 && item.endDate ? 
            `${moment(item.endDate).diff(moment(), 'days') + 1}일 남음` :
            item.type === 4 && item.endDate ?
            `${moment(item.endDate).diff(moment(), 'days') + 1}일 남음` :
            item.type === 5 && item.endDate ?
            `${moment(item.endDate).diff(moment(), 'days') + 1}일 남음` :
            secondToHour(item.remainTime);
          

            // 퇴장처리 버튼
            item.exitBtn =
              item.isUsing === 1 ? (
                <button
                  className="btn-secondary btn-sm"
                  onClick={() => userTicketExit(item)}
                >
                  퇴장하기
                </button>
              ) : item.isUsing === 2 ? (
                <button
                  className="btn-secondary btn-sm"
                  onClick={() => overTimeExit(item.isUsing)}
                >
                  퇴장하기
                </button>
              ) : (
                <button className="btn-sm" disabled>
                  퇴장하기
                </button>
              );
          }),
        ];

        setLists(changeList[0]);
        setIsReload(false);
      }
    }
  };

  //사용자 퇴장처리
  const userTicketExit = (ticketData) => {
    if (ticketData.isUsing === 1) {
      Swal.fire({
        showCancelButton: true,
        confirmButtonColor: "#04c9db",
        cancelButtonColor: "#F53C14",
        confirmButtonText: "네",
        cancelButtonText: "아니오",
        reverseButtons: true,
        text: "퇴장하시겠습니까?",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let exitRes = await ticketExit({
            merchantId: ticketData.merchantId,
            id: ticketData.id,
            userId: ticketData.userId,
          });
          if (exitRes.status === 200) {
            Swal.fire({
              text: "퇴장처리가 완료되었습니다.",
              timer: 3500,
            });
            setIsReload(true);
          } else {
            Swal.fire("퇴장처리 중 에러가 발생했습니다.");
          }
        }

        //
      });
    }
  };

  //초과결제(isUsing===2)
  const overTimeExit = (isUsingStatus) => {
    if (isUsingStatus === 2) {
      Swal.fire("초과 결제가 필요한 입장권은\n웹에서 퇴장이 불가능 합니다.");
    }
  };
  useEffect(() => {
    getUserTicketListApi(merchantId, phoneNum);
  }, [startDate, endDate, currentPage, isReload]);
  return (
    <>
      <Breadcrumb parent="결제정보 조회" title="결제내역" />
      <Container fluid={true}>
        <CardContainer>
          <Row>
            <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Card>
                <CardBody>
                  <div className="topbar">
                    {/* <DatePickerStyle>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        selectsStart
                        locale={ko}
                        startDate={startDate}
                        maxDate={endDate}
                        endDate={endDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <h5> - </h5>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        locale={ko}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <SelectDate
                        setStartDate={(val) => setStartDate(val)}
                        setEndDate={(val) => setEndDate(val)}
                      />
                    </DatePickerStyle> */}
                    {/* <Form
                  style={{
                    width: window.innerWidth > 480 ? "30%" : "100%",
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    searchData();
                  }}
                >
                  <LabelRow className="m-0">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="검색어를 입력해주세요"
                      defaultValue={searchKeyword}
                      onChange={(e) => handleSearchKeyword(e.target.value)}
                    />

                    <i onClick={searchData} className="fa fa-search" />
                  </LabelRow>
                </Form> */}
                  </div>
                  {lists.length > 0 ? (
                    <DataTable
                      data={lists}
                      columns={UserTicketListColumn}
                      striped={true}
                      center={true}
                    />
                  ) : (
                    <h5 style={{ textAlign: "center" }}>정보가 없습니다.</h5>
                  )}
                </CardBody>
                {lists.length > 0 && (
                  <PaginationComponent
                    setCurrentPage={setCurrentPage}
                    setPostsPerPageAdd={setPostsPerPageAdd}
                    setPostsPerPageAdd1={setPostsPerPageAdd1}
                    setPageNum={setPageNum}
                    totalLength={total}
                    postsPerPage={postsPerPage}
                    currentPage={currentPage}
                    pageNum={pageNum}
                    postsPerPageAdd={postsPerPageAdd}
                    postsPerPageAdd1={postsPerPageAdd1}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </>
  );
};
export default UserTicketInfo;
