import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { getPaylogList, userTicketBlock } from "../../lib/api";
import { numberWithCommas } from "../common";

const SeminarPayLog = ({ close, payData }) => {
  const [choicePayData, setChoicePayData] = useState({});

  const getpayLog = async () => {
    const data = await getPaylogList(
      payData?.merchantId,
      moment(payData?.today)
        .subtract(5, "years")
        .format("YYYYMMDD000000"),
      moment(payData?.today).format("YYYYMMDD235959"),
      1,
      100,
      payData.phoneNum
    );

    if (data.status === 200) {
      let filterData = data.data.info.filter(
        (item) => item.ticketId === payData?.id
      );

      setChoicePayData(filterData[0]);
    }
  };

  const reservationCancel = async () => {
    Swal.fire({
      title: "세미나실 예약을 취소 하시겠습니까?",
      text:
        "예약취소를 하실경우 환불은 결제정보조회-결제내역에서 해주셔야합니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: "#04c9db",
      confirmButtonText: "예약취소",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await userTicketBlock({
          ticketId: payData?.id,
          merchantId: payData?.merchantId,
          type: 4,
          block: 1,
        });
        if (result.status === 200) {
          Swal.fire({
            icon: "success",
            title: "예약이 취소되었습니다.",
            timer: 2000,
          }).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "예약 취소중 에러가 발생했습니다. 다시 시도해주세요",
            timer: 2000,
          }).then(() => {
            close();
          });
        }
      }
    });
  };

  useEffect(() => {
    if (payData) {
      getpayLog();
    }
  }, []);
  return (
    <>
      <Modal centered isOpen={close} toggle={close}>
        <ModalHeader className="modal-title" toggle={close}>
          {"결제정보"}
        </ModalHeader>
        <ModalBody>
          <div>
            <p>
              <strong>결제수단</strong>:{" "}
              {choicePayData?.paymentType?.includes("cash") && "현금"}
              {choicePayData?.paymentType?.includes("credit") && "카드"}
              {choicePayData?.paymentType?.includes("kakaopay") && "간편결제"}
              {choicePayData?.paymentType?.includes("payco") && "페이코"}
              {choicePayData?.paymentType?.includes("virtual") &&
                "가매입/가매출"}
              {choicePayData?.paymentType?.includes("coupon") && "쿠폰"}
              {choicePayData?.paymentType?.includes("mileage") && "마일리지"}
              {choicePayData?.paymentType?.includes("toss") && "토스"}
              {choicePayData?.paymentType?.includes("applepay") && "애플페이"}
              {choicePayData?.paymentType?.includes("naverpay") && "네이버페이"}
              {choicePayData?.paymentType?.includes("hecto") && "웹결제(헥토)"}
            </p>
            <p>
              <strong>결제내역</strong>:{" "}
              {choicePayData?.amount === null
                ? null
                : numberWithCommas(+choicePayData?.amount)}
              원
            </p>
            <p>
              <strong>거래고유번호</strong>: {choicePayData?.meta1}
            </p>
            <p>
              <strong>승인번호</strong>: {choicePayData?.meta2}
            </p>
            <p>
              <strong>카드번호</strong>: {choicePayData?.meta3}
            </p>
            <p>
              <strong>전표번호</strong>: {choicePayData?.meta4}
            </p>
            <p>
              <strong>카드종류</strong>: {choicePayData?.meta5}
            </p>
            <p>
              <strong>발급사</strong>: {choicePayData?.meta6}
            </p>
            <p>
              <strong>거래유형</strong>: {choicePayData?.meta7}
            </p>
            <p>
              <strong>내부메시지</strong>:{choicePayData?.meta8}
            </p>
            <p>
              <strong>매입사</strong>: {choicePayData?.meta9}
            </p>
            <p>
              <strong>응답정보</strong>: {choicePayData?.meta10}
            </p>
            <p>
              <strong>거래일자</strong>: {choicePayData?.meta11}
            </p>
            <p>
              <strong>가맹점번호</strong>: {choicePayData?.meta12}
            </p>
            <p>
              <strong>표시메시지</strong>: {choicePayData?.meta13}
            </p>
            <p>
              <strong>결제일</strong>:{" "}
              {moment(choicePayData?.updatedAt)
                .utcOffset("+0900")
                .format("YYYY-MM-DD HH:mm:ss")}
            </p>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "2vw",
              }}
            >
              <button
                className="btn btn-danger btn-sm"
                onClick={() => reservationCancel()}
              >
                세미나실 예약 취소하기
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SeminarPayLog;
