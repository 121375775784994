import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";

import configDB from "../../data/customizer/config";

import { ModalBackground } from "./choiceModal";

const TextBox = styled.textarea`
  border: 1px solid #e3e4e4;
  width: 100%;
  height: 50vh;
  resize: none;
`;
const HeaderText = styled.div`
  width: 100%;

  p {
    color: #404a57;
    text-align: center;
    margin: 0;
    font-size: 1.5vw;
  }
  @media screen and (max-width: 480px) {
    p {
      text-align: left;
      font-size: 6.5vw;
      margin-bottom: -5vw;
    }
  }
`;

const AgreeBtn = styled.button`
  display: block;
  margin: 1vw auto 0;
  background: #05c7d9;
  border: none;
  width: 25%;
  font-size: 1.2vw;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.7vw;
  @media screen and (max-width: 480px) {
    width: 55%;
    font-size: 5.2vw;
  }
`;

const ModalStyle = styled.div`
  h5 {
    width: 100%;
  }
  .modal-header {
    border: none;
  }
`;
const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const TermsModal = ({ content, onClose, show, title, agreeFunc, index }) => {
  return (
    <>
      <ModalStyle>
        <Modal
          style={{
            maxWidth:
              window.innerWidth < 480
                ? "100%"
                : window.innerWidth < 1080
                ? "80%"
                : "50%",
            margin: "0 auto",
          }}
          centered
          isOpen={show}
          toggle={onClose}
        >
          <ModalStyle>
            <ModalHeader className="modal-title" toggle={onClose}>
              <HeaderText>
                <p>{title && window.innerWidth < 480 ? "이용약관" : title}</p>
              </HeaderText>
            </ModalHeader>
          </ModalStyle>
          <ModalBody>
            <TextBox disabled>{content && content}</TextBox>
            <div style={{ width: "100%" }}>
              <AgreeBtn
                onClick={() => {
                  agreeFunc(true, index === 3 ? "isSmsDeny" : index + 1);
                  onClose();
                }}
              >
                동의
              </AgreeBtn>
            </div>
          </ModalBody>
        </Modal>
      </ModalStyle>
      {show && <ModalBackground />}
    </>
  );
};
export default TermsModal;
