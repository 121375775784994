import React, { Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Button, Card, CardBody, Container, Form, Input } from "reactstrap";
import Breadcrumb from "../layout/breadcrumb";
import { ko } from "date-fns/esm/locale";
import { useState } from "react";

import styled from "styled-components";
import moment from "moment";
import {
  getSeminarInfoList,
  getSeminarItemList,
  merchantId_api,
  ticketForceExit,
  ticketUsing,
  userTicketBlock,
  userList,
} from "../lib/api";
import DataTable from "react-data-table-component";
import { seminarReserved } from "../data/ticketList";
import PaginationComponent from "../components/pagination";
import Swal from "sweetalert2";
import { exportExcel } from "../components/common";
import { ExampleCustomInput, LabelRow } from "./ticketCustom";
import { DatePickerStyle } from "./seatInfo";
import SelectDateFuture from "../components/selectDateFuture";
import DetailsModal from "../components/modal/detailsModal";

const TopObject = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  width: 100%;
  margin-bottom: 10px;
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
  }
  .seminar_name {
    width: 150px;
  }
  .m-0 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* position: relative; */
    width: 100%;

    input {
      width: 80% !important;
    }
    .fa-search {
      position: absolute;
      right: 12px;
      top: 16.8px;
    }
  }
  @media screen and (max-width: 768px) {
    input {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    > div {
      width: 100%;
      flex-direction: column;
    }
    select {
      padding: 9px 5px;
    }
    .seminar_name {
      width: 100%;
    }
    .m-0 {
      margin: 3vw 0 !important;
      input {
        width: 100% !important;
      }
    }
  }
`;

const CardContainer = styled.div`
  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

let mid;
const SeminarInfo = ({ setIsLoading }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment(new Date()).add(1, "y")._d);
  const [reservationData, setReservationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const [seminarData, setSeminarData] = useState([]);
  const [seminarId, setSeminarId] = useState();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [defaultList, setDefaultList] = useState([]);

  //모달 state
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  // 디테일 데이터 모달로 보내기
  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };
  // 휴대혼 번호 가져오기
  const getUserList = async (phoneNum) => {
    const result = await userList(mid, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  //모달 on/off
  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getData = async () => {
    const data = await merchantId_api();
    if (data.status === 200) {
      mid = data.data.id;
      getSeminarData(data.data.id);
      getSeminarList(data.data.id);
    }
  };

  const getSeminarData = async (e) => {
    let data = await getSeminarInfoList(e);

    if (data.status === 200) {
      setSeminarData(data.data);
      setSeminarId(data.data[0].id);
    }
  };

  const getSeminarList = async (e, timedata) => {
    setIsLoading(true);
    let data = await getSeminarItemList(
      e,
      moment(startDate).format("YYYYMMDD000000"),
      moment(endDate).format("YYYYMMDD235959")
    );

    if (e !== undefined && data.status === 200) {
      let changeData = [
        data.data,
        data.data.map((item) => {
          item.start = moment(item.startDate).format("YYYY-MM-DD HH:mm:ss");
          item.end = moment(item.endDate).format("YYYY-MM-DD HH:mm:ss");
          item.cancel = (
            <div
              className="btn btn-danger"
              onClick={() => reservationCancel(item)}
            >
              예약취소
            </div>
          );
          item.phoneNum1 = (
            <div
              className="phoneNum-click"
              onClick={(e) => {
                sendDetailData(e.currentTarget.textContent);
              }}
            >
              {item.phoneNum}
            </div>
          );
        }),
      ];
      setReservationData(
        changeData[0].filter((item) => +item.seminarId === +seminarId)
      );

      setDefaultList(
        data.data,
        data.data.map((item) => {
          item.start = moment(item.startDate).format("YYYY-MM-DD HH:mm:ss");
          item.end = moment(item.endDate).format("YYYY-MM-DD HH:mm:ss");
          item.cancel = (
            <div
              className="btn btn-danger"
              onClick={() => reservationCancel(item)}
            >
              예약취소
            </div>
          );
        })
      );
    }

    setIsLoading(false);
  };

  const reservationCancel = async (i) => {
    Swal.fire({
      title: "세미나실 예약을 취소 하시겠습니까?",
      text:
        "예약취소를 하실경우 환불은 결제정보조회-결제내역에서 해주셔야합니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: "#04c9db",
      confirmButtonText: "예약취소",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await userTicketBlock({
          ticketId: i.id,
          merchantId: i.merchantId,
          type: 4,
          block: !i.block,
        });

        if (result.status === 200) {
          let data = await ticketUsing(i.userId, i.merchantId);

          if (data.status === 200 && data.data.length !== 0) {
            //퇴장처리
            let res = await ticketForceExit({
              merchantId: i.merchantId,
              id: i.id,
              userId: i.userId,
              seatId: data.data.seatId,
            });

            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "예약이 취소되었습니다.",
                timer: 2000,
              }).then(() => {
                getData();
              });
            }
          } else {
            Swal.fire({
              icon: "success",
              title: "예약이 취소되었습니다.",
              timer: 2000,
            }).then(() => {
              getData();
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "예약 취소중 에러가 발생했습니다. 다시 시도해주세요",
            timer: 2000,
          }).then(() => {
            getData();
          });
        }
      }
    });
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const searchData = () => {
    if (!searchKeyword) {
      Swal.fire({ icon: "error", title: "검색어를 입력해주세요", timer: 200 });
    } else {
      let copyData = [...defaultList];
      let searchData = copyData.filter(
        (item) =>
          item.phoneNum?.includes(searchKeyword) ||
          item.start?.includes(searchKeyword) ||
          item.end?.includes(searchKeyword)
      );

      setReservationData(
        searchData.filter((item) => +item.seminarId === +seminarId)
      );
      setCurrentPage(1);
    }
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === "") {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (mid) {
      getSeminarList(mid);
    }
  }, [startDate, endDate, seminarId]);
  return (
    <>
      <Fragment>
        {detailUserModal && (
          <DetailsModal
            isOpen={detailUserModal}
            close={showDetailModal}
            seat={userData}
            back={true}
          />
        )}
        <Breadcrumb parent="세미나실 예약" title="세미나실 예약확인" />
        <Container fluid={true} style={{ padding: "0 5px" }}>
          <Card>
            <CardBody>
              <Button
                className="hide-480"
                onClick={() =>
                  exportExcel(
                    reservationData,
                    startDate,
                    endDate,
                    "세미나실 예약 현황"
                  )
                }
              >
                엑셀 출력하기
              </Button>
              <TopObject>
                <div>
                  <select
                    className="seminar_name"
                    value={seminarId}
                    onChange={(e) => setSeminarId(e.target.value)}
                  >
                    {seminarData.map((item, index) => (
                      <Fragment key={item.id}>
                        <option value={item.id} defaultChecked={(index = 0)}>
                          {item.name}
                        </option>
                      </Fragment>
                    ))}
                  </select>
                  <DatePickerStyle>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      selectsStart
                      locale={ko}
                      startDate={startDate}
                      maxDate={endDate}
                      endDate={endDate}
                      customInput={<ExampleCustomInput />}
                    />
                    <h5> - </h5>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      locale={ko}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      customInput={<ExampleCustomInput />}
                    />
                    <SelectDateFuture
                      setStartDate={(val) => setStartDate(val)}
                      setEndDate={(val) => setEndDate(val)}
                    />
                  </DatePickerStyle>
                </div>
                <Form
                  style={{
                    width: window.innerWidth > 480 ? "30%" : "100%",
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    searchData();
                  }}
                >
                  <LabelRow className="m-0">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="검색어를 입력해주세요"
                      defaultValue={searchKeyword}
                      onChange={(e) => handleSearchKeyword(e.target.value)}
                    />

                    <i onClick={searchData} className="fa fa-search" />
                  </LabelRow>
                </Form>
              </TopObject>
              <CardContainer>
                <DataTable
                  data={currentPosts(reservationData)}
                  columns={seminarReserved}
                  striped={true}
                  center={true}
                />
              </CardContainer>

              {reservationData.length > 0 && (
                <PaginationComponent
                  setCurrentPage={setCurrentPage}
                  setPostsPerPageAdd={setPostsPerPageAdd}
                  setPostsPerPageAdd1={setPostsPerPageAdd1}
                  setPageNum={setPageNum}
                  totalLength={
                    // searchData.length >= 1
                    //   ? searchData.length:
                    reservationData.length
                  }
                  postsPerPage={postsPerPage}
                  currentPage={currentPage}
                  pageNum={pageNum}
                  postsPerPageAdd={postsPerPageAdd}
                  postsPerPageAdd1={postsPerPageAdd1}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    </>
  );
};

export default SeminarInfo;
