import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import Breadcrumb from "../layout/breadcrumb";
import { getMerchantInfo, merchantId_api } from "../lib/api";
import {
  innerKRestart,
  innerPRestart,
  innerUpdate,
  kRestart,
  outerUpdate,
  pRestart,
} from "../lib/socket";
import { Title } from "./setting";
import { Button } from "../pages/lockerChange";
import configDB from "../data/customizer/config";
import OpenDoorModal from "../components/modal/openDoorModal";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const Btns = styled.div`
  display: flex;
  margin: 10px 0px 30px;
  > button {
    width: 230px;
    :not(:last-child) {
      margin-right: 5px;
    }
  }
`;

let merchantId;
const Kiosk_setting = () => {
  const [isRestartModal, setIsRestartModal] = useState(false);
  const [multiNum, setMultiNum] = useState(null);
  const [kind, setKind] = useState("");
  useEffect(() => {
    MerchantId();
  }, []);

  const MerchantId = async () => {
    const { data } = await merchantId_api();

    if (data) {
      merchantId = data.id;
      const res = await getMerchantInfo(data.id);
      if (res.status === 200) {
        setMultiNum(res.data[0].multiKioskFloor);
      }
    }
  };

  const programUpdate = async (mid) => {
    Swal.fire({
      title: "키오스크 재시작",
      text: "외부 키오스크를 재시작하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: primary,
      confirmButtonText: "실행",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        pRestart(mid);
        Swal.fire({
          icon: "success",
          title: "키오스크가 재시작됩니다.",
          timer: 2000,
        });
      }
    });
  };

  const inner_programUpdate = async (mid) => {
    // const { value: password } = await

    Swal.fire({
      title: "키오스크 재시작",
      text: "내부 키오스크를 재시작하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: primary,
      confirmButtonText: "실행",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        innerPRestart(mid);
        Swal.fire({
          icon: "success",
          title: "키오스크가 재시작됩니다.",
          timer: 2000,
        });
      }
    });
  };

  const pcUpdate = async (mid) => {
    Swal.fire({
      title: "PC 재시작",
      text: "외부 PC를 재시작 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: primary,
      confirmButtonText: "실행",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        kRestart(mid);
        Swal.fire({
          icon: "success",
          title: "키오스크가 재시작됩니다.",
          timer: 2000,
        });
      }
    });
  };

  const inner_pcUpdate = async (mid) => {
    Swal.fire({
      title: "PC 재시작",
      text: "내부 PC를 재시작 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: primary,
      confirmButtonText: "실행",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        innerKRestart(mid);
        Swal.fire({
          icon: "success",
          title: "키오스크가 재시작됩니다.",
          timer: 2000,
        });
      }
    });
  };

  //층별 모달
  const openModal = (kindString) => {
    setKind(kindString);
    setIsRestartModal(true);
  };

  return (
    <Fragment>
      {isRestartModal && multiNum && (
        <OpenDoorModal
          show={isRestartModal}
          onClose={setIsRestartModal}
          data={multiNum}
          id={merchantId}
          kind={kind === "PC" ? "PC" : "Program"}
        />
      )}
      <Breadcrumb parent="하드웨어 및 설정" title="키오스크 설정" />
      <Container fluid={true}>
        <Row>
          <Col style={{ padding: "0 6px" }}>
            <Card>
              <CardHeader>
                <Title>키오스크 및 PC재시작</Title>
              </CardHeader>
              <CardBody>
                <h6>외부 키오스크</h6>
                <Btns>
                  <Button
                    color={primary}
                    onClick={() => programUpdate(merchantId)}
                  >
                    외부 프로그램 재시작
                  </Button>
                  <Button color="#fff" onClick={() => pcUpdate(merchantId)}>
                    외부PC 재시작
                  </Button>
                </Btns>{" "}
                <h6 style={{ marginTop: "10px" }}>내부 키오스크</h6>
                {!multiNum && (
                  <Btns>
                    <Button
                      color={primary}
                      onClick={() => inner_programUpdate(merchantId)}
                    >
                      내부 프로그램 재시작
                    </Button>
                    <Button
                      color="#fff"
                      onClick={() => inner_pcUpdate(merchantId)}
                    >
                      내부PC 재시작
                    </Button>
                  </Btns>
                )}
                {multiNum && (
                  <>
                    <Btns>
                      <Button
                        color={primary}
                        onClick={() => inner_programUpdate(merchantId)}
                      >
                        내부 프로그램 전체 재시작
                      </Button>
                      <Button
                        color="#fff"
                        onClick={() => inner_pcUpdate(merchantId)}
                      >
                        내부PC 전체 재시작
                      </Button>
                    </Btns>

                    <Btns>
                      <Button
                        color={primary}
                        onClick={() => openModal("Program")}
                      >
                        내부 프로그램 층별 재시작
                      </Button>
                      <Button
                        color="#fff"
                        onClick={() => {
                          openModal("PC");
                        }}
                      >
                        내부PC 층별 재시작
                      </Button>
                    </Btns>
                  </>
                )}
                <h6>키오스크 업데이트</h6>
                <Btns>
                  <Button
                    color={primary}
                    onClick={() => outerUpdate(merchantId)}
                  >
                    외부 키오스크 업데이트
                  </Button>
                  <Button color="#fff" onClick={() => innerUpdate(merchantId)}>
                    내부 키오스크 업데이트
                  </Button>
                </Btns>{" "}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Kiosk_setting;
