import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import { levelDecode } from "../../components/common";
import { getSaleData } from "../../lib/api";

const cookies = new Cookies();

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .pc,
  .mobile {
    display: flex;
    align-items: center;
    p {
      font-size: 0.65vw;
      color: #91979e;
    }
    strong {
      color: #91979e;
      font-weight: 400;

      margin: 0 10px;
    }
    :not(:last-child) {
      border-right: 1px solid #91979e;
      margin-right: 10px;
      height: 0.65vw;
    }
  }
  @media screen and (max-width: 805px) {
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    .mobile {
      width: 100%;
      font-size: 3.4vw;
      height: 3.4vw;
      :not(:last-child) {
        border-right: none;
        height: 3.4vw;
        margin-right: 0px;
      }
      p {
        font-size: 3.4vw;
      }
    }

    .mobile {
      justify-content: center;
      .pc {
        justify-content: center;
        height: 3.4vw;
        :not(:last-child) {
        }
      }
    }
  }
`;
const Footer = (props) => {
  const [merchantData, setMerchantData] = useState({});
  let level = levelDecode(localStorage.getItem("Token"));
  const {
    user_name,
    store_address,
    contact,
    br_number,
    mail_order_sales_number,
  } = merchantData;
  const isMobile = window.innerWidth < 806;

  const getMerchantInfo = async () => {
    try {
      if (+level > 1) {
      } else {
        const res = await getSaleData(props.data.id);
        setMerchantData(res.data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (props?.data?.id) {
      getMerchantInfo(props.data.id);
    }
  }, [props?.data?.id]);

  const footerStyle = {
    margin: 0,
    display: props?.data ? "flex" : "none",
    width: "calc(100% - 12.5vw)",
    marginLeft: "12.5vw",
    height: "2.39vw",
    alignItems: "center",
    position: "absolute",
    fontSize: "0.65vw",
    boxShadow: "none",
    borderTop: "1px solid #DDE4EB",
  };

  const footerMobileStyle = {
    margin: 0,
    display: props?.data ? "flex" : "none",
    width: "100%",
    height: "38.88vw",
    alignItems: "center",
    position: "relative",
    fontSize: "0.72vw",
    boxShadow: "none",
    borderTop: "1px solid #DDE4EB",
    padding: "5vw 0",
  };
  return (
    <>
      <footer
        className="footer"
        style={isMobile ? footerMobileStyle : footerStyle}
      >
        <FooterContainer>
          <>
            {" "}
            <div className="mobile">
              <div className="pc">
                <p className="mb-0">대표이사:</p>
                <strong>{+level === 1 ? user_name : "심세용"}</strong>
              </div>
              <div className="pc">
                <p className="mb-0">상호명: </p>
                <strong>샘랩 주식회사</strong>
              </div>
            </div>
            <div className="mobile pc">
              <p className="mb-0">주소:</p>
              <strong>
                {+level === 1
                  ? store_address
                  : "서울 금천구 가산디지털1로 181 W센터 609호"}
              </strong>
            </div>
            <div className="mobile">
              <div className="pc">
                <p className="mb-0">사업자 등록번호: </p>{" "}
                <strong>{+level === 1 ? br_number : "531-87-00830"}</strong>
              </div>
              <div className="pc">
                <p className="mb-0">문의: </p>
                <strong>{+level === 1 ? contact : "01066594264"}</strong>
              </div>
            </div>
            <div className="mobile pc">
              <p className="mb-0">통신판매업 신고번호: </p>
              <strong>
                {+level === 1 ? mail_order_sales_number : "2019-경기안산-0776"}
              </strong>
            </div>
          </>
        </FooterContainer>
      </footer>
    </>
  );
};

export default Footer;
