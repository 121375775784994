import styled from "styled-components";
import checkmark from "../assets/images/checkmark.svg";
// 대시보드 카드
export const DashboardCard = styled.div`
  width: 100%;
  padding: 1.04vw 1.25vw;
  background-color: #ffffff;
  border: 1px solid #dde4eb;
  border-radius: 0.83vw;
  @media screen and (max-width: 805px) {
    padding: 5.55vw;
    border-radius: 4.44vw;
  }
`;

// 라디오 버튼
export const RadioBtn = styled.input.attrs({ type: "radio" })`
  background: center 55% no-repeat url(${checkmark});
  background-color: #ffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #e1e1e1;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  :checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--dashboard-default);
  }
  :disabled {
    background-color: #e1e1e1;
  }
  margin-left: 16px;
  margin-right: 5px;
`;

// RadioLabel
export const RadioLabel = styled.label``;

// solidBtn(noneRound)
export const SolidBtn = styled.button`
  border: none;
  background-color: var(--dashboard-default);
  color: #ffffff;
  width: fit-content;
  padding: 2vw;
  border-radius: 100vw;
`;
