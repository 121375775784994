import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  CardFooter,
  Button,
} from "reactstrap";
import {
  getMerchantInfo,
  getMileageLog,
  merchantId_api,
  putMerchantSetting,
  userList,
} from "../lib/api";
import { useState } from "react";
import Swal from "sweetalert2";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ko } from "date-fns/esm/locale";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { mileageColumn } from "../data/ticketList";
import PaginationComponent from "../components/pagination";
import { exportExcel, numberWithCommas } from "../components/common";
import DetailsModal from "../components/modal/detailsModal";
import { ExampleCustomInput } from "./ticketCustom";
import { Label1 } from "./lockerChange";
import { ToggleContainer } from "./setting";
import { ToggleBtn } from "./seatList";
import SelectDate from "../components/selectDate";
import { DatePickerStyle } from "./seatInfo";

const StyledContainer = styled.div`
  width: 100%;
  .top-object {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1vh 0;
    .datepicker {
      width: 100%;
      display: flex;
      align-items: center;
      h5 {
        margin: 0 8px;
        font-weight: 400;
      }
      > div > div > button {
        width: 100%;
      }
      select {
        background: #f8fcff;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        padding: 9px 12px;
        font-size: 14px;
        margin: 0 10px;
        height: 38px;
      }
    }
  }

  .phoneNum-click {
    color: #04c9db;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 375px) {
    .top-object {
      width: 100%;
      .datepicker {
        flex-direction: column;
        select {
          width: 100%;
          padding: 9px 12px;
          margin: 0 5px;
          text-align: center;
        }
      }
    }
  }
`;

let merchantId;

const Mileage = ({ setIsLoading }) => {
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().startOf('week').add(1, 'day').toDate());
  const [endDate, setEndDate] = useState(moment().startOf('week').add(7, 'days').toDate());
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [mileageLog, setMileageLog] = useState([]);
  const [logCount, setLogCount] = useState(0);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [mileage, setMileageSetting] = useState({
    mileageRate: 0,
    mileageBaseline: 0,
    mileageAccumulateBaseline: 0,
    usingMileage: 0,
  });

  const getData = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      getMileageData(data.id);
      getMileageInfo(data.id);
    } else {
      Swal.fire("데이터를 불러오는데 실패하였습니다. 다시 시도해주세요");
    }
  };
  const getMileageInfo = async (mid) => {
    const res = await getMerchantInfo(mid);

    if (res.status === 200) {
      setMileageSetting({
        mileageRate: res.data[0].mileageRate,
        mileageBaseline: res.data[0].mileageBaseline,
        mileageAccumulateBaseline: res.data[0].mileageAccumulateBaseline,
        usingMileage: res.data[0].usingMileage,
      });
    } else {
      Swal.fire("데이터를 불러오는데 실패하였습니다. 다시 시도해주세요");
    }
  };

  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  const getMileageData = async (mid) => {
    setIsLoading(true);
    let data;
    if (searchKeyword) {
      let userRes = await userList(mid, searchKeyword);

      data = await getMileageLog(
        mid,
        moment(startDate).format("YYYY-MM-DD 00:00:00"),
        moment(endDate).format("YYYY-MM-DD 23:59:59"),
        currentPage,
        postsPerPage,
        userRes.id
      );
    } else {
      data = await getMileageLog(
        mid,
        moment(startDate).format("YYYY-MM-DD 00:00:00"),
        moment(endDate).format("YYYY-MM-DD 23:59:59"),
        currentPage,
        postsPerPage,
        "all"
      );
    }

    if (data.status === 200) {
      setMileageLog(
        data.data.result,
        data.data.result.map((item) => {
          item.usingTime = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
          item.price = numberWithCommas(item.amount);
          item.phoneNumTag = (
            <div
              onClick={() => {
                sendDetailData(item.phoneNum);
              }}
              className="phoneNum-click"
            >
              {item.phoneNum}
            </div>
          );
        })
      );
      setLogCount(data.data.count);
    }
    setIsLoading(false);
  };

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };
  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const handleSingleCheck = (checked, target) => {
    setMileageSetting({ ...mileage, [target.name]: checked ? 1 : 0 });
  };

  const handleOnChange = (e) => {
    setMileageSetting({ ...mileage, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await putMerchantSetting({ ...mileage, merchantId });

    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "수정이 완료됐습니다.",
        timer: 2000,
      }).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "수정에 실패했습니다. 다시 시도해주세요.",
        timer: 2000,
      });
    }
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === "") {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const exportExcelData = async () => {
    let data;
    if (searchKeyword) {
      let userRes = await userList(merchantId, searchKeyword);

      data = await getMileageLog(
        merchantId,
        moment(startDate).format("YYYY-MM-DD 00:00:00"),
        moment(endDate).format("YYYY-MM-DD 23:59:59"),
        1,
        logCount,
        userRes.id
      );
    } else {
      data = await getMileageLog(
        merchantId,
        moment(startDate).format("YYYY-MM-DD 00:00:00"),
        moment(endDate).format("YYYY-MM-DD 23:59:59"),
        1,
        logCount,
        "all"
      );
    }

    if (data.status === 200) {
      let excelData = [
        data.data.result,
        data.data.result.map((item) => {
          item.usingTime = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
          item.price = numberWithCommas(item.amount);
          item.phoneNumTag = (
            <div
              onClick={() => {
                sendDetailData(item.phoneNum);
              }}
              className="phoneNum-click"
            >
              {item.phoneNum}
            </div>
          );
        }),
      ];
      exportExcel(excelData[0], startDate, endDate, "마일리지");
    }
  };
  useEffect(() => {
    getData();
  }, [startDate, endDate, currentPage]);

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
        />
      )}
      <Breadcrumbs parent="마일리지 쿠폰 관리" title="마일리지" />
      <Container fluid={true}>
        <StyledContainer>
          <Row>
            <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Card>
                <CardBody>
                  <h5 style={{ marginBottom: "20px" }}>마일리지 설정</h5>
                  <ToggleContainer>
                    <h5>매장 마일리지 사용</h5>
                    <ToggleBtn>
                      <Input
                        id="usingMileage"
                        name="usingMileage"
                        type="checkbox"
                        checked={mileage.usingMileage || ""}
                        onChange={({ target }) =>
                          handleSingleCheck(target.checked, target)
                        }
                      />
                      <span className="onoff-switch"></span>
                    </ToggleBtn>
                  </ToggleContainer>
                  <Label1>
                    <h5>마일리지 적립률(%, 0.0~100.0)</h5>
                    <Input
                      className="form-control m-input digits"
                      type="text"
                      name="mileageRate"
                      placeholder="숫자+. 만 입력해주세요"
                      value={mileage.mileageRate || ""}
                      onChange={handleOnChange}
                    />
                  </Label1>
                  <Label1>
                    <h5>마일리지 사용가능 기준금액</h5>
                    <Input
                      className="form-control m-input digits"
                      type="text"
                      pattern="^[0-9]*$"
                      name="mileageAccumulateBaseline"
                      placeholder="숫자만 입력해주세요"
                      value={mileage.mileageAccumulateBaseline || ""}
                      onChange={handleOnChange}
                    />
                  </Label1>
                  <Label1>
                    <h5>마일리지 적립가능 기준금액</h5>
                    <Input
                      className="form-control m-input digits"
                      type="text"
                      pattern="^[0-9]*$"
                      name="mileageBaseline"
                      placeholder="숫자만 입력해주세요"
                      value={mileage.mileageBaseline || ""}
                      onChange={handleOnChange}
                    />
                  </Label1>
                </CardBody>
                <CardFooter className="text-center">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => handleSubmit(e)}
                  >
                    저장하기
                  </button>
                </CardFooter>
              </Card>
            </Col>
            <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Card>
                <CardBody>
                  <h5 style={{ marginBottom: "20px" }}>
                    마일리지 적립/사용 내역
                  </h5>
                  <Button
                    className="hide-480"
                    onClick={() => exportExcelData()}
                  >
                    엑셀 출력하기
                  </Button>
                  <div className="top-object">
                    <div className="datepicker">
                      <DatePickerStyle style={{ margin: 0 }}>
                        <ReactDatePicker
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                          }}
                          selectsStart
                          locale={ko}
                          startDate={startDate}
                          maxDate={endDate}
                          endDate={endDate}
                          customInput={<ExampleCustomInput />}
                        />
                        <h5> - </h5>
                        <ReactDatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          locale={ko}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          customInput={<ExampleCustomInput />}
                        />
                        <SelectDate
                          setStartDate={(val) => setStartDate(val)}
                          setEndDate={(val) => setEndDate(val)}
                          nowTime={"이번 주"}
                        />
                      </DatePickerStyle>
                    </div>

                    {/* // 기존에 있던 검색 폼으로 추후 검색폼이 다시 생길 수 있기에 남겨둔 상태 
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <FormGroup className="m-0">
                        <Input
                          style={{ width: "300px" }}
                          placeholder="전화번호를 입력해주세요"
                          className="form-control"
                          type="text"
                          defaultValue={searchKeyword}
                          onChange={(e) => handleSearchKeyword(e.target.value)}
                        />
                        <SearchBtn
                          className="btn btn-primary"
                          onClick={() => getMileageData(merchantId)}
                        >
                          <i className="fa fa-search" />
                        </SearchBtn>
                      </FormGroup>
                    </Form> */}
                  </div>
                  <DataTable
                    data={mileageLog}
                    columns={mileageColumn}
                    striped={true}
                    center={true}
                  />
                  {logCount > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage}
                      setPostsPerPageAdd={setPostsPerPageAdd}
                      setPostsPerPageAdd1={setPostsPerPageAdd1}
                      setPageNum={setPageNum}
                      totalLength={logCount}
                      postsPerPage={postsPerPage}
                      currentPage={currentPage}
                      pageNum={pageNum}
                      postsPerPageAdd={postsPerPageAdd}
                      postsPerPageAdd1={postsPerPageAdd1}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </StyledContainer>
      </Container>
    </Fragment>
  );
};

export default Mileage;
