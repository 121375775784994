// 결제 수단을 한글로 변환하는 함수
export function paymentTypeConversionsKr(paymentType) {
  switch (paymentType) {
    case "cash":
    case "cash-cancel":
      return "현금";
    case "credit":
    case "credit-cancel":
      return "카드";
    case "kakaopay":
    case "kakaopay-cancel":
      return "카카오페이";
    case "payco":
    case "payco-cancel":
      return "페이코";
    case "mileage":
    case "mileage-cancel":
      return "마일리지";
    case "virtual":
      return "가매입/가매출";
    case "toss":
      return "토스";
    case "hecto":
    case "hecto-cancel":
      return "헥토";
    case "naverpay":
    case "naverpay-cancel":
      return "네이버페이";
    case "applepay":
    case "applepay-cancel":
      return "애플페이";
    default:
      return "쿠폰";
  }
}

export const allCho = [
  "ㄱ",
  "ㄴ",
  "ㄷ",
  "ㄹ",
  "ㅁ",
  "ㅂ",
  "ㅅ",
  "ㅇ",
  "ㅈ",
  "ㅊ",
  "ㅋ",
  "ㅌ",
  "ㅍ",
  "ㅎ",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
export const etcCho = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
