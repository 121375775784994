import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
  Label,
} from "reactstrap";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

import {
  deleteSeat,
  getMapViewList,
  getMerchantInfo,
  merchantId_api,
  postCreateSeat,
} from "../lib/api";
import "moment/locale/ko";
import Swal from "sweetalert2";

import { LabelS } from "./ticketList";

import configDB from "../data/customizer/config";
import { ToggleBtn, SeatView, SeatArrayS, TabButton } from "./seatList";
import { Button, Btns, AddBtn, Label1 } from "./lockerChange";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const CardContainer = styled.div`
  .cardBody {
    display: flex;
  }
  .date-pickers {
    display: flex;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      i {
        position: absolute;
        top: 0.8vw;
        right: 0.5vw;
      }
      input {
        width: 80% !important;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .topbar {
      align-items: baseline;
      flex-direction: column;
      .m-0 {
        margin: 3vw 0 !important;
      }
    }
    button {
      margin: 1vw 0 5vw;
    }
    .cardBody {
      flex-direction: column;
    }
  }
`;
const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 13%;
  margin-right: 1vw;
  @media screen and (max-width: 480px) {
    flex-direction: row;
    width: 100%;
  }
`;

const AddBtns = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1vw;
  > button {
    width: 100%;
    margin-bottom: 0.6vw;
  }
  @media screen and (max-width: 480px) {
    flex-direction: row;
  }
`;
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  font-weight: 500;
  margin-bottom: 1vw;
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;

const Seat = styled.div`
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  position: absolute;
  background-color: ${(props) => (props.used ? props.used : "transparent")};
  border: 1px solid #fff;
  width: 77px;
  height: 55px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  opacity: ${(props) => (props.rest ? 0.8 : 1)};
  p {
    color: #fff;
    margin-bottom: 0;
    font-size: 10px;
  }
  .seatType {
    font-size: 1.4vw;
  }
`;

const FontContainer = styled.div`
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  position: absolute;
`;

const Font = styled.p`
  font-size: ${(props) => `${props.font}px`};
  color: #ebebeb;
`;

const FontSizeBtn = styled.button`
  background: #4a99ff;
  color: white;
  margin: 2px;
  border: 1px solid;
  border-radius: 1vw;
`;

const Explan = styled.div`
  button {
    background: white;
    border: 1px solid ${primary};
    border-radius: 1vw;
    width: 24px;
    height: 24px;
    color: ${primary};
    padding: 0;
  }
  div {
    position: absolute;
    z-index: 10;
    background: white;
    border: 1px solid gray;
    padding: 1vw;
    border-radius: 1vw;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 10px;
  > p {
    font-size: 14px;
    margin-right: 1vw;
    margin-bottom: 0;
  }
  label {
    margin-bottom: 0;
  }
`;

const WallContainer = styled.div`
  top: ${(props) =>
    props.min ? `calc(${props.top}px * ${props.ratio})` : `${props.top}px`};
  left: ${(props) =>
    props.min ? `calc(${props.left}px * ${props.ratio})` : `${props.left}px`};
  width: ${(props) =>
    props.min ? `calc(${props.width}px * ${props.ratio})` : `${props.width}px`};
  height: ${(props) =>
    props.min
      ? `calc(${props.height}px * ${props.ratio})`
      : `${props.height}px`};
  position: absolute;
  background-color: #fff;
`;

const SettingWall = styled.div`
  position: absolute;
  margin: 50px;
  width: 55px;
  color: white;
  input {
    width: 100%;
  }
`;
let merchantId = "";

let posX,
  posY = 0;
const SeatChange = ({ setIsLoading }) => {
  const [lists, setLists] = useState([]);
  const [tagModal, setTagModal] = useState(false);
  const [selected, setSelected] = useState();
  const [isType, setIsType] = useState("seat");
  const [isText, setIsText] = useState(true);
  const [basicData, setBasicData] = useState([]);
  const [isExplan, setIsExplan] = useState(false);
  const [isWall, setIsWall] = useState(false);
  const [multiNum, setMultiNum] = useState(null);
  const [floorNum, setFloorNum] = useState(0);
  const [floorList, setFloorList] = useState([]);
  var isMobile = /Mobi/i.test(window.navigator.userAgent);

  useEffect(() => {
    let getId = getMerchantId();
    return () => clearTimeout(getId);
  }, []);

  useEffect(() => {
    if (isMobile) {
      Swal.fire(
        "모바일에서는 좌석배치를 변경할수 없습니다. 변경을 원하실경우 PC에서 시도해주세요 "
      );
    }
  }, []);
  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      const multiNumData = await getMerchantInfo(data.id);
      if (
        multiNumData &&
        multiNumData.status === 200 &&
        multiNumData.data[0].multiKioskFloor > 0
      ) {
        setMultiNum(multiNumData.data[0].multiKioskFloor);
        setFloorNum(1);
        getSeatListApi(data.id, 1);
      } else {
        getSeatListApi(data.id, null);
      }
    }
  };

  const getSeatListApi = async (merchantId, floorData) => {
    setIsLoading(true);
    const res3 = await getMapViewList(merchantId);

    if (res3.status === 200) {
      if (floorData) {
        setFloorList(res3.data.filter((item) => item.floor === +floorData));
      }
      setLists(res3.data);
      setBasicData(res3.data);
    } else {
      Swal.fire("에러가 생겨 제대로 불러오지 못했습니다. 다시 시도해주세요");
    }
    setIsLoading(false);
  };
  // };

  // 드래그 시작시 이벤트
  const dragStartHandler = (e, seat, i) => {
    // if (seat.type === "wall") {
    //   const targetData = document.querySelector(
    //     `.wall${seat.fakeId ? seat.fakeId : seat.id}`
    //   );
    //   const center = {
    //     x: targetData.getBoundingClientRect().left + targetData.clientWidth / 2,
    //     y: targetData.getBoundingClientRect().top + targetData.clientHeight / 2,
    //   };
    //   posX = e.clientX;
    //   posY = e.clientY;

    //   const x = center.x - posX;
    //   const y = center.y - posY;

    //   const rangeX = targetData.clientWidth / 2 - 10;
    //   const rangeY = targetData.clientHeight / 2 - 10;
    //   if (Math.abs(x) > rangeX || Math.abs(y) > rangeY) {
    //     e.stopPropagation();
    //     e.preventDefault();
    //   }
    // } else {
    posX = e.clientX;
    posY = e.clientY;
    // }
  };

  //드래그 중일때
  const dragHandler = (e) => {
    e.target.style.left = `${e.target.offsetLeft + e.clientX - posX}px`;
    e.target.style.top = `${e.target.offsetTop + e.clientY - posY}px`;
    posX = e.clientX;
    posY = e.clientY;
  };

  const dragEndHandler = (e, seat) => {
    e.target.style.left = `${
      e.target.offsetLeft + e.clientX - posX > 0
        ? e.target.offsetLeft + e.clientX - posX < 920 - 77
          ? e.target.offsetLeft + e.clientX - posX
          : 920 - 77
        : 0
    }px`;
    e.target.style.top = `${
      e.target.offsetTop + e.clientY - posY > 0
        ? e.target.offsetTop + e.clientY - posY < 1444 - 55
          ? e.target.offsetTop + e.clientY - posY
          : 1444 - 55
        : 0
    }px`;
    posX = e.clientX;
    posY = e.clientY;
    if (!floorNum) {
      let copyData = [...lists];
      let changeAll = copyData.map(
        (item) =>
          item.id === seat.id &&
          item?.fakeId === seat?.fakeId && {
            ...seat,
            pos_top:
              e.target.offsetTop + e.clientY - posY > 0
                ? e.target.offsetTop + e.clientY - posY < 1444 - 55
                  ? e.target.offsetTop + e.clientY - posY
                  : 1444 - 55
                : 0,
            pos_left:
              e.target.offsetLeft + e.clientX - posX > 0
                ? e.target.offsetLeft + e.clientX - posX < 920 - 77
                  ? e.target.offsetLeft + e.clientX - posX
                  : 920 - 77
                : 0,
          }
      );

      let filter = changeAll.filter(
        (item) =>
          item.pos_top !== lists.pos_top || item.pos_left !== lists.pos_left
      );

      setLists(
        copyData.map((item) =>
          item.id == filter[0].id && item.fakeId == filter[0].fakeId
            ? filter[0]
            : item
        )
      );
    } else {
      let copyData = [...floorList];
      let changeAll = copyData.map(
        (item) =>
          item.id === seat.id &&
          item?.fakeId === seat?.fakeId && {
            ...seat,
            pos_top:
              e.target.offsetTop + e.clientY - posY > 0
                ? e.target.offsetTop + e.clientY - posY < 1444 - 55
                  ? e.target.offsetTop + e.clientY - posY
                  : 1444 - 55
                : 0,
            pos_left:
              e.target.offsetLeft + e.clientX - posX > 0
                ? e.target.offsetLeft + e.clientX - posX < 920 - 77
                  ? e.target.offsetLeft + e.clientX - posX
                  : 920 - 77
                : 0,
          }
      );

      let filter = changeAll.filter(
        (item) =>
          item.pos_top !== lists.pos_top || item.pos_left !== lists.pos_left
      );

      setFloorList(
        copyData.map((item) =>
          item.id == filter[0].id && item.fakeId == filter[0].fakeId
            ? filter[0]
            : item
        )
      );
      setLists(
        lists.map((item) =>
          item.id == filter[0].id && item.fakeId == filter[0].fakeId
            ? filter[0]
            : item
        )
      );
    }
  };

  const handleSeatOnClick = (seat, e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsType(seat.type);
    tagToggle();
    setSelected(seat);
  };
  const tagToggle = () => {
    setTagModal(!tagModal);
  };
  const handleError = (msg) => {
    Swal.fire({
      icon: "error",
      timer: 2000,
      title: "현재 사용중인 좌석은 변경이 불가능합니다.",
    });
  };
  const handleAddSeat = () => {
    const fakeId = new Date().getTime();

    const newObj = {
      fakeId,
      number: lists.length + 1,
      name: "",
      status: 0,
      type: "seat",
      level: 1,
      pos_top: 0,
      pos_left: 0,
      id: 0,
      floor: floorNum ? floorNum : null,
      seatType: 0,
    };
    setLists([...lists, newObj]);
    setFloorList([...floorList, newObj]);
  };

  const handleAddSeminar = () => {
    const fakeId = new Date().getTime();

    const newObj = {
      fakeId,
      number: lists.length + 1,
      name: "세미나실",
      status: 0,
      type: "seminar",
      level: 1,
      pos_top: 0,
      pos_left: 0,
      id: 0,
      seatType: 0,
      floor: floorNum ? floorNum : null,
    };
    setLists([...lists, newObj]);
    setFloorList([...floorList, newObj]);
  };

  const handleAddWall = () => {
    const fakeId = new Date().getTime();

    const newObj = {
      fakeId,
      number: lists.length + 1,
      name: "",
      status: 0,
      type: "wall",
      level: 1,
      pos_top: 0,
      pos_left: 500,
      id: 0,
      floor: floorNum ? floorNum : null,
      seatType: 0,
      pos_height: 200,
      pos_width: 50,
    };
    setLists([...lists, newObj]);
    setFloorList([...floorList, newObj]);
  };

  const handleAddFont = () => {
    const fakeId = new Date().getTime();

    const newObj = {
      fakeId,
      number: lists.length + 1,
      name: "텍스트를 입력해주세요",
      status: 0,
      type: "text",
      level: 1,
      pos_top: 0,
      pos_left: 500,
      id: 0,
      seatType: 0,
      floor: floorNum ? floorNum : null,
      font_size: 15,
    };
    setLists([...lists, newObj]);
    setFloorList([...floorList, newObj]);
  };

  const handleOnchange = ({ target }) => {
    if (
      target.name === "seatType" ||
      target.name === "number" ||
      target.name === "level"
    ) {
      setSelected({ ...selected, [target.name]: +target.value });
    } else if (target.name === "status") {
      if (target.value === "idle") {
        setSelected({ ...selected, status: 1 });
      } else {
        setSelected({ ...selected, status: "idle" });
      }
    } else if (target.name === "isType") {
      if (target.value === "seminar") {
        setSelected({ ...selected, type: "seminar", level: 1, seatType: 0 });
      } else {
        setSelected({ ...selected, type: "seat", level: 1, seatType: 0 });
      }
      setIsType(target.value);
    } else if (target.name === "seatAmount" && target.value === "") {
      setSelected({ ...selected, seatAmount: null });
    } else if (target.name === "floor") {
      setSelected({ ...selected, floor: +target.value });
    } else {
      setSelected({ ...selected, [target.name]: target.value });
    }
  };

  const seatInfoChange = () => {
    if (floorNum) {
      let copyData = [...floorList];

      let filterChangeData = copyData
        .map((item) =>
          (+item.id === +selected.id && +item?.fakeId === +selected?.fakeId) ||
          (!selected?.fakeId && +item.id === +selected.id)
            ? selected
            : item
        )
        .filter((list) => +list.floor === +floorNum);

      let ListChangeData = lists.map((item) =>
        (+item.id === +selected.id && +item?.fakeId === +selected?.fakeId) ||
        (!selected?.fakeId && +item.id === +selected.id)
          ? selected
          : item
      );

      setFloorList(filterChangeData);
      setLists(ListChangeData);
      setIsType("seat");
      tagToggle();
      setSelected();
    } else {
      let copyData = [...lists];

      let filterChangeData = copyData.map((item) =>
        (+item.id === +selected.id && +item?.fakeId === +selected?.fakeId) ||
        (!selected?.fakeId && +item.id === +selected.id)
          ? selected
          : item
      );

      setLists(filterChangeData);
      setIsType("seat");
      tagToggle();
      setSelected();
    }
  };

  const seatChangeCancel = () => {
    tagToggle();
    setSelected();
    setIsType("seat");
  };

  const seatDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (selected.id === 0) {
      let changeList;
      if (floorNum) {
        let copyData = [...floorList];
        changeList = copyData.filter(
          (item) => item?.fakeId !== selected?.fakeId
        );

        let ListChangeData = lists.filter(
          (item) => item?.fakeId !== selected?.fakeId
        );

        setFloorList(changeList);
        setLists(ListChangeData);

        tagToggle();
        setSelected();
        setIsType("seat");
      } else {
        let copyData = [...lists];
        changeList = copyData.filter(
          (item) => item?.fakeId !== selected?.fakeId
        );

        setLists(changeList);

        tagToggle();
        setSelected();
        setIsType("seat");
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "좌석을 삭제하시겠습니까?",
        text: "저장하지 않은 내용은 초기화 됩니다. 변경 후 삭제해주세요",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: "#F53C14 ",
        cancelButtonColor: primary,
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = await deleteSeat(selected.id, selected.merchantId);

          if (data.status === 200) {
            Swal.fire({
              title: "삭제가 완료되었습니다.",
              timer: 2000,
            }).then(
              getSeatListApi(merchantId, floorNum ? floorNum : ""),
              setSelected()
            );
          }
        }
      });
    }
  };

  const handleOnSubmit = async () => {
    let copyData = [...lists];
    let changeFilterArr = copyData.filter(
      (item, i) =>
        (item.id && item?.name != basicData[i]?.name) ||
        (item.id && item?.type != basicData[i]?.type) ||
        (item.id && item?.number != basicData[i]?.number) ||
        (item.id && item?.level != basicData[i]?.level) ||
        (item.id && item?.seatType != basicData[i]?.seatType) ||
        (item.id && item?.block != basicData[i]?.block) ||
        (item.id && item?.pos_left != basicData[i]?.pos_left) ||
        (item.id && item?.pos_top != basicData[i]?.pos_top) ||
        (item.id && item?.pos_width != basicData[i]?.pos_width) ||
        (item.id && item?.pos_height != basicData[i]?.pos_height) ||
        (item.id && item?.font_size != basicData[i]?.font_size) ||
        (item.id && item?.status != basicData[i]?.status) ||
        (item.id && item?.seatAmount != basicData[i]?.seatAmount) ||
        (item.id && item?.floor != basicData[i]?.floor)
    );

    let newData = copyData.filter((item) => item.fakeId);

    let changeData = changeFilterArr.concat(newData);

    const res = await postCreateSeat({ seat: changeData, merchantId });
    try {
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "변경이 완료됐습니다.",
          timer: 3000,
        }).then(() => window.location.reload());
      } else if (res) {
        Swal.fire({
          icon: "error",
          title: "변경에 실패했습니다. 다시 시도해주세요.",
          timer: 3000,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "변경에 실패했습니다. 다시 시도해주세요.",
        timer: 3000,
      });
    }
  };
  const handleReset = () => {
    Swal.fire({
      title: "저장하지 않은 데이터는 사라집니다.",
      text: "변경을 취소하시겠습니까?",
      showCancelButton: true,
      confirmButtonColor: "#F53C14 ",
      cancelButtonColor: primary,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(() => window.location.reload());
  };

  //가벽 리사이즈(현재 사용x)
  const resize = (e, direction, ref, d, index, seat) => {
    e.stopPropagation();
    e.preventDefault();

    let copyData = [...lists];
    let dataChange = copyData.map((item, idx) =>
      (item.fakeId && item.fakeId === seat.fakeId) ||
      (item.id !== 0 && item.id === seat.id)
        ? {
            ...item,
            pos_width: item.pos_width + d.width,
            pos_height: item.pos_height + d.height,
          }
        : { ...item }
    );

    setLists(dataChange);
  };

  const font_click = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    setIsText(true);
    setSelected(idx);
  };
  const close = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setIsText(false);
    setSelected(null);
  };

  const fontChange = (e, idx) => {
    if (floorNum) {
      setFloorList(
        floorList.map((item, i) =>
          i == idx ? { ...item, name: e.target.value } : item
        )
      );

      setLists(
        lists.map((item, i) =>
          (item.fakeId && item.fakeId === +e.target.id) ||
          (!item.fakeId && item.id === +e.target.id)
            ? { ...item, name: e.target.value }
            : item
        )
      );
    } else {
      setLists(
        lists.map((item, i) =>
          i == idx ? { ...item, name: e.target.value } : item
        )
      );
    }
  };

  const fontSizeChange = (e, size, idx) => {
    if (floorList) {
      setFloorList(
        floorList.map((item, i) =>
          i == idx ? { ...item, font_size: item.font_size + size } : item
        )
      );

      setLists(
        lists.map((item, i) =>
          (item.fakeId && item.fakeId === +e.target.id) ||
          (item.id !== 0 && item.id === +e.target.id)
            ? { ...item, font_size: item.font_size + size }
            : item
        )
      );
    }
    setLists(
      lists.map((item, i) =>
        i == idx ? { ...item, font_size: item.font_size + size } : item
      )
    );
  };
  const deleteColor = "#F53C14";

  const deleteItem = (e, seat) => {
    e.preventDefault();

    if (seat.fakeId) {
      Swal.fire({
        icon: "warning",
        title: "해당 아이템을 삭제하시겠습니까?",
        showCancelButton: true,
        confirmButtonColor: "#F53C14 ",
        cancelButtonColor: primary,
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLists(lists.filter((item) => item.fakeId !== seat.fakeId));
          if (floorNum) {
            setFloorList(
              floorList.filter((item) => item.fakeId !== seat.fakeId)
            );
          }
        }
      });
    } else {
      Swal.fire({
        title: "해당 아이템을 삭제하시겠습니까?",
        text: "저장하지 않은 내용은 초기화 됩니다. 변경 후 삭제해주세요",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F53C14 ",
        cancelButtonColor: primary,
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = await deleteSeat(seat.id, merchantId);

          if (data.status === 200) {
            Swal.fire({
              title: "삭제가 완료되었습니다.",
              timer: 2000,
            }).then(getSeatListApi(merchantId), setSelected());
          }
        }
      });
    }
  };

  const explan = () => {
    setIsExplan(!isExplan);
  };

  const wallClick = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    setIsWall(true);
    setSelected(idx);
  };

  const wallClose = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setIsWall(false);
    setSelected(null);
  };
  const wallHeightChange = (e, idx) => {
    if (floorNum) {
      setFloorList(
        floorList.map((item, i) =>
          i == idx ? { ...item, pos_height: e.target.value } : item
        )
      );
      setLists(
        lists.map((item, i) =>
          (item.fakeId && item.fakeId === +e.target.id) ||
          (item.id !== 0 && item.id === +e.target.id)
            ? { ...item, pos_height: e.target.value }
            : item
        )
      );
    } else {
      setLists(
        lists.map((item, i) =>
          i == idx ? { ...item, pos_height: e.target.value } : item
        )
      );
    }
  };

  const wallWidthChange = (e, idx) => {
    if (floorNum) {
      setFloorList(
        floorList.map((item, i) =>
          i == idx ? { ...item, pos_width: e.target.value } : item
        )
      );
      setLists(
        lists.map((item, i) =>
          (item.fakeId && item.fakeId === +e.target.id) ||
          (item.id !== 0 && item.id === +e.target.id)
            ? { ...item, pos_width: e.target.value }
            : item
        )
      );
    } else {
      setLists(
        lists.map((item, i) =>
          i == idx ? { ...item, pos_width: e.target.value } : item
        )
      );
    }
  };

  useEffect(() => {
    if (merchantId) {
      getSeatListApi(merchantId, floorNum);
    }
  }, [floorNum]);

  return (
    <Fragment>
      {tagModal && selected && (
        <Modal centered isOpen={tagModal} toggle={tagToggle}>
          <ModalHeader className="modal-title" toggle={tagToggle}>
            좌석정보 수정
          </ModalHeader>
          <ModalBody>
            <Label1>
              <select value={isType} name="isType" onChange={handleOnchange}>
                <option defaultChecked value="seat">
                  좌석
                </option>
                <option value="seminar">세미나실</option>
              </select>
            </Label1>
            <Label1>
              {isType === "seat" ? "좌석" : "세미나실"} 번호
              <Input
                type="text"
                name="number"
                value={selected?.number || 0}
                onChange={handleOnchange}
              />
            </Label1>
            <Label1>
              {isType === "seat" ? "좌석" : "세미나실"} 이름:
              <Input
                type="text"
                name="name"
                value={selected?.name || ""}
                onChange={handleOnchange}
              />
            </Label1>
            {selected?.Type === "seminar" ||
              (isType === "seminar" && (
                <Label1>
                  세미나실 예약 가격(30분)
                  <Input
                    type="text"
                    name="seatAmount"
                    value={selected?.seatAmount || ""}
                    onChange={handleOnchange}
                  />
                </Label1>
              ))}
            {floorNum && (
              <Label1>
                층 설정
                <Input
                  type="text"
                  name="floor"
                  value={selected?.floor || ""}
                  onChange={handleOnchange}
                />
              </Label1>
            )}
            {isType === "seat" && (
              <>
                <Label1>
                  좌석 등급
                  <Input
                    type="number"
                    name="level"
                    value={selected?.level || 1}
                    min="1"
                    max="4"
                    onChange={handleOnchange}
                  />
                </Label1>
                <RadioContainer>
                  <p>좌석 타입</p>
                  <LabelS>
                    <input
                      type="radio"
                      name="seatType"
                      value={1}
                      onChange={handleOnchange}
                      checked={selected?.seatType === 1}
                    />
                    고정석
                  </LabelS>
                  <LabelS>
                    <input
                      type="radio"
                      name="seatType"
                      value={0}
                      onChange={handleOnchange}
                      checked={selected?.seatType === 0}
                    />
                    자유석
                  </LabelS>
                </RadioContainer>
              </>
            )}
            <ToggleContainer>
              {isType === "seat" ? "좌석" : "세미나실"} 사용불가 설정
              <ToggleBtn>
                <input
                  type="checkbox"
                  name="status"
                  onChange={handleOnchange}
                  value={selected.status || ""}
                  checked={
                    selected.status === 1 || selected?.status === "block"
                  }
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "10px",
              }}
            >
              <Button color="#fff" onClick={seatChangeCancel}>
                취소
              </Button>
              <Button color={primary} onClick={seatInfoChange}>
                확인
              </Button>
              <Button color="#F53C14" onClick={seatDelete}>
                삭제
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <Breadcrumb parent="좌석 및 사물함" title="좌석 배치 변경" />
      <Container fluid={true}>
        <CardContainer>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="cardBody">
                  <BtnContainer>
                    <AddBtns>
                      <AddBtn onClick={handleAddSeat}>좌석 추가</AddBtn>
                      <AddBtn onClick={handleAddSeminar}>세미나실 추가</AddBtn>
                      <AddBtn onClick={handleAddWall}>가벽 추가</AddBtn>
                      <AddBtn onClick={handleAddFont}>텍스트 추가</AddBtn>
                    </AddBtns>
                    <Explan>
                      <button onClick={() => explan()}>?</button>
                      {isExplan && (
                        <div>
                          좌석 배치 변경 방법
                          <br /> 1. 좌석&세미나
                          <br />
                          - 드래그 : 좌석 위치 변경
                          <br />
                          - 우클릭 : 좌석 삭제
                          <br />
                          - 클릭 : 좌석 정보 변경
                          <br /> 2. 가벽
                          <br />
                          - 드래그 : 중심부 - 가벽 위치 변경, 끝부분 -
                          가벽사이즈 변경
                          <br />
                          - 우클릭 : 가벽 삭제
                          <br /> 3. 문자
                          <br />
                          - 드래그 : 문자위치변경
                          <br />
                          - 우클릭 : 문자 삭제
                          <br />- 클릭 : 문자 정보 변경 (바로 반영)
                        </div>
                      )}
                    </Explan>
                  </BtnContainer>
                  <div>
                    {multiNum && (
                      <div className="floorBtn">
                        {[...Array(multiNum)].map((item, index) => (
                          <TabButton
                            selected={floorNum === index + 1}
                            onClick={() => setFloorNum(index + 1)}
                            value={index + 1}
                          >
                            {index + 1}층
                          </TabButton>
                        ))}
                      </div>
                    )}
                    <SeatView>
                      <SeatArrayS>
                        {(floorNum ? floorList : lists)?.map((seat, index) => (
                          <React.Fragment>
                            {seat.type === "seat" && seat.status === 0 && (
                              <Seat
                                key={seat.id == 0 ? seat.fakeId : seat.id}
                                onClick={(e) => handleSeatOnClick(seat, e)}
                                top={seat.pos_top}
                                left={seat.pos_left}
                                draggable
                                onDoubleClick={(e) => seatDelete(e)}
                                onDragStart={(e) => dragStartHandler(e, seat)}
                                onDrag={dragHandler}
                                onDragEnd={(e) => dragEndHandler(e, seat)}
                                onContextMenu={(e) => deleteItem(e, seat)}
                              >
                                <p>
                                  {seat.number}.{seat.name}
                                </p>
                              </Seat>
                            )}

                            {seat.type === "seat" && seat.status === "idle" && (
                              <Seat
                                key={seat.id == 0 ? seat.fakeId : seat.id}
                                onClick={(e) => handleSeatOnClick(seat, e)}
                                top={seat.pos_top}
                                left={seat.pos_left}
                                draggable
                                onDragStart={(e) => dragStartHandler(e, seat)}
                                onDrag={dragHandler}
                                onDragEnd={(e) => dragEndHandler(e, seat)}
                                onTouchStart={(e) => dragStartHandler(e, seat)}
                                onContextMenu={(e) => deleteItem(e, seat)}
                                // onTouchMove={TouchHandle}
                              >
                                <p>
                                  {seat.number}.{seat.name}
                                </p>
                              </Seat>
                            )}

                            {seat.type === "seat" &&
                              seat.status === "using" &&
                              seat.seatType === 1 && (
                                <Seat
                                  key={seat.id == 0 ? seat.fakeId : seat.id}
                                  onClick={handleError}
                                  used="#1BBABC"
                                  top={seat.pos_top}
                                  left={seat.pos_left}
                                  draggable
                                  onDragStart={handleError}
                                >
                                  <p>
                                    {seat.number}.{seat.name}
                                  </p>
                                </Seat>
                              )}
                            {seat.type === "seat" &&
                              seat.status === "using" &&
                              seat.seatType === 0 && (
                                <Seat
                                  key={seat.id == 0 ? seat.fakeId : seat.id}
                                  onClick={handleError}
                                  used={
                                    seat.ticketType === 1
                                      ? "#616161"
                                      : seat.ticketType === 2
                                      ? "#FF8F00"
                                      : "#00838F"
                                  }
                                  top={seat.pos_top}
                                  left={seat.pos_left}
                                  draggable
                                  onDragStart={handleError}
                                >
                                  <p>
                                    {seat.number}.{seat.name}
                                  </p>
                                </Seat>
                              )}
                            {seat.type === "seat" && seat.status === "rest" && (
                              <Seat
                                key={seat.id == 0 ? seat.fakeId : seat.id}
                                onClick={handleError}
                                rest
                                used={
                                  seat.ticketType === 1
                                    ? "#B0BEC5"
                                    : seat.ticketType === 2
                                    ? "#FFAB91"
                                    : "#80DEEA"
                                }
                                top={seat.pos_top}
                                left={seat.pos_left}
                                draggable
                                onDragStart={handleError}
                              >
                                <p>
                                  {seat.number}.{seat.name}
                                </p>
                              </Seat>
                            )}
                            {/* {seat.type === "seminar" &&
                            seminarArr?.map((li) =>
                              li.seminarId === seat.id ? (
                                <Seat
                                  key={li.id}
                                  onClick={handleError}
                                  used="#616161"
                                  top={seat.pos_top}
                                  left={seat.pos_left}
                                  draggable
                                  onDragStart={handleError}
                                >
                                  <p>
                                    {seat.number}.{seat.name}
                                  </p>
                                </Seat>
                              ) : (
                                <Seat
                                  key={li.id}
                                  onClick={(e) => {
                                    handleSeatOnClick(seat, e);
                                  }}
                                  top={seat.pos_top}
                                  left={seat.pos_left}
                                  draggable
                                  onDragStart={(e) => dragStartHandler(e, seat)}
                                  onDrag={dragHandler}
                                  onDragEnd={(e) => dragEndHandler(e, seat)}
                                  onContextMenu={(e) => deleteItem(e, seat)}
                                >
                                  <p>
                                    {seat.number}.{seat.name} {seat.type}
                                  </p>
                                </Seat>
                              )
                            )} */}
                            {seat.type === "seat" &&
                              (seat.status === "block" ||
                                seat.status === 1) && (
                                <Seat
                                  key={seat.id == 0 ? seat.fakeId : seat.id}
                                  used="#979797"
                                  onClick={(e) => handleSeatOnClick(seat, e)}
                                  top={seat.pos_top}
                                  left={seat.pos_left}
                                  draggable
                                  onDragStart={(e) => dragStartHandler(e, seat)}
                                  onDrag={dragHandler}
                                  onDragEnd={(e) => dragEndHandler(e, seat)}
                                  onContextMenu={(e) => deleteItem(e, seat)}
                                >
                                  <p>
                                    {seat.number}.{seat.name}
                                  </p>
                                </Seat>
                              )}
                            {seat.type === "seminar" && (
                              <Seat
                                key={seat.id == 0 ? seat.fakeId : seat.id}
                                used={
                                  seat.status === "block" || seat.status === 1
                                    ? "#979797"
                                    : null
                                }
                                onClick={(e) => {
                                  handleSeatOnClick(seat, e);
                                }}
                                top={seat.pos_top}
                                left={seat.pos_left}
                                draggable
                                onDragStart={(e) => dragStartHandler(e, seat)}
                                onDrag={dragHandler}
                                onDragEnd={(e) => dragEndHandler(e, seat)}
                                onContextMenu={(e) => deleteItem(e, seat)}
                              >
                                <p>
                                  {seat.number}.{seat.name} {seat.type}
                                </p>
                              </Seat>
                            )}

                            {seat.type === "wall" && (
                              <WallContainer
                                className={`wall${
                                  seat.id == 0 ? seat.fakeId : seat.id
                                }`}
                                top={seat.pos_top}
                                left={seat.pos_left}
                                width={seat.pos_width}
                                height={seat.pos_height}
                                onClick={(e) => wallClick(e, index)}
                                draggable
                                onDragStart={(e) =>
                                  dragStartHandler(e, seat, index)
                                }
                                onDrag={dragHandler}
                                onDragEnd={(e) => dragEndHandler(e, seat)}
                                onContextMenu={(e) => deleteItem(e, seat)}
                              >
                                {isWall && selected == index && (
                                  <SettingWall>
                                    <Label>가로</Label>
                                    <input
                                      id={seat.fakeId ? seat.fakeId : seat.id}
                                      value={seat.pos_width}
                                      onChange={(e) =>
                                        wallWidthChange(e, index)
                                      }
                                    />
                                    <Label>세로</Label>
                                    <input
                                      id={seat.fakeId ? seat.fakeId : seat.id}
                                      value={seat.pos_height}
                                      onChange={(e) =>
                                        wallHeightChange(e, index)
                                      }
                                    />
                                    <FontSizeBtn
                                      style={{ backgroundColor: "#8D5FFA" }}
                                      onClick={(e) => wallClose(e, seat)}
                                    >
                                      닫기
                                    </FontSizeBtn>
                                  </SettingWall>
                                )}
                              </WallContainer>
                            )}

                            {seat.type === "text" && (
                              <FontContainer
                                top={seat.pos_top}
                                left={seat.pos_left}
                                draggable
                                onDragStart={(e) => dragStartHandler(e, seat)}
                                onDrag={dragHandler}
                                onDragEnd={(e) => dragEndHandler(e, seat)}
                                onTouchStart={(e) => dragStartHandler(e, seat)}
                                onClick={(e) => font_click(e, index)}
                                onContextMenu={(e) => deleteItem(e, seat)}
                              >
                                <Font font={seat.font_size}>{seat.name}</Font>
                                {isText && selected == index && (
                                  <div>
                                    <Input
                                      id={seat.fakeId ? seat.fakeId : seat.id}
                                      value={seat.name}
                                      onChange={(e) => fontChange(e, index)}
                                    />
                                    <FontSizeBtn
                                      id={seat.fakeId ? seat.fakeId : seat.id}
                                      onClick={(e) =>
                                        fontSizeChange(e, -10, index)
                                      }
                                    >
                                      -10
                                    </FontSizeBtn>
                                    <FontSizeBtn
                                      id={seat.fakeId ? seat.fakeId : seat.id}
                                      onClick={(e) =>
                                        fontSizeChange(e, -1, index)
                                      }
                                    >
                                      -1
                                    </FontSizeBtn>
                                    <FontSizeBtn
                                      id={seat.fakeId ? seat.fakeId : seat.id}
                                      onClick={(e) =>
                                        fontSizeChange(e, 1, index)
                                      }
                                    >
                                      +1
                                    </FontSizeBtn>
                                    <FontSizeBtn
                                      id={seat.fakeId ? seat.fakeId : seat.id}
                                      onClick={(e) =>
                                        fontSizeChange(e, 10, index)
                                      }
                                    >
                                      +10
                                    </FontSizeBtn>
                                    <FontSizeBtn
                                      style={{ backgroundColor: "#8D5FFA" }}
                                      onClick={(e) => close(e)}
                                    >
                                      닫기
                                    </FontSizeBtn>
                                  </div>
                                )}
                              </FontContainer>
                            )}
                          </React.Fragment>
                        ))}
                      </SeatArrayS>
                    </SeatView>
                  </div>
                  <Btns>
                    <Button onClick={handleOnSubmit}>변경하기</Button>
                    <Button onClick={handleReset}>취소하기</Button>
                  </Btns>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </Fragment>
  );
};

export default SeatChange;
