import React from "react";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { postSendOneMessage } from "../../lib/api";
import { Button, Label1 } from "../../pages/lockerChange";
import { InputS } from "../../pages/ticketList";

const Btns = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const MessageSendModal = ({ isOpen, onClose, phoneNum, mid }) => {
  const [msg, setMsg] = useState("");

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (msg.length > 0) {
        const res = await postSendOneMessage({
          phoneNum: [phoneNum],
          mid,
          msg,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "메세지 전송이 완료됐습니다.",
            timer: 2000,
          }).then(() => {
            setMsg("");
            onClose();
          });
        } else if (res.status === 202) {
          Swal.fire({
            icon: "error",
            title: "메세지포인트가 부족합니다. 충전후 사용 부탁드립니다.",
            timer: 3000,
          });
        } else if (res.status === 203) {
          Swal.fire({
            icon: "error",
            title: "메세지 수신을 차단한 회원입니다.",
            timer: 3000,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "메세지를 입력해주세요.",
          timer: 2000,
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "전송에 실패했습니다.",
        timer: 2000,
      }).then(() => {
        setMsg("");
      });
    }
  };

  return (
    <>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader className="modal-title" toggle={onClose}>
          {phoneNum} 문자발송
        </ModalHeader>
        <ModalBody>
          <Label1>
            <h5>전송메시지</h5>
            <InputS
              type="text"
              placeholder="메세지를 입력해주세요."
              name="msg"
              onChange={({ target }) => setMsg(target.value)}
              value={msg || ""}
            />
          </Label1>
          <Btns>
            <Button color="#fff" onClick={onClose}>
              취소
            </Button>
            <Button color="#722DE2" onClick={handleOnSubmit}>
              전송
            </Button>
          </Btns>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MessageSendModal;
