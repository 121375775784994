import React, { forwardRef, Fragment, useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import moment from "moment";
import styled from "styled-components";

import { numberWithCommas } from "../../common";
import SelectDate from "../../selectDate";
import {
  getPayAllList,
  merchantId_api,
  ticketSales,
  ticketSalesAmount,
} from "../../../lib/api";
import Arrow from "../../../assets/images/dashboard/arrow.svg";
import Schedule from "../../../assets/images/dashboard/schedule.svg";
import { DashboardCard } from "../../commonStyle";

const StyleComponent = styled(DashboardCard)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .topContainer {
    width: 100%;
    > div > p {
      &:first-child {
        font-weight: 700;
        line-height: 1.5;
        font-size: var(--dashboard-main);
      }
    }
    > p {
      font-size: var(--dashboard-count);
      color: var(--dashboard-default);
      font-weight: 700;
    }
  }

  .flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 15vw);
  }

  #circlechart {
    width: 14vw;
    margin-right: 1vw;
  }

  @media screen and (max-width: 805px) {
    .topContainer {
      > div > p {
        &:first-child {
          font-size: 5.42vw;
        }
      }
      > p {
        font-size: 8.88vw;
        margin: 0;
      }
    }
    .flex {
      flex-direction: column;
    }
    .margin-top {
      margin-top: 0;
    }
    #circlechart {
      width: 100%;
      margin-right: 0;
    }

    .flex-col {
      width: 100%;
    }
  }
`;

export const DatePickerStyle = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  margin: 0 10px;
  h5 {
    margin: 0 8px;
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 5px 0 0;
    > div > div > button {
      padding: 6px 10px;
      font-size: 13px;
      > svg {
        width: 17px;
      }
    }
    select {
      padding: 9px 5px;
    }
  }
`;

export const DatePickerRenewal = styled.div`
  display: flex;
  border: 1px solid #dde4eb;
  background-color: #ffffff !important;
  border-radius: 0.41vw;
  font-size: 0.83vw;
  align-items: center;
  .react-datepicker__input-container {
    button {
      background: none !important;
      color: #686c71;
      border: none;
      font-size: 0.83vw;
      :focus {
        box-shadow: none;
      }
      :active {
        box-shadow: none !important;
        color: #686c71 !important ;
        border: none;
      }
    }
  }

  h5 {
    margin-bottom: 0;
  }
  select {
    padding: 0 1.3vw 0 12px;
    -webkit-appearance: none; /* for chrome */
    -moz-appearance: none; /*for firefox*/
    appearance: none;
    border: none;
    border-right: 1px solid #dde4eb;
    color: #686c71;
    background: url(${Arrow}) no-repeat no-repeat 90% 50%;
  }
  > img {
    padding-left: 0.3vw;
  }
  @media screen and (max-width: 805px) {
    height: 11.11vw;
    border-radius: 2.22vw;
    padding: 2.22vw 3.55vw;
    margin: 4.44vw 0;
    .react-datepicker-wrapper {
      display: flex !important;
      align-items: center;
    }
    .react-datepicker__input-container {
      display: flex;
      justify-content: center;
      button {
        font-size: 4.44vw;
        padding: 0;
      }
    }
    h5 {
      margin-top: 0;
    }
    img {
      width: 5vw;
    }
  }
`;

const SaleData = styled.div`
  display: flex;
  font-size: 0.83vw;
  background: ${(props) => `${props.color}10`};
  border-radius: 100px;
  height: 2.13vw;
  .title {
    width: 5vw;
    display: flex;
    align-items: center;
    background: ${(props) => props.color};
    justify-content: center;
    border-radius: 100px;
    padding: 0 0.625vw;
    > p {
      color: #ffffff;
      font-size: 0.83vw;
      font-weight: 600;
      margin-bottom: 0;
    }
    &.ticketTitle {
      width: 10vw;
    }
  }
  .data {
    display: grid;
    width: 100%;
    align-items: center;
    p {
      height: 60%;
      text-align: center;
      margin-bottom: 0;
      font-size: 0.83vw;
      font-weight: 600;
      color: #686c71;
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #dde4eb;
      }
    }
    &.ticketData {
      grid-template-columns: 1fr 2fr;
      p {
        &:nth-child(2) {
          border-right: none;
        }
      }
    }
  }
  @media screen and (max-width: 805px) {
    height: 11.11vw;
  }
`;

const SaleDataMobile = styled.div`
  font-size: 3.33vw;
  .title {
    display: flex;
    align-items: center;

    span {
      display: block;
      width: 2.22vw;
      height: 2.22vw;
      border-radius: 100vw;
      background: ${(props) => `${props.color}`};
    }
    p {
      margin-left: 2.22vw;
      margin-bottom: 0;
      font-weight: 600;
    }
  }
  .data {
    background: ${(props) => `${props.color}10`};
    border-radius: 100px;
    height: 11.11vw;
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    margin: 2.22vw 0;
    p {
      height: 60%;
      text-align: center;
      margin-bottom: 0;
      font-weight: 600;
      color: #686c71;
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #dde4eb;
      }
    }
    &.ticketData {
      justify-content: space-around;
      p {
        &:nth-child(2) {
          border-right: none;
        }
      }
    }
  }

  &:last-child {
    .data {
      margin-bottom: 0;
    }
  }
`;

const TicketSale = ({
  paddingStyle,
  isMobile,
  setIsLoading,
  mid,
  startDate,
  endDate,
}) => {
  const defaultData = [
    {
      type: 1,
      ticketName: "일회권",
      color: "#EC6E85",
      total_amount: 0,
      count: 0,
    },
    {
      type: 2,
      ticketName: "기간권",
      color: "#6ead69",
      total_amount: 0,
      count: 0,
    },
    {
      type: 3,
      ticketName: "시간정액권",
      color: "#8E78AA",
      total_amount: 0,
      count: 0,
    },
    {
      type: 5,
      ticketName: "사물함",
      color: "#F2A354",
      total_amount: 0,
      count: 0,
    },
  ];
  const [ticketSaleData, setTicketSaleData] = useState([...defaultData]);
  const [ticketArray, setTicketArray] = useState([...defaultData]);
  const [ticketSaleAmountData, setTicketSaleAmountData] = useState([]);
  const getTicketSaleData = async () => {
    //ticket count
    const ticketData = await ticketSales(
      mid,
      moment(startDate).format("YYYY-MM-DD 00:00:00"),
      moment(endDate).format("YYYY-MM-DD 23:59:59")
    );

    //ticket totalAmount
    const ticketAmountData = await ticketSalesAmount(
      mid,
      moment(startDate).format("YYYY-MM-DD 00:00:00"),
      moment(endDate).format("YYYY-MM-DD 23:59:59")
    );

    if (ticketData.status === 200 || ticketAmountData.status === 200) {
      if (ticketData.data.length !== 0) {
        let copyData = [...defaultData];
        for (let i = 0; ticketData.data.length > i; i++) {
          if (ticketData.data[i].type === 1) {
            copyData[0].count = ticketData.data[i].count;
            copyData[0].total_amount = ticketAmountData.data[i].total_amount;
          } else if (ticketData.data[i].type === 2) {
            copyData[1].count = ticketData.data[i].count;
            copyData[1].total_amount = ticketAmountData.data[i].total_amount;
          } else if (ticketData.data[i].type === 3) {
            copyData[2].count = ticketData.data[i].count;
            copyData[2].total_amount = ticketAmountData.data[i].total_amount;
          } else if (ticketData.data[i].type === 5) {
            copyData[3].count = ticketData.data[i].count;
            copyData[3].total_amount = ticketAmountData.data[i].total_amount;
          }
        }

        setTicketSaleAmountData(ticketAmountData.data);
        setTicketSaleData(copyData);
      } else {
        setTicketSaleData(defaultData);
      }
    }
  };
  useEffect(() => {
    if (mid) getTicketSaleData();
  }, [mid, startDate, endDate]);

  return (
    <Fragment>
      <StyleComponent>
        <div className="topContainer">
          <div className="flex">
            <p>이용권별 판매 현황 및 금액</p>
          </div>
        </div>
        <div className="flex margin-top">
          {isMobile &&
            ticketSaleData &&
            ticketSaleData.map((item) => (
              <SaleDataMobile color={item.color}>
                <div className="title ticketTitle">
                  <p>{item.ticketName}</p>
                </div>
                <div className="ticketData data">
                  <p>{item?.count || 0}개</p>
                  <p>{numberWithCommas(item?.total_amount) || 0}원</p>
                </div>
              </SaleDataMobile>
            ))}

          {!isMobile &&
            ticketSaleData &&
            ticketSaleData.map((item) => (
              <SaleData color={item.color}>
                <div className="title ticketTitle">
                  <p>{item.ticketName}</p>
                </div>
                <div className="ticketData data">
                  <p>{item?.count || 0}개</p>
                  <p>{numberWithCommas(item?.total_amount) || 0}원</p>
                </div>
              </SaleData>
            ))}
        </div>
      </StyleComponent>
    </Fragment>
  );
};
export default TicketSale;
