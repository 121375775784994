import React, { Fragment, useState } from "react";
import Breadcrumbs from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  CardFooter,
  Button,
} from "reactstrap";
import { LabelS } from "./ticketList";
import moment from "moment";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import Swal from "sweetalert2";
import {
  couponEnroll,
  deleteCoupon,
  getCouponData,
  getCouponIssuedData,
  merchantId_api,
  userList,
} from "../lib/api";
import { useEffect } from "react";
import { couponIssuedColumn, couponUsedColumn } from "../data/ticketList";
import DataTable from "react-data-table-component";
import styled from "styled-components";

import { Label1 } from "./lockerChange";
import { ExampleCustomInput, LabelRow } from "./ticketCustom";
import { DatePickerStyle } from "./seatInfo";
import { TabButton } from "./seatList";
import PaginationComponent from "../components/pagination";
import SelectDate from "../components/selectDate";
import { exportExcel } from "../components/common";
import DetailsModal from "../components/modal/detailsModal";

let merchantId;

const TabList1 = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  border-bottom: solid 1px #ccc;
  > button {
    width: 100px;
  }
  @media screen and (max-width: 480px) {
    > button {
      width: 65px;
      font-size: 13px;
      margin-right: 1px;
    }
  }
`;
export const Inputs = styled.div`
  display: flex;
  > label {
    > p {
      width: 30px;
    }
    > input {
      margin-top: 0;
      margin-right: 5px;
    }
  }
`;

export const Label2 = styled.label`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 14px;
    strong {
      color: red;
    }
  }
  label {
    margin: 0;
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #555;
  }
  input,
  select {
    margin-top: 5px;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 6px 13px;
  }

  @media screen and (max-width: 480px) {
    h5 {
      font-size: 13px;
    }
    input,
    select {
      padding: 5px 13px;
      font-size: 13px;
    }
  }
`;

const CardContainer = styled.div`
  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

const Coupon = ({ setIsLoading }) => {
  const [couponIssue, setCouponIssue] = useState({
    couponType: 1,
    seatType: 0,
    discountRate: 0,
    time: 1,
    day: 1,
    originalAmount: 1000,
    hour: 1,
    min: 0,
    level: 1,
  });
  const [expirationDate, setExpirationDate] = useState();
  const [phoneNumData, setPhoneNumData] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment(new Date()).add(7, "day")._d);
  const [startTableDate, setStartTableDate] = useState(moment().startOf('week').add(1, 'day').toDate());
  const [endTableDate, setEndTableDate] = useState(moment().startOf('week').add(7, 'days').toDate());
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [couponData, setConponData] = useState([]);
  const [tabMenu, setTabMenu] = useState(1);
  const [count, setCount] = useState(0);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);

  // 모달 state
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  // 디테일 데이터 모달로 보내기
  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };
  // 휴대혼 번호 가져오기

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getData = async () => {
    setIsLoading(true);
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      let res;
      if (tabMenu === 1) {
        res = await getCouponIssuedData(
          data.id,
          moment(startTableDate).format("YYYY-MM-DD 00:00:00"),
          moment(endTableDate).format("YYYY-MM-DD 23:59:59"),
          "",
          currentPage,
          postsPerPage
        );
      } else {
        res = await getCouponData(
          data.id,
          moment(startTableDate).format("YYYY-MM-DD 00:00:00"),
          moment(endTableDate).format("YYYY-MM-DD 23:59:59"),
          "",
          currentPage,
          postsPerPage,
          true
        );
      }

      if (res.status === 200) {
        setCount(res.data.totalPage);
        setConponData(
          res.data.coupons,
          res.data.coupons.map((item) => {
            item.create = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
            item.couponType =
              +item.type === 1
                ? "일회권"
                : +item.type === 2
                ? "기간권"
                : +item.type === 3
                ? "시간정액권"
                : +item.type === 4
                ? "세미나실"
                : "사물함";
            item.expdate = `${moment(item.expStartDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )} ~
                ${moment(item.expEndDate).format("YYYY-MM-DD HH:mm:ss")}`;
            item.used = +item.isUsed === 0 ? "사용전" : "사용완료";
            item.expirationDate = +item.type === 3 ? item.day : "-";
            item.delete = (
              <button
                className="btn-secondary btn-sm"
                onClick={() => couponDelete(item)}
              >
                삭제
              </button>
            );
            item.phoneNum = (
              <div
                className="phoneNum-click"
                onClick={(e) => sendDetailData(e.currentTarget.textContent)}
              >
                {item.phoneNum}
              </div>
            );
          })
        );
      }
    } else {
      Swal.fire("데이터를 불러오는데 실패하였습니다. 다시 시도해주세요");
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.name === "seatType") {
      setCouponIssue({
        ...couponIssue,
        [e.target.name]: e.target.checked ? 1 : 0,
      });
    } else if (e.target.name === "couponType") {
      if (
        +e.target.value === 1 ||
        +e.target.value === 3 ||
        +e.target.value === 4
      ) {
        setCouponIssue({
          ...couponIssue,
          [e.target.name]: +e.target.value,
          day: 0,
        });
      } else {
        setCouponIssue({
          ...couponIssue,
          [e.target.name]: +e.target.value,
          time: 0,
        });
      }
    } else if (e.target.name === "expirationDate") {
      setExpirationDate(e.target.value);
    } else {
      setCouponIssue({ ...couponIssue, [e.target.name]: +e.target.value });
    }
  };

  const handleSubmit = async () => {
    let totalcount =
      (couponIssue.originalAmount * (100 - couponIssue.discountRate)) / 100;

    if (+totalcount % 500 !== 0) {
      Swal.fire({ icon: "error", title: "최종금액을 500단위로 맞춰주세요" });
    } else if (phoneNumData === "") {
      Swal.fire({ icon: "error", title: "핸드폰번호를 입력해주세요" });
    } else if (couponIssue.couponType == 3 && !expirationDate) {
      Swal.fire({ icon: "error", title: "상품의 유효기간을 입력해주세요" });
    } else {
      const phoneArr = phoneNumData
        .replaceAll(" ", "")
        .replaceAll("-", "")
        .split(",");
      let data;
      if (couponIssue.couponType == 3) {
        data = {
          ...couponIssue,
          time: couponIssue.hour * 3600 + couponIssue.min * 60,
          phoneNumArr: phoneArr,
          expStartDate: moment(startDate).format("YYYY-MM-DD 00:00:00"),
          expEndDate: moment(endDate).format("YYYY-MM-DD 23:59:59"),
          day: expirationDate,
        };
      } else if (couponIssue.couponType == 2 || couponIssue.couponType == 5) {
        data = {
          ...couponIssue,
          time: 0,
          phoneNumArr: phoneArr,
          expStartDate: moment(startDate).format("YYYY-MM-DD 00:00:00"),
          expEndDate: moment(endDate).format("YYYY-MM-DD 23:59:59"),
        };
      } else {
        data = {
          ...couponIssue,
          day: 0,
          time: couponIssue.hour * 3600 + couponIssue.min * 60,
          phoneNumArr: phoneArr,
          expStartDate: moment(startDate).format("YYYY-MM-DD 00:00:00"),
          expEndDate: moment(endDate).format("YYYY-MM-DD 23:59:59"),
        };
      }
      if (data) {
        const res = await couponEnroll(merchantId, data);

        if (res.status === 200) {
          let failData = res.data.messages.map((item) => item);
          if (failData.length > 0) {
            Swal.fire({
              title: "발급이 완료되었습니다.",
              text: `${res.data.success}개 성공,${res.data.failure}개 실패했습니다.`,
              html: `
        실패사유<br/>${failData}는 존재하지 않는 번호입니다.`,
            }).then(() => setPhoneNumData(""), getData());
          } else {
            Swal.fire("발급이 완료되었습니다.").then(() => {
              window.location.reload();
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "발급하는데 오류가 발생했습니다.",
            timer: 2000,
          });
        }
      }
    }
  };

  const couponDelete = async (e) => {
    let result = await deleteCoupon(e.merchantId, e.id);

    if (result.status === 200) {
      Swal.fire({
        icon: "success",
        title: "쿠폰삭제가 완료되었습니다.",
        timer: 2000,
      }).then(() => getData());
    }
  };

  const exportExcelData = async () => {
    let res;
    if (tabMenu === 1) {
      res = await getCouponIssuedData(
        merchantId,
        moment(startTableDate).format("YYYY-MM-DD 00:00:00"),
        moment(endTableDate).format("YYYY-MM-DD 23:59:59"),
        "",
        1,
        count * 10
      );
    } else {
      res = await getCouponData(
        merchantId,
        moment(startTableDate).format("YYYY-MM-DD 00:00:00"),
        moment(endTableDate).format("YYYY-MM-DD 23:59:59"),
        "",
        1,
        count * 10,
        true
      );
    }

    if (res.status === 200) {
      let excelData = [
        res.data.coupons,
        res.data.coupons.map((item) => {
          item.create = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
          item.couponType =
            +item.type === 1
              ? "일회권"
              : +item.type === 2
              ? "기간권"
              : +item.type === 3
              ? "시간정액권"
              : +item.type === 4
              ? "세미나실"
              : "사물함";
          item.expdate = `${moment(item.expStartDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )} ~
            ${moment(item.expEndDate).format("YYYY-MM-DD HH:mm:ss")}`;
          item.used = +item.isUsed === 0 ? "사용전" : "사용완료";
          item.expirationDate = +item.type === 3 ? item.day : "-";
        }),
      ];
      if (tabMenu === 1) {
        exportExcel(
          excelData[0],
          startTableDate,
          endTableDate,
          "쿠폰 발급 내역"
        );
      } else {
        exportExcel(
          excelData[0],
          startTableDate,
          endTableDate,
          "쿠폰 사용 내역"
        );
      }
    }
  };

  useEffect(() => {
    getData();
  }, [tabMenu, startTableDate, endTableDate, currentPage]);

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
        />
      )}
      <Breadcrumbs parent="마일리지 쿠폰 관리" title="쿠폰" />
      <Container fluid={true}>
        <Row>
          <Col style={{ padding: "0 6px" }}>
            <Card>
              <CardBody>
                <h5 style={{ marginBottom: "20px" }}>쿠폰 발급</h5>
                <Label1>
                  <h5>쿠폰 타입</h5>
                  <select
                    value={couponIssue.couponType}
                    name="couponType"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value={1}>일회권</option>
                    <option value={2}>기간권</option>
                    <option value={3}>시간정액권</option>
                    {/* <option value={4}>세미나실</option> */}
                    <option value={5}>사물함</option>
                  </select>
                </Label1>
                {couponIssue.couponType === 2 && (
                  <Label1>
                    <h5>고정석 여부</h5>
                    <div>
                      <LabelS htmlFor="seatType">
                        <Input
                          id="seatType"
                          name="seatType"
                          type="checkbox"
                          checked={couponIssue.seatType || ""}
                          onChange={(e) => handleChange(e)}
                        />
                        고정석 사용
                      </LabelS>
                    </div>
                  </Label1>
                )}

                <Label1>
                  <h5>
                    고객 전화번호 (여러개를 원하실 경우 , 를 입력해주세요)
                  </h5>

                  <Input
                    type="text"
                    name="phoneNumData"
                    placeholder="전화번호를 입력해주세요"
                    value={phoneNumData || ""}
                    onChange={(e) => setPhoneNumData(e.target.value)}
                  />
                </Label1>
                {(couponIssue.couponType === 2 ||
                  couponIssue.couponType === 5) && (
                  <Label1>
                    <h5>상품기간(일)</h5>
                    <Input
                      type="text"
                      name="day"
                      placeholder="숫자만 입력해주세요"
                      value={couponIssue.day || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </Label1>
                )}
                {(couponIssue.couponType === 1 ||
                  couponIssue.couponType === 3 ||
                  couponIssue.couponType === 4) && (
                  <Label1>
                    <h5>상품시간</h5>
                    <Inputs>
                      <LabelRow>
                        <Input
                          type="number"
                          min="0"
                          name="hour"
                          value={couponIssue.hour || ""}
                          onChange={(e) => handleChange(e)}
                        />
                        <p>시간</p>{" "}
                      </LabelRow>
                      <LabelRow>
                        <Input
                          type="number"
                          min="0"
                          name="min"
                          value={couponIssue.min || ""}
                          onChange={(e) => handleChange(e)}
                        />
                        <p> 분</p>{" "}
                      </LabelRow>
                    </Inputs>
                  </Label1>
                )}
                {couponIssue.couponType === 3 && (
                  <>
                    <Label1>
                      <h5>상품유효기간(일)</h5>
                      <Input
                        type="number"
                        min="0"
                        name="expirationDate"
                        value={expirationDate || ""}
                        onChange={(e) => handleChange(e)}
                      />
                    </Label1>
                  </>
                )}
                <Label1>
                  <h5>등급</h5>
                  <Input
                    type="text"
                    name="level"
                    placeholder="숫자만 입력해주세요"
                    value={couponIssue.level || ""}
                    onChange={(e) => handleChange(e)}
                  />
                </Label1>
                <Label1>
                  <h5>원가(정상판매가)</h5>
                  <Input
                    type="text"
                    name="originalAmount"
                    placeholder="숫자만 입력해주세요"
                    value={couponIssue.originalAmount || ""}
                    onChange={(e) => handleChange(e)}
                  />
                </Label1>
                <Label1>
                  <h5>할인율(500원단위로 떨어지게 해주세요)</h5>
                  <Input
                    className="form-control m-input digits"
                    type="text"
                    name="discountRate"
                    placeholder="숫자만 입력해주세요"
                    value={couponIssue.discountRate || ""}
                    onChange={(e) => handleChange(e)}
                  />
                </Label1>
                <Label1>
                  <h5>최종금액</h5>
                  <p>
                    {(couponIssue.originalAmount *
                      (100 - couponIssue.discountRate)) /
                      100}
                  </p>
                </Label1>
                <Label2>
                  <h5>쿠폰유효기간</h5>
                </Label2>
                <Label1>
                  <DatePickerStyle style={{ margin: 0 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      selectsStart
                      locale={ko}
                      startDate={startDate}
                      maxDate={endDate}
                      endDate={endDate}
                      customInput={<ExampleCustomInput />}
                    />
                    <h5 style={{ margin: "0 8px" }}> - </h5>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      locale={ko}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      customInput={<ExampleCustomInput />}
                    />
                  </DatePickerStyle>
                </Label1>
                <CardFooter className="text-center">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    발급하기
                  </button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <h5 style={{ marginBottom: "20px" }}>쿠폰 내역</h5>
                <Button className="hide-480" onClick={() => exportExcelData()}>
                  엑셀 출력하기
                </Button>
                <DatePickerStyle style={{ margin: 0 }}>
                  <DatePicker
                    selected={startTableDate}
                    onChange={(date) => {
                      setStartTableDate(date);
                    }}
                    selectsStart
                    locale={ko}
                    startDate={startTableDate}
                    maxDate={endTableDate}
                    endDate={endTableDate}
                    customInput={<ExampleCustomInput />}
                  />
                  <h5> - </h5>
                  <DatePicker
                    selected={endTableDate}
                    onChange={(date) => setEndTableDate(date)}
                    selectsEnd
                    locale={ko}
                    startDate={startTableDate}
                    endDate={endTableDate}
                    minDate={startTableDate}
                    customInput={<ExampleCustomInput />}
                  />
                  <SelectDate
                    setStartDate={(val) => setStartTableDate(val)}
                    setEndDate={(val) => setEndTableDate(val)}
                    nowTime={"이번 주"}

                  />
                </DatePickerStyle>
                <TabList1>
                  <TabButton
                    selected={tabMenu === 1}
                    onClick={() => setTabMenu(1)}
                  >
                    발급내역
                  </TabButton>
                  <TabButton
                    selected={tabMenu === 2}
                    onClick={() => setTabMenu(2)}
                  >
                    사용내역
                  </TabButton>
                </TabList1>

                {tabMenu === 2 && (
                  <CardContainer>
                    {" "}
                    <DataTable
                      data={
                        // searchData.length >= 1
                        //   ? currentPosts(searchData):
                        couponData
                      }
                      columns={couponUsedColumn}
                      striped={true}
                      center={true}
                    />
                    {count > 0 && (
                      <PaginationComponent
                        setCurrentPage={setCurrentPage}
                        setPostsPerPageAdd={setPostsPerPageAdd}
                        setPostsPerPageAdd1={setPostsPerPageAdd1}
                        setPageNum={setPageNum}
                        totalLength={count}
                        postsPerPage={postsPerPage}
                        currentPage={currentPage}
                        pageNum={pageNum}
                        postsPerPageAdd={postsPerPageAdd}
                        postsPerPageAdd1={postsPerPageAdd1}
                        pagecount={true}
                      />
                    )}
                  </CardContainer>
                )}
                {tabMenu === 1 && (
                  <CardContainer>
                    <DataTable
                      data={couponData}
                      columns={couponIssuedColumn}
                      striped={true}
                      center={true}
                    />
                    {count > 0 && (
                      <PaginationComponent
                        setCurrentPage={setCurrentPage}
                        setPostsPerPageAdd={setPostsPerPageAdd}
                        setPostsPerPageAdd1={setPostsPerPageAdd1}
                        setPageNum={setPageNum}
                        totalLength={count}
                        postsPerPage={postsPerPage}
                        currentPage={currentPage}
                        pageNum={pageNum}
                        postsPerPageAdd={postsPerPageAdd}
                        postsPerPageAdd1={postsPerPageAdd1}
                        pagecount={true}
                      />
                    )}
                  </CardContainer>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Coupon;
