import React, { useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import styled from "styled-components";

import moment from "moment";
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import DataTable from "react-data-table-component";
import PaginationComponent from "../../components/pagination";
import { getPaylogList, getUserData } from "../../lib/api";
import { UserpaylogListColumns2 } from "../../data/ticketList";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../components/common";

const CardContainer = styled.div`
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;

    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      .fa-search {
        position: absolute;
        right: 12px;
        top: 16.8px;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .topbar {
      align-items: center;
      flex-direction: column;
      .m-0 {
        margin: 3vw 0 !important;
      }
    }
  }

  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

const PaymentInfo = () => {
  const [merchantId, setMerchantId] = useState(0);
  const [phoneNum, setPhoneNum] = useState(null);

  const [tagModal, setTagModal] = useState(false);
  const [lists, setLists] = useState([]);
  const [startDate, setStartDate] = useState(new Date("2020-01-01"));
  const [endDate, setEndDate] = useState(new Date("2030-01-01"));
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [selected, setSelected] = useState(null);
  const history = useHistory();

  const tagToggle = () => {
    setTagModal(!tagModal);
  };

  // Open Modal
  const handleSetLists = (e) => {
    setSelected(e.target.id);
    tagToggle();
  };

  const getUserInfo = async () => {
    try {
      const { data } = await getUserData();
      setPhoneNum(data[0]?.phoneNum);
      setMerchantId(data[0]?.id);
      getPaylogListApi(data[0]?.id, data[0]?.phoneNum);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUserInfo();

    if (document.referrer.includes("toss")) {
      window.location.href = `${process.env.PUBLIC_URL}/user/ticket-buy`;
    }
  }, []);

  const getPaylogListApi = async (merchantId, phoneNum) => {
    if (merchantId) {
      const { data } = await getPaylogList(
        merchantId,
        moment(startDate).format("YYYY-MM-DD 00:00:00"),
        moment(endDate).format("YYYY-MM-DD 23:59:59"),
        currentPage,
        postsPerPage,
        phoneNum
      );

      setTotalLength(data.count);
      if (data.info.length > 0) {
        setLists(
          data.info,
          data.info.map((item, idx) => {
            item.p_type =
              item.type === 1 ? (
                <p style={{ color: "#EBAF22" }}>일회권</p>
              ) : item.type === 2 ? (
                <p style={{ color: "#E03D64" }}>기간권</p>
              ) : item.type === 3 ? (
                <p style={{ color: "#33946B" }}>시간정액권</p>
              ) : item.type === 4 ? (
                <p style={{ color: "#3E67B6" }}>세미나실</p>
              ) : item.type === 5 ? (
                <p style={{ color: "#AB84FF" }}>사물함</p>
              ) : item.meta8?.includes("자판기") ? (
                <p>자판기</p>
              ) : item.meta8?.includes("컵코인") ? (
                <p>컵코인</p>
              ) : item.meta8?.includes("백오피스") ? (
                <p>백오피스</p>
              ) : (
                <p>-</p>
              );
            item.price = numberWithCommas(item.amount);
            item.createdAt = moment(item.createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            item.cancel = item.paymentType?.includes("cancel") ? (
              <p
                style={{
                  textDecorationLine: "line-through",
                  color: "#E00F2C",
                }}
              >
                결제완료(환불완료)
              </p>
            ) : (
              <p>결제완료</p>
            );
            item.paymentType = item.paymentType?.includes("cash")
              ? "현금"
              : item.paymentType?.includes("credit")
              ? "카드"
              : item.paymentType?.includes("kakaopay")
              ? "카카오페이"
              : item.paymentType?.includes("payco")
              ? "페이코"
              : item.paymentType?.includes("virtual")
              ? "가매입/가매출"
              : item.paymentType?.includes("coupon")
              ? "쿠폰"
              : item.paymentType?.includes("toss")
              ? "토스"
              : item.paymentType?.includes("applepay")
              ? "애플페이"
              : item.paymentType?.includes("naverpay")
              ? "네이버페이"
              : item.paymentType?.includes("hecto")
              ? "헥토파이낸셜"
              : "마일리지";
            item.action = (
              <button
                className="btn-primary btn-sm"
                id={idx}
                onClick={(e) => handleSetLists(e)}
              >
                자세히
              </button>
            );
          })
        );
      } else {
        setLists([]);
      }
    }
  };

  useEffect(() => {
    getPaylogListApi(merchantId, phoneNum);
  }, [startDate, endDate, currentPage]);

  return (
    <>
      <Breadcrumb parent="결제정보 조회" title="결제내역" />
      <Container fluid={true}>
        <Modal centered isOpen={tagModal} toggle={tagToggle}>
          <ModalHeader className="modal-title" toggle={tagToggle}>
            {"결제정보"}
          </ModalHeader>
          <ModalBody>
            <div>
              <p>
                <strong>결제수단</strong>:{" "}
                {lists[selected]?.paymentType?.includes("cash") && "현금"}
                {lists[selected]?.paymentType?.includes("credit") && "카드"}
                {lists[selected]?.paymentType?.includes("kakaopay") &&
                  "카카오페이"}
                {lists[selected]?.paymentType?.includes("payco") && "페이코"}
                {lists[selected]?.paymentType?.includes("virtual") &&
                  "가매입/가매출"}
                {lists[selected]?.paymentType?.includes("coupon") && "쿠폰"}
                {lists[selected]?.paymentType?.includes("mileage") &&
                  "마일리지"}
                {lists[selected]?.paymentType?.includes("toss") && "토스"}
                {lists[selected]?.paymentType?.includes("applepay") &&
                  "애플페이"}
                {lists[selected]?.paymentType?.includes("naverpay") &&
                  "네이버페이"}
                {lists[selected]?.paymentType?.includes("hecto") &&
                  "웹결제(헥토)"}
              </p>
              <p>
                <strong>결제내역</strong>: {lists[selected]?.price}원
              </p>
              <p>
                <strong>거래고유번호</strong>: {lists[selected]?.meta1}
              </p>
              <p>
                <strong>승인번호</strong>: {lists[selected]?.meta2}
              </p>
              <p>
                <strong>카드번호</strong>: {lists[selected]?.meta3}
              </p>
              <p>
                <strong>전표번호</strong>: {lists[selected]?.meta4}
              </p>
              <p>
                <strong>카드종류</strong>: {lists[selected]?.meta5}
              </p>
              <p>
                <strong>발급사</strong>: {lists[selected]?.meta6}
              </p>
              <p>
                <strong>거래유형</strong>: {lists[selected]?.meta7}
              </p>
              <p>
                <strong>내부메시지</strong>:{lists[selected]?.meta8}
              </p>
              <p>
                <strong>매입사</strong>: {lists[selected]?.meta9}
              </p>
              <p>
                <strong>응답정보</strong>: {lists[selected]?.meta10}
              </p>
              <p>
                <strong>거래일자</strong>: {lists[selected]?.meta11}
              </p>
              <p>
                <strong>가맹점번호</strong>: {lists[selected]?.meta12}
              </p>
              <p>
                <strong>표시메시지</strong>: {lists[selected]?.meta13}
              </p>
              <p>
                <strong>결제일</strong>:{" "}
                {moment(lists[selected]?.updatedAt)
                  .utcOffset("+0900")
                  .format("YYYY-MM-DD HH:mm:ss")}
              </p>
            </div>
          </ModalBody>
        </Modal>
        <CardContainer>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <DataTable
                    data={lists}
                    columns={UserpaylogListColumns2}
                    striped={true}
                    center={true}
                  />
                </CardBody>
                {totalLength > 0 && (
                  <PaginationComponent
                    setCurrentPage={setCurrentPage}
                    setPostsPerPageAdd={setPostsPerPageAdd}
                    setPostsPerPageAdd1={setPostsPerPageAdd1}
                    setPageNum={setPageNum}
                    totalLength={totalLength}
                    postsPerPage={postsPerPage}
                    currentPage={currentPage}
                    pageNum={pageNum}
                    postsPerPageAdd={postsPerPageAdd}
                    postsPerPageAdd1={postsPerPageAdd1}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </>
  );
};
export default PaymentInfo;
