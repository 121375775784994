import React from "react";

const ServiceRule = () => {
  return (
    <p>{`**제1조 (목적)**

  본 약관은 샘랩(주) (이하 “회사”라 합니다.)가 운영하는 렛미업 렛미업 스카 클라우드 서비스(이하 “렛미업 스카 클라우드 서비스”라 합니다.)에서 제공하는 전자상거래 등 인터넷 관련 서비스, 디지털 콘텐츠 및 제반 서비스의 이용과 관련하여 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 그 목적으로 합니다.
  
  **제2조 (정의)**
  
  1. “렛미업 스카 클라우드 서비스”: Kiosk.letmeup.co.kr 웹사이트를 의미합니다.
  
  2. 이용자 : “렛미업 스카 클라우드 서비스”에 접속하여 이 약관 및 개인정보처리방침에 동의하고 “회사”가 제공하는 서비스를 이용하는 자를 말합니다.
  
  3. 아이디 (ID) : "이용자"의 식별과 “렛미업 스카 클라우드 서비스” 이용을 위하여 "운영자"이 선정하고 "회사"가 승인하여 등록한 문자와 숫자의 조합 또는 휴대폰 번호를 의미합니다.
  
  4. 핀번호  : “운영자”의 동일성 확인 과 “운영자” 의 권익 및 비밀보호를 위하여 “운영자” 스스로 설정하여 “렛미업 스카 클라우드 서비스”에 등록한 영문과 숫자, 특수문자 등의 조합을 말합니다.
  
  7. 이 약관에서 정의되지 않은 용어는 관련 법령이 정하는 바에 따릅니다.
  
  **제3조 (약관의 명시, 설명, 효력 및 변경)**
  
  1. “회사”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 주소를 포함), 전화번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 “이용자”,“운영자”가 쉽게 확인할 수 있도록 각 “렛미업 스카 클라우드 서비스”의 화면 또는 링크로 연결된 화면에 게시합니다.
  
  2. “회사”는「약관의 규제에 관한 법률」, 「전자서명법」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
  
  3. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 "사이트" 내 공지사항에 최소 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.
  
  4. 전 항에 따라 공지 된 약관 적용일자 이후에 “운영자”가 “렛미업 스카 클라우드 서비스”를 계속 이용하는 경우 개정된 약관에 동의하는 것으로 봅니다. 변경된 약관의 정보를 알지 못해 발생하는 “운영자”의 피해는 “회사”가 책임지지 않습니다.
  
  5. 개정된 약관에 동의하지 아니하는 "운영자"은 언제든지 자유롭게 “렛미업 스카 클라우드 서비스” 이용계약을 해지할 수 있습니다. 다만, 위 서비스와 관련된 관련 계약과의 연계 여부는 “회사”와 논의하여야 합니다.
  
  6. 회사가 약관을 개정할 경우 그 개정약관은 그 적용 일자 이후에 체결되는 계약에만 적용되고 그 이전에 체결된 계약에 대해서는 개정전의 약관 조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정 약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정 약관의 공지 기간 내에 “회사”에 송신하여 “회사”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
  
  **제 4조 (서비스에 대한 약관 및 이용조건)**
  
  “회사”는 제공하는 “렛미업 스카 클라우드 서비스”의 구체적인 내용에 따라 “렛미업 스카 클라우드 서비스”별로 약관 및 이용 조건을 별도로 정하여 “운영자”의 동의를 얻을 수 있습니다. 이 경우 각 “렛미업 스카 클라우드 서비스”에 대한 개별 약관 및 이용 조건이 본 약관보다 우선합니다.
  
  **제 5 조 (약관 외 준칙 및 관할법원)**
  
  1. 본 약관에 명시되지 않은 사항 및 해석은 약관의 규제에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
  
  2. 본 약관의 해석 및 “회사” 와 “이용자”, “운영자” 간의 분쟁에 대해서는 대한민국의 법령을 적용하며, 관할 법원은 서울중앙지방법원으로 합니다.
  
  **제 6 조 ( “렛미업 스카 클라우드 서비스”의 제공, 변경 및 종료)**
  
  1. “회사”는 “이용자”,”운영자”에게 각 “렛미업 스카 클라우드 서비스” 약관에 따른 서비스를 제공합니다. “회사”는 일부 서비스의 경우 “운영자”가 “회사”가 정한 절차와 방식으로 대금을 지급하여야만 사용할 수 있도록 정할 수 있고 이러한 서비스를 세미나 예약 및 결제 서비스라 합니다.
  
  2. “회사” 는 다음과 같은 경우에 제공하는 서비스의 내용을 변경하거나 중단할 수 있습니다.
  
  3. ① 서비스용 설비의 보수·교체 및 고장, 통신의 두절 등으로 인한 부득이한 경우나 전기통신사업법에 규정된 시간
  
  4. ② 통신사업자가 전기통신서비스를 중지했을 경우
  
  5. ③ 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
  
  6. ④ 회사가 그 필요에 있어 운영자에게 공지한 경우
  
  7. ⑤ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “회사”가 “운영자”에게 이를 고지합니다.
  
  **제 7조 ( “렛미업 스카 클라우드 서비스” 이용약관 성립)**
  
  1. 이용자가 서비스 신청 시 본 약관을 읽고 “동의함” 버튼을 클릭한 경우, 이용자가 “렛미업 스카 클라우드 서비스”에서 아이디를 부여 받고 서비스를 시작한 경우 본 약관에 동의하는 것으로 간주됩니다.
  
  2. 이용약관은 서비스 이용희망자의 이용약관 동의 후 이용 신청에 대하여 “회사”가 승낙함으로써 성립합니다.
  
  **제8조 ( “렛미업 스카 클라우드 서비스” 운영자 가입)**
  
  1. “운영자”은 약관에 동의한다는 의사를 표시하고, “회사”가 정한 양식에 따라 개인정보를 기입한 후 운영자가입을 신청함으로써 “운영자”으로 등록됩니다.
  
  2. “회사”는 전 항에 따른 운영자가입신청 시 “운영자”에게 전문기관을 통한 본인 인증절차를 요구할 수 있습니다.
  
  3. 다음의 경우에는 “회사”가 임의적으로 운영자가입을 인정하지 않거나 운영자자격을 박탈할 수 있습니다.
  
  ① “회사”가 실명확인절차를 실시할 경우에 “운영자”의 실명이 아님이 확인된 경우
  
  ② 이미 가입 된 “운영자”과 “아이디(전자우편주소)”가 동일한 경우
  
  ③ 만 14세 되지 않은 자가 법정대리인 동의 없이 가입하였음이 증명될 경우
  
  ④ 타인 명의의 전자우편주소를 도용하거나 이용 신청 시 필수 내용을 허위로 기재하여 신청하였을 경우
  
  ⑤ "회사"에 의하여 이용계약이 해지된 날로부터 3개월 이내에 재이용을 신청을 하는 경우
  
  ⑥ 부정한 용도 또는 영리를 추구할 목적으로 "서비스"를 이용하고자 하는 경우
  
  ⑦ 관련 법령에 위배되거나 사회의 안녕과 질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우
  
  ⑧ "회사"의 "서비스" 운영에 따른 설비에 여유가 없거나 기술상 지장이 있는 경우. 다만, 이 경우 그 사유가 해소될 때까지 이용 승낙을 유보할 수 있습니다.
  
  ⑨ 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 "회사"가 합리적인 판단에 의하여 필요하다고 인정하는 경우
  
  1. “운영자”은 “회사”가 제공하는 세미나 예약 및 결제 서비스를 이용하기 위해서는 “회사”가 정한 정책에 따라 “세미나 예약 및 결제 서비스 이용 대금”을 지급받도록 도와야 합니다.
  
  2. “회사”의 귀책사유로 인하여 “운영자”가 세미나 예약 및 결제 서비스를 이용하지 못한 경우에는 해당 “운영자”가 회사에 대하여 지급한 세미나 예약 및 결제 서비스 이용 대금을 상한선으로 하여 “운영자”에게 손해 배상을 진행할 수 있습니다.
  
  3. “회사”는 전항에 따른 손해배상 외에는 “운영자”에 대하여 세미나 예약 및 결제 서비스와 관련하여 그 어떠한 책임도 부담하지 않습니다.
  
  4. “회사”는 “운영자”과 세미나 예약 및 결제 서비스 공급시기에 관하여 별도의 약정이 없는 이상, “운영자”이 청약을 한 날부터 7일 이내 세미나 예약 및 결제 서비스 공급을 위한 조치를 취합니다. 단, “회사”가 세미나 예약 및 결제 서비스 이용 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 7영업일 이내 또는 지정한 날에 조치를 취합니다.
  
  5. “회사”는 “운영자”에 대하여 세미나 예약 및 결제 서비스를 제공할 수 없을 때에 지체 없이 그 사유를 “운영자”에게 통지하고, 사전에 “운영자”으로부터 세미나 예약 및 결제 서비스 이용 대금을 지급받은 경우에는 그 대금을 받은 날로부터 3영업일 이내 환급하거나 환급에 필요한 조치를 취합니다.
  
  6. “회사”는 세미나 예약 및 결제 서비스에 관한 구체적인 환급 정책을 정할 수 있고, 이를 서비스를 통하여 안내합니다.
  
  **제 9조 (청약철회 등)**
  
  1. 세미나 예약 및 결제 서비스 이용을 위한 계약을 체결한 “운영자”은 「전자상거래 등에서의 소비자보호에 관한 법률」(이하, 전자상거래법) 제14조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 전자상거래법에 달리 정함이 있는 경우에는 동법 규정에 따릅니다.
  
  2. “운영자”가 이용하는 세미나 예약 및 결제 서비스는 전자상거래법에서 샘랩(주정하는 청약철회 불가 대상에 포함되는 경우, “운영자”은 이에 대한 청약철회 등을 주장할 수 없습니다.
  
  3. 전항에도 불구하고, “회사”가 사전에 세미나 예약 및 결제 서비스에 대한 청약철회 등이 제한되는 사실을 “운영자”이 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 “운영자”의 청약철회 등이 제한되지 않습니다.
  
  4. “운영자”은 제1항 및 제2항의 규정에 불구하고 세미나 예약 및 결제 서비스의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 세미나 예약 및 결제 서비스를 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
  
  5. “회사”는 “운영자”으로부터 세미나 예약 및 결제 서비스를 반환 받은 경우 3영업일 이내에 이미 지급받은 세미나 예약 및 결제 서비스의 대금을 환급합니다. 이 경우 회사가 운영자에게 세미나 예약 및 결제 서비스의 환급을 지연한 때에는 그 지연기간에 대하여 전자상거래법 시행령 제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
  
  6. 회사는 위 대금을 환급함에 있어서 운영자이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 세미나 예약 및 결제 서비스 이용 대금의 청구를 정지 또는 취소하도록 요청합니다.
  
  7. 청약철회 등의 경우 공급받은 세미나 예약 및 결제 서비스의 반환에 필요한 비용은 운영자이 부담합니다. 회사는 운영자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 세미나 예약 및 결제 서비스의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 세미나 예약 및 결제 서비스의 반환에 필요한 비용은 회사가 부담합니다.
  
  8. 이미 세미나 예약 및 결제 서비스가 일부 사용 또는 일부 소비된 경우, 회사는 그 세미나 예약 및 결제 서비스의 사용 또는 일부 소비에 의하여 운영자이 얻은 이익 또는 그 세미나 예약 및 결제 서비스의 공급에 소요된 비용에 상당하는 금액으로서 관련 법령에서 정하는 범위의 금액의 지급을 운영자에게 청구할 수 있습니다.
  
  9. 운영자가 세미나 예약 및 결제 서비스를 이용하여 적립금, 마일리지 등을 지급 받은 경우 청약철회 시 이를 회사에 반환하여야 합니다.
  
  **제 10조 ( “렛미업 스카 클라우드 서비스” 해지)**
  
  1. 이용자는 “회사”가 각 “렛미업 스카 클라우드 서비스” 내 정한 절차에 따라 “렛미업 스카 클라우드 서비스” 이용계약을 해지할 수 있습니다.
  
  2. “렛미업 스카 클라우드 서비스” 이용계약이 해지될 경우, 관련 법령 및 개인정보 처리방침에 따라 이용자의 정보를 보유하는 경우를 제외하고는 이용자의 정보나 게시물 등 모든 데이터는 삭제됩니다.
  
  **제 11조 (회사의 의무)**
  
  1. “회사”는 본 약관이 정하는 바에 따라 지속적이고 안정적인 “렛미업 스카 클라우드 서비스”를 제공하는데 최선을 다하여야 합니다.
  
  2. “회사”는 항상 “이용자”의 신용정보를 포함한 개인신상정보에 대하여 관리적, 기술적 안전조치를 강구하여 “이용자”의 정보보안에 최선을 다하여야 합니다.
  
  3. “회사”는 공정하고 건전한 운영을 통해 전자상거래 질서 유지에 최선을 다하며 공공질서, 미풍양속에 반하는 행위를 하지 않습니다.
  
  4. “회사”는 소비자보호단체 및 공공기관의 소비자 보호업무의 추진에 필요한 자료 및 기타 관계 법령에 따라 집행되는 자료 요청에 적극 협력합니다.
  
  **제 12조 (운영자의 의무)**
  
  1. “운영자”은 본 약관의 규정, 이용안내 및 관계 법령 , “렛미업 스카 클라우드 서비스”와 관련하여 공지한 주의사항, 각 서비스가 “운영자”에게 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.
  
  2. “운영자” 은 다음 각 호에 해당하는 행위를 하여서는 안 됩니다.
  
  ① 운영자가입 신청 또는 변경 시 허위내용을 등록하는 행위
  
  ② 다른 운영자의 아이디 및 비밀번호를 도용하여 부당하게 “렛미업 스카 클라우드 서비스”를 이용하는 행위
  
  ③ 타인의 허락 없이 계좌번호 및 신용카드번호 등 타인의 결제정보를 이용하여 “렛미업 스카 클라우드 서비스”를 이용하는 행위
  
  ④ “회사”가 게시한 정보를 허가 없이 변경하거나 “렛미업 스카 클라우드 서비스”를 이용하여 얻은 정보를 “회사”의 승낙 없이 영리 또는 비영리의 목적으로 사용하거나 제3자에게 제공하는 행위
  
  ⑤ “회사” 및 기타 제3자의 저작권 등 지적 재산권에 대한 침해
  
  ⑥ "회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
  
  ⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 미풍양속에 반하는 정보를 “렛미업 스카 클라우드 서비스”에 공개 또는 게시하는 행위
  
  ⑧ 기타 관련 법령이나 “회사”에서 정한 규정에 위배되는 행위
  
  **제 13조 (개인정보보호)**
  
  1. "회사"는 "이용자"의 개인정보를 보호하기 위하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보 보호법 등 관계 법령에서 정하는 바를 준수합니다. 개인정보의 보호 및 사용에 대해서는 관련법령에 따라 "회사"의 개인정보보방침을 수립하고 개인정보보호 책임자를 지정하여 이를 게시하고 운영합니다.
  
  2. "회사"는 이용계약을 위하여 "이용자”가 제공한 정보를 "회사"의 “렛미업 스카 클라우드 서비스” 운영을 위한 목적 이외의 용도로 사용하거나 "이용자"의 동의 없이 제3자에게 제공하지 않습니다. 단, 예외의 경우는 개인정보보호방침에 따릅니다.
  
  **제14조(면책 조항)**
  
  1. "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 “렛미업 스카 클라우드 서비스”를 제공할 수 없는 경우에는 “렛미업 스카 클라우드 서비스” 제공에 관한 책임이 면제됩니다.
  
  2. "회사"는 "이용자"의 귀책사유로 인한 “렛미업 스카 클라우드 서비스” 이용의 장애에 대하여 책임을 지지 않습니다.
  
  3. "회사"는 "이용자”가 “렛미업 스카 클라우드 서비스”를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그밖에 “렛미업 스카 클라우드 서비스”를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
  
  4. "회사"는 "운영자"이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.
  
  5. "회사"는 "이용자” 간 또는 "이용자"와 제3자 상호간에 “렛미업 스카 클라우드 서비스”를 매개로 하여 거래 등을 한 경우에는 책임을 지지 않습니다.
  
  **제15조(분쟁 해결)**
  
  1. "회사"는 "이용자” 가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위한 접수처를 운영합니다.
  
  2. “회사”는 “이용자”로부터 제기되는 불편사항 및 문제가 정당하다고 판단되어 질 경우 우선적으로 그 문제를 처리해야 합니다. 단. 신속한 처리가 곤란할 경우, 고객에게 그 사유와 처리일정을 즉시 통보하여야 합니다.
  
  **제 16조(준거법 및 관할법원)**
  
  1. 이 약관의 해석 및 "회사"와 "이용자" 간의 분쟁에 대하여는 대한민국의 법령을 적용합니다.
  
  2. “렛미업 스카 클라우드 서비스” 이용 중 발생한 "회사"와 "이용자" 간의 소송은 서울중앙지방법원에 제소합니다.
  
  o  이용약관 변경공고일자 : 2022년 7월 **22**일
  
  o  이용약관 시행일자 : 2022년 7월 **11**일`}</p>
  );
};

export default ServiceRule;
