import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import styled from "styled-components";
import { numberWithCommas } from "../common";

const DefaultTableStyle = styled.table`
  width: 60%;
  thead {
    color: #334d6e;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #e1e1e1;
    tr {
    }
    th {
      padding: 15px;
    }
  }

  td,
  th {
    width: 12%;
    :first-child {
      width: 20%;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e1e1e1;
      td {
        padding: 20px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    td,
    th {
      width: fit-content;
    }
  }
`;

const FranchiseList = ({
  tableData,
  currentPosts,
  setCurrentPage,
  columnDatas,
}) => {
  const columnData =
    //    useMemo(
    //     () =>
    [
      {
        accessor: "name",
        Header: "지점명",
      },
      {
        accessor: "totalPrice",
        Header: "총매출",
        sortMethod: (a, b) => {
          a = Number(a); // Converting into number
          b = Number(b); // Converting into number
          if (a.length === b.length) {
            return a > b ? 1 : -1; // same length condition
          }
          return a.length > b.length ? 1 : -1; // comparing length of string
        },
      },
      {
        accessor: "credit1",
        Header: "카드매출",
        sortMethod: (a, b) => {
          a = Number(a); // Converting into number
          b = Number(b); // Converting into number
          if (a.length === b.length) {
            return a > b ? 1 : -1; // same length condition
          }
          return a.length > b.length ? 1 : -1; // comparing length of string
        },
      },
      {
        accessor: "cash1",
        Header: "현금매출",
        sortMethod: (a, b) => {
          a = Number(a); // Converting into number
          b = Number(b); // Converting into number
          if (a.length === b.length) {
            return a > b ? 1 : -1; // same length condition
          }
          return a.length > b.length ? 1 : -1; // comparing length of string
        },
      },
      {
        accessor: "kakaopay1",
        Header: "간편결제",
        sortMethod: (a, b) => {
          a = Number(a); // Converting into number
          b = Number(b); // Converting into number
          if (a.length === b.length) {
            return a > b ? 1 : -1; // same length condition
          }
          return a.length > b.length ? 1 : -1; // comparing length of string
        },
      },
      {
        accessor: "webPayment1",
        Header: "웹결제",
        sortMethod: (a, b) => {
          a = Number(a); // Converting into number
          b = Number(b); // Converting into number
          if (a.length === b.length) {
            return a > b ? 1 : -1; // same length condition
          }
          return a.length > b.length ? 1 : -1; // comparing length of string
        },
      },
      {
        accessor: "virtualPrice1",
        Header: "가매입/가매출",
        sortMethod: (a, b) => {
          a = Number(a); // Converting into number
          b = Number(b); // Converting into number
          if (a.length === b.length) {
            return a > b ? 1 : -1; // same length condition
          }
          return a.length > b.length ? 1 : -1; // comparing length of string
        },
      },
    ];
  // []
  //);

  const columns = useMemo(() => (columnDatas ? columnDatas : columnData), []);

  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  useEffect(() => {
    if (setCurrentPage) setCurrentPage(1);
  }, [rows]);

  return (
    <DefaultTableStyle {...getTableProps()}>
      <thead>
        {headerGroups?.map((header) => (
          // getHeaderGroupProps를 통해 header 배열을 호출한다
          <tr {...header.getHeaderGroupProps()}>
            {header.headers.map((col, index) => (
              // getHeaderProps는 각 셀 순서에 맞게 header를 호출한다
              <th
                {...col.getHeaderProps(col.getSortByToggleProps())}
                // onClick={() => setIsChange(index)}
              >
                {col.render("Header")}
                <span>
                  {col.isSorted ? (col.isSortedDesc ? " ⬇︎" : " ⬆︎") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {currentPosts
          ? currentPosts(
              rows.map((row) => {
                prepareRow(row);
                return (
                  // getRowProps는 각 row data를 호출해낸다
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      // getCellProps는 각 cell data를 호출해낸다
                      <td {...cell.getCellProps()}>
                        {typeof cell.value === "string"
                          ? cell.render("Cell")
                          : numberWithCommas(cell.value)}
                      </td>
                    ))}
                  </tr>
                );
              })
            )
          : rows.map((row) => {
              prepareRow(row);
              return (
                // getRowProps는 각 row data를 호출해낸다
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    // getCellProps는 각 cell data를 호출해낸다
                    <td {...cell.getCellProps()}>
                      {typeof cell.value === "string"
                        ? cell.render("Cell")
                        : numberWithCommas(cell.value)}
                    </td>
                  ))}
                </tr>
              );
            })}
      </tbody>
    </DefaultTableStyle>
  );
};

export default FranchiseList;
