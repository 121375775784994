import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Button,
} from "reactstrap";
import {
  merchantId_api,
  userBlock,
  userList,
  userTicketAccess,
} from "../lib/api";
import { useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { userListColumn } from "../data/ticketList";
import styled from "styled-components";
import DetailsModal from "../components/modal/detailsModal";
import PaginationComponent from "../components/pagination";
import Swal from "sweetalert2";
import { LabelRow } from "./ticketCustom";
import { exportExcel } from "../components/common";
import { ToggleBtn } from "./seatList";

const FormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1vh 0;
  .m-0 {
    display: flex;
    width: 15vw;
    .fa-search {
      position: absolute;
      right: 12px;
      top: 12.8px;
    }
  }

  @media screen and (max-width: 480px) {
    margin: 0;
    input {
      margin-top: 0;
    }
    .m-0 {
      width: 100%;
    }
    width: 100%;
  }
`;

const CardContainer = styled.div`
  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
  .btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0 10px 0 0;
      border: 1px solid #cdcdcd;
      padding: 5px;
      border-radius: 7px;
    }
    .active {
      color: #04c9db;
    }
    .right_btn {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .filter_btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .show-480 {
      display: none;
    }
  }
  @media screen and (max-width: 480px) {
    .right_btn {
      width: 100% !important;
      flex-direction: column;
      margin-bottom: 2vw;
      .filter_btn {
        margin: 0 0 2vw;
        justify-content: flex-start !important;
      }
    }
  }
`;

let mid;
const UserList = ({ setIsLoading }) => {
  const [phoneNum, setPhoneNum] = useState("all");
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userPhoneNum, setUserPhoneNum] = useState();
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [isShowSuperEnter, setIsShowSuperEnter] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [isShowAccess, setIsShowAccess] = useState(false);
  const getMerchantId = async () => {
    const midData = await merchantId_api();

    if (midData && midData.status === 200) {
      getUserList(midData.data.id);
      mid = midData.data.id;
    }
  };

  const changeAccessFunc = async (item, clickData) => {
    const result = await userTicketAccess(
      clickData === "super"
        ? {
            merchantId: mid,
            superEnter:
              item.superEnter === 1 ? 0 : item.superEnter === 0 ? 1 : 2,
            userId: item.id,
            smsDeny: item.smsDeny,
            access: item.easyTicketAccessUser,
          }
        : {
            merchantId: mid,
            superEnter: item.superEnter,
            userId: item.id,
            smsDeny: item.smsDeny,
            access:
              clickData === "access"
                ? !item.easyTicketAccessUser
                : item.easyTicketAccessUser,
          }
    );

    if (result.status === 200) {
      getUserList(mid);
    }
  };

  const getUserList = async (mid) => {
    setIsLoading(true);
    const result = await userList(mid, phoneNum);

    if (result && result.status === 200) {
      let reverseData = result.data.reverse();
      let changeData = [
        reverseData,
        reverseData.map((item) => {
          item.create = moment(item.createdAt).format("YYYY-MM-DD");
          item.finger = item.fingerPrintNum ? "O" : "-";
          item.kiosk = item.pinNum ? "O" : "-";
          item.backoffice = item.password ? "O" : "-";
          item.superEnterTag = (
            <ToggleBtn>
              <input
                type="checkbox"
                value={item.superEnter}
                checked={item.superEnter === 1}
                disabled={item.superEnter === 2}
                onChange={() => changeAccessFunc(item, "super")}
              />
              <span
                className={
                  item.superEnter === 2
                    ? "onoff-switch disabled"
                    : "onoff-switch"
                }
              ></span>
            </ToggleBtn>
          );
          item.access = (
            <ToggleBtn>
              <input
                type="checkbox"
                value={item.easyTicketAccessUser}
                checked={item.easyTicketAccessUser}
                onChange={() => changeAccessFunc(item, "access")}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          );
          // item.easyTicketAccessUser ? "O" : "-";

          item.block1 = item.block ? (
            <button
              className="btn-success btn-sm"
              onClick={() => userblockFunc(item)}
            >
              차단해제
            </button>
          ) : (
            <button
              className="btn-secondary btn-sm"
              onClick={() => userblockFunc(item)}
            >
              차단
            </button>
          );
          item.phoneNum2 = (
            <div
              onClick={() => {
                sendDetailData(item);
              }}
              className="phoneNum-click"
            >
              {item.phoneNum}
            </div>
          );
          item.info = (
            <button
              className="btn-primary btn-sm"
              onClick={() => sendDetailData(item)}
            >
              자세히
            </button>
          );
        }),
      ];

      if (isShowSuperEnter && isShowAccess) {
        let filterData = changeData[0].filter(
          (item) =>
            item.superEnter === 1 &&
            (item.easyTicketAccessUser === 1 ||
              item.easyTicketAccessUser === true)
        );
        setUserData(filterData);
        setSearchData(filterData);
      } else if (isShowSuperEnter) {
        let filterData = changeData[0].filter((item) => item.superEnter === 1);
        setUserData(filterData);
        setSearchData(filterData);
      } else if (isShowAccess) {
        let filterData = changeData[0].filter(
          (item) =>
            item.easyTicketAccessUser === 1 ||
            item.easyTicketAccessUser === true
        );
        setUserData(filterData);
        setSearchData(filterData);
      } else {
        setUserData(changeData[0]);
        setSearchData(changeData[0]);
      }
      setDefaultData(changeData[0]);

      if (searchKeyword.length > 0) {
        searchDataFun();
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const sendDetailData = (item) => {
    setDetailUserModal(true);
    setUserPhoneNum(item);
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === "") {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const searchDataFun = () => {
    if (userData) {
      setCurrentPage(1);
      let copyData = [...userData];

      setSearchData(
        copyData.filter((item) => item.phoneNum.includes(searchKeyword))
      );
    }
  };

  const userblockFunc = async (e) => {
    let blockData;
    if (e.block) {
      blockData = 0;
    } else {
      blockData = 1;
    }
    let result = await userBlock({
      merchantId: e.merchantId,
      userId: e.id,
      block: blockData,
    });
    if (result.status === 200 && blockData === 1) {
      Swal.fire({
        icon: "success",
        title: "차단 설정이 완료되었습니다.",
        timer: 2000,
      }).then(() => getUserList(mid));
    } else if (result.status === 200 && blockData === 0) {
      Swal.fire({
        icon: "success",
        title: "차단 해제가 완료되었습니다.",
        timer: 2000,
      }).then(() => getUserList(mid));
    } else {
      Swal.fire({
        icon: "error",
        title: "처리도중 에러가 발생했습니다. 다시 시도해주세요",
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    if (searchKeyword) {
      searchDataFun();
    }
  }, [userData]);

  useEffect(() => {
    getUserList(mid);
  }, [isShowSuperEnter, isShowAccess]);
  return (
    <>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userPhoneNum}
          back={true}
          setData={getUserList}
          setSearchData={searchDataFun}
        />
      )}
      <Fragment>
        <Breadcrumbs parent="정보 조회" title="회원 정보 조회" />
        <Container fluid={true}>
          <CardContainer>
            <Row>
              <Col sm="12" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <Card>
                  <CardBody>
                    <div className="btns">
                      <Button
                        className="hide-480"
                        onClick={() =>
                          exportExcel(searchData, "", "", "회원 정보 조회")
                        }
                      >
                        엑셀 출력하기
                      </Button>
                      <div className="right_btn">
                        <div className="filter_btn">
                          <p
                            onClick={() => {
                              setIsShowAccess(!isShowAccess);
                              setCurrentPage(1);
                            }}
                            className={isShowAccess ? "active" : ""}
                          >
                            QR/PIN 제한해제 {isShowAccess ? "ON" : "OFF"}
                          </p>
                          <p
                            onClick={() => {
                              setIsShowSuperEnter(!isShowSuperEnter);
                              setCurrentPage(1);
                            }}
                            className={isShowSuperEnter ? "active" : ""}
                          >
                            슈퍼입장 {isShowSuperEnter ? "ON" : "OFF"}
                          </p>
                        </div>

                        <FormContainer>
                          <Form
                            style={{
                              width: "100%",
                              marginBottom: "5px",
                            }}
                            onSubmit={(e) => {
                              e.preventDefault();
                              searchDataFun();
                            }}
                          >
                            <LabelRow className="m-0">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder="전화번호를 입력해주세요"
                                defaultValue={searchKeyword}
                                onChange={(e) =>
                                  handleSearchKeyword(e.target.value)
                                }
                              />

                              <i
                                onClick={searchDataFun}
                                className="fa fa-search"
                              />
                            </LabelRow>
                          </Form>
                        </FormContainer>
                      </div>
                    </div>

                    <DataTable
                      data={currentPosts(searchData)}
                      columns={userListColumn}
                      striped={true}
                      center={true}
                    />
                    {userData.length > 0 && (
                      <PaginationComponent
                        setCurrentPage={setCurrentPage}
                        setPostsPerPageAdd={setPostsPerPageAdd}
                        setPostsPerPageAdd1={setPostsPerPageAdd1}
                        setPageNum={setPageNum}
                        totalLength={searchData.length}
                        postsPerPage={postsPerPage}
                        currentPage={currentPage}
                        pageNum={pageNum}
                        postsPerPageAdd={postsPerPageAdd}
                        postsPerPageAdd1={postsPerPageAdd1}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardContainer>
        </Container>
      </Fragment>
    </>
  );
};

export default UserList;
