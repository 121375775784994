import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { changeSeat, getSeatList, merchantId_api } from "../../lib/api";
import { Button, Label1 } from "../../pages/lockerChange";
import configDB from "../../data/customizer/config";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const ChangeModal = ({
  isOpen,
  close,
  choiceModalClose,
  seat,
  setLists,
  lists,
}) => {
  const [changeNum, setChangeNum] = useState(0);

  const getSeatListApi = async () => {
    const res = await getSeatList(seat.merchantId);
    if (res.status === 200) {
      setLists(res.data.filter((item) => item.type === "seat"));
    }
  };

  const seatChange = async () => {
    const changeSeatData = lists.filter((item) => item.number === +changeNum);
    if (changeSeatData.length > 0) {
      if (seat.seatType === changeSeatData[0].seatType) {
        try {
          const data = await changeSeat({
            merchantId: seat.merchantId,
            userId: seat.userId,
            number1: seat.number,
            number2: +changeNum,
            ticketId: seat.ticketId,
            seatId1: seat.id,
            seatId2: changeSeatData[0].id,
          });

          if (data.status === 200) {
            Swal.fire({
              icon: "success",
              title: "좌석변경이 완료됐습니다.",
              timer: 3000,
            }).then(() => {
              choiceModalClose();
              close();
              getSeatListApi();
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "선택하신 좌석으로 이동이 불가합니다.",
          text: `${seat.seatType === 1 ? "고정석" : "자유석"}만 이용가능`,
          timer: 3000,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "해당번호는 없는 좌석번호입니다. 다시 입력해주세요.",
        timer: 3000,
      });
    }
  };

  return (
    <>
      <Modal centered isOpen={isOpen} toggle={close}>
        <ModalHeader className="modal-title" toggle={close}>
          좌석이동
        </ModalHeader>
        <ModalBody>
          <Label1>
            <h5>이동할 좌석번호를 입력해주세요.</h5>

            <Input
              type="text"
              value={changeNum}
              placeholder="좌석번호를 입력해주세요."
              onChange={({ target }) => setChangeNum(target.value)}
            />
          </Label1>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "10px",
            }}
          >
            <Button color="#fff" onClick={close}>
              취소
            </Button>
            <Button onClick={seatChange} color={primary}>
              좌석이동
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChangeModal;
