import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import Breadcrumbs from "../layout/breadcrumb";
import { EntryExitLog, getUserData, userList } from "../lib/api";
import DetailsModal from "../components/modal/detailsModal";
import { ExampleCustomInput, LabelRow } from "./ticketCustom";
import DataTable from "react-data-table-component";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import { DatePickerStyle } from "./seatInfo";
import SelectDate from "../components/selectDate";
import { userExitColumns } from "../data/ticketList";
import PaginationComponent from "../components/pagination";
import styled from "styled-components";
import { exportExcel } from "../components/common";
import { TabButton, TabList } from "./seatList";

const CardContainer = styled.div`
  .date-pickers {
    display: flex;
    h5 {
      margin: 0 6px;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;

    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      input {
        width: 100%;
      }
      .fa-search {
        position: absolute;
        right: 12px;
        top: 16.8px;
      }
    }
  }
  .searchContainer {
    display: flex;
    input {
      margin-top: 0;
      width: 100%;
    }
    form {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .topbar {
      flex-direction: column;
    }
    .searchContainer {
      width: 100%;
      margin-top: 1.5vw;
      input {
        width: 100%;
        height: 38px;
      }
      div {
        margin: 0;
        width: fit-content;
      }
      select {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .topbar {
      align-items: center;

      .m-0 {
        margin: 3vw 0 !important;
      }
    }
  }
  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

let merchantId;
const ExitLog = ({ setIsLoading }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [exitLog, setExitLog] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [defaultList, setDefaultList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [tabData, setTabData] = useState("All");

  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const [searchOption, setSearchOption] = useState("좌석번호");

  let searchKeywordData = ["좌석번호", "입장권", "내역", "전화번호", "권번"];

  const getData = async () => {
    let data = await getUserData();
    if (data.status === 200) {
      merchantId = data.data[0].id;
      getEntryExitLog(data.data[0].id, data.data[0].phoneNum);
    } else {
      Swal.fire("데이터를 불러오는데 실패하였습니다. 다시 시도해주세요");
    }
  };

  // 디테일 데이터 모달로 보내기
  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };
  // 휴대혼 번호 가져오기

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getEntryExitLog = async (e) => {
    setIsLoading(true);
    let ExitLogData = await EntryExitLog(
      e,
      moment(startDate).format("YYYY-MM-DD 00:00:00"),
      moment(endDate).format("YYYY-MM-DD 23:59:59")
    );

    let processData = [
      ExitLogData.data,
      ExitLogData.data.map((item) => {
        item.idx = item.id;
        item.message1 =
          item.message === "휴식종료(입장)" ? (
            <p style={{ color: "#3661E6" }}>외출종료(입장)</p>
          ) : item.message === "휴식" ? (
            <p style={{ color: "#E66036" }}>외출</p>
          ) : item.message.includes("입장") ? (
            <p style={{ color: "#3661E6" }}>{item.message}</p>
          ) : item.message.includes("퇴장") ? (
            <p style={{ color: "#E66036" }}>{item.message}</p>
          ) : item.message.includes("차감") ? (
            <p style={{ color: "#E66036" }}>{item.message}</p>
          ) : item.message.includes("병합") ? (
            <p style={{ color: "#3661E6" }}>{item.message}</p>
          ) : item.message.includes("자리이동") ? (
            <p style={{ color: "#1b431d" }}>
              {item.message.split("번")[0]}번에서 자리이동
            </p>
          ) : item.message.includes("사물함 만료") ? (
            <p style={{ color: "#3661E6" }}>{item.message}</p>
          ) : item.message.includes("사물함 사용") ? (
            <p style={{ color: "#E66036" }}>{item.message}</p>
          ) : (
            <p style={{ color: "red" }}>비상출입</p>
          );

        item.message2 =
          item.message === "휴식종료(입장)"
            ? "외출종료(입장)"
            : item.message === "휴식"
            ? "외출"
            : item.message.includes("입장")
            ? item.message
            : item.message.includes("퇴장")
            ? item.message
            : item.message.includes("차감")
            ? item.message
            : item.message.includes("병합")
            ? item.message
            : item.message.includes("자리이동")
            ? "자리이동"
            : item.message.includes("사물함 만료")
            ? "사물함 만료"
            : item.message.includes("사물함 사용")
            ? "사물함 사용"
            : "비상출입";
        item.phoneNumTag =
          item.phoneNum == 0 ? null : !item.phoneNum &&
            item.message.includes("비상출입") ? (
            `${item.message.split("-")[0]}`
          ) : (
            <div
              className="phoneNum-click"
              onClick={(e) => {
                sendDetailData(e.currentTarget.textContent);
              }}
            >
              {item.phoneNum}
            </div>
          );

        item.seatNumber1 = (item.seatNumber ? item.seatNumber : "-") + "번";
        item.create1 = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        item.typeData =
          item.type === 1
            ? "일회권"
            : item.type === 2
            ? "기간권"
            : item.type === 3
            ? "시간정액권"
            : item.type === 4
            ? "세미나실"
            : item.type === 5
            ? "사물함"
            : "";

        item.typeTag =
          item.type === 1 ? (
            <p style={{ color: "#EBAF22" }}>일회권</p>
          ) : item.type === 2 ? (
            <p style={{ color: "#E03D64" }}>기간권</p>
          ) : item.type === 3 ? (
            <p style={{ color: "#33946B" }}>시간정액권</p>
          ) : item.type === 4 ? (
            <p style={{ color: "#3E67B6" }}>세미나실</p>
          ) : item.type === 5 ? (
            <p style={{ color: "#AB84FF" }}>사물함</p>
          ) : (
            ""
          );
      }),
    ];
    if (tabData === "All") {
      setTotalCount(ExitLogData.data.length);
      setExitLog(processData[0]);
      setDefaultList(processData[0]);
      setCurrentPage(1);
    } else if (tabData === "seat") {
      const ticketData = processData[0].filter((item) => item.type === 1);
      setTotalCount(ticketData.length);
      setExitLog(ticketData);
      setDefaultList(processData[0]);
      setCurrentPage(1);
    } else if (tabData === "locker") {
      const lockerData = processData[0].filter(
        (item) => item.type === 5 || item.subtype.includes("locker")
      );
      setTotalCount(lockerData.length);
      setExitLog(lockerData);
      setDefaultList(processData[0]);
      setCurrentPage(1);
    }
    setIsLoading(false);
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === "") {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const searchList = (listData) => {
    let searchData;
    if (searchOption === "전화번호") {
      searchData = listData.filter((item) =>
        item.phoneNum?.includes(searchKeyword)
      );
    } else if (searchOption === "입장권") {
      searchData = listData.filter((item) =>
        item.typeData?.toString().includes(searchKeyword)
      );
    } else if (searchOption === "내역") {
      searchData = listData.filter((item) =>
        item.message2?.toString().includes(searchKeyword)
      );
    } else if (searchOption === "좌석번호") {
      if (searchKeyword.includes("번")) {
        searchData = listData.filter(
          (item) => item.seatNumber1?.toString() === searchKeyword
        );
      } else {
        searchData = listData.filter(
          (item) => item.seatNumber1?.toString() === searchKeyword + "번"
        );
      }
    } else if (searchOption === "권번") {
      searchData = listData.filter((item) =>
        item.ticketId?.toString().includes(searchKeyword)
      );
    }

    return searchData;
  };

  const searchData = () => {
    if (!searchKeyword) {
      Swal.fire({ icon: "error", title: "검색어를 입력해주세요", timer: 2000 });
    } else {
      let copyData;

      if (tabData === "All") {
        setExitLog(defaultList);
        copyData = [...defaultList];
      } else if (tabData === "seat") {
        const ticketData = defaultList.filter((item) => item.type === 1);
        copyData = [...ticketData];
      } else if (tabData === "locker") {
        const lockerData = defaultList.filter(
          (item) => item.type === 5 || item.subtype.includes("locker")
        );
        copyData = [...lockerData];
        setExitLog(lockerData);
        setTotalCount(lockerData.length);
      }

      let searchDatas = searchList(copyData);
      // copyData.filter(
      //   (item) =>
      //     item.id.toString()?.includes(searchKeyword) ||
      //     item.seatNumber1?.toString().includes(searchKeyword) ||
      //     item.phoneNum?.includes(searchKeyword) ||
      //     item.ticketId?.toString().includes(searchKeyword) ||
      //     item.message2?.toString().includes(searchKeyword) ||
      //     item.typeData?.toString().includes(searchKeyword)
      // );
      setExitLog(searchDatas);
      setTotalCount(searchDatas.length);
      setCurrentPage(1);
    }
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const changeShowData = (tabName) => {
    setIsLoading(true);
    setTabData(tabName);
    setCurrentPage(1);
    setSearchKeyword(null);
    if (tabName === "All") {
      setExitLog(defaultList);
      setTotalCount(defaultList.length);
    } else if (tabName === "seat") {
      const ticketData = defaultList.filter((item) => item.type === 1);
      setExitLog(ticketData);
      setTotalCount(ticketData.length);
    } else if (tabName === "locker") {
      const lockerData = defaultList.filter(
        (item) => item.type === 5 || item.subtype.includes("locker")
      );
      setExitLog(lockerData);
      setTotalCount(lockerData.length);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [startDate, endDate]);
  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
          // setData={getUserTicketListApi}
        />
      )}
      <Breadcrumbs parent="정보조회" title="지점 출입 정보 조회" />
      <Container fluid={true}>
        <CardContainer>
          <Row>
            <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Card>
                <CardBody>
                  <Button
                    className="hide-480"
                    onClick={() =>
                      exportExcel(
                        exitLog,
                        startDate,
                        endDate,
                        "지점 출입 정보 조회"
                      )
                    }
                  >
                    엑셀 출력하기
                  </Button>
                  <TabList>
                    <TabButton
                      selected={tabData === "All"}
                      onClick={() => changeShowData("All")}
                    >
                      전체
                    </TabButton>
                    <TabButton
                      selected={tabData === "locker"}
                      onClick={() => changeShowData("locker")}
                    >
                      사물함
                    </TabButton>
                    <TabButton
                      selected={tabData === "seat"}
                      onClick={() => changeShowData("seat")}
                    >
                      일회권
                    </TabButton>
                  </TabList>
                  <div className="topbar">
                    <DatePickerStyle>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        selectsStart
                        locale={ko}
                        startDate={startDate}
                        maxDate={endDate}
                        endDate={endDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <h5> - </h5>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        locale={ko}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <SelectDate
                        setStartDate={(val) => setStartDate(val)}
                        setEndDate={(val) => setEndDate(val)}
                      />
                    </DatePickerStyle>
                    <div className="searchContainer">
                      <DatePickerStyle>
                        <select
                          onChange={(e) => setSearchOption(e.target.value)}
                          value={searchOption}
                        >
                          {searchKeywordData.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </DatePickerStyle>
                      <Form
                        style={{
                          width: window.innerWidth > 480 ? "30%" : "100%",
                        }}
                        onSubmit={(e) => {
                          e.preventDefault();
                          searchData();
                        }}
                      >
                        <LabelRow className="m-0">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="검색어를 입력해주세요"
                            value={searchKeyword || ""}
                            onChange={(e) =>
                              handleSearchKeyword(e.target.value)
                            }
                          />

                          <i onClick={searchData} className="fa fa-search" />
                        </LabelRow>
                      </Form>
                    </div>
                  </div>
                  {totalCount > 0 ? (
                    <DataTable
                      data={currentPosts(exitLog)}
                      columns={userExitColumns}
                      striped={true}
                      center={true}
                    />
                  ) : (
                    <h5 style={{ textAlign: "center" }}>정보가 없습니다.</h5>
                  )}
                </CardBody>
                {totalCount > 0 && (
                  <PaginationComponent
                    setCurrentPage={setCurrentPage}
                    totalLength={totalCount}
                    postsPerPage={postsPerPage}
                    currentPage={currentPage}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </Fragment>
  );
};

export default ExitLog;
