import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";

import { withRouter } from "react-router-dom";

import { Login_api } from "../lib/api";
import Swal from "sweetalert2";

import Footer from "../layout/footer";
import { levelDecode } from "../components/common";

import AuthHeader from "./headerComponent";

const macaddress = require("macaddress");

const Logins = () => {
  const [LoginData, setLoginData] = useState({ id: "", password: "" });

  const [togglePassword, setTogglePassword] = useState(false);
  const { id, password } = LoginData;
  const handleOnChange = (e) => {
    setLoginData({ ...LoginData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    macaddress.all(async function(err, all) {
      try {
        const res = await Login_api(id, password);
        if (res.status === 200) {
          let levelData = levelDecode(res.data.access.token);

          if (+levelData === 99) {
            window.location.href = `${process.env.PUBLIC_URL}/merchant-list`;
          } else if (+levelData === 50) {
            window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard`;
          } else {
            window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
          }
        } else if (res.status === 202 && res.data === 102) {
          Swal.fire({
            text: "아이디가 잘못됐습니다. 다시 입력해주세요.",
            confirmButtonColor: "#04c9db",
          });
        } else if (res.status === 202 && res.data === 101) {
          Swal.fire({
            text: "비밀번호가 잘못됐습니다. 다시 입력해주세요.",
            confirmButtonColor: "#04c9db",
          });
          // let encryption = encryptFunc(res.data.user.level);
          // localStorage.setItem("number", encryption);

          //   if (decryptFunc(encryption) === 99) {
          //     window.location.href = `${process.env.PUBLIC_URL}/merchant-list`;
          //   } else if (decryptFunc(encryption) <= 50) {
          //     window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
          //   }
          // } else if (res.status === 202 && res.data === 102) {
          //   Swal.fire("아이디가 잘못됐습니다. 다시 입력해주세요.");
          // } else if (res.status === 202 && res.data === 101) {
          //   Swal.fire("비밀번호가 잘못됐습니다. 다시 입력해주세요.");
        }
      } catch (err) {
        console.log(err);
        if (err.toString().includes("401" || "500")) {
          Swal.fire(`아이디 또는 비밀번호가 틀렸습니다.`);
        } else {
          Swal.fire({
            html: "에러가 발생했습니다.<br/>잠시후 다시 시도해 주세요",
            confirmButtonColor: "#04c9db",
          });
        }
      }
    });
  };

  const onClickjoin = () => {
    window.location.href = `${process.env.PUBLIC_URL}/auth/join`;
  };

  const onClickPassword = () => {
    window.location.href = `${process.env.PUBLIC_URL}/auth/password`;
  };

  const handleKeyup = () => {
    if (window.event.keyCode === 13) {
      handleLogin();
    }
  };
  return (
    <Container style={{ overflow: "hidden" }} fluid={true} className="p-0">
      {window.innerWidth > 480 && <AuthHeader />}
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div className={window.innerWidth < 1080 ? "" : "card-style"}>
              <div style={{ marginBottom: "40px" }}>
                <img
                  className="img-fluid for-light"
                  src={require("../assets/images/logo/login.svg")}
                  alt=""
                />
                {/* <img
                  style={{ width: 140, maxHeight: 35, objectFit: "cover" }}
                  className="img-fluid for-dark"
                  src={require("../assets/images/logo/logo_dark.png")}
                  alt=""
                /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
                className="login-main login-tab"
              >
                <Form className="theme-form">
                  <FormGroup>
                    <Input
                      className="form-control"
                      placeholder="휴대폰 번호를 적어주세요."
                      type="text"
                      name="id"
                      required
                      onChange={handleOnChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                    <Input
                      className="form-control"
                      placeholder="비밀번호를 적어주세요."
                      type={togglePassword ? "text" : "password"}
                      name="password"
                      onChange={handleOnChange}
                      onKeyUp={() => handleKeyup()}
                      required
                    />
                  </FormGroup>
                  <a
                    className="ml-2"
                    href="#javascript"
                    onClick={() => onClickPassword()}
                  >
                    비밀번호 찾기
                  </a>
                </Form>
                <button className="loginBtn" onClick={() => handleLogin()}>
                  로그인
                </button>
                <p className="text">
                  아직 렛미업 회원이 아니신가요?
                  <br />
                </p>
                <button className="loginBtn line" onClick={() => onClickjoin()}>
                  회원가입
                </button>
                {/* <p className="mt-4 mb-0">
                  비밀번호를 모르실 경우
                  <br />
                  <a
                    className="ml-2"
                    href="#javascript"
                    onClick={() => onClickPassword()}
                  >
                    여기
                  </a>
                  를 클릭해주세요
                </p> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default withRouter(Logins);
