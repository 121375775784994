import React from "react";

const InformationAgreement = () => {
  return (
    <p>
      {` 샘랩주식회사(이하 귀사)와의 거래와 관련하여 귀사가 본인의 개인정보를 수집 및 이용하고자 하는 경우에는 개인정보보호법 제 15조에 따라 동의를 얻어야 합니다.
이에 본인은 귀사가 아래의 내용과 같이 본인의 개인정보를 수집 및 이용하는데 동의합니다.
- 개인정보의 수집, 이용 목적:계약의 체결, 유지, 이행, 관리 개선, 이용 서비스 제공, 법령상 의무 이행, 분쟁처리, 전화상담업무, 본인여부 확인
- 수집, 이용할 개인정보의 내용:개인식별정보: 연락처(휴대폰), 성명, 보호자의 연락처
- 개인정보의 보유, 이용 기간:거래기간 및 거래종료일로부터 5년
- 샘랩주식회사는 수집된 개인정보를 제 3자에게 제공하지 않습니다.
- 동의는 거부할 수 있으며, 동의 거부 시 제공되는 서비스에 일부 제한이 있을 수 있습니다.`}
    </p>
  );
};

export default InformationAgreement;
