import React, { Fragment, useEffect } from "react";

import { Card, CardBody, Container } from "reactstrap";
import Breadcrumb from "../layout/breadcrumb";
import { ko } from "date-fns/esm/locale";
import { useState } from "react";

import styled from "styled-components";
import moment from "moment";
import { timeTable } from "../components/arrayData";
import {
  getSeminarInfoList,
  getSeminarItemList,
  merchantId_api,
} from "../lib/api";
import Swal from "sweetalert2";
import SeminarReservationModal from "../components/modal/seminarReservationModal";
import { PossibleColor } from "./seatList";
import configDB from "../data/customizer/config";
import { ExampleCustomInput } from "./ticketCustom";
import DatePicker from "react-datepicker";
import SeminarPayLog from "../components/modal/seminarPayLog";
import "moment/locale/ko";
const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const PossibleColor1 = styled(PossibleColor)`
  border: 1px solid ${(props) => (props.border ? props.border : props.color)};
  color: ${(props) => (props.border ? props.border : "#fff")};
  font-weight: 600;
`;

const TopObject = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  > div {
    display: flex;
  }
  .react-datepicker-wrapper {
    width: 210px;
    margin-right: 20px;
    > div {
      > button {
        width: 100%;
      }
    }
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 8px 15px;
    margin-right: 20px;
    width: 210px;
  }
  > p {
    font-weight: 500;
  }
  @media screen and (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;
    > div {
      width: 100%;
    }
    .react-datepicker-wrapper {
      width: 100%;
      margin-right: 0;
    }
    select {
      margin-right: 5px;
      width: 100%;
    }
    > p {
      margin-left: 5px;
    }
  }
`;

const TimeTableColumn = styled.div`
  width: 88%;
  height: 40px;
  border: 1px solid ${primary};
  border-radius: 4px;
  padding: 1vw;
  margin-top: -17px;
  margin-left: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background: #fff;
    border: 1px solid #00c3c5;
    padding: 0px 15px;
    color: #00c3c5;
    font-weight: 500;
    height: 25px;
    border-radius: 4px;
    margin: 0 10px;
  }
  .reservation {
    display: flex;
  }
  p {
    margin-bottom: 0;
    margin-top: 1px;
  }
  /* p {
    display: none;
  } */
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-top: -15px;
    margin-left: 0%;
    p {
      display: block;
    }
  }
`;

const TimeContainer = styled.div`
  width: 70%;
  margin: 20px auto;
  .active {
    background: #dbf8f9;
    border: 1px solid #00c3c5;
  }
  .disable {
    background: ${primary};
    border: 1px solid ${primary};
  }
  .notTouch {
    background: #e9e9e9;
    border: 1px solid #222;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

let mid;
const Seminar = ({ setIsLoading }) => {
  const [today, setToday] = useState(new Date());
  const [timeLine, setTimeLine] = useState([]);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [seminarData, setSeminarData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [seminarId, setSeminarId] = useState();
  const [isPayLog, setIsPayLog] = useState(false);
  const [sendData, setSendData] = useState({
    phoneNum: "",
    id: "",
  });
  const [totalTime, setTotalTime] = useState(0);
  const [seminarNum, setSeminarNum] = useState(0);
  const choiceItem = (itemData, index) => {
    let totalTimeData;
    //시작시간만 있는경우
    if (startTime && !endTime && itemData.reserved === 0) {
      if (startTime) {
        let start1 = `${startTime.date} ${startTime.day.substr(6, 7)}:00`;
        let start = moment(start1);
        let end1 = `${timeLine[index + 1].date} ${timeLine[
          index + 1
        ].day.substr(6, 7)}:00`;
        let end = moment(end1);
        totalTimeData = `${moment.duration(end.diff(start)).asMinutes()}`;
      }

      //시작시간o 끝나는 시간 x
      //시작시간보다 이후시간을 잡을경우
      // a: 정상적으로 시간 잡았을때: startTime.number<reserved<index
      if (index > startTime.number) {
        //시작시간이 있는 상태에서 사이에 예약이 있는 것을 잡았을때
        let filterData = timeLine
          .filter((item, idx) => startTime.number < idx && idx < index)
          .some((item) => item.reservation === 1);
        if (filterData) {
          Swal.fire({
            icon: "error",
            title: "선택불가",
            text:
              "선택한 시간사이에 예약된 시간이 있을경우 선택할 수 없습니다.",
            timer: 4000,
          });
        } else {
          setTimeLine(
            timeLine
              .map((item, idx) =>
                idx === index ? { ...item, isEnd: 1 } : item
              )
              .map((item, idx) =>
                idx > startTime.number && index > idx
                  ? { ...item, reserved: 1 }
                  : item
              )
          );

          setEndTime(timeLine[index + 1]);
          setTotalTime(+totalTimeData);
        }
      } //startTime이 있는 상황에서 같은 시간을 잡을때
      else if (index === startTime.number) {
        setTimeLine(
          timeLine.map((item, idx) =>
            idx === index
              ? { ...item, isStart: 0, reserved: 0 }
              : { ...item, reserved: 0 }
          )
        );

        setStartTime();
        setTotalTime();
      } else if (endTime) {
        Swal.fire({
          icon: "error",
          title: "선택불가",
          text: "이미 선택된 값이 있습니다.",
          timer: 4000,
        });
      }
      //시작시간보다 이전 시간을 잡을 경우
      else {
        Swal.fire({
          icon: "error",
          title: "선택불가",
          text: "시작시간 보다 이전 시간은 끝나는 시간으로 잡을 수 없습니다.",
          timer: 4000,
        });
      }
    } else if (startTime && endTime) {
      if (startTime.number === index) {
        setTimeLine(
          timeLine.map((item, idx) =>
            idx === index
              ? { ...item, isStart: 0, reserved: 0 }
              : { ...item, reserved: 0 }
          )
        );

        setStartTime();
        setTotalTime();
      } else if (+endTime.number - 1 !== index) {
        Swal.fire({
          icon: "error",
          title: "선택불가",
          text: "이미 선택된 시간이 있습니다.",
          timer: 4000,
        });
      } else {
        setTimeLine(
          timeLine.map((item, idx) =>
            idx === index
              ? { ...item, isEnd: 0, reserved: 0 }
              : { ...item, reserved: 0 }
          )
        );
        setEndTime();
        setTotalTime();
      }
    }
    //끝나는 시간만 있는경우
    else if (endTime) {
      //끝나는 시간보다 이후 시간을 잡을 경우
      if (endTime.number <= index) {
        Swal.fire({
          icon: "error",
          title: "선택불가",
          text: "끝나는 시간보다 이후 시간은 잡을 수 없습니다.",
          timer: 4000,
        });
      } else if (endTime.number - 1 === index) {
        setTimeLine(
          timeLine.map((item, idx) =>
            idx === index
              ? { ...item, isEnd: 0, reserved: 0 }
              : { ...item, reserved: 0 }
          )
        );
        setEndTime();
        setTotalTime();
      }

      //끝나는 시간보다 이전시간을 잡을 경우(정상))
      else if (!startTime && endTime.number > index) {
        let filterData = timeLine
          .filter((item, idx) => endTime.number > idx && idx > index)
          .some((item) => item.reservation === 1);

        if (filterData) {
          Swal.fire({
            icon: "error",
            title: "선택불가",
            text:
              "선택한 시간사이에 예약된 시간이 있을경우 선택할 수 없습니다.",
            timer: 4000,
          });
        } else {
          if (endTime) {
            let start1 = `${timeLine[index].date} ${timeLine[index].day.substr(
              6,
              7
            )}:00`;
            let start = moment(start1);
            let end1 = `${endTime.date} ${endTime.day.substr(6, 7)}:00`;
            let end = moment(end1);
            totalTimeData = `${moment.duration(end.diff(start)).asMinutes()}`;
          }

          setTimeLine(
            timeLine
              .map((item, idx) =>
                idx === index ? { ...item, isStart: 1 } : item
              )
              .map((item, idx) =>
                idx > index && endTime.number > idx
                  ? { ...item, reserved: 1 }
                  : item
              )
          );

          setStartTime(timeLine[index]);
          setTotalTime(+totalTimeData);
        }
      } else if (startTime && endTime.number - 1 === index) {
        timeLine.map((item, idx) =>
          idx === index
            ? { ...item, isEnd: 0, reserved: 0 }
            : { ...item, reserved: 0 }
        );

        setEndTime();
      }
    }

    //둘다 없는경우
    else {
      //선택한 값이 마지막 일때
      if (itemData.number === 59) {
        Swal.fire({
          icon: "error",
          title: "선택불가",
          text: "선택하신 시간대는 시작 시간으로 잡을 수 없습니다.",
          timer: 4000,
        });
      } //마지막시간이 아닐때
      else {
        setTimeLine(
          timeLine.map((item, idx) =>
            idx === index ? { ...item, isStart: 1 } : item
          )
        );

        setStartTime(timeLine[index]);
      }
    }
  };

  const getData = async () => {
    const data = await merchantId_api();
    if (data.status === 200) {
      mid = data.data.id;
      getSeminarData(data.data.id);
      getSeminarList(data.data.id, timeTable);
    }
  };

  const getSeminarData = async (e) => {
    let data = await getSeminarInfoList(e);

    if (data.data.length > 0 && data.status === 200) {
      let filterData = data.data.filter((item) => item.status !== "block");
      setSeminarData(filterData);
      setSeminarId(filterData[0].id);
      setSeminarNum(filterData[0].number);
    }
  };

  const getSeminarList = async (e, timedata) => {
    setIsLoading(true);
    let data = await getSeminarItemList(
      e,
      moment(today)
        .subtract(1, "days")
        .format("YYYYMMDD000000"),
      moment(today)
        .add(1, "days")
        .format("YYYYMMDD060000")
    );

    if (data.status === 200) {
      //현재시각에 가장 비슷한 시간 찾는 함수
      let todayDate = moment(today).format(`YYYY-MM-DD HH:mm:ss`);
      let nowDateYMD = moment(new Date()).format(`YYYY-MM-DD`);
      let dateBoolean = moment(today).format("YYYY-MM-DD") === nowDateYMD;
      let array = timedata.map((item, idx) => ({
        format:
          idx >= timedata.length - 13
            ? moment(today)
                .add(1, "days")
                .format(
                  `YYYY-MM-DD ${item.time.substr(0, 2)}:${item.time.substr(
                    2,
                    2
                  )}:00`
                )
            : moment(today).format(
                `YYYY-MM-DD ${item.time.substr(0, 2)}:${item.time.substr(
                  2,
                  2
                )}:00`
              ),
        index: idx,
      }));

      let findIndexData = array.filter(
        (item) =>
          moment
            .duration(moment(todayDate).diff(moment(item.format)))
            .asMilliseconds() < 0
      );

      let nowDateIndex = findIndexData[0];

      //^^^현재시각에 가장 비슷한 시간 찾는 함수끝

      if (data.data.length === 0) {
        let data = timedata.map((item, idx) => ({
          ...item,
          previous:
            idx < nowDateIndex.index - 2 && dateBoolean === true ? 1 : 0,
          now: +idx === +nowDateIndex.index && dateBoolean === true ? 1 : 0,
          day:
            idx >= timedata.length - 13
              ? moment(today)
                  .add(1, "days")
                  .format(
                    `MM-DD ${item.time.substr(0, 2)}:${item.time.substr(2, 2)}`
                  )
              : moment(today).format(
                  `MM-DD ${item.time.substr(0, 2)}:${item.time.substr(2, 2)}`
                ),
          date:
            idx >= timedata.length - 13
              ? moment(today)
                  .add(1, "days")
                  .format(`YYYY-MM-DD`)
              : moment(today).format(`YYYY-MM-DD`),
          number: idx,
          dateKr:
            idx >= timedata.length - 13
              ? moment(today)
                  .add(1, "days")
                  .format(
                    `MM월 DD일 ${
                      +item.time.substr(0, 2) > 11 ? "오후" : "오전"
                    } ${
                      +item.time.substr(0, 2) > 12
                        ? +item.time.substr(0, 2) - 12
                        : item.time.substr(0, 2)
                    }시${item.time.substr(2, 2)}분`
                  )
              : moment(today).format(
                  `MM월 DD일 ${
                    +item.time.substr(0, 2) > 11 ? "오후" : "오전"
                  } ${
                    +item.time.substr(0, 2) > 12
                      ? +item.time.substr(0, 2) - 12
                      : item.time.substr(0, 2)
                  }시${item.time.substr(2, 2)}분`
                ),
        }));

        setTimeLine(data);
      } else {
        //선택날짜 변환

        let seminarItem = data.data.filter(
          (item) => +item.seminarId === +seminarId
        );

        let time = [
          seminarItem,
          seminarItem.map((item) => {
            item.startTime = moment(item.startDate, "YYYY-MM-DDTHH:mm:ssZ")
              ._d.toString()
              .split(" ")[4]
              .replaceAll(":", "");

            item.endTime = moment(item.endDate, "YYYY-MM-DDTHH:mm:ssZ")
              ._d.toString()
              .split(" ")[4]
              .replaceAll(":", "");
            item.startDay = moment(item.startDate).format(`YYYY-MM-DD`);
            item.endDay = moment(item.endDate).format(`YYYY-MM-DD`);
          }),
        ];

        let todayData = moment(today).format(`YYYY-MM-DD`);
        let tomorrow = moment(today)
          .add(1, "days")
          .format(`YYYY-MM-DD`);
        //하루전 데이터에서 걸쳐있는 출력되야하는데이터 가져오기(startDate가 today 이거나 enddate가 today이거나)
        let newTime = time[0].filter(
          (item) =>
            item.endDay === todayData ||
            item.startDay === todayData ||
            item.endDay === tomorrow
        );

        timedata = timedata.map((item, idx) => ({
          ...item,
          previous:
            idx < nowDateIndex.index - 2 && dateBoolean === true ? 1 : 0,
          now: +idx === +nowDateIndex.index && dateBoolean === true ? 1 : 0,
          day:
            idx >= timedata.length - 13
              ? moment(today)
                  .add(1, "days")
                  .format(
                    `MM-DD ${item.time.substr(0, 2)}:${item.time.substr(2, 2)}`
                  )
              : moment(today).format(
                  `MM-DD ${item.time.substr(0, 2)}:${item.time.substr(2, 2)}`
                ),
          date:
            idx >= timedata.length - 13
              ? moment(today)
                  .add(1, "days")
                  .format(`YYYY-MM-DD`)
              : moment(today).format(`YYYY-MM-DD`),
          number: idx,
          dateKr:
            idx >= timedata.length - 13
              ? moment(today)
                  .add(1, "days")
                  .format(
                    `MM월 DD일 ${
                      +item.time.substr(0, 2) > 11 ? "오후" : "오전"
                    } ${
                      +item.time.substr(0, 2) > 12
                        ? +item.time.substr(0, 2) - 12
                        : item.time.substr(0, 2)
                    }시${item.time.substr(2, 2)}분`
                  )
              : moment(today).format(
                  `MM월 DD일 ${
                    +item.time.substr(0, 2) > 11 ? "오후" : "오전"
                  } ${
                    +item.time.substr(0, 2) > 12
                      ? +item.time.substr(0, 2) - 12
                      : item.time.substr(0, 2)
                  }시${item.time.substr(2, 2)}분`
                ),
        }));

        let newData = [...timedata];

        for (let i = 0; i < newTime.length; i++) {
          //현재날짜하고 다르게 선택했을때 걸쳐있는 데이터처리
          let startData = moment(newTime[i].startDay);
          let diffData = moment.duration(startData.diff(todayData));

          //a: 시작 끝 날짜가 다르면서 startDate가 어제 날짜 일때
          if (
            newTime[i].startDay !== newTime[i].endDay &&
            diffData.asDays() === -1
          ) {
            newData = newData.map((item, index) =>
              item.date === newTime[i].endDay &&
              item.time < newTime[i].endTime.slice(0, 4)
                ? {
                    ...item,
                    reservation: 1,
                    phoneNum: newTime[i].phoneNum,
                    id: newTime[i].id,
                  }
                : item
            );
          }
          //b:  시작 끝 날짜가 다르면서 startDate가 오늘 날짜 일때
          else if (
            newTime[i].startDay !== newTime[i].endDay &&
            diffData.asDays() === 0
          ) {
            newData = newData.map((item, index) =>
              (item.date === newTime[i].startDay &&
                item.time >= newTime[i].startTime.slice(0, 4)) ||
              (item.date === newTime[i].endDay &&
                item.time < newTime[i].endTime.slice(0, 4))
                ? {
                    ...item,
                    reservation: 1,
                    phoneNum: newTime[i].phoneNum,
                    id: newTime[i].id,
                  }
                : item
            );
          }
          //c: 시작날짜 끝날짜가 동일할때&&그외에
          else {
            newData = newData.map((item, index) =>
              item.date === newTime[i].startDay &&
              item.time >= newTime[i].startTime.slice(0, 4) &&
              item.time < newTime[i].endTime.slice(0, 4)
                ? {
                    ...item,
                    reservation: 1,
                    phoneNum: newTime[i].phoneNum,
                    id: newTime[i].id,
                  }
                : item
            );
          }
        }

        setTimeLine(
          newData.map((item, idx) => ({
            ...item,
            previous:
              idx < nowDateIndex.index - 2 && dateBoolean === true ? 1 : 0,
            now: +idx === +nowDateIndex.index && dateBoolean === true ? 1 : 0,
            day:
              idx >= newData.length - 13
                ? moment(today)
                    .add(1, "days")
                    .format(
                      `MM-DD ${item.time.substr(0, 2)}:${item.time.substr(
                        2,
                        2
                      )}`
                    )
                : moment(today).format(
                    `MM-DD ${item.time.substr(0, 2)}:${item.time.substr(2, 2)}`
                  ),
            date:
              idx >= newData.length - 13
                ? moment(today)
                    .add(1, "days")
                    .format(`YYYY-MM-DD`)
                : moment(today).format(`YYYY-MM-DD`),
            number: idx,
            dateKr:
              idx >= timedata.length - 13
                ? moment(today)
                    .add(1, "days")
                    .format(
                      `MM월 DD일 ${
                        +item.time.substr(0, 2) > 11 ? "오후" : "오전"
                      } ${
                        +item.time.substr(0, 2) > 12
                          ? +item.time.substr(0, 2) - 12
                          : item.time.substr(0, 2)
                      }시${item.time.substr(2, 2)}분`
                    )
                : moment(today).format(
                    `MM월 DD일 ${
                      +item.time.substr(0, 2) > 11 ? "오후" : "오전"
                    } ${
                      +item.time.substr(0, 2) > 12
                        ? +item.time.substr(0, 2) - 12
                        : item.time.substr(0, 2)
                    }시${item.time.substr(2, 2)}분`
                  ),

            // moment(item.day).format(`M월 D일 a h시mm분`),
          }))
        );
      }
    } else {
      Swal.fire(
        "세미나실 기존예약을 불러 오는데 실패하였습니다.",
        "다시 시도해주세요"
      );
    }
    setIsLoading(false);
  };

  const reservationModal = (e) => {
    e.stopPropagation();
    if (!endTime || !startTime) {
      Swal.fire({
        icon: "error",
        title: "시간을 설정해주세요",
        timer: 2000,
      });
    } else {
      setIsModal(!isModal);
    }
  };

  const showPayLog = (phoneNum, id) => {
    setIsPayLog(!isPayLog);
    setSendData({ ...sendData, phoneNum, id, merchantId: mid, today });
  };

  const changeSeclect = (e) => {
    setSeminarId(e.target.value);
    setSeminarNum(e.target.options[e.target.selectedIndex].text.split(".")[0]);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (mid) {
      getSeminarList(mid, timeTable);
    }

    setStartTime();
    setEndTime();
  }, [today, seminarId]);

  return (
    <>
      {isModal && (
        <SeminarReservationModal
          close={reservationModal}
          startTime={startTime}
          endTime={endTime}
          mid={mid}
          seminarId={seminarId}
          seminarNum={seminarNum}
        />
      )}
      {isPayLog && <SeminarPayLog close={showPayLog} payData={sendData} />}
      <Fragment>
        <Breadcrumb parent="세미나실 예약" title="세미나실 예약하기" />
        <Container fluid={true} style={{ padding: "0 5px" }}>
          <Card>
            <CardBody>
              <TopObject>
                <div>
                  <select
                    value={seminarId}
                    name={seminarNum}
                    onChange={(e) => {
                      changeSeclect(e);
                    }}
                  >
                    {seminarData.length !== 0 &&
                      seminarData.map((item, index) => (
                        <Fragment key={item.id + "sem"}>
                          <option value={item.id} defaultChecked={(index = 0)}>
                            {item.number ? `${item.number}.` : ""}
                            {item.name}
                          </option>
                        </Fragment>
                      ))}
                  </select>
                  <DatePicker
                    style={{ width: "30%" }}
                    selected={today}
                    onChange={(date) => {
                      setToday(date);
                    }}
                    // selectsStart
                    locale={ko}
                    // startDate={startDate}
                    // maxDate={endDate}
                    // endDate={endDate}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
                <p>예약할 세미나실, 날짜, 시간을 선택하세요</p>
              </TopObject>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <PossibleColor1 color="#e9e9e9" border="#222">
                  <p>선택불가</p>
                </PossibleColor1>
                <PossibleColor1 color={primary}>
                  <p>예약완료</p>
                </PossibleColor1>
                <PossibleColor1 color="#DBF8F9" border="#00C3C5">
                  <p>예약선택</p>
                </PossibleColor1>
                <PossibleColor1 color="#fff" border={primary}>
                  <p>예약가능</p>
                </PossibleColor1>
              </div>
              {seminarData.length !== 0 && (
                <TimeContainer>
                  {timeLine.map((item, idx) => (
                    <Fragment key={item.day + "TT"}>
                      {idx === timeLine.length - 1 ? (
                        <>
                          <p>
                            {/* {moment(item.day).format(`M월 D일 a h시mm분`)} */}
                            {item.dateKr}
                          </p>
                        </>
                      ) : +item.reservation === 1 ? (
                        <>
                          <p>
                            {/* {moment(item.day).format(`M월 D일 a h시mm분`)} */}
                            {item.dateKr}
                          </p>

                          <TimeTableColumn
                            className={
                              +item.isEnd === 1 || +item.isStart === 1
                                ? "active"
                                : +item.reservation === 1
                                ? "disable"
                                : ""
                            }
                            onClick={() => showPayLog(item.phoneNum, item.id)}
                          >
                            {+item.reservation === 1 && item.phoneNum && (
                              <p style={{ color: "#fff" }}>
                                {item.phoneNum} 예약완료
                              </p>
                            )}
                          </TimeTableColumn>
                        </>
                      ) : +item.previous === 1 ? (
                        <>
                          <p>
                            {/* {moment(item.day).format(`M월 D일 a h시mm분`)} */}
                            {item.dateKr}
                          </p>

                          <TimeTableColumn className="notTouch">
                            <p style={{ color: "#222222" }}>선택불가</p>
                          </TimeTableColumn>
                        </>
                      ) : (
                        <>
                          {item.now === 1 ? (
                            <p style={{ color: "#04c9db", fontWeight: 700 }}>
                              {item.dateKr} (현재)
                            </p>
                          ) : (
                            <p>
                              {/* {moment(item.day).format(`M월 D일 a h시mm분`)} */}
                              {item.dateKr}
                            </p>
                          )}

                          <TimeTableColumn
                            onClick={() => choiceItem(item, idx)}
                            className={
                              +item.isEnd === 1 ||
                              +item.isStart === 1 ||
                              +item.reserved === 1
                                ? "active"
                                : item.reservation === 1
                                ? "disable"
                                : ""
                            }
                          >
                            {item.isEnd === 1 && (
                              <span className="reservation">
                                {totalTime && (
                                  <p>
                                    총 예약시간: {Math.floor(+totalTime / 60)}
                                    시간 {+totalTime % 60}분{" "}
                                  </p>
                                )}
                                <button onClick={(e) => reservationModal(e)}>
                                  <p>예약하기</p>
                                </button>
                              </span>
                            )}
                            {item.isStart === 1 && (
                              <span className="start_choice">
                                예약시간:
                                {item.dateKr}
                              </span>
                            )}
                            {item.isStart === 0 &&
                              item.isEnd === 0 &&
                              item.reserved === 0 &&
                              item.reservation === 0 && (
                                <span className="normal">선택가능</span>
                              )}
                            {item.isStart === 0 &&
                              item.isEnd === 0 &&
                              item.reserved === 1 && (
                                <span className="normal">선택완료</span>
                              )}
                            {+item.reservation === 1 && item.phoneNum && (
                              <p style={{ color: "#fff" }}>
                                {item.phoneNum} 예약완료
                              </p>
                            )}
                          </TimeTableColumn>
                        </>
                      )}
                    </Fragment>
                  ))}
                </TimeContainer>
              )}
              {seminarData.length === 0 && (
                <>
                  <br />
                  <p>데이터를 불러올 수 없습니다.</p>
                  <p>새로고침을 하시거나 세미나실 설정을 확인해주세요</p>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    </>
  );
};

export default Seminar;
