import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Card } from "reactstrap";
import styled from "styled-components";
import { noticeColumns } from "../../data/ticketList";
import {
  announcementRead,
  getMerchantInfo,
  merchantId_api,
} from "../../lib/api";

import { levelDecode } from "../common";
import NoticeModal from "../modal/noticeModal";
import PaginationComponent from "../pagination";
import ChannelService from "./channelTalk";
import StoreOverview from "../storeOverview";
import { BackgroundLoading } from "../Loading";
import UsageComponent from "./adminDashboard/usageComponent";
import TicketUserComponent from "./adminDashboard/ticketUserComponent";
import SalesChart from "./adminDashboard/saleChart";
import NoticeListTable from "./adminDashboard/NoticeList";
import SimplePayData from "./adminDashboard/simplePayData";
import QuickMenu from "./adminDashboard/quickMenu";
import { useRef } from "react";
import TicketSale from "./adminDashboard/ticketSale";

// const LinkBtns = styled.div`
//   a {
//     box-shadow: rgb(0 0 0 / 20%) 0px 2px 14px -3px;
//     margin-right: 1vw;
//     button {
//       background-color: #ffffff !important ;
//       border: none;
//       color: #04c9db;
//     }
//     button:hover {
//       background-color: #04c9db !important;
//       border-color: #04c9db !important;
//       color: #ffffff;
//     }
//   }

//   @media screen and (max-width: 768px) {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     gap: 10px;
//     a {
//       button {
//         width: 100%;
//         border-radius: 0.25rem;
//       }
//     }
//   }
// `;
// const FirstLine = styled.div`;
//   display: flex;
//   margin-top: 30px;
//   justify-content: space-between;
//   > div:nth-child(2) {
//     width: 57%;
//   }
//   @media screen and (max-width: 768px) {
//     flex-direction: column;
//     .card {
//       width: 100%;
//     }
//     > div:nth-child(2) {
//       width: 100%;
//     }
//   }
// `;
// const NoticeContainer = styled.div`
//   padding: 30px;
// `;

// const FlexContainer = styled.div`
//   width: 100%;
//   display: grid;
// `;

const FlexColunmContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.1vw;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1.1vw;
  height: fit-content;
  > div {
    width: 100%;
    gap: 1.1vw;
    display: grid;
    &:first-child {
      grid-template-columns: 1fr 1fr 1fr 2fr;
    }
    &:nth-child(2) {
      grid-template-columns: 2fr 0.5fr;
    }
  }
`;

const MobileContainer = styled.div`
  width: 83.33vw;
  margin: 4.44vw auto;
  display: grid;
  grid-row-gap: 4.44vw;
`;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  margin: -1px auto 0;
  gap: 1.66vw;
  height: 18.88vw;
  justify-content: space-between;
  position: fixed;
  padding: 0 calc((103vw - 86.92vw) / 2);
  background-color: #f1f3f6;
  align-items: center;
  z-index: 1;
  button {
    height: 10vw;
    background-color: #ffffff;
    border-radius: 100vw;
    color: #91979e;
    border: 1px solid #dde4eb;
    font-weight: 500;
    font-size: 4.44vw;
    padding: 0;
    width: 100%;
    &.check {
      background-color: var(--dashboard-default);
      color: #ffffff;
    }
  }
`;

const CenterContainer = styled.div`
  width: 100%;
  height: calc(100vh - 6.56vw);
  margin-top: calc(4.16vw - 6.24vw);
  display: flex;
  align-items: center;
`;
const AdminDashboard = ({ setIsLoading }) => {
  const [merchantData, setMerchantData] = useState({});
  const isMobile = window.innerWidth < 806;
  const isPc = window.innerWidth < 1920;
  const [selectContent, setSelectContent] = useState(0);
  const tabRef = useRef([]);
  const [mid, setMid] = useState();
  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("month")._d
  );
  const [endDate, setEndDate] = useState(new Date());

  let level = levelDecode(localStorage.getItem("Token"));

  const getMerchantData = async () => {
    const { data } = await merchantId_api();

    if (data) {
      const res = await getMerchantInfo(data.id);
      setMid(data.id);
      if (res.status === 200) {
        setMerchantData(res.data[0]);
      }
    }
  };

  const tabArray = [
    { name: "통계", tab: 0 },
    { name: "매출", tab: 1 },
    { name: "공지", tab: 2 },
  ];

  const moveContent = (tab) => {
    tabRef.current[tab].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setSelectContent(tab);
  };

  useEffect(() => {
    if (+level === 1) {
      window.location.href = `${window.location.origin}/dashboard`;
    }
    getMerchantData();
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (merchantData) {
      ChannelService.boot({
        pluginKey: "c7244504-2def-4fd9-b399-3336a3cbcceb", //please fill with your plugin key
        memberId: merchantData.merchant_code,
        profile: {
          name: merchantData.name,
          mobileNumber: merchantData.contact,
          merchant: merchantData.name,
          phoneNum: merchantData.contact,
        },
      });
    }
  }, [merchantData]);

  return (
    <>
      <Fragment>
        {/* <Breadcrumbs parent="대시보드" title="대시보드" /> */}
        {/* 1920 */}
        {!isMobile && (
          <CenterContainer>
            <GridContainer>
              <div className="rowContent">
                <UsageComponent type="seat" />
                <UsageComponent type="locker" />
                <UsageComponent type="message" />
                <TicketUserComponent isMobile={isMobile} />
              </div>
              <div className="rowContent">
                <FlexColunmContainer>
                  <div className="grid">
                    <SalesChart
                      mid={mid}
                      paddingStyle={"none"}
                      setIsLoading={setIsLoading}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                    <NoticeListTable />
                  </div>
                  <TicketSale
                    mid={mid}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </FlexColunmContainer>
                <FlexColunmContainer>
                  <SimplePayData mid={mid} />
                  <QuickMenu
                    isMobile={isMobile}
                    isMulti={merchantData.multiKioskFloor}
                    mid={mid}
                  />
                </FlexColunmContainer>
              </div>
            </GridContainer>
          </CenterContainer>
        )}
        {/* mobile */}
        {isMobile && (
          <>
            <TabContainer>
              {tabArray.map((item) => (
                <button
                  className={selectContent === item.tab ? "check" : ""}
                  onClick={() => moveContent(item.tab)}
                >
                  {item.name}
                </button>
              ))}
            </TabContainer>
            <MobileContainer>
              <div
                style={{
                  scrollMargin: "50.88vw",
                  marginTop: "14.88vw",
                }}
                ref={(el) => (tabRef.current[0] = el)}
              />
              <QuickMenu
                isMobile={isMobile}
                isMulti={merchantData.multiKioskFloor}
                mid={mid}
              />
              <UsageComponent type="seat" />
              <UsageComponent type="locker" />
              <UsageComponent type="message" />
              <TicketUserComponent isMobile={isMobile} />
              <div
                style={{ scrollMargin: "32vw" }}
                ref={(el) => (tabRef.current[1] = el)}
              />
              <SimplePayData mid={mid} />

              <SalesChart
                mid={mid}
                isMobile={isMobile}
                paddingStyle={"none"}
                setIsLoading={setIsLoading}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <TicketSale
                isMobile={isMobile}
                mid={mid}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <div
                style={{ scrollMargin: "32vw" }}
                ref={(el) => (tabRef.current[2] = el)}
              />
              <NoticeListTable isMobile={isMobile} />
            </MobileContainer>
          </>
        )}
      </Fragment>
    </>
  );
};

export default AdminDashboard;
