import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Input,
  Row,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import Breadcrumbs from "../../layout/breadcrumb";
import {
  getUserData,
  getUserMerchantList,
  userInfoUpdate,
  Logout_api,
} from "../../lib/api";
import { Label1 } from "../lockerChange";
import configDB from "../../data/customizer/config";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

export const SearchLabel = styled(Label1)`
  width: 80%;
  > div {
    display: flex;
    align-items: center;
    width: 50%;

    > input {
      padding: 7px 13px;
      width: 70%;
      margin: 0 2px 0 0;
    }
  }
  @media screen and (max-width: 768px) {
    > div {
      width: 60%;
    }
  }
  @media screen and (max-width: 480px) {
    > div {
      width: 100%;
      > input {
        width: 75%;
      }
    }
    button {
      width: 39%;
    }
  }
`;

export const SearchLists = styled.ul`
  width: 40.5%;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.15);
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const SearchList = styled.li`
  color: ${(props) => (props.selected ? primary : "#555")};
  :not(:last-child) {
    margin-bottom: 7px;
  }
`;
const User = () => {
  const [userData, setUserData] = useState({});

  const {
    phoneNum,
    merchantId,
    gender,
    parentPhoneNum,
    userId,
    email,
    job,
    smsDeny,
    userName,
  } = userData;

  const [editData, setEditData] = useState({});
  const [searchMerchant, setSearchMerchant] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const getUserInfo = async () => {
    try {
      const { data } = await getUserData();

      setUserData(data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  const handleChange = ({ target }) => {
    var regex = /[^0-9]/g;
    if (
      target.name === "gender" ||
      target.name === "smsDeny" ||
      target.name === "alertKaKaoToggle"
    ) {
      setUserData({ ...userData, [target.name]: +target.value });
      setEditData({ ...editData, [target.name]: +target.value });
    } else if (target.name === "userName") {
      setUserData({ ...userData, userName: target.value });
      setEditData({ ...editData, name: target.value });
    } else if (target.name === "email") {
      setUserData({ ...userData, email: target.value });
      setEditData({ ...editData, email: target.value });
    } else if (target.name === "parentPhoneNum") {
      setUserData({
        ...userData,
        parentPhoneNum: target.value.replace(regex, ""),
      });
      setEditData({
        ...editData,
        parentPhoneNum: target.value.replace(regex, ""),
      });
    } else {
      setEditData({ ...editData, [target.name]: target.value });
    }
  };

  const handleSearchKeyword = ({ target }) => {
    const regexp = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
    setSearchMerchant(target.value.replace(regexp, ""));
  };
  const keyword = ["스터디카페", "스터디", "카페", "스터"];
  const handleSearchData = async () => {
    if (searchMerchant.length < 2) {
      return Swal.fire({
        icon: "error",
        title: "2글자 이상 검색해주세요.",
        timer: 3500,
      });
    }
    if (keyword.some((word) => searchMerchant.includes(word))) {
      return;
    }
    const { data } = await getUserMerchantList(merchantId, searchMerchant);

    if (data?.length > 0) {
      setMerchantList(data);
    }
  };
  const handleOnSubmit = async () => {
    //한글만
    const nameTest = /^[ㄱ-ㅎ|가-힣]{1,5}$/;

    //6자 이상이면서 영문,숫자,특수문자(!@$%^&* 만 허용)
    const pwCheck = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/;
    //이메일
    const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    //전화번호
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

    if (!userName || !editData) {
      return Swal.fire({
        icon: "error",
        title: "이름을 입력해주세요",
        timer: 3500,
      });
      //이름의 정규표현식이 틀린경우
    } else if (
      (editData.name && !nameTest.test(editData.name)) ||
      (userName && !nameTest.test(userName))
    ) {
      Swal.fire({
        icon: "error",
        title: "이름의 형식을 맞춰주세요",
        timer: 3500,
      });
      //부모님 전화번호 형식이 틀린경우
    } else if (
      (editData.email && !regEmail.test(editData.email)) ||
      (email && !regEmail.test(email))
    ) {
      Swal.fire({
        icon: "error",
        title: "이메일 형식을 맞춰주세요",
        timer: 3500,
      });
    } else if (
      (editData.parentPhoneNum && !regPhone.test(editData.parentPhoneNum)) ||
      (parentPhoneNum && !regPhone.test(parentPhoneNum))
    ) {
      Swal.fire({
        icon: "error",
        title: "핸드폰번호 형식을 맞춰주세요",
        timer: 3500,
      });
    } else {
      try {
        Swal.fire({
          icon: "question",
          title: "위 내용으로 변경하시겠습니까?",
          text: `${
            selectedMerchant ? "지점변경시 자동으로 로그아웃 됩니다." : ""
          }`,
          showCancelButton: true,
          confirmButtonColor: "#04c9db",
          cancelButtonColor: "#F53C14",
          confirmButtonText: "예",
          cancelButtonText: "취소",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let info = {
              ...editData,
              merchantId: userData.id,
              userId,
            };
            if (selectedMerchant) {
              info = {
                ...info,
                merchantIdChange: selectedMerchant.id,
              };
            }
            const res = await userInfoUpdate(info);

            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: `변경이 완료됐습니다.${
                  selectedMerchant ? "자동으로 로그아웃 됩니다." : ""
                }`,
                timer: 2500,
              }).then(async () => {
                if (selectedMerchant) {
                  await Logout_api(userData.id, userData.phoneNum);
                } else {
                  window.location.reload();
                }
              });
            }
          }
        });
      } catch (err) {}
    }
  };

  return (
    <>
      <Breadcrumbs parent="하드웨어 및 설정" title="지점설정" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Card>
              <CardBody>
                <Label1 as="div">
                  <h5>전화번호</h5>
                  <h6>{phoneNum}</h6>
                </Label1>

                <Label1>
                  <h5>
                    이름 <strong>*</strong>
                  </h5>

                  <input
                    type="text"
                    value={editData.name ? editData.name : userName || ""}
                    name="userName"
                    onChange={handleChange}
                    required
                    placeholder="한글만, 최대 5자"
                  />
                </Label1>

                <Label1>
                  <h5>이메일</h5>

                  <Input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={editData.email ? editData.email : email || ""}
                    placeholder="이메일을 입력해주세요"
                  />
                </Label1>

                <Label1>
                  <h5>성별</h5>
                  <select
                    name="gender"
                    onChange={handleChange}
                    value={editData.gender ? editData.gender : gender}
                    defaultValue={editData.gender ? editData.gender : gender}
                  >
                    <option value={0}>남성</option>
                    <option value={1}>여성</option>
                  </select>
                </Label1>

                <Label1>
                  <h5>회원 유형 선택</h5>
                  <select
                    name="job"
                    onChange={handleChange}
                    value={editData.job ? editData.job : job}
                    defaultValue={editData.job ? editData.job : job}
                  >
                    <option name="job" value="middle">
                      중학생
                    </option>
                    <option name="job" value="high">
                      고등학생/N수생
                    </option>
                    <option name="job" value="college">
                      대학생
                    </option>
                    <option name="job" value="adult">
                      성인
                    </option>
                  </select>
                </Label1>
                <Label1>
                  <h5>부모님 전화번호</h5>

                  <input
                    type="text"
                    value={
                      editData.parentPhoneNum
                        ? editData.parentPhoneNum
                        : parentPhoneNum || ""
                    }
                    name="parentPhoneNum"
                    onChange={handleChange}
                    placeholder="-를 제외하고 입력해주세요."
                  />
                </Label1>

                <Label1>
                  <h5>
                    문자수신 <strong>*</strong>
                  </h5>
                  <select
                    name="smsDeny"
                    onChange={handleChange}
                    value={editData.smsDeny ? editData.smsDeny : smsDeny}
                    defaultValue={editData.smsDeny ? editData.smsDeny : smsDeny}
                  >
                    <option value={0}>동의</option>
                    <option value={1}>미동의</option>
                  </select>
                </Label1>

                {/* // 카카오톡 수신이 지워져있는지는 의문..? 이유는 기억안남. 일단 카카오톡 수신도 가입시 유무를 체크하기 떄문에 남겨둠 
                <Label1>
                  <h5>
                    카카오톡수신 <strong>*</strong>
                  </h5>
                  <select
                    name="alertKaKaoToggle"
                    onChange={handleChange}
                    value={
                      editData.alertKaKaoToggle
                        ? editData.alertKaKaoToggle
                        : alertKaKaoToggle
                    }
                    defaultValue={
                      editData.alertKaKaoToggle
                        ? editData.alertKaKaoToggle
                        : alertKaKaoToggle
                    }
                  >
                    <option value={0}>동의</option>
                    <option value={1}>미동의</option>
                  </select>
                </Label1> */}
                <SearchLabel>
                  <h5>지점 변경</h5>
                  <div>
                    <input
                      type="text"
                      value={searchMerchant || ""}
                      // name="parentPhoneNum"
                      onChange={handleSearchKeyword}
                      placeholder=""
                    />
                    <button
                      className="btn btn-primary"
                      onClick={handleSearchData}
                    >
                      검색
                    </button>
                  </div>
                </SearchLabel>
                {merchantList?.length > 0 && (
                  <SearchLists>
                    {merchantList.map((li) => (
                      <SearchList
                        selected={selectedMerchant?.name === li.name}
                        key={li.id}
                        onClick={() => setSelectedMerchant(li)}
                      >
                        {li.name}
                      </SearchList>
                    ))}
                  </SearchLists>
                )}
                {selectedMerchant && (
                  <p style={{ margin: "15px 0  0 7px" }}>
                    <strong>{selectedMerchant?.name}</strong> 으로 변경
                  </p>
                )}
              </CardBody>
              <CardFooter className="text-center">
                <button onClick={handleOnSubmit} className="btn btn-primary">
                  내정보 수정
                </button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default User;
