import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DetailsModal from "../components/modal/detailsModal";
import moment from "moment";
import {
  merchantId_api,
  ticketForceExit,
  ticketUsing,
  TicketOverTime,
  userTicketUpdate,
  userTicketBlock,
  userList,
  allTicketList,
} from "../lib/api";
import { ticketListColumns } from "../data/ticketList";
import "moment/locale/ko";
import PaginationComponent from "../components/pagination";
import Swal from "sweetalert2";
import AddTimeModal from "../components/modal/addTimeModal";
import TicketCustom, { ExampleCustomInput, LabelRow } from "./ticketCustom";
import { exportExcel, payLog } from "../components/common";
import { AddBtn, Button } from "./lockerChange";
import configDB from "../data/customizer/config";
import { DatePickerStyle } from "./seatInfo";
import SelectDate from "../components/selectDate";
import { ToggleBtn } from "./seatList";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

export const LabelS = styled(Label)`
  margin-right: 1vw;
  input {
    margin-right: 0.4vw;
    position: static;
    margin-left: 0;
  }
`;
export const InputS = styled(Input).attrs({ required: true })``;

export const InputWithP = styled.span`
  display: flex;
  align-items: center;

  input {
    width: 40%;
    margin-right: 0.2vw;
    margin-top: 0;
    :not(:first-child) {
      margin-left: 0.8vw;
    }
  }
  p {
    margin-bottom: 0;
  }
`;
const ModalContainer = styled.div`
  strong {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    color: #222;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #555;
  }
`;

const CardContainer = styled.div`
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1vh;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;
    .m-0 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      /* position: relative; */
      width: 100%;

      .fa-search {
        position: absolute;
        right: 12px;
        top: 16.8px;
      }
    }
  }
  .addBtns {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    > button {
      margin-right: 0;
      margin-bottom: 10px;
    }
    > button:nth-child(2) {
      width: 20%;
      margin-left: 10px;
    }
  }
  .table {
    width: 100%;

    overflow: auto;
    > div {
      overflow-x: auto;
      ::-webkit-scrollbar {
        width: 0.6vw;
        height: 0.6vw;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c4cbd6;
        border-radius: 1vw;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 1vw;
      }
    }
  }

  .searchContainer {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    div {
      display: flex;
    }
    input {
      margin-top: 0;
      width: 100%;
    }
    form {
      width: 100%;
    }

    p {
      margin: 0 10px 0 0;
      border: 1px solid #cdcdcd;
      padding: 5px;
      border-radius: 7px;
    }
    .active {
      color: #04c9db;
    }
    .right_btn {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .filter_btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .show-480 {
      display: none;
    }
  }

  .tooltip-text {
    display: none;
    position: absolute;
    z-index: 3;
    white-space: break-spaces;
    width: 22vw;
    background: white;
    border: 1px solid #222222;
    right: 57px;
    top: -22px;
    border-radius: 7px;
    padding: 8px;
    @media screen and (max-width: 768px) {
      width: 38vw;
      top: -30px;
    }
    @media screen and (max-width: 480px) {
      width: 53vw;
      top: -53px;
    }
  }

  .rdt_TableCell:hover .tooltip-text {
    display: block;
  }
  @media screen and (max-width: 768px) {
    .topbar {
      flex-direction: column;
    }
    .searchContainer {
      width: 100%;
      justify-content: space-between;
      margin-top: 1.5vw;
      flex-direction: column-reverse;
      input {
        width: 100%;
        height: 38px;
      }
      div {
        margin: 0;
        display: flex;
        width: 100%;
        select {
          margin: 0;
        }
      }
      select {
        margin-left: 0;
      }
    }
    form {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 480px) {
    .topbar {
      align-items: center;

      .m-0 {
        margin: 3vw 0 !important;
        input {
          width: 100% !important;
        }
      }
      select {
        margin-left: 5px;
      }
    }
    .btn {
      padding: 5px 10px;
    }
    .searchContainer {
      form {
        width: 100%;
      }
    }
    .addBtns {
      > button:nth-child(2) {
        width: 40%;
        font-size: 3vw;
      }
    }
  }

  .rbt_TabaleRow {
    div:nth-child(2) {
      width: 30px;
    }
  }

  .ptag {
    padding: 2px 5px;
    border-radius: 5px;
  }

  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

const LabelRow1 = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    margin-right: 1vw;
    margin-bottom: 0;
    font-size: 14px;
    strong {
      color: red;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #555;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
  input,
  select {
    margin-top: 5px;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 6px 13px;
  }
  select {
    width: 50%;
  }
  > div {
    width: 150px;
    > div > button {
      width: 100%;
    }
  }
`;

export const secondToHour = (seconds) => {
  if (seconds >= 0) {
    const hour =
      parseInt(seconds / 3600) < 10
        ? "0" + parseInt(seconds / 3600)
        : parseInt(seconds / 3600);
    const min =
      parseInt((seconds % 3600) / 60) < 10
        ? "0" + parseInt((seconds % 3600) / 60)
        : parseInt((seconds % 3600) / 60);
    const sec = seconds % 60 < 10 ? "0" + (seconds % 60) : seconds % 60;
    return hour + ":" + min + ":" + sec;
  } else {
    const hour =
      parseInt(seconds / 3600) < 10
        ? "0" + parseInt(seconds / 3600)
        : parseInt(seconds / 3600);
    const min =
      parseInt((seconds % 3600) / 60) < 10 &&
      parseInt((seconds % 3600) / 60) > 0
        ? "0" + parseInt((seconds % 3600) / 60)
        : parseInt((seconds % 3600) / 60);
    const sec =
      seconds % 60 < 10 && parseInt((seconds % 3600) / 60) > 0
        ? "0" + (seconds % 60)
        : seconds % 60;
    return `${Math.abs(hour)}:${Math.abs(min)}:${Math.abs(sec)} 초과`;
  }
};

let merchantId = "";

const TicketInfo = ({ setIsLoading }) => {
  const [tagModal, setTagModal] = useState(false);
  const [lists, setLists] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    moment()
      .startOf("isoWeek")
      .toDate()
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf("isoWeek")
      .toDate()
  );
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [defaultList, setDefaultList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [choiceStart, setChoiceStart] = useState();
  const [choiceEnd, setChoiceEnd] = useState();
  const [isModal, setIsModal] = useState(false);
  const [addTime, setAddTime] = useState(null);
  const [addDay, setAddDay] = useState(null);
  const [amount, setAmount] = useState(0);
  const [payType, setPayType] = useState("cash");
  const [addTimeCheck, setAddTimeCheck] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [sign, setSign] = useState("+");
  const [level, setLevel] = useState("");

  // 초과결제 filter on/off
  const [isShowOverTime, setIsShowOverTime] = useState(false);

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const [searchOption, setSearchOption] = useState("전화번호");

  let searchKeywordData = [
    "전화번호",
    "권번",
    "유형",
    "등급",
    "사용가능여부",
    "사용상태",
    "퇴장처리",
    "입장권차단",
  ];

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      getUserTicketListApi(data.id);
    }
  };

  // Open Modal
  const handleSetLists = (e, idx) => {
    setSelected(e.target.id);

    // tagToggle();
    setTagModal(true);
  };

  useEffect(() => {
    if (selected) {
      setLevel([lists[selected].level]);
    }
  }, [selected]);

  const tagToggle = () => {
    if (tagModal === false) {
      setTagModal(true);
    } else {
      setTagModal(false);
    }

    if (addTimeCheck) {
      setAddTimeCheck(!addTimeCheck);
    }
  };

  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getUserTicketListApi = async (merchantId) => {
    setIsLoading(true);
    if (merchantId) {
      const data = await allTicketList(
        merchantId,
        moment(startDate).format("YYYYMMDD000000"),
        moment(endDate).format("YYYYMMDD235959")
      );

      if (data.status === 200) {
        let changeList = [
          data.data,
          data.data.map((item, idx) => {
            item.typeName =
              item.type === 1 && item.seatNumber
                ? ` 일회권 (${item.seatNumber}번)`
                : item.type === 1 && !item.seatNumber
                ? " 일회권"
                : item.type === 4 && item.seatNumber
                ? ` 세미나실 (${item.seatNumber}번)`
                : item.type === 4 && !item.seatNumber
                ? " 세미나실"
                : item.type === 5 && item.seatNumber
                ? ` 사물함 (${item.seatNumber}번)`
                : item.type === 5 && !item.seatNumber
                ? " 사물함"
                : item.type === 2
                ? ` 기간권 - ${item.seatType === 0 ? "자유석" : "고정석"}`
                : item.type === 3
                ? "시간정액권"
                : "사물함";

            item.type1 =
              item.type === 1 ? (
                <p style={{ color: "#EBAF22" }}>
                  일회권
                  {item.seatNumber ? `(${item.seatNumber}번)` : ""}
                </p>
              ) : item.type === 2 ? (
                <>
                  {item.seatType === 0 ? (
                    <p style={{ color: "#E03D64" }}>기간권 - 자유석</p>
                  ) : (
                    <p style={{ color: "#1BBABC" }}>기간권 - 고정석</p>
                  )}
                </>
              ) : item.type === 3 ? (
                <p style={{ color: "#33946B" }}>시간정액권</p>
              ) : item.type === 4 ? (
                <p style={{ color: "#3E67B6" }}>
                  세미나실
                  {item.seatNumber ? `(${item.seatNumber}번)` : ""}
                </p>
              ) : (
                <p style={{ color: "#AB84FF" }}>
                  사물함
                  {item.seatNumber ? `(${item.seatNumber}번)` : ""}
                </p>
              );
            item.phoneNumtag = (
              <div
                onClick={() => {
                  sendDetailData(item.phoneNum);
                }}
                className="phoneNum-click"
              >
                {item.phoneNum}
              </div>
            );
            item.lastIn = item.lastIn
              ? moment(item.lastIn).format("YYYY-MM-DD HH:mm:ss")
              : "";

            item.startDate = item.startDate
              ? moment(item.startDate).format("YYYY-MM-DD HH:mm:ss")
              : "";

            const t1 = moment(item.endDate, "YYYY-MM-DD HH:mm:ss");
            const t2 = moment(item.startDate, "YYYY-MM-DD HH:mm:ss");
            const t3 = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
            const diffTime = {
              years: moment.duration(moment(t1).diff(t2)).years(),
              month: moment.duration(moment(t1).diff(t2)).months(),
              day: moment.duration(moment(t1).diff(t2)).asDays(),
              hours: moment.duration(moment(t1).diff(t2)).hours(),
              minutes: moment.duration(moment(t1).diff(t2)).minutes(),
              seconds: moment.duration(moment(t1).diff(t2)).seconds(),
            };

            item.action =
              +item.type === 2 && +item.seatType === 0
                ? secondToHour(item.remainTime)
                : secondToHour(item.remainTime + item.usedTime);

            item.expireDate2 =
              (item.type === 3 || item.type === 5) &&
              Math.ceil(moment.duration(moment(t1).diff(t3)).asDays()) > 0
                ? `${Math.ceil(
                    moment.duration(moment(t1).diff(t3)).asDays()
                  )}일`
                : "-";

            item.T_isUsing =
              item.isUsing === 1 ? (
                <div>사용중</div>
              ) : item.isUsing === 2 ? (
                <div style={{ color: "#F04E36" }}>시간 초과</div>
              ) : item.lastOut ? (
                <div style={{ color: "#53852A" }}>퇴장완료</div>
              ) : (
                <div>사용전</div>
              );

            item.usingTimeKeyword =
              item.isUsing === 1
                ? "사용중"
                : item.isUsing === 2
                ? "시간초과"
                : item.lastOut
                ? "퇴장완료"
                : "사용전";

            item.blockKeyword =
              item.block === 1 ? "티켓사용불가" : "티켓사용가능";
            item.T_block =
              item.block === 1 ? (
                <p style={{ color: "#F04E36" }}>티켓사용불가</p>
              ) : (
                <p>티켓사용가능</p>
              );
            item.action2 = (
              <button
                className="btn-primary btn-sm"
                id={idx}
                onClick={(e) => handleSetLists(e, idx)}
              >
                자세히
              </button>
            );

            item.outButton =
              item.isUsing === 1 ? (
                <button
                  className="btn-secondary btn-sm"
                  onClick={() => outTicket(item)}
                >
                  강제퇴장처리
                </button>
              ) : item.isUsing === 2 ? (
                <button
                  className="btn-success btn-sm"
                  onClick={() => additional(item)}
                >
                  초과결제해제
                </button>
              ) : (
                <button
                  disabled
                  className="btn-sm"
                  onClick={() => outTicket(item)}
                >
                  퇴장처리
                </button>
              );

            item.last2 = item.lastOut
              ? moment(item.lastOut).format("YYYY-MM-DD HH:mm:ss")
              : "";
            item.isUsingKeyword =
              item.isUsing === 1
                ? "강제퇴장처리"
                : item.isUsing === 2
                ? "초과결제해제"
                : "퇴장처리";
            const t11 = moment();
            const t22 = moment(item.endDate);
            const t23 = moment(item.startDate);
            const t33 = moment(item.lastIn);
            const t44 = moment(item.lastOut);
            const today_diff = moment.duration(t22.diff(t11)).asMilliseconds();
            //현재시간기준으로 시작시간하고의 차이
            const today_diff2 = moment.duration(t11.diff(t23)).asMilliseconds();
            const remainTimeData = moment.duration(t11.diff(t33)).asSeconds();
            const seminarNotUsing = moment.duration(t22.diff(t11)).asSeconds();
            const excessData = moment.duration(t11.diff(t44)).asSeconds();

            item.remainTimeSec =
              //일회권, 기간권, 시간정액권 사용중이면서, 퇴장기록이 없는경우
              item.isUsing === 1 &&
              !item.lastOut &&
              item.lastIn &&
              (item.type === 1 || item.type === 2 || item.type === 3)
                ? item.remainTime - Math.ceil(remainTimeData)
                : //세미나실인경우 (입실처리x 시간초과x)
                item.type === 4 && today_diff > 0 && today_diff2 > 0
                ? Math.ceil(seminarNotUsing)
                : //세미나실인경우 (입실처리x 시간초과o)
                item.type === 4 && today_diff < 0 && today_diff2 > 0
                ? "00:00:00"
                : // 일회권,기간권, 시간정액권 사용중이면서 시간을 초과한경우
                item.isUsing === 2 && item.lastOut
                ? +Math.ceil(excessData)
                : //그외에 조건
                  secondToHour(item.remainTime);
            //잔여시간 조회

            item.remainTime2 =
              // 일회권, 기간권, 시간정액권 사용 중이면서, 퇴장 기록이 없는 경우
              item.isUsing === 1 &&
              !item.lastOut &&
              item.lastIn &&
              (item.type === 1 || item.type === 2 || item.type === 3)
                ? secondToHour(item.remainTime - Math.ceil(remainTimeData))
                : // 세미나실인 경우 (입실 처리x, 시간 초과x)
                item.type === 4 && today_diff > 0 && today_diff2 > 0
                ? secondToHour(Math.ceil(seminarNotUsing))
                : // 세미나실인 경우 (입실 처리x, 시간 초과o)
                item.type === 4 && today_diff < 0 && today_diff2 > 0
                ? "00:00:00"
                : // 일회권, 기간권, 시간정액권 사용 중이면서 시간을 초과한 경우
                item.isUsing === 2 && item.lastOut
                ? `${secondToHour(+Math.ceil(excessData))} 초과`
                : // 그외에 조건
                item.type === 2 && item.endDate
                ? `${moment(item.endDate).diff(moment(), "days") + 1}일 남음`
                : item.type === 4 && item.endDate
                ? `${moment(item.endDate).diff(moment(), "days") + 1}일 남음`
                : item.type === 5 && item.endDate
                ? `${moment(item.endDate).diff(moment(), "days") + 1}일 남음`
                : secondToHour(item.remainTime);

            item.ticketBlock =
              //입장권 차단 활성화
              //1. 끝나는 시간이 지나지 않았고, 차단이 가능한 상태인경우(block:0), 남아있는 시간이 있는 경우
              (today_diff > 0 && +item.block === 0 && item.remainTime > 0) ||
              (item.type === 5 && today_diff > 0 && item.block !== 1) ? (
                <ToggleBtn>
                  <input
                    type="checkbox"
                    checked={item.block === 0}
                    onClick={() => blockApiFunc(item)}
                  />
                  <span className="onoff-switch"></span>
                </ToggleBtn>
              ) : //입장권 허용 활성화
              //1. 끝나는 시간이 지나지 않았고,차단되어있으면서, 남아있는 시간이 있는 경우
              today_diff > 0 &&
                +item.block === 1 &&
                item.remainTime > 0 &&
                (item.type === 2 || item.type === 3) ? (
                <ToggleBtn>
                  <input
                    type="checkbox"
                    checked={item.block === 0}
                    onClick={() => blockApiFunc(item)}
                  />
                  <span className="onoff-switch"></span>
                </ToggleBtn>
              ) : (
                <>
                  <ToggleBtn>
                    <input
                      type="checkbox"
                      disabled
                      // onClick={() => blockApiFunc(item)}
                    />
                    <span className="onoff-switch disabled"></span>
                  </ToggleBtn>
                  <span className="tooltip-text">
                    유효기간이 지났거나 입장권 특성 상 허용이 불가능한
                    입장권입니다.
                  </span>
                </>
              );

            item.ticketBlockKeyword =
              //입장권 차단 활성화 키워드
              //1. 끝나는 시간이 지나지 않았고, 차단이 가능한 상태인경우(block:0), 남아있는 시간이 있는 경우
              today_diff > 0 && +item.block === 0 && item.remainTime > 0
                ? "입장권 차단"
                : //입장권 허용 활성화
                //1. 끝나는 시간이 지나지 않았고,차단되어있으면서, 남아있는 시간이 있는 경우
                today_diff > 0 && +item.block === 1 && item.remainTime > 0
                ? "입장권 허용"
                : "입장권 차단";
          }),
        ];

        if (searchKeyword) {
          if (isShowOverTime) {
            searchData(
              changeList[0].filter((item) =>
                item.isUsingKeyword?.includes("초과")
              ),
              true
            );
            setDefaultList(
              changeList[0].filter((item) =>
                item.isUsingKeyword?.includes("초과")
              )
            );
          } else {
            searchData(changeList[0], true);
            setDefaultList(changeList[0]);
          }
        } else {
          if (isShowOverTime) {
            setLists(
              changeList[0].filter((item) =>
                item.isUsingKeyword?.includes("초과")
              )
            );
            setDefaultList(
              changeList[0].filter((item) =>
                item.isUsingKeyword?.includes("초과")
              )
            );
          } else {
            setLists(changeList[0]);
            setDefaultList(changeList[0]);
          }
        }
      }
    }
    setIsLoading(false);
  };

  const blockApiFunc = async (item) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: "#04c9db",
      cancelButtonColor: "#F53C14",
      confirmButtonText: "확인",
      cancelButtonText: "취소",
      reverseButtons: true,
      title: `${item.block === 0 ? "입장권 차단" : "입장권 허용"}하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // 퇴장처리->입장권차단 순서로 진행
        // 1. 입장되어있는 이용권 퇴장처리(isUsing이 1일 경우에만 퇴장처리)
        if (item.isUsing === 1 && item.block === 0) {
          let data = await ticketUsing(item.userId, item.merchantId);
          if (data.status === 200) {
            //퇴장처리
            let res = await ticketForceExit({
              merchantId: item.merchantId,
              id: item.id,
              userId: item.userId,
              seatId: data.data.seatId,
            });
            //퇴장 후 처리(차단-block)
            if (res.status === 200) {
              if (item.block === 0) {
                let result = await userTicketBlock({
                  ticketId: item.id,
                  merchantId: item.merchantId,
                  block: 1,
                  // type: 4,
                });

                if (result.status === 200) {
                  Swal.fire({
                    icon: "success",
                    title: "입장권 차단이 완료되었습니다.",
                    timer: 3000,
                  }).then(() => getUserTicketListApi(merchantId));
                }
              }
            }
          }
        } //2. 초과 결제 처리
        else if (item.isUsing === 2) {
          Swal.fire({
            icon: "error",
            title:
              "초과결제를 해야합니다. 초과결제를 처리하신 후 다시 시도해주세요",
            timer: 3000,
          });
        } // 3. 입장하지 않은 상태의 이용권 차단
        else if (
          (item.isUsing === 0 && item.block === 0) ||
          (item.isUsing === 3 && item.block === 0)
        ) {
          let result = await userTicketBlock({
            ticketId: item.id,
            merchantId: item.merchantId,
            block: 1,
            // type: 4,
          });
          if (result.status === 200) {
            Swal.fire({
              icon: "success",
              title: "입장권 차단이 완료되었습니다.",
              timer: 3000,
            }).then(() => getUserTicketListApi(merchantId));
          }
        } // 4. 이미 차단된 이용권
        else if (item.block === 1) {
          let resultEnter = await userTicketBlock({
            ticketId: item.id,
            merchantId: item.merchantId,
            block: 0,
          });

          if (resultEnter.status === 200) {
            Swal.fire({
              icon: "success",
              title: "입장권 허용이 완료되었습니다.",
              timer: 3000,
            }).then(() => getUserTicketListApi(merchantId));
          }
        }
      }
    });
  };

  const outTicket = async (e) => {
    let nowTime = moment();
    let diffTime = moment.duration(nowTime.diff(e.endDate)).asMilliseconds();
    if (diffTime > 0) {
      Swal.fire(
        "이미 만료되어 퇴장처리가 불가능한 이용자 입니다.",
        "초과결제 취소가 필요한경우 퇴장여부의 초과결제 취소를 눌러주세요"
      );
    } else {
      Swal.fire({
        title: "퇴장처리를 하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F53C14",
        cancelButtonColor: "#04c9db",
        confirmButtonText: "퇴장",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = await ticketUsing(e.userId, e.merchantId);
          if (data.status === 200) {
            let result = await ticketForceExit({
              merchantId: e.merchantId,
              id: e.id,
              userId: e.userId,
              seatId: data.data.seatId,
            });

            if (result.status === 200) {
              Swal.fire("퇴장처리가 완료되었습니다.");
              getMerchantId();
            } else {
              Swal.fire("퇴장처리 중 에러가 발생했습니다. 다시 시도해주세요.");
            }
          }
        }
      });
    }
  };

  //입장권 초과결제
  const additional = async (ticket) => {
    Swal.fire({
      title: "초과결제 해제 하시겠습니까?",
      text: "초과결제 해제시 해당 이용권은 퇴장완료로 변경됩니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: "#04c9db",
      confirmButtonText: "초과결제 해제",
      cancelButtonText: "창닫기",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await TicketOverTime({
          merchantId: ticket.merchantId,
          ticketId: ticket.id,
        });

        if (res.status === 200) {
          Swal.fire("초과 결제 해제가 완료되었습니다.");
          getMerchantId();
        } else {
          Swal.fire(
            "초과 결제 해제 처리 중 에러가 발생했습니다. 다시시도해주세요."
          );
        }
      }
    });
  };

  useEffect(() => {
    getUserTicketListApi(merchantId);
    setCurrentPage(1);
  }, [startDate, endDate]);

  const handleSearchKeyword = (keyword) => {
    if (keyword === "") {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const handleSave = async (e) => {
    if (addTime || addDay) {
      const maxTime = Math.ceil(
        moment.duration(moment(e.endDate).diff(new Date())).asSeconds() + 30
      );

      const h = Math.floor(maxTime / 3600);
      const m = Math.floor((maxTime % 3600) / 60) - 1;

      if (sign === "-" && addTime > maxTime) {
        Swal.fire({
          icon: "error",
          title: "남은시간보다 차감시간이 클 수 없습니다.",
          text: `${
            h > 0 ? (m > 0 ? `${h}시간 ${m}분` : `${h}시간`) : `${m}분`
          } 차감 가능합니다.`,
          timer: 3500,
        });
      } else {
        Swal.fire({
          title: "변경한 내용을 저장 하시겠습니까?",
          showCancelButton: true,
          confirmButtonColor: "#F53C14",
          cancelButtonColor: "#04c9db",
          confirmButtonText: "저장",
          cancelButtonText: "취소",
        }).then(async (result) => {
          if (result.value) {
            let res = await userTicketUpdate({
              ticketId: e.id,
              merchantId: e.merchantId,
              userId: e.userId,
              startDate: choiceStart ? choiceStart : e.startDate,
              endDate: choiceEnd ? choiceEnd : e.endDate,
              time:
                lists[selected].type === 2 || lists[selected].type === 5
                  ? 0
                  : (lists[selected].type !== 2 ||
                      lists[selected].type !== 5) &&
                    sign === "-"
                  ? -addTime
                  : addTime,
              day:
                lists[selected].type === 2 || lists[selected].type === 5
                  ? addDay
                  : 0,
              paymentType: payType,
              amount: amount,
              level: level,
            });

            if ((addTime || addDay) && res.status === 200) {
              let payData = {
                payType: payType,
                amount: amount,
                userId: e.userId,
                addTime: addTime,
                addDay: addDay,
                meta8: "추가 시간/기간 결제",
                merchantId: merchantId,
                ticketId: e.id,
              };
              const payLogFunc = await payLog(payData);

              if (payLogFunc === 200) {
                Swal.fire({
                  title: "저장이 완료되었습니다.",
                  timer: 2000,
                }).then(tagToggle(), getMerchantId());
              } else {
                Swal.fire("저장이 완료되었습니다").then(
                  tagToggle(),
                  getMerchantId()
                );
              }
            }
          }
        });
      }
    } else {
      Swal.fire({
        title: "변경한 내용을 저장 하시겠습니까?",
        showCancelButton: true,
        confirmButtonColor: "#F53C14",
        cancelButtonColor: "#04c9db",
        confirmButtonText: "저장",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.value) {
          let res = await userTicketUpdate({
            ticketId: e.id,
            merchantId: e.merchantId,
            userId: e.userId,
            startDate: choiceStart ? choiceStart : e.startDate,
            endDate: choiceEnd ? choiceEnd : e.endDate,
            time:
              lists[selected].type === 2 || lists[selected].type === 5
                ? 0
                : (lists[selected].type !== 2 || lists[selected].type !== 5) &&
                  sign === "-"
                ? -addTime
                : addTime,
            day:
              lists[selected].type === 2 || lists[selected].type === 5
                ? addDay
                : 0,
            paymentType: payType,
            amount: amount,
            level: level,
          });

          if (res.status === 200) {
            Swal.fire({
              title: "저장이 완료되었습니다.",
              timer: 2000,
            }).then(tagToggle(), getMerchantId());
          } else {
            Swal.fire("저장 중 에러가 발생했습니다.").then(
              tagToggle(),
              getMerchantId()
            );
          }
        }
      });
    }
  };

  const openAddTimeModal = () => {
    setIsModal(!isModal);
  };

  const saveAddData = (e) => {
    setAddTimeCheck(e);
  };

  const close = () => {
    setIsCustom(!isCustom);
  };

  const searchData = (datalist, currentSetting) => {
    if (!searchKeyword) {
      getUserTicketListApi(merchantId);
      setCurrentPage(1);
    } else {
      let copyData;
      if (datalist) {
        copyData = [...datalist];
      } else {
        copyData = [...defaultList];
      }
      let searchData;
      if (searchOption === "권번") {
        searchData = copyData.filter((item) =>
          item.id?.toString().includes(searchKeyword)
        );
      } else if (searchOption === "유형") {
        searchData = copyData.filter((item) =>
          item.typeName?.includes(searchKeyword)
        );
      } else if (searchOption === "등급") {
        searchData = copyData.filter((item) =>
          item.level?.toString().includes(searchKeyword)
        );
      } else if (searchOption === "전화번호") {
        searchData = copyData.filter((item) =>
          item.phoneNum?.includes(searchKeyword)
        );
      } else if (searchOption === "사용가능여부") {
        searchData = copyData.filter((item) =>
          item.blockKeyword?.includes(searchKeyword)
        );
      } else if (searchOption === "사용상태") {
        searchData = copyData.filter((item) =>
          item.usingTimeKeyword?.includes(searchKeyword)
        );
      } else if (searchOption === "퇴장처리") {
        searchData = copyData.filter((item) =>
          item.isUsingKeyword?.includes(searchKeyword)
        );
      } else if (searchOption === "입장권차단") {
        searchData = copyData.filter((item) =>
          item.ticketBlockKeyword?.includes(searchKeyword)
        );
      }

      setLists(
        searchData,
        searchData.map((item, idx) => {
          item.typeName =
            item.type === 1 && item.seatNumber
              ? ` 일회권 (${item.seatNumber}번)`
              : item.type === 1 && !item.seatNumber
              ? " 일회권"
              : item.type === 4 && item.seatNumber
              ? ` 세미나실 (${item.seatNumber}번)`
              : item.type === 4 && !item.seatNumber
              ? " 세미나실"
              : item.type === 5 && item.seatNumber
              ? ` 사물함 (${item.seatNumber}번)`
              : item.type === 5 && !item.seatNumber
              ? " 사물함"
              : item.type === 2
              ? ` 기간권 - ${item.seatType === 0 ? "자유석" : "고정석"}`
              : item.type === 3
              ? "시간정액권"
              : "사물함";

          item.type1 =
            item.type === 1 ? (
              <p style={{ color: "#EBAF22" }}>
                일회권
                {item.seatNumber ? `(${item.seatNumber}번)` : ""}
              </p>
            ) : item.type === 2 ? (
              <>
                {item.seatType === 0 ? (
                  <p style={{ color: "#E03D64" }}>기간권 - 자유석</p>
                ) : (
                  <p style={{ color: "#1BBABC" }}>기간권 - 고정석</p>
                )}
              </>
            ) : item.type === 3 ? (
              <p style={{ color: "#33946B" }}>시간정액권</p>
            ) : item.type === 4 ? (
              <p style={{ color: "#3E67B6" }}>
                세미나실
                {item.seatNumber ? `(${item.seatNumber}번)` : ""}
              </p>
            ) : (
              <p style={{ color: "#AB84FF" }}>
                사물함
                {item.seatNumber ? `(${item.seatNumber}번)` : ""}
              </p>
            );
          item.phoneNumtag = (
            <div
              onClick={() => {
                sendDetailData(item.phoneNum);
              }}
              className="phoneNum-click"
            >
              {item.phoneNum}
            </div>
          );
          item.lastIn = item.lastIn
            ? moment(item.lastIn).format("YYYY-MM-DD HH:mm:ss")
            : "";

          item.startDate = item.startDate
            ? moment(item.startDate).format("YYYY-MM-DD HH:mm:ss")
            : "";

          const t1 = moment(item.endDate, "YYYY-MM-DD HH:mm:ss");
          const t2 = moment(item.startDate, "YYYY-MM-DD HH:mm:ss");
          const diffTime = {
            years: moment.duration(moment(t1).diff(t2)).years(),
            month: moment.duration(moment(t1).diff(t2)).months(),
            day: moment.duration(moment(t1).diff(t2)).days(),
            hours: moment.duration(moment(t1).diff(t2)).hours(),
            minutes: moment.duration(moment(t1).diff(t2)).minutes(),
            seconds: moment.duration(moment(t1).diff(t2)).seconds(),
          };

          item.action =
            +item.type === 2 && +item.seatType === 0
              ? secondToHour(item.remainTime)
              : secondToHour(item.remainTime + item.usedTime);
          item.expireDate = `${
            diffTime.years > 0 ? `${diffTime.years}년` : ""
          } ${
            diffTime.years === 0 && diffTime.month > 0
              ? `${diffTime.month}일`
              : ""
          } ${
            diffTime.month === 0 && diffTime.day > 0 ? `${diffTime.day}일` : ""
          } ${
            diffTime.day === 0 && diffTime.hours > 0
              ? `${diffTime.hours}시간`
              : ""
          } ${
            diffTime.hours === 0 && diffTime.minutes > 0
              ? `${diffTime.minutes}분`
              : ""
          } ${
            diffTime.minutes === 0 && diffTime.seconds > 0
              ? `${diffTime.seconds}초`
              : ""
          }`;

          item.T_remainTime =
            +item.type === 2 && +item.seatType === 0
              ? secondToHour(item.remainTime - item.usedTime)
              : secondToHour(item.remainTime);
          item.T_isUsing =
            item.isUsing === 1 ? (
              <div>사용중</div>
            ) : item.isUsing === 2 ? (
              <div style={{ color: "#F04E36" }}>시간 초과</div>
            ) : item.lastOut ? (
              <div style={{ color: "#53852A" }}>퇴장완료</div>
            ) : (
              <div>사용전</div>
            );

          item.usingTimeKeyword =
            item.isUsing === 1
              ? "사용중"
              : item.isUsing === 2
              ? "시간초과"
              : item.lastOut
              ? "퇴장완료"
              : "사용전";

          item.blockKeyword =
            item.block === 1 ? "티켓사용불가" : "티켓사용가능";
          item.T_block =
            item.block === 1 ? (
              <p style={{ color: "#F04E36" }}>티켓사용불가</p>
            ) : (
              <p>티켓사용가능</p>
            );
          item.action2 = (
            <button
              className="btn-primary btn-sm"
              id={idx}
              onClick={(e) => handleSetLists(e, idx)}
            >
              자세히
            </button>
          );

          item.outButton =
            item.isUsing === 1 ? (
              <button
                className="btn-secondary btn-sm"
                onClick={() => outTicket(item)}
              >
                강제퇴장처리
              </button>
            ) : item.isUsing === 2 ? (
              <button
                className="btn-success btn-sm"
                onClick={() => additional(item)}
              >
                초과결제해제
              </button>
            ) : (
              <button
                disabled
                className="btn-sm"
                onClick={() => outTicket(item)}
              >
                퇴장처리
              </button>
            );

          item.isUsingKeyword =
            item.isUsing === 1
              ? "강제퇴장처리"
              : item.isUsing === 2
              ? "초과결제해제"
              : "퇴장처리";
          const t11 = moment();
          const t22 = moment(item.endDate);
          const t23 = moment(item.startDate);
          const t33 = moment(item.lastIn);
          const t44 = moment(item.lastOut);
          const today_diff = moment.duration(t22.diff(t11)).asMilliseconds();
          //현재시간기준으로 시작시간하고의 차이
          const today_diff2 = moment.duration(t11.diff(t23)).asMilliseconds();
          const remainTimeData = moment.duration(t11.diff(t33)).asSeconds();
          const seminarNotUsing = moment.duration(t22.diff(t11)).asSeconds();
          const excessData = moment.duration(t11.diff(t44)).asSeconds();
          //잔여시간 조회
          item.remainTimeSec =
            //일회권, 기간권, 시간정액권 사용중이면서, 퇴장기록이 없는경우
            item.isUsing === 1 &&
            !item.lastOut &&
            item.lastIn &&
            (item.type === 1 || item.type === 2 || item.type === 3)
              ? item.remainTime - Math.ceil(remainTimeData)
              : //세미나실인경우 (입실처리x 시간초과x)
              item.type === 4 && today_diff > 0 && today_diff2 > 0
              ? Math.ceil(seminarNotUsing)
              : //세미나실인경우 (입실처리x 시간초과o)
              item.type === 4 && today_diff < 0 && today_diff2 > 0
              ? "00:00:00"
              : // 일회권,기간권, 시간정액권 사용중이면서 시간을 초과한경우
              item.isUsing === 2 && item.lastOut
              ? +Math.ceil(excessData)
              : //그외에 조건
                secondToHour(item.remainTime);
          item.remainTime2 =
            // 일회권, 기간권, 시간정액권 사용 중이면서, 퇴장 기록이 없는 경우
            item.isUsing === 1 &&
            !item.lastOut &&
            item.lastIn &&
            (item.type === 1 || item.type === 2 || item.type === 3)
              ? secondToHour(item.remainTime - Math.ceil(remainTimeData))
              : // 세미나실인 경우 (입실 처리x, 시간 초과x)
              item.type === 4 && today_diff > 0 && today_diff2 > 0
              ? secondToHour(Math.ceil(seminarNotUsing))
              : // 세미나실인 경우 (입실 처리x, 시간 초과o)
              item.type === 4 && today_diff < 0 && today_diff2 > 0
              ? "00:00:00"
              : // 일회권, 기간권, 시간정액권 사용 중이면서 시간을 초과한 경우
              item.isUsing === 2 && item.lastOut
              ? `${secondToHour(+Math.ceil(excessData))} 초과`
              : // 그외에 조건
              item.type === 2 && item.endDate
              ? `${moment(item.endDate).diff(moment(), "days") + 1}일 남음`
              : item.type === 4 && item.endDate
              ? `${moment(item.endDate).diff(moment(), "days") + 1}일 남음`
              : item.type === 5 && item.endDate
              ? `${moment(item.endDate).diff(moment(), "days") + 1}일 남음`
              : secondToHour(item.remainTime);

          item.ticketBlock =
            //입장권 차단 활성화
            //1. 끝나는 시간이 지나지 않았고, 차단이 가능한 상태인경우(block:0), 남아있는 시간이 있는 경우
            (today_diff > 0 && +item.block === 0 && item.remainTime > 0) ||
            (item.type === 5 && today_diff > 0 && item.block !== 1) ? (
              <ToggleBtn>
                <input
                  type="checkbox"
                  checked={item.block === 0}
                  onClick={() => blockApiFunc(item)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            ) : //입장권 허용 활성화
            //1. 끝나는 시간이 지나지 않았고,차단되어있으면서, 남아있는 시간이 있는 경우
            today_diff > 0 &&
              +item.block === 1 &&
              item.remainTime > 0 &&
              (item.type === 2 || item.type === 3) ? (
              <ToggleBtn>
                <input
                  type="checkbox"
                  checked={item.block === 0}
                  onClick={() => blockApiFunc(item)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            ) : (
              <>
                <ToggleBtn>
                  <input
                    type="checkbox"
                    disabled
                    // onClick={() => blockApiFunc(item)}
                  />
                  <span className="onoff-switch disabled"></span>
                </ToggleBtn>
                <span className="tooltip-text">
                  유효기간이 지났거나 입장권 특성 상 허용이 불가능한
                  입장권입니다.
                </span>
              </>
            );

          item.ticketBlockKeyword =
            //입장권 차단 활성화 키워드
            //1. 끝나는 시간이 지나지 않았고, 차단이 가능한 상태인경우(block:0), 남아있는 시간이 있는 경우
            today_diff > 0 && +item.block === 0 && item.remainTime > 0
              ? "입장권 차단"
              : //입장권 허용 활성화
              //1. 끝나는 시간이 지나지 않았고,차단되어있으면서, 남아있는 시간이 있는 경우
              today_diff > 0 && +item.block === 1 && item.remainTime > 0
              ? "입장권 허용"
              : "입장권 차단";
        })
      );
      if (!currentSetting) {
        setCurrentPage(1);
      }
    }
  };

  //초과결제 filterbtn
  const overTimeFilter = () => {
    if (!isShowOverTime) {
      setIsShowOverTime(!isShowOverTime);
      setCurrentPage(1);
      setStartDate(moment(new Date()).subtract(1, "y")._d);
      setEndDate(new Date());
    } else {
      setIsShowOverTime(!isShowOverTime);
      setCurrentPage(1);
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
          // setData={getUserTicketListApi}
        />
      )}
      <Breadcrumb parent="정보 조회" title="입장권 정보 조회" />
      <Container fluid={true}>
        {isModal && (
          <AddTimeModal
            isOpen={isModal}
            data={lists[selected]}
            time={setAddTime}
            day={setAddDay}
            payType={setPayType}
            amount={setAmount}
            timeValue={addTime}
            dayValue={addDay}
            payTypeValue={payType}
            amountValue={amount}
            close={openAddTimeModal}
            check={saveAddData}
            signData={setSign}
          />
        )}

        {isCustom && (
          <TicketCustom
            mid={merchantId}
            close={close}
            reloading={getUserTicketListApi}
          />
        )}

        <Modal centered isOpen={tagModal}>
          <ModalHeader className="modal-title" toggle={tagToggle}>
            이용권 정보
          </ModalHeader>
          <ModalBody>
            <ModalContainer>
              <p>
                <strong>타입 : </strong>
                {lists[selected]?.type === 1
                  ? "일회권"
                  : lists[selected]?.type === 2
                  ? "기간권"
                  : lists[selected]?.type === 3
                  ? "시간정액권"
                  : lists[selected]?.type === 4
                  ? "세미나"
                  : "사물함"}
              </p>
              <p>
                <strong>구매자</strong>: {lists[selected]?.phoneNum}
              </p>
              {(lists[selected]?.type === 1 ||
                lists[selected]?.type === 2 ||
                lists[selected]?.type === 3) && (
                <DatePickerStyle style={{ margin: 0, marginBottom: "1rem" }}>
                  <p style={{ margin: 0 }}>
                    <strong>등급</strong>{" "}
                  </p>
                  :
                  <select
                    value={+level}
                    onChange={(e) => setLevel(e.target.value)}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </DatePickerStyle>
              )}
              <p>
                <strong>시작일 : </strong>
                {moment(lists[selected]?.startDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </p>
              <p>
                <strong>만료일 : </strong>
                {moment(lists[selected]?.endDate).format("YYYY-MM-DD HH:mm:ss")}
              </p>
              <p>
                <strong>마지막입장</strong>: {lists[selected]?.lastIn}
              </p>
              <p>
                <strong>마지막퇴장 : </strong>
                {lists[selected]?.lastOut &&
                lists[selected]?.lastOut !== lists[selected]?.lastIn
                  ? moment(lists[selected]?.lastOut).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : ""}
              </p>
              <p>
                <strong>사용시간 : </strong>
                {lists[selected]?.usedTime
                  ? secondToHour(lists[selected]?.usedTime)
                  : lists[selected]?.isUsing === 2
                  ? secondToHour(lists[selected]?.remainTime)
                  : !lists[selected]?.usedTime &&
                    lists[selected]?.type === 1 &&
                    lists[selected]?.remainTime -
                      lists[selected]?.remainTimeSec >
                      0
                  ? secondToHour(
                      lists[selected]?.remainTime -
                        lists[selected]?.remainTimeSec
                    )
                  : !lists[selected]?.usedTime &&
                    lists[selected]?.type === 1 &&
                    lists[selected]?.remainTime -
                      lists[selected]?.remainTimeSec <
                      0
                  ? secondToHour(
                      `${+(
                        lists[selected]?.remainTime -
                        lists[selected]?.remainTimeSec
                      )}초과`
                    )
                  : "00:00:00"}
              </p>
              <p>
                <strong>남은시간 :</strong> {lists[selected]?.remainTime2}
              </p>
              {/* <div>
              <strong>등록용 패스워드</strong>
              <Input type="text" />
            </div> */}
              {lists[selected]?.type !== 4 && (
                <div>
                  <LabelRow1>
                    <h5>유효기간 시작일 강제 지정</h5>
                    <DatePicker
                      selected={
                        choiceStart
                          ? choiceStart
                          : moment(lists[selected]?.startDate)._d
                      }
                      onChange={(date) => {
                        setChoiceStart(date);
                      }}
                      selectsStart
                      locale={ko}
                      startDate={
                        choiceStart
                          ? choiceStart
                          : moment(lists[selected]?.startDate)._d
                      }
                      maxDate={
                        choiceEnd
                          ? choiceEnd
                          : moment(lists[selected]?.endDate)._d
                      }
                      endDate={
                        choiceEnd
                          ? choiceEnd
                          : moment(lists[selected]?.endDate)._d
                      }
                      customInput={<ExampleCustomInput />}
                    />
                  </LabelRow1>
                  <LabelRow1>
                    <h5>유효기간 만료일 강제 지정</h5>
                    <DatePicker
                      selected={
                        choiceEnd
                          ? choiceEnd
                          : moment(lists[selected]?.endDate)._d
                      }
                      onChange={(date) => {
                        setChoiceEnd(date);
                      }}
                      selectsEnd
                      locale={ko}
                      startDate={
                        choiceStart
                          ? choiceStart
                          : moment(lists[selected]?.startDate)._d
                      }
                      minDate={
                        choiceStart
                          ? choiceStart
                          : moment(lists[selected]?.startDate)._d
                      }
                      endDate={
                        choiceEnd
                          ? choiceEnd
                          : moment(lists[selected]?.endDate)._d
                      }
                      customInput={<ExampleCustomInput />}
                    />
                  </LabelRow1>
                </div>
              )}
              {addTimeCheck && (
                <>
                  {lists[selected].type === 1 || lists[selected].type === 3 ? (
                    <p>
                      <strong>추가시간 : </strong>{" "}
                      {addTime && sign === "-"
                        ? `-${Math.floor(+addTime / 3600)}시간 
                  ${(+addTime / 60) % 60}분`
                        : `${Math.floor(+addTime / 3600)}시간 
                  ${(+addTime / 60) % 60}분`}
                    </p>
                  ) : (
                    <p>
                      {" "}
                      <strong>추가일자 : </strong>
                      {addDay}일
                    </p>
                  )}

                  <p>
                    {" "}
                    <strong>금액 : </strong>
                    {amount}원
                  </p>
                  <p>
                    <strong>결제 종류 : </strong>
                    {payType === "credit"
                      ? "카드"
                      : payType === "cash"
                      ? "현금"
                      : payType === "kakaopay"
                      ? "간편결제"
                      : "가매입/가매출"}
                  </p>
                </>
              )}
            </ModalContainer>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              {lists[selected]?.type !== 4 && (
                <Button color="#186A3A" onClick={() => setIsModal(true)}>
                  시간/기간 수정
                </Button>
              )}

              <Button
                color="#fff"
                onClick={() => {
                  tagToggle();
                  setAddTime(null);
                  setAddDay(null);
                  setPayType("cash");
                  setAmount(0);
                  setAddTimeCheck(false);
                }}
              >
                취소
              </Button>
              <Button
                color={primary}
                onClick={() => handleSave(lists[selected])}
              >
                저장
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <CardContainer>
          <Row>
            <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Card>
                <CardBody>
                  <div className="addBtns">
                    <AddBtn onClick={() => setIsCustom(true)}>
                      입장권 발행
                    </AddBtn>
                    <Button
                      className="btn-secondary hide-480"
                      onClick={() =>
                        exportExcel(
                          lists,
                          startDate,
                          endDate,
                          "입장권 정보 조회"
                        )
                      }
                    >
                      엑셀 출력하기
                    </Button>
                  </div>

                  <div className="topbar">
                    <DatePickerStyle>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        selectsStart
                        locale={ko}
                        startDate={startDate}
                        maxDate={endDate}
                        endDate={endDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <h5> - </h5>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        locale={ko}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <SelectDate
                        page="ticket"
                        setStartDate={(val) => setStartDate(val)}
                        setEndDate={(val) => setEndDate(val)}
                        nowTime={"이번 주"}
                      />
                    </DatePickerStyle>
                    <div className="searchContainer">
                      <div className="filter_btn">
                        <p
                          onClick={overTimeFilter}
                          className={isShowOverTime ? "active" : ""}
                        >
                          초과결제 확인
                          {isShowOverTime ? "ON" : "OFF"}
                        </p>
                      </div>
                      <div>
                        <DatePickerStyle
                          style={{ width: "fit-content", marginRight: "10px" }}
                        >
                          <select
                            onChange={(e) => setSearchOption(e.target.value)}
                            value={searchOption}
                          >
                            {searchKeywordData.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </DatePickerStyle>
                        <Form
                          style={{
                            width: window.innerWidth > 480 ? "50%" : "100%",
                          }}
                          onSubmit={(e) => {
                            e.preventDefault();
                            searchData();
                          }}
                        >
                          <LabelRow className="m-0">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="검색어를 입력해주세요"
                              defaultValue={searchKeyword}
                              onChange={(e) =>
                                handleSearchKeyword(e.target.value)
                              }
                            />

                            <i
                              onClick={() => searchData()}
                              className="fa fa-search"
                            />
                          </LabelRow>
                        </Form>
                      </div>
                    </div>
                  </div>
                  {lists.length > 0 ? (
                    <DataTable
                      data={currentPosts(lists)}
                      columns={ticketListColumns}
                      striped={true}
                      center={true}
                    />
                  ) : (
                    <h5 style={{ textAlign: "center" }}>정보가 없습니다.</h5>
                  )}
                </CardBody>
                {lists.length > 0 && (
                  <PaginationComponent
                    setCurrentPage={setCurrentPage}
                    setPostsPerPageAdd={setPostsPerPageAdd}
                    setPostsPerPageAdd1={setPostsPerPageAdd1}
                    setPageNum={setPageNum}
                    totalLength={lists.length}
                    postsPerPage={postsPerPage}
                    currentPage={currentPage}
                    pageNum={pageNum}
                    postsPerPageAdd={postsPerPageAdd}
                    postsPerPageAdd1={postsPerPageAdd1}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </Fragment>
  );
};

export default TicketInfo;
