import {
  Home,
  User,
  DollarSign,
  Settings,
  BarChart2,
  Gift,
  Send,
  Package,
  Calendar,
  File,
  AlignLeft,
  Key,
  Bell,
  Clipboard,
} from "react-feather";
import SeatIcon from "./seat.svg";
import TicketIcon from "./ticket.svg";

import enabledCardIcon from "../../assets/images/meunIcon/enabled/card.svg";
import enableChairIcon from "../../assets/images/meunIcon/enabled/chair.svg";
import enableChartIcon from "../../assets/images/meunIcon/enabled/chart.svg";
import enableDashboardIcon from "../../assets/images/meunIcon/enabled/dashboard.svg";
import enableGiftIcon from "../../assets/images/meunIcon/enabled/gift.svg";
import enableMessageIcon from "../../assets/images/meunIcon/enabled/message.svg";
import enablePeopleIcon from "../../assets/images/meunIcon/enabled/people.svg";
import enablePersonIcon from "../../assets/images/meunIcon/enabled/person.svg";
import enablePointIcon from "../../assets/images/meunIcon/enabled/point.svg";
import enableScheduleIcon from "../../assets/images/meunIcon/enabled/schedule.svg";
import enableSettingIcon from "../../assets/images/meunIcon/enabled/setting.svg";
import enableTicketIcon from "../../assets/images/meunIcon/enabled/ticket.svg";

import hoveredCardIcon from "../../assets/images/meunIcon/hovered/card.svg";
import hoveredChairIcon from "../../assets/images/meunIcon/hovered/chair.svg";
import hoveredChartIcon from "../../assets/images/meunIcon/hovered/chart.svg";
import hoveredDashboardIcon from "../../assets/images/meunIcon/hovered/dashboard.svg";
import hoveredGiftIcon from "../../assets/images/meunIcon/hovered/gift.svg";
import hoveredMessageIcon from "../../assets/images/meunIcon/hovered/message.svg";
import hoveredPeopleIcon from "../../assets/images/meunIcon/hovered/people.svg";
import hoveredPersonIcon from "../../assets/images/meunIcon/hovered/person.svg";
import hoveredPointIcon from "../../assets/images/meunIcon/hovered/point.svg";
import hoveredScheduleIcon from "../../assets/images/meunIcon/hovered/schedule.svg";
import hoveredSettingIcon from "../../assets/images/meunIcon/hovered/setting.svg";
import hoveredTicketIcon from "../../assets/images/meunIcon/hovered/ticket.svg";

import pressedCardIcon from "../../assets/images/meunIcon/pressed/card.svg";
import pressedeChairIcon from "../../assets/images/meunIcon/pressed/chair.svg";
import pressedeChartIcon from "../../assets/images/meunIcon/pressed/chart.svg";
import pressedeDashboardIcon from "../../assets/images/meunIcon/pressed/dashboard.svg";
import pressedeGiftIcon from "../../assets/images/meunIcon/pressed/gift.svg";
import pressedeMessageIcon from "../../assets/images/meunIcon/pressed/message.svg";
import pressedePeopleIcon from "../../assets/images/meunIcon/pressed/people.svg";
import pressedePersonIcon from "../../assets/images/meunIcon/pressed/person.svg";
import pressedePointIcon from "../../assets/images/meunIcon/pressed/point.svg";
import pressedeScheduleIcon from "../../assets/images/meunIcon/pressed/schedule.svg";
import pressedeSettingIcon from "../../assets/images/meunIcon/pressed/setting.svg";
import pressedeTicketIcon from "../../assets/images/meunIcon/pressed/ticket.svg";

export const MENUITEMS = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "대시보드",
        icon: {
          enabled: enableDashboardIcon,
          hovered: hoveredDashboardIcon,
          pressed: pressedeDashboardIcon,
        },
        type: "link",
        active: false,
        path: `${process.env.PUBLIC_URL}/admin/dashboard`,
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "좌석 및 사물함",
        icon: {
          enabled: enableChairIcon,
          hovered: hoveredChairIcon,
          pressed: pressedeChairIcon,
        },
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/seat`,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/seat/list`,
            title: "맵뷰어",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seat/info`,
            title: "맵뷰어 상세 조회",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/exit-data`,
            title: "지점 출입 정보 조회",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/seat/change`,
            title: "좌석 배치 변경",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/locker/change`,
            title: "사물함 배치 변경",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "입장권 관리",
        icon: {
          enabled: enableTicketIcon,
          hovered: hoveredTicketIcon,
          pressed: pressedeTicketIcon,
        },
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/ticket`,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/ticket/ticket-list`,
            title: "입장권 정보 조회",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "결제정보 조회",
        icon: {
          enabled: enabledCardIcon,
          hovered: hoveredCardIcon,
          pressed: pressedCardIcon,
        },
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/pay`,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/pay/info`,
            title: "결제내역",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/pay/chart`,
          //   title: "매출분석",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/pay/chart`,
          //   title: "결제통계 (개발중)",
          //   type: "link",
          // },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "매출 분석",
        icon: {
          enabled: enableChartIcon,
          hovered: hoveredChartIcon,
          pressed: pressedeChartIcon,
        },
        type: "sub",
        path: `${process.env.PUBLIC_URL}/sales`,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/sales/day`,
            title: "일 별 매출 보기",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/sales/month`,
            title: "월 별 매출 보기",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "세미나실 예약",
        icon: {
          enabled: enableScheduleIcon,
          hovered: hoveredScheduleIcon,
          pressed: pressedeScheduleIcon,
        },
        type: "sub",
        path: `${process.env.PUBLIC_URL}/seminar`,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/seminar/reservation`,
            title: "세미나실 예약하기",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seminar/info`,
            title: "세미나실 예약확인",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "회원 관리",
        icon: {
          enabled: enablePeopleIcon,
          hovered: hoveredPeopleIcon,
          pressed: pressedePeopleIcon,
        },
        type: "sub",
        path: `${process.env.PUBLIC_URL}/user`,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/user/userlist`,
            title: "회원 정보 조회",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/user/userEnroll`,
            title: "회원 수동 등록",
            type: "link",
          },
        ],
      },
    ],
  },

  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "메시지 관리",
        icon: {
          enabled: enableMessageIcon,
          hovered: hoveredMessageIcon,
          pressed: pressedeMessageIcon,
        },
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/message`,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/message/send`,
            title: "메세지 전송",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/message/bulk`,
            title: "단체 메세지 전송",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/message/info`,
            title: "메세지 전송 내역",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/message/charge`,
            title: "메세지 포인트 충전",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "마일리지 쿠폰 관리",
        icon: {
          enabled: enablePointIcon,
          hovered: hoveredPointIcon,
          pressed: pressedePointIcon,
        },
        type: "sub",
        path: `${process.env.PUBLIC_URL}/point`,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/mileage`,
            title: "마일리지",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/coupon`,
            title: "쿠폰관리",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "상품관리",
        icon: {
          enabled: enableGiftIcon,
          hovered: hoveredGiftIcon,
          pressed: pressedeGiftIcon,
        },
        type: "sub",
        path: `${process.env.PUBLIC_URL}/product`,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/product/product-list`,
            title: "상품 목록",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/product/vending`,
            title: "자판기 상품관리",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    path: `${process.env.PUBLIC_URL}`,
    Items: [
      {
        title: "하드웨어 및 설정",
        icon: {
          enabled: enableSettingIcon,
          hovered: hoveredSettingIcon,
          pressed: pressedeSettingIcon,
        },
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/setting`,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/setting`,
            title: "기본설정",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/store-setting`,
            title: "지점설정",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/kiosk-setting`,
            title: "키오스크 설정",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/image-setting`,
            title: "광고 / 배너 이미지 설정",
            type: "link",
          },
        ],
      },
    ],
  },
];
export const UserMenuItems = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "대시보드",
        icon: {},
        type: "link",
        active: false,
        path: `${process.env.PUBLIC_URL}/dashboard`,
      },
    ],
  },

  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "매장 좌석 조회",
        icon: User,
        type: "link",
        active: false,
        path: `${process.env.PUBLIC_URL}/user/seat/list`,
      },
    ],
  },

  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "입장권",
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/user/ticket/buy`,
            title: "입장권 구입",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/user/ticket/info`,
            title: "입장권 목록조회",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "이용내역",
        icon: AlignLeft,
        type: "link",
        active: false,
        path: `${process.env.PUBLIC_URL}/user/user-info`,
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "결제내역",
        icon: {
          enabled: enabledCardIcon,
          hovered: hoveredCardIcon,
          pressed: pressedCardIcon,
        },
        type: "link",
        active: false,
        path: `${process.env.PUBLIC_URL}/user/payment-info`,
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "세미나실",
        icon: {
          enabled: enableScheduleIcon,
          hovered: hoveredScheduleIcon,
          pressed: pressedeScheduleIcon,
        },
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/user/seminar/buy`,
            title: "세미나실 예약",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/user/seminar/info`,
            title: "세미나실 예약조회",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "마일리지 & 쿠폰",
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/user/mileage`,
            title: "마일리지",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/user/coupon`,
            title: "쿠폰",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "내정보",
        icon: User,
        type: "link",
        active: false,
        path: `${process.env.PUBLIC_URL}/user/info`,
      },
    ],
  },
];
