import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Button,
} from "reactstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getMessageLog, merchantId_api, userList } from "../lib/api";
import { messagelogListColumn } from "../data/ticketList";
import "moment/locale/ko";
import PaginationComponent from "../components/pagination";
import { ExampleCustomInput, LabelRow } from "./ticketCustom";
import { DatePickerStyle } from "./seatInfo";
import SelectDate from "../components/selectDate";
import { exportExcel } from "../components/common";
import DetailsModal from "../components/modal/detailsModal";

const CardContainer = styled.div`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  .date-pickers {
    display: flex;
    h5 {
      margin: 0 6px;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;

    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      input {
        width: 100%;
      }
      .fa-search {
        position: absolute;
        right: 12px;
        top: 16.8px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .topbar {
      margin-top: 1vw;
      .noMargin {
        margin-left: 0;
      }
    }
    form {
      input {
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .topbar {
      align-items: center;
      flex-direction: column;
      .m-0 {
        margin: 3vw 0 !important;
      }
    }
  }

  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

let merchantId = "";

const MessageInfo = ({ setIsLoading }) => {
  const [lists, setLists] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().startOf('week').add(1, 'day').toDate());
  const [endDate, setEndDate] = useState(moment().startOf('week').add(7, 'days').toDate());
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  //모달 state
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      getMessagelogListApi(data.id);
    }
  };

  const getMessagelogListApi = async (merchantId, page) => {
    setIsLoading(true);
    if (merchantId) {
      try {
        const { data } = await getMessageLog(
          merchantId,
          moment(startDate).format("YYYY-MM-DD 00:00:00"),
          moment(endDate).format("YYYY-MM-DD 23:59:59"),
          page ? 1 : currentPage,
          postsPerPage,
          searchKeyword
        );

        if (page) {
          setCurrentPage(1);
        }
        if (data) {
          setTotalLength(data.count);
          if (data.result.length > 0) {
            setLists(
              data.result,
              data.result.map((item, idx) => {
                item.createdAt = moment(item.createdAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
                item.phone = (
                  <div
                    className="phoneNum-click"
                    onClick={(e) => sendDetailData(e.currentTarget.textContent)}
                  >
                    {item.phone}
                  </div>
                );
              })
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMessagelogListApi(merchantId);
  }, [startDate, endDate, currentPage]);

  // 디테일 데이터 모달로 보내기
  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };
  // 휴대혼 번호 가져오기
  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  //모달 on/off
  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === "") {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const exportExcelData = async () => {
    const data = await getMessageLog(
      merchantId,
      moment(startDate).format("YYYY-MM-DD 00:00:00"),
      moment(endDate).format("YYYY-MM-DD 23:59:59"),
      1,
      totalLength,
      searchKeyword
    );
    if (data.status === 200) {
      let excelData = [
        data.data.result,
        data.data.result.map((item, idx) => {
          item.createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        }),
      ];
      if (excelData) {
        exportExcel(excelData[0], startDate, endDate, "메세지 전송 내역");
      }
    }
  };

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
        />
      )}
      <Breadcrumb parent="메시지 관리" title="메세지 전송내역" />
      <Container fluid={true}>
        <CardContainer>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Button
                    className="hide-480"
                    onClick={() => exportExcelData()}
                  >
                    엑셀 출력하기
                  </Button>
                  <div className="topbar">
                    <DatePickerStyle className="noMargin">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        selectsStart
                        locale={ko}
                        startDate={startDate}
                        maxDate={endDate}
                        endDate={endDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <h5> - </h5>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        locale={ko}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <SelectDate
                        setStartDate={(val) => setStartDate(val)}
                        setEndDate={(val) => setEndDate(val)}
                        nowTime={"이번 주"}

                      />
                    </DatePickerStyle>

                    <Form
                      style={{
                        width:
                          window.innerWidth < 480
                            ? "100%"
                            : window.innerWidth < 768
                            ? "60%"
                            : "30%",
                      }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        getMessagelogListApi(merchantId, 1);
                      }}
                    >
                      <LabelRow className="m-0">
                        <Input
                          placeholder="전화번호 입력해주세요"
                          className="form-control"
                          type="text"
                          defaultValue={searchKeyword}
                          onChange={({ target }) =>
                            handleSearchKeyword(target.value)
                          }
                        />

                        <i
                          onClick={() => getMessagelogListApi(merchantId, 1)}
                          className="fa fa-search"
                        />
                      </LabelRow>
                    </Form>
                  </div>
                  {totalLength > 0 ? (
                    <DataTable
                      data={lists}
                      columns={messagelogListColumn}
                      striped={true}
                      center={true}
                    />
                  ) : (
                    <h5 style={{ textAlign: "center" }}>정보가 없습니다.</h5>
                  )}
                </CardBody>

                {totalLength > 0 && (
                  <PaginationComponent
                    setCurrentPage={setCurrentPage}
                    setPostsPerPageAdd={setPostsPerPageAdd}
                    setPostsPerPageAdd1={setPostsPerPageAdd1}
                    setPageNum={setPageNum}
                    totalLength={totalLength}
                    postsPerPage={postsPerPage}
                    currentPage={currentPage}
                    pageNum={pageNum}
                    postsPerPageAdd={postsPerPageAdd}
                    postsPerPageAdd1={postsPerPageAdd1}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </Fragment>
  );
};

export default MessageInfo;
