import React from "react";
import moment from "moment";

const SelectDateFuture = ({ setStartDate, setEndDate, nowTime }) => {
  const month = ["오늘", "어제", "이번 주", "이번 달"];
  const handleDate = ({ target }) => {
    if (target.value === "어제") {
      setStartDate(moment(new Date()).subtract(1, "d")._d);
      setEndDate(moment(new Date()).subtract(1, "d")._d);
    } else if (target.value === "이번 주") {
      setStartDate(moment(new Date()).startOf("isoweek")._d);
      setEndDate(moment(new Date()).endOf("isoweek")._d);
    } else if (target.value === "이번 달") {
      setStartDate(moment(new Date()).startOf("month")._d);
      setEndDate(moment(new Date()).endOf("month")._d);
    } else if (target.value === "오늘") {
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (target.value === "기본") {
      setStartDate(new Date());
      setEndDate(moment(new Date()).add(1, "y")._d);
    }
  };

  return (
    <select onChange={handleDate} defaultValue={nowTime ? nowTime : "기본"}>
      {!nowTime && (
        <>
          <option value="기본" defaultChecked>
            기본
          </option>
          {month.map((li) => (
            <option key={li} value={li}>
              {li}
            </option>
          ))}
        </>
      )}
      {nowTime && (
        <>
          <option value="기본">기본</option>
          {month.map((li) => (
            <>
              {nowTime == li ? (
                <option defaultChecked key={li} value={li}>
                  {li}
                </option>
              ) : (
                <option key={li} value={li}>
                  {li}
                </option>
              )}
            </>
          ))}
        </>
      )}
    </select>
  );
};
export default SelectDateFuture;
