import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Button,
} from "reactstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DetailsModal from "../components/modal/detailsModal";
import moment from "moment";
import { getSeatLogList, merchantId_api, userList } from "../lib/api";
import { seatlogListColumn } from "../data/ticketList";
import "moment/locale/ko";
import PaginationComponent from "../components/pagination";
import { exportExcel } from "../components/common";
import { ExampleCustomInput, LabelRow } from "./ticketCustom";
import SelectDate from "../components/selectDate";
import { TabButton, TabList } from "./seatList";

const CardContainer = styled.div`
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;

    .m-0 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      width: 100%;

      .fa-search {
        position: absolute;
        right: 12px;
        top: 16.8px;
      }
    }
  }
  .phoneNum-click {
    color: #04c9db;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
  .searchContainer {
    display: flex;
    input {
      margin-top: 0;
      width: 100%;
    }
    form {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .topbar {
      flex-direction: column;
      justify-content: center;
      form {
        margin: 1vh 0;
      }
    }

    .searchContainer {
      width: 100%;
      input {
        width: 100%;
        height: 38px;
      }
      div {
        margin: 0;
        width: fit-content;
      }
      select {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
`;

export const DatePickerStyle = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 10px;
  h5 {
    margin: 0 8px;
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    margin-left: 10px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 5px 0 0;
    h5 {
      margin: 0 3px;
      font-weight: 400;
    }
    > div > div > button {
      padding: 6px 10px;
      font-size: 13px;
      > svg {
        width: 17px;
      }
    }
    select {
      margin-left: 5px;

      padding: 9px 5px;
    }
  }
`;

let merchantId = "";

const SeatInfo = ({ setIsLoading }) => {
  const [lists, setLists] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [detailUserModal, setDetailUserModal] = useState(false);

  const [userData, setUserData] = useState({});
  const [defaultList, setDefaultList] = useState([]);

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const [tabData, setTabData] = useState("all");
  const [searchFilterData, setSearchFilterData] = useState([]);
  const [searchOption, setSearchOption] = useState("좌석번호");

  let searchKeywordData = ["좌석번호", "유형", "전화번호", "권번"];
  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      getSeatlogListApi(data.id);
    }
  };

  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getSeatlogListApi = async (merchantId) => {
    if (merchantId) {
      setIsLoading(true);

      const data = await getSeatLogList(
        merchantId,
        moment(startDate).format("YYYYMMDD000000"),
        moment(endDate).format("YYYYMMDD235959")
      );

      setTotalLength(data.data.count);
      if (data.data.result.length > 0 && data.status === 200) {
        let value = [];
        let data1 = [];

        for (let i = 0; i < data.data.result.length; i++) {
          value[i] = data.data.result[i].ticketId;
        }
        if (value.length > 0) {
          const list = Array.from(new Set(value)).sort();
          for (let i = 0; i < list.length; i++) {
            let day = "";

            data1[i] = {
              day: "",
              id: list[i],
              message: "",
            };
            for (let j = 0; j < data.data.result.length; j++) {
              if (data.data.result[j].ticketId === list[i]) {
                day += `${data.data.result[j].createdAt}_`;
                data1[i].message += data.data.result[j].message;
                data1[i].day = day.split("_");
              }
            }
          }
        }

        let changeData = [
          data.data.result,
          data.data.result.map((item, idx) => {
            item.typeData =
              item.ticketType === 1
                ? "일회권"
                : item.ticketType === 2
                ? "기간권"
                : item.ticketType === 4
                ? "세미나실"
                : item.ticketType === 5
                ? "사물함"
                : "시간정액권";
            item.type =
              item.ticketType === 1 ? (
                <div className="text-danger">일회권</div>
              ) : item.ticketType === 2 ? (
                <div className="text-primary">기간권</div>
              ) : item.ticketType === 3 ? (
                <div className="text-secondary">시간정액권</div>
              ) : item.ticketType === 4 ? (
                <div style={{ color: "#3E67B6" }}>세미나실</div>
              ) : (
                <div style={{ color: "#AB84FF" }}>사물함</div>
              );
            item.lastIn = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
            item.isUsing =
              item.message === "입장" || item.message === "사물함 사용" ? (
                <div
                  className="btn-secondary btn-sm"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.message}
                </div>
              ) : (
                <div
                  className="btn-success btn-sm"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.message}
                </div>
              );
            item.phoneNumTag = (
              <div
                onClick={() => {
                  sendDetailData(item.phoneNum);
                }}
                className="phoneNum-click"
              >
                {item.phoneNum}
              </div>
            );
            item.action = data1
              .map(
                (li) =>
                  item.ticketId === li.id &&
                  moment(li.day[0]).format("YYYY-MM-DD HH:mm:ss")
              )
              .filter((item) => item !== false)[0];

            let hours;
            let minutes;
            let seconds;
            const diffTime = (t2, t1) => {
              hours = moment.duration(moment(t1).diff(t2)).hours();
              minutes = moment.duration(moment(t1).diff(t2)).minutes();
              seconds = moment.duration(moment(t1).diff(t2)).seconds();

              return (
                (hours < 10 ? "0" + hours : hours) +
                " : " +
                (minutes < 10 ? "0" + minutes : minutes) +
                " : " +
                (seconds < 10 ? "0" + seconds : seconds)
              );
            };
            item.numberName = (item.number !== null ? item.number : "-") + "번";
            //수정부분

            item.expireDate = data1
              .map((li) =>
                item.ticketId === li.id && item.ticketType === 5
                  ? "-"
                  : item.ticketId === li.id &&
                    li.day.length >= 3 &&
                    li.message.includes("퇴장")
                  ? diffTime(
                      moment(li.day[li.day.length - 2], "YYYY-MM-DD HH:mm:ss"),
                      moment(li.day[0], "YYYY-MM-DD HH:mm:ss")
                    )
                  : item.ticketId === li.id &&
                    li.day.length < 3 &&
                    li.message.includes("입장")
                  ? diffData(li.day[0])
                  : item.ticketId === li.id &&
                    li.day.length < 3 &&
                    li.message.includes("퇴장")
                  ? "해당기간의 입장내역이 없습니다."
                  : ""
              )
              .filter((item) => item !== "")[0];

            // item.expireDate = data1.map((li) =>
            //   item.ticketId === li.id && li.day.length === 3
            //     ? diffTime(
            //         moment(li.day[li.day.length - 2], "YYYY-MM-DD HH:mm:ss"),
            //         moment(li.day[0], "YYYY-MM-DD HH:mm:ss")
            //       )
            //     : diffData(li.day[0])
            // );
          }),
        ];

        if (tabData === "locker") {
          let ticketData = changeData[0].filter(
            (item) => item.ticketType === 5 || item.seatType === "locker"
          );
          setLists(ticketData);
          setSearchFilterData(ticketData);
          setCurrentPage(1);
        } else if (tabData === "seat") {
          let ticketData = changeData[0].filter(
            (item) => item.ticketType === 1
          );
          setLists(ticketData);
          setSearchFilterData(ticketData);
          setCurrentPage(1);
        } else {
          setLists(changeData[0]);
          setSearchFilterData(changeData[0]);
          setCurrentPage(1);
        }
        setDefaultList(changeData[0]);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const diffData = (t1) => {
    const day = moment.duration(moment(new Date()).diff(t1)).days();
    const hours =
      day > 0
        ? day * 24 + moment.duration(moment(new Date()).diff(t1)).hours()
        : moment.duration(moment(new Date()).diff(t1)).hours();
    const minutes = moment.duration(moment(new Date()).diff(t1)).minutes();
    const seconds = moment.duration(moment(new Date()).diff(t1)).seconds();

    return (
      (hours < 10 ? "0" + hours : hours) +
      " : " +
      (minutes < 10 ? "0" + minutes : minutes) +
      " : " +
      (seconds < 10 ? "0" + seconds : seconds)
    );
  };

  useEffect(() => {
    getSeatlogListApi(merchantId);
  }, [startDate, endDate]);

  const handleSearchKeyword = (keyword) => {
    if (keyword === "") {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const searchData = () => {
    if (!searchKeyword) {
      setLists(searchFilterData);
    } else {
      let copyData = [...searchFilterData];
      let searchData;
      if (searchOption === "유형") {
        searchData = copyData.filter((item) =>
          item.typeData?.includes(searchKeyword)
        );
      } else if (searchOption === "좌석번호") {
        if (searchKeyword.includes("번")) {
          searchData = copyData.filter(
            (item) => item.numberName?.toString() === searchKeyword
          );
        } else {
          searchData = copyData.filter(
            (item) => item.numberName?.toString() === searchKeyword + "번"
          );
        }
      } else if (searchOption === "전화번호") {
        searchData = copyData.filter((item) =>
          item.phoneNum?.includes(searchKeyword)
        );
      } else {
        searchData = copyData.filter((item) =>
          item.ticketId?.toString().includes(searchKeyword)
        );
      }

      setLists(searchData);
    }
    setCurrentPage(1);
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const changeShowData = (tabName) => {
    setIsLoading(true);
    setTabData(tabName);
    setCurrentPage(1);
    setSearchKeyword(null);
    if (tabName === "all") {
      setLists(defaultList);
      setSearchFilterData(defaultList);
      setTotalLength(defaultList.length);
    } else if (tabName === "seat") {
      const ticketData = defaultList.filter((item) => item.ticketType === 1);
      setLists(ticketData);
      setSearchFilterData(ticketData);
      setTotalLength(ticketData.length);
    } else if (tabName === "locker") {
      const lockerData = defaultList.filter(
        (item) => item.ticketType === 5 || item.seatType === "locker"
      );
      setLists(lockerData);
      setSearchFilterData(lockerData);
      setTotalLength(lockerData.length);
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
          // setData={getUserTicketListApi}
        />
      )}
      <Breadcrumb parent="좌석 및 사물함" title="맵뷰어 상세 조회" />
      <Container fluid={true}>
        <CardContainer>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Button
                    className="hide-480"
                    onClick={() =>
                      exportExcel(lists, startDate, endDate, "맵뷰어 상세 조회")
                    }
                  >
                    엑셀 출력하기
                  </Button>
                  <TabList>
                    <TabButton
                      selected={tabData === "all"}
                      onClick={() => changeShowData("all")}
                    >
                      전체
                    </TabButton>
                    <TabButton
                      selected={tabData === "locker"}
                      onClick={() => changeShowData("locker")}
                    >
                      사물함
                    </TabButton>
                    <TabButton
                      selected={tabData === "seat"}
                      onClick={() => changeShowData("seat")}
                    >
                      일회권
                    </TabButton>
                  </TabList>
                  <div className="topbar">
                    <DatePickerStyle>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        selectsStart
                        locale={ko}
                        startDate={startDate}
                        maxDate={endDate}
                        endDate={endDate}
                        customInput={<ExampleCustomInput />}
                      />
                      <h5> - </h5>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        locale={ko}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        customInput={<ExampleCustomInput />}
                      />

                      <SelectDate
                        setStartDate={(val) => setStartDate(val)}
                        setEndDate={(val) => setEndDate(val)}
                      />
                    </DatePickerStyle>
                    <div className="searchContainer">
                      <DatePickerStyle>
                        <select
                          onChange={(e) => setSearchOption(e.target.value)}
                          value={searchOption}
                        >
                          {searchKeywordData.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </DatePickerStyle>
                      <Form
                        style={{
                          width: window?.innerWidth > 480 ? "30%" : "100%",
                        }}
                        onSubmit={(e) => {
                          e.preventDefault();
                          searchData();
                        }}
                      >
                        <LabelRow className="m-0">
                          <Input
                            placeholder="검색어를 입력해주세요"
                            className="form-control"
                            type="text"
                            value={searchKeyword || ""}
                            onChange={(e) =>
                              handleSearchKeyword(e.target.value)
                            }
                          />

                          <i
                            onClick={() => searchData()}
                            className="fa fa-search"
                          />
                        </LabelRow>
                      </Form>
                    </div>
                  </div>

                  {totalLength > 0 ? (
                    <div id="seatInfoTable">
                      <DataTable
                        data={currentPosts(lists)}
                        columns={seatlogListColumn}
                        striped={true}
                        center={true}
                      />
                    </div>
                  ) : (
                    <h6 style={{ textAlign: "center" }}>정보가 없습니다.</h6>
                  )}
                </CardBody>
                {totalLength > 0 && (
                  <PaginationComponent
                    setCurrentPage={setCurrentPage}
                    setPostsPerPageAdd={setPostsPerPageAdd}
                    setPostsPerPageAdd1={setPostsPerPageAdd1}
                    setPageNum={setPageNum}
                    totalLength={lists.length}
                    postsPerPage={postsPerPage}
                    currentPage={currentPage}
                    pageNum={pageNum}
                    postsPerPageAdd={postsPerPageAdd}
                    postsPerPageAdd1={postsPerPageAdd1}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </Fragment>
  );
};

export default SeatInfo;
