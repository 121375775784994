import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import IconChair from "../../../assets/images/dashboard/chair.svg";
import IconBox from "../../../assets/images/dashboard/box.svg";
import IconMessage from "../../../assets/images/dashboard/message.svg";
import {
  getLockerList,
  getMerchantInfo,
  getSeatList,
  merchantId_api,
} from "../../../lib/api";
import { numberWithCommas } from "../../common";
import { useHistory } from "react-router-dom";
import { DashboardCard } from "../../commonStyle";
/** 좌석,사물함, 메세지 사용량 component */

const UsageData = styled.div`
  display: flex;
  width: 100%;
  .titleImg {
    border: 1px solid #dde4eb;
    width: 4.16vw;
    height: 4.16vw;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    img {
      width: 2.08vw;
    }
  }
  .text {
    margin-left: 1vw;
    width: calc(100% - 5.16vw);
    div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      > button {
        font-size: 0.62vw;
        height: 1.14vw;
        color: #91979e;
        background: none;
        border: 1px solid #dde4eb;
        border-radius: 100px;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #f1f3f6;
        }
      }
      > p {
        margin-bottom: 0;
        font-weight: 700;
        line-height: 1.5;
        font-size: var(--dashboard-main);
      }
    }
    > p {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 1.5;

      font-size: var(--dashboard-count);
      color: ${(props) =>
        props.color === "red"
          ? " #D31F40"
          : props.color === "orange"
          ? "#D75E1B"
          : "var(--dashboard-default)"};
    }
  }

  @media screen and (max-width: 805px) {
    margin-bottom: 4.44vw;
    .titleImg {
      width: 22.22vw;
      height: 22.22vw;

      img {
        width: 11.11vw;
      }
    }
    .text {
      margin-left: 4.44vw;
      width: calc(100% - 26.66vw);
      div {
        > button {
          font-size: 3.33vw;
          height: 6.11vw;
        }
        > p {
          font-size: 5.55vw;
        }
      }
      > p {
        font-size: 8.88vw;
      }
    }
  }
`;

const StyleComponent = styled(DashboardCard)`
  display: flex;
  height: 10vw;
  justify-content: space-between;
  flex-flow: column;
  align-items: center;

  progress {
    width: 13.1vw;
    border-radius: 1.25vw;
    background: #f1f3f6;
    height: 1.25vw;
  }
  progress::-webkit-progress-bar {
    width: 13.1vw;
    border-radius: 1.25vw;
    background: #f1f3f6;
  }
  progress::-webkit-progress-value {
    border-radius: 1.25vw;
    background: ${(props) =>
      props.color === "red"
        ? "#EC6E85"
        : props.color === "orange"
        ? "#F2A354"
        : "#00cfe2"};
  }
  progress::-moz-progress-bar {
    border-radius: 1.25vw;
    background: #f1f3f6;
    width: 13.1vw;
  }
  .totalText {
    display: flex;
    p {
      font-size: 0.83vw;
      margin-bottom: 0;
      &:first-child {
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 805px) {
    height: fit-content;
    progress {
      width: 100%;
      border-radius: 100vw;
      height: 6.66vw;
      margin-bottom: 2.22vw;
    }
    progress::-webkit-progress-bar {
      width: 100%;
      border-radius: 100vw;
      background: #f1f3f6;
    }
    progress::-webkit-progress-value {
      border-radius: 100vw;
      background: ${(props) =>
        props.color === "red"
          ? "#EC6E85"
          : props.color === "orange"
          ? "#F2A354"
          : "#00cfe2"};
    }
    progress::-moz-progress-bar {
      width: 100%;
      border-radius: 100vw;
      background: #f1f3f6;
    }
    .totalText {
      p {
        font-size: 4.44vw;
      }
    }
  }
`;

const UsageComponent = ({ type }) => {
  const [share, setShare] = useState();
  const [seatLength, setSeatLength] = useState(0);
  const [occData, setOccData] = useState(0);
  const [usingLocker, setUsingLocker] = useState(0);
  const [lockerLength, setLockerLength] = useState(0);
  const [usingMassage, setUsingMassage] = useState(0);
  const [massageLength, setMassageLength] = useState(0);
  const [usingData, setUsingData] = useState([]);
  const history = useHistory();
  const getMerchantId = async () => {
    const data = await merchantId_api();

    if (data.status === 200) {
      if (type === "seat") seatData(data.data.id);
      else if (type === "locker") LockerData(data.data.id);
      else messageData(data.data.id);
    }
  };
  const seatData = async (e) => {
    let data = await getSeatList(e);
    if (data.status === 200) {
      let usingFilterData = data.data.filter((item) => item.isUsing === 1);
      setSeatLength(data.data.length);
      setOccData(usingFilterData.length);
      let usingSeatData = {
        oneTicket: 0,
        dayTicket: 0,
        timeTicket: 0,
      };
      for (let i = 0; i < usingFilterData.length; i++) {
        if (usingFilterData[i].ticketType === 1) {
          usingSeatData.oneTicket += 1;
        } else if (usingFilterData[i].ticketType === 2) {
          usingSeatData.dayTicket += 1;
        } else if (usingFilterData[i].ticketType === 3) {
          usingSeatData.timeTicket += 1;
        }
      }

      setUsingData(usingSeatData);
      setShare(
        Math.floor(
          (data.data.filter((item) => item.isUsing === 1).length /
            data.data.length) *
            100
        )
      );
    }
  };

  const LockerData = async (e) => {
    let data = await getLockerList(e);

    if (data.status === 200) {
      setLockerLength(data.data.length);
      setUsingLocker(data.data.filter((item) => item.userId).length);
      setShare(
        Math.floor(
          (data.data.filter((item) => item.userId).length / data.data.length) *
            100
        )
      );
    }
  };

  const messageData = async (e) => {
    const res = await getMerchantInfo(e);

    if (res.status === 200) {
      setMassageLength(res.data[0].maxSendMessage);
      setUsingMassage(res.data[0].sendMessageUseCount);
      setShare(
        Math.floor(
          (res.data[0].sendMessageUseCount / res.data[0].maxSendMessage) * 100
        )
      );
    }
  };

  const goMessagePage = () => {
    history.push("/message/charge");
  };
  useEffect(() => {
    getMerchantId();
  }, []);

  return (
    <Fragment>
      <StyleComponent
        color={
          type === "message" && share > 89
            ? "red"
            : type === "message" && share > 49
            ? "orange"
            : "nomal"
        }
      >
        <UsageData
          color={
            type === "message" && share > 89
              ? "red"
              : type === "message" && share > 49
              ? "orange"
              : "nomal"
          }
        >
          <div className="titleImg">
            <img
              src={
                type === "seat"
                  ? IconChair
                  : type === "locker"
                  ? IconBox
                  : IconMessage
              }
              alt="icon"
            />
          </div>
          <div className="text">
            <div>
              <p>
                {type === "seat"
                  ? "좌석 점유율"
                  : type === "locker"
                  ? "사물함 점유율"
                  : "메세지 사용량"}
              </p>
              {type === "message" && (
                <button onClick={() => goMessagePage()}>충전</button>
              )}
            </div>

            <p>{share ? share : 0}%</p>
          </div>
        </UsageData>
        <progress
          max={
            type === "seat"
              ? seatLength
              : type === "locker"
              ? lockerLength
              : massageLength
          }
          value={
            type === "seat"
              ? occData
              : type === "locker"
              ? usingLocker
              : usingMassage
          }
        />
        <div className="totalText">
          <p>
            {type === "seat"
              ? `${occData}석 사용중`
              : type === "locker"
              ? `${usingLocker}개 사용중`
              : `${numberWithCommas(usingMassage)}개 사용`}
          </p>{" "}
          <p>
            / 총{" "}
            {type === "seat"
              ? `${seatLength}석`
              : type === "locker"
              ? `${lockerLength}개`
              : `${numberWithCommas(massageLength)}개`}
          </p>
        </div>
      </StyleComponent>
    </Fragment>
  );
};

export default UsageComponent;
