import React, { useEffect } from "react";
import {
  getUserData,
  getWebPayRequest,
  merchantId_api,
  msgPointWebpay,
} from "../../lib/api";
import Breadcrumb from "../../layout/breadcrumb";
import { Card, CardHeader, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Receipt from "../../components/receipt";
import { DashboardCard, SolidBtn } from "../../components/commonStyle";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Success = () => {
  const url1 = window.location.href;
  const url = new URL(url1);
  console.log(url1);
  const urlParams = url.searchParams;
  const history = useHistory();

  const handleSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "결제가 완료됐습니다.",
      text: "자동으로 결제내역 페이지로 이동됩니다.",
      timer: 2500,
      showCancelButton: false,
      showConfirmButton: false,
    }).then(() => {
      window.location.href = `${process.env.PUBLIC_URL}/user/payment-info`;
    });
  };
  // const handleApi = async () => {
  //   const { data } = await getUserData();
  //   let startDate = urlParams.get("startDate");
  //   const res = await getWebPayRequest({
  //     merchantId: data[0]?.id,
  //     amount: urlParams.get("amount"),
  //     orderId: urlParams.get("orderId"),
  //     paymentKey: urlParams.get("paymentKey"),
  //     startDate:
  //       urlParams.get("startDate") !== "null"
  //         ? `${startDate.slice(0, -8)} ${startDate.slice(-8)}`
  //         : moment().format("YYYY-MM-DD HH:mm:ss"),
  //     itemId:
  //       urlParams.get("itemId") !== "null" ? urlParams.get("itemId") : null,
  //     phoneNum: urlParams.get("phoneNum"),
  //     seatId:
  //       urlParams.get("seatId") !== "null" ? urlParams.get("seatId") : null,
  //     seminarId:
  //       urlParams.get("seminarId") !== "null"
  //         ? urlParams.get("seminarId")
  //         : null,
  //     seminarCount:
  //       urlParams.get("seminarCount") !== "null"
  //         ? urlParams.get("seminarCount")
  //         : null,
  //   });
  //   console.log(res);
  //   console.log(
  //     "startDate",
  //     startDate,
  //     `${startDate.slice(-6)} ${startDate.slice(5, 11)}`
  //   );
  //   window.history.replaceState({}, null, window.location.pathname);

  //   if (res.status === 200) {
  //     if (res.data === 0) {
  //       // 결제실패 ( 가격변조 )
  //       Swal.fire({
  //         icon: "error",
  //         title: "결제에 실패했습니다.",
  //         text: "자동으로 이용권 구매 페이지로 이동됩니다.",
  //         timer: 2500,
  //       }).then(() => {
  //         window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //       });
  //     } else if (res.data === 1) {
  //       // 중복결제
  //       Swal.fire({
  //         icon: "error",
  //         title: "중복결제로 인한 결제에 실패했습니다.",
  //         text: "자동으로 이용권 구매 페이지로 이동됩니다.",
  //         timer: 2500,
  //       }).then(() => {
  //         window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "success",
  //         title: "결제가 완료됐습니다.",
  //         text: "자동으로 결제내역 페이지로 이동됩니다.",
  //         timer: 2500,
  //         showCancelButton: false,
  //         showConfirmButton: false,
  //       }).then(() => {
  //         window.location.href = `${process.env.PUBLIC_URL}/user/payment-info`;
  //       });
  //     }
  //   } else if (res.status === 202) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "현재시간이 정확하지 않습니다. \n 관리자에게 문의해주세요.",
  //       text: "자동으로 이용권 구매 페이지로 이동됩니다.",
  //       timer: 3000,
  //     }).then(() => {
  //       window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //       localStorage.removeItem("startDate");
  //       localStorage.removeItem("seat");
  //     });
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "결제에 실패했습니다.",
  //       text: "자동으로 이용권 구매 페이지로 이동됩니다.",
  //       timer: 2500,
  //     }).then(() => {
  //       window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //       localStorage.removeItem("startDate");
  //       localStorage.removeItem("seat");
  //     });
  //   }
  // };

  // const buyMessageApi = async () => {
  //   const { data } = await merchantId_api();

  //   const userData = await getUserData();
  //   const res = await msgPointWebpay({
  //     merchantId: data.id,
  //     userId: userData.data[0].userId,
  //     amount: urlParams.get("amount"),
  //     orderId: urlParams.get("orderId"),
  //     paymentKey: urlParams.get("paymentKey"),
  //     msgItemId: urlParams.get("itemId"),
  //   });
  //   window.history.replaceState({}, null, window.location.pathname);

  //   if (res.status === 200) {
  //     if (res.data === 0) {
  //       // 결제실패 ( 가격변조 )
  //       Swal.fire({
  //         icon: "error",
  //         title: "결제에 실패했습니다.",
  //         text: "자동으로 메세지 충전 페이지로 이동됩니다.",
  //         timer: 2500,
  //       }).then(() => {
  //         window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //       });
  //     } else if (res.data === 1) {
  //       // 중복결제
  //       Swal.fire({
  //         icon: "error",
  //         title: "중복결제로 인한 결제에 실패했습니다.",
  //         text: "자동으로 메세지 충전 페이지로 이동됩니다.",
  //         timer: 2500,
  //       }).then(() => {
  //         window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "success",
  //         title: "결제가 완료됐습니다.",
  //         text: "자동으로 결제내역 페이지로 이동됩니다.",
  //         timer: 2500,
  //         showCancelButton: false,
  //         showConfirmButton: false,
  //       }).then(() => {
  //         window.location.href = `${process.env.PUBLIC_URL}/pay/info`;
  //       });
  //     }
  //   } else if (res.status === 202) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "현재시간이 정확하지 않습니다. \n 관리자에게 문의해주세요.",
  //       text: "자동으로 메세지 충전 페이지로 이동됩니다.",
  //       timer: 3000,
  //     }).then(() => {
  //       window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //     });
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "결제에 실패했습니다.",
  //       text: "자동으로 메세지 충전 페이지로 이동됩니다.",
  //       timer: 2500,
  //     }).then(() => {
  //       window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
  //     });
  //   }
  // };

  useEffect(() => {
    handleSuccess();
  }, []);
  return (
    <>
      <Row>
        <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
          <DashboardCard>
            <Container>
              <p>결제가 완료되었습니다.</p>
            </Container>
          </DashboardCard>
        </Col>
      </Row>
    </>
  );
};
export default Success;
