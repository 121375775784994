import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import {
  getLockerInfoList,
  getMapViewList,
  getMerchantInfo,
  getSeminarItemList,
  merchantId_api,
} from "../../lib/api";
import "moment/locale/ko";
import ChoiceModal from "../../components/modal/choiceModal";
import { secondToHour } from "../ticketList";
import { LockerS, LockersArray, LockerView } from "../lockerChange";
import ChangeLockerModal from "../../components/modal/lockerChangeModal";
import configDB from "../../data/customizer/config";
import { Maximize2, Minimize2 } from "react-feather";
import SeatBlockModal from "../../components/modal/seatBlockModal";
import { BackgroundLoading } from "../../components/Loading";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const CardContainer = styled.div`
  .date-pickers {
    display: flex;
  }

  .card-header {
    display: flex;
    justify-content: space-around;
  }

  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      i {
        position: absolute;
        top: 0.8vw;
        right: 0.5vw;
      }
      input {
        width: 80% !important;
      }
    }
  }
  > button {
    margin: 1vw 0 6vw;
  }
  .seatContainer {
    display: flex;
  }

  @media screen and (max-width: 768px) {
    .seatContainer {
      flex-direction: column-reverse;
      overflow: hidden;
    }
  }
`;
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isSeat ? "100%" : "13%")};
  font-weight: 600;
  margin-bottom: 14px;
  margin-right: ${(props) => (props.isSeat ? "0" : "0.6vw")};

  /* margin: 0.6vw 2% 0; */
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
  @media screen and (max-width: 480px) {
    width: 96%;
    margin-bottom: 0px;
    margin-right: 10px;
  }
`;

export const ToggleBtn = styled.label`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
  margin-top: 0.5rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .onoff-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background-color: #ccc;
    transition: 0.2s;
    width: 46px;
  }
  .onoff-switch:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: #fff;
    transition: 0.2s;
    border-radius: 20px;
  }
  input:checked + .onoff-switch {
    background-color: ${primary};
  }
  input:checked + .onoff-switch:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(20px);
  }

  .disabled {
    cursor: not-allowed !important;
  }

  .tootip-text {
    position: absolute;
    width: 10vw;
  }
`;

export const TabList = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 10px 0;
  width: 100%;
  border-bottom: solid 1px #ccc;
`;

export const TabButton = styled.button`
  width: 60px;
  margin-right: 50px;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  color: ${(props) => (props.selected ? "#222" : "#A9AFC0")};
  border-bottom: solid 2px ${(props) => (props.selected ? primary : "#fff")};
  @media screen and (max-width: 480px) {
    height: 45px;
    margin-right: 20px;
  }
`;
const SeatListS = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isSeat ? "column" : "row")};
  width: ${(props) => (props.isSeat ? "15%" : "100%")};
  margin-left: ${(props) => (props.isSeat ? "20px" : "0")};

  .floorBtn {
    margin-bottom: 10px;
    flex-direction: column;
    display: flex;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    .toggleBtnContainer {
      width: 100%;
      display: flex;
      justify-content: space-around;
      div {
        width: 40%;
      }
    }
    .floorBtn {
      width: 100%;

      display: flex;
      justify-content: space-around;
      flex-direction: row;
      button {
        margin-right: 0;
      }
    }
    .ticket-kind {
      display: flex;
      width: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-left: 0;
    width: 100%;
    .floorBtn {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      button {
        width: 25%;
        margin-right: 0;
      }
    }
  }
`;
export const PossibleColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 50px;
  margin-bottom: 14px;
  flex: 1;
  padding: 5px;
  text-align: center;
  background-color: ${(props) => (props.color ? props.color : "#222222")};
  border: solid 1px ${(props) => (props.color ? props.color : "#222222")};
  color: #fff;
  border-radius: 5px;
  > p {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`;
export const PossibleColorL = styled(PossibleColor)`
  background-color: ${(props) => (props.isUse ? "#888ea5" : "#fff")};
  border: 1px solid ${(props) => (props.isUse ? "#888ea5" : "#222")};

  color: ${(props) => (props.isUse ? "#fff" : "#222")};
`;
export const SeatView = styled.div`
  /* width: 920px; */

  background-color: cadetblue;
  border-radius: 4px;
  position: relative;
  /* margin: 0 auto; */
  width: 920px;
  height: 1444px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4cbd6;
    border-radius: 1vw;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 1vw;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: ${(props) =>
      props.isMin ? `calc(920px * ${props.ratio})` : ` 100%`};
    height: ${(props) =>
      props.isMin ? `calc(1444px * ${props.ratio})` : ` 1444px`};
    margin: 0 auto;
    ::-webkit-scrollbar {
      width: 0vw;
      height: 0;
    }
  }
`;
const HoverInfo = styled.span`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  padding: 3px;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  left: 3px;
  z-index: 1;
  background-color: ${(props) => props.used};
  p {
    font-size: 9px;
    letter-spacing: -0.35px;
    line-height: 1.7;
  }
`;
export const Seat = styled.div`
  position: absolute;
  top: ${(props) =>
    props.isMin ? `calc(${props.top}px * ${props.ratio})` : `${props.top}px`};
  left: ${(props) =>
    props.isMin ? `calc(${props.left}px * ${props.ratio})` : `${props.left}px`};
  background-color: ${(props) => (props.used ? props.used : "transparent")};
  border: 1px solid #fff;

  width: ${(props) => (props.isMin ? `calc(77px * ${props.ratio})` : ` 77px`)};
  height: ${(props) => (props.isMin ? `calc(55px * ${props.ratio})` : ` 55px`)};

  /* height: 55px; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  opacity: ${(props) => (props.rest ? 0.8 : 1)};
  p {
    color: #fff;
    margin-bottom: 0;
    line-height: 1;
    font-size: ${(props) =>
      props.isMin ? `calc(12px * ${props.ratio})` : ` 12px`};
  }
  .seatType {
    font-size: 1.4vw;
  }
  &:hover ${HoverInfo} {
    display: flex;

    background-color: ${(props) => props.used};
  }
`;
const LockerS1 = styled(LockerS)`
  position: relative;
  &:hover ${HoverInfo} {
    display: flex;
    flex-direction: column;
  }
`;

export const SeatArrayS = styled.div`
  position: absolute;
  width: 920px;
  height: 1444px;
  display: flex;
  @media screen and (max-width: 480px) {
    left: 0%;
    transform: translate(-0%, 0);
    width: ${(props) => (props.isMin ? `calc(920px * ${props.ratio})` : ``)};
    height: ${(props) =>
      props.isMin ? `calc(1444px * ${props.ratio})` : ` 1444px`};
  }
`;

export const Wall = styled.div`
  top: ${(props) =>
    props.isMin ? `calc(${props.top}px * ${props.ratio})` : `${props.top}px`};
  left: ${(props) =>
    props.isMin ? `calc(${props.left}px * ${props.ratio})` : `${props.left}px`};
  width: ${(props) =>
    props.isMin
      ? `calc(${props.width}px * ${props.ratio})`
      : `${props.width}px`};
  height: ${(props) =>
    props.isMin
      ? `calc(${props.height}px * ${props.ratio})`
      : `${props.height}px`};
  position: absolute;
  background-color: #fff;
`;
export const Text = styled.div`
  position: absolute;
  top: ${(props) =>
    props.isMin ? `calc(${props.top}px * ${props.ratio})` : `${props.top}px`};
  left: ${(props) =>
    props.isMin ? `calc(${props.left}px * ${props.ratio})` : `${props.left}px`};
  color: #fff;
  font-size: ${(props) =>
    props.isMin ? `calc(${props.size}px * ${props.ratio})` : `${props.size}px`};
`;

export const SizeBtn = styled.button`
  display: none;
  position: fixed;
  bottom: 5%;
  right: 2%;
  z-index: 999;
  border-radius: 50%;
  background-color: ${primary};
  border: none;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  > svg {
    width: 18px;
    color: #fff;
  }
  @media screen and (max-width: 480px) {
    display: ${(props) => (props.show ? "flex" : "none")};
  }
`;

let merchantId = "";

const SeatListInfo = ({ setIsLoading }) => {
  const [tabMenu, setTabMenu] = useState("seat");
  const [lists, setLists] = useState([]);
  const [lockerList, setLockerList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [seatData, setSeatData] = useState();
  const [isLockerChange, setIsLockerChange] = useState(false);
  const [selectedLocker, setSelectedLocker] = useState();
  const [toggle, setToggle] = useState(true);
  const [walls, setWalls] = useState([]);
  const [texts, setTexts] = useState([]);
  const [level, setLevels] = useState(true);
  const [isMinimal, setIsMinimal] = useState(false);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [seminarArr, setSeminarArr] = useState(null);
  const [multiNum, setMultiNum] = useState(null);
  const [floorNum, setFloorNum] = useState(0);

  useEffect(() => {
    console.log("로그 useEffect");
    let getId = getMerchantId();
    return () => clearTimeout(getId);
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;

      const multiNumData = await getMerchantInfo(data.id);
      if (multiNumData && multiNumData.status === 200) {
        if (multiNumData.data[0].multiKioskFloor) {
          setMultiNum(multiNumData.data[0].multiKioskFloor);
          setFloorNum(1);
          getSeatListApi(data.id, 1);
          getLockerListApi(data.id, 1);
        } else {
          getSeatListApi(data.id, null);
          getLockerListApi(data.id, null);
        }
      }
      const res = await getSeminarItemList(
        data.id,
        moment(new Date()).format("YYYYMMDD000000"),
        moment(new Date()).format("YYYYMMDD235959")
      );
      if (res.data?.length > 0) {
        const filter = res.data.filter((li) =>
          moment(new Date()).isBetween(moment(li.startDate), moment(li.endDate))
        );

        setSeminarArr(filter);
      }
    }
  };
  const getLockerListApi = async (merchantId, floorNum) => {
    const res = await getLockerInfoList(merchantId);
    if (res.status === 200) {
      if (floorNum) {
        setLockerList(res.data.filter((item) => item.floor === +floorNum));
      } else {
        setLockerList(res.data);
      }
    }
  };
  const getSeatListApi = async (merchantId, floorNum) => {
    setIsLoading(true);
    const res = await getMapViewList(merchantId);

    if (res.status === 200) {
      if (floorNum) {
        setLists(
          res.data.filter(
            (item) =>
              (item.type === "seat" || item.type === "seminar") &&
              item.floor === floorNum
          )
        );
        setWalls(
          res.data.filter((li) => li.type === "wall" && li.floor === floorNum)
        );
        setTexts(
          res.data.filter((li) => li.type === "text" && li.floor === floorNum)
        );
      } else {
        setLists(
          res.data.filter(
            (item) => item.type === "seat" || item.type === "seminar"
          )
        );
        setWalls(res.data.filter((li) => li.type === "wall"));
        setTexts(res.data.filter((li) => li.type === "text"));
      }
    }
    setIsLoading(false);
  };

  const diffTime = (t1) => {
    const day = moment.duration(moment(t1).diff(new Date())).days();
    const hours =
      day > 0
        ? day * 24 + moment.duration(moment(t1).diff(new Date())).hours()
        : moment.duration(moment(t1).diff(new Date())).hours();
    const minutes = moment.duration(moment(t1).diff(new Date())).minutes();
    const seconds = moment.duration(moment(t1).diff(new Date())).seconds();

    return (
      (hours > -1 && hours < 10 ? "0" + hours : hours) +
      " : " +
      (minutes > -1 && minutes < 10 ? "0" + minutes : minutes) +
      " : " +
      (seconds > -1 && seconds < 10 ? "0" + seconds : seconds)
    );
  };
  const diffTime1 = (t1, remainTime) => {
    const day = moment.duration(moment(new Date()).diff(t1)).days();
    const hours =
      day > 0
        ? day * 24 + moment.duration(moment(new Date()).diff(t1)).hours()
        : moment.duration(moment(new Date()).diff(t1)).hours();
    const minutes =
      hours > 0
        ? hours * 60 + moment.duration(moment(new Date()).diff(t1)).minutes()
        : moment.duration(moment(new Date()).diff(t1)).minutes();
    const seconds =
      minutes > 0
        ? minutes * 60 + moment.duration(moment(new Date()).diff(t1)).seconds()
        : moment.duration(moment(new Date()).diff(t1)).seconds();
    return remainTime - seconds;
  };

  const diffTimeToRest = (t2) => {
    const day = moment.duration(moment(new Date()).diff(t2)).days();
    const hours =
      day > 0
        ? day * 24 + moment.duration(moment(new Date()).diff(t2)).hours()
        : moment.duration(moment(new Date()).diff(t2)).hours();
    const minutes = moment.duration(moment(new Date()).diff(t2)).minutes();
    const seconds = moment.duration(moment(new Date()).diff(t2)).seconds();
    return (
      (hours > 0 && hours < 10 ? "0" + hours : hours) +
      " : " +
      (minutes > -1 && minutes < 10 ? "0" + minutes : minutes) +
      " : " +
      (seconds > -1 && seconds < 10 ? "0" + seconds : seconds)
    );
  };
  const diffTimeToLocker = (t1) => {
    const day = moment.duration(moment(t1).diff(moment(new Date()))).asDays();

    return Math.floor(day + 1);
  };
  const choiceList = () => {
    setIsModal(!isModal);
  };

  const blockModal = (seat) => {
    setIsBlockModal(!isBlockModal);
    setSeatData(seat);
  };
  const showModal = (seat) => {
    setSeatData(seat);
    setIsModal(true);
  };
  const handleSelectLocker = (locker) => {
    setIsLockerChange(!isLockerChange);
    setSelectedLocker(locker);
  };

  useEffect(() => {
    if (merchantId) {
      getSeatListApi(merchantId, floorNum);
      getLockerListApi(merchantId, floorNum);
    }
  }, [floorNum]);

  const ratio = (920 - window.innerWidth) * 0.74 * 0.001;

  return (
    <>
      {isModal && (
        <ChoiceModal
          isOpen={isModal}
          seatData={seatData}
          onClose={choiceList}
          setLists={setLists}
          lists={lists}
          floorNum={floorNum}
        />
      )}
      {isBlockModal && (
        <SeatBlockModal
          isOpen={isBlockModal}
          seatData={seatData}
          onClose={blockModal}
          setLists={setLists}
          lists={lists}
          floorNum={floorNum}
        />
      )}
      {isLockerChange && (
        <ChangeLockerModal
          isOpen={isLockerChange}
          onClose={() => setIsLockerChange(!isLockerChange)}
          selected={selectedLocker}
          lists={lockerList}
        />
      )}

      <Fragment>
        <Breadcrumb parent="좌석 및 사물함" title="맵뷰어" />
        <Container fluid={true}>
          <CardContainer>
            <Row>
              <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Card>
                  {/* 로딩 컴포넌트 */}

                  <TabList>
                    <TabButton
                      selected={tabMenu === "seat"}
                      onClick={() => setTabMenu("seat")}
                    >
                      좌석
                    </TabButton>
                    <TabButton
                      selected={tabMenu === "locker"}
                      onClick={() => setTabMenu("locker")}
                    >
                      사물함
                    </TabButton>
                  </TabList>
                  <SizeBtn
                    show={tabMenu === "seat"}
                    onClick={() => setIsMinimal(!isMinimal)}
                  >
                    {isMinimal ? <Minimize2 /> : <Maximize2 />}
                  </SizeBtn>
                  <CardBody
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: 0,
                      width: "96%",
                      margin: "1vw auto",
                    }}
                  >
                    {tabMenu === "seat" ? (
                      <div className="seatContainer">
                        <SeatView ratio={ratio} isMin={isMinimal}>
                          <SeatArrayS ratio={ratio} isMin={isMinimal}>
                            {lists?.map((seat) => (
                              <React.Fragment key={seat.id}>
                                {(seat.status === "block" ||
                                  seat.status === 1) && (
                                  <Seat
                                    ratio={ratio}
                                    isMin={isMinimal}
                                    used="#222"
                                    top={seat.pos_top}
                                    left={seat.pos_left}
                                  >
                                    <p>
                                      {seat.number}.{seat.name}
                                    </p>
                                    {level && (
                                      <>
                                        <p>{seat.level}등급</p>
                                        <p>
                                          {seat.seatType === 0
                                            ? "자유석"
                                            : "고정석"}
                                        </p>
                                      </>
                                    )}
                                  </Seat>
                                )}
                                {seat.status === "idle" && (
                                  <Seat
                                    ratio={ratio}
                                    isMin={isMinimal}
                                    className="seat"
                                    top={seat.pos_top}
                                    left={seat.pos_left}
                                  >
                                    <p>
                                      {seat.number}.{seat.name}
                                    </p>
                                    {level && (
                                      <>
                                        <p>{seat.level}등급</p>{" "}
                                        <p>
                                          {seat.seatType === 0
                                            ? "자유석"
                                            : "고정석"}
                                        </p>
                                      </>
                                    )}
                                  </Seat>
                                )}
                                {/* 세미나실 배열 - 220726기준 좌석과 함께 출력중*/}
                                {seminarArr?.map(
                                  (li) =>
                                    li.seminarId === seat.id && (
                                      <Seat
                                        key={li.id}
                                        ratio={ratio}
                                        isMin={isMinimal}
                                        used="#616161"
                                        top={seat.pos_top}
                                        left={seat.pos_left}
                                      >
                                        <p>
                                          {seat.number}.{seat.name}
                                        </p>
                                        {level && (
                                          <>
                                            <p>등급 : {seat.level}</p>{" "}
                                            <p>
                                              {seat.seatType === 0
                                                ? "자유석"
                                                : "고정석"}
                                            </p>
                                          </>
                                        )}
                                      </Seat>
                                    )
                                )}
                                {/* 현재사용중인 이용권-일회권 */}
                                {seat.status === "using" &&
                                  seat.seatType === 1 && (
                                    <Seat
                                      ratio={ratio}
                                      isMin={isMinimal}
                                      className="seat"
                                      used="#1BBABC"
                                      top={seat.pos_top}
                                      left={seat.pos_left}
                                    >
                                      <p>
                                        {seat.number}.{seat.name}
                                      </p>
                                      {level && (
                                        <>
                                          <p>{seat.level}등급</p>{" "}
                                          <p>
                                            {seat.seatType === 0
                                              ? "자유석"
                                              : "고정석"}
                                          </p>
                                        </>
                                      )}
                                    </Seat>
                                  )}
                                {/* 사용중인 좌석 - 세미나실 제외,자유석 */}
                                {seat.status === "using" &&
                                  seat.ticketType !== 4 &&
                                  seat.seatType === 0 && (
                                    <Seat
                                      ratio={ratio}
                                      isMin={isMinimal}
                                      className="seat"
                                      top={seat.pos_top}
                                      left={seat.pos_left}
                                      used={
                                        seat.ticketType === 1
                                          ? "#616161"
                                          : seat.ticketType === 2
                                          ? "#FF8F00"
                                          : "#00838F"
                                      }
                                    >
                                      <p>
                                        {seat.number}.{seat.name}
                                      </p>
                                      {level && (
                                        <>
                                          <p>{seat.level}등급</p>{" "}
                                          <p>
                                            {seat.seatType === 0
                                              ? "자유석"
                                              : "고정석"}
                                          </p>
                                        </>
                                      )}
                                    </Seat>
                                  )}
                                {/* 휴식중인 좌석 - 고정석 */}
                                {seat.status === "rest" &&
                                  seat.seatType === 1 && (
                                    <Seat
                                      ratio={ratio}
                                      isMin={isMinimal}
                                      className="seat"
                                      top={seat.pos_top}
                                      left={seat.pos_left}
                                      used="#7bb4ff"
                                    >
                                      <p>
                                        {seat.number}.{seat.name}
                                      </p>
                                      {level && (
                                        <>
                                          <p>{seat.level}등급</p>{" "}
                                          <p>
                                            {seat.seatType === 0
                                              ? "자유석"
                                              : "고정석"}
                                          </p>
                                        </>
                                      )}
                                    </Seat>
                                  )}
                                {/* 휴식중인 좌석 - 자유석 */}
                                {seat.status === "rest" &&
                                  seat.seatType === 0 && (
                                    <Seat
                                      ratio={ratio}
                                      isMin={isMinimal}
                                      className="seat"
                                      top={seat.pos_top}
                                      left={seat.pos_left}
                                      used={
                                        seat.ticketType === 1
                                          ? "#B0BEC5"
                                          : seat.ticketType === 2
                                          ? "#FFAB91"
                                          : "#80DEEA"
                                      }
                                    >
                                      <p>
                                        {seat.number}.{seat.name}
                                      </p>
                                      {level && (
                                        <>
                                          <p>{seat.level}등급</p>{" "}
                                          <p>
                                            {seat.seatType === 0
                                              ? "자유석"
                                              : "고정석"}
                                          </p>
                                        </>
                                      )}
                                    </Seat>
                                  )}
                                {/* {seat.status === "using" &&
                                  seat.type === "seminar" && (
                                    <Seat
                                      ratio={ratio}
                                      isMin={isMinimal}
                                      used="#616161"
                                      top={seat.pos_top}
                                      left={seat.pos_left}
                                    >
                                      <p>
                                        {seat.number}.{seat.name}
                                      </p>
                                      {level && (
                                        <>
                                          <p>{seat.level}등급</p>{" "}
                                          <p>
                                            {seat.seatType === 0
                                              ? "자유석"
                                              : "고정석"}
                                          </p>
                                        </>
                                      )}

                                    </Seat>
                                  )} */}
                              </React.Fragment>
                            ))}
                            {/* 가벽 */}
                            {walls?.map((wall) => (
                              <React.Fragment key={wall.id}>
                                <Wall
                                  ratio={ratio}
                                  isMin={isMinimal}
                                  width={wall.pos_width}
                                  height={wall.pos_height}
                                  top={wall.pos_top}
                                  left={wall.pos_left}
                                />
                              </React.Fragment>
                            ))}
                            {/* 글씨 */}
                            {texts?.map((text) => (
                              <React.Fragment key={text.id}>
                                <Text
                                  ratio={ratio}
                                  isMin={isMinimal}
                                  size={text.font_size}
                                  top={text.pos_top}
                                  left={text.pos_left}
                                >
                                  {text?.name}
                                </Text>
                              </React.Fragment>
                            ))}
                          </SeatArrayS>
                        </SeatView>
                        <SeatListS isSeat>
                          <div className="toggleBtnContainer"></div>
                          {multiNum && (
                            <div className="floorBtn">
                              {[...Array(multiNum)].map((item, index) => (
                                <TabButton
                                  selected={floorNum === index + 1}
                                  onClick={() => setFloorNum(index + 1)}
                                  value={index + 1}
                                >
                                  {index + 1}층
                                </TabButton>
                              ))}
                            </div>
                          )}
                          <div className="ticket-kind">
                            <PossibleColor color="#006eff">
                              <p>고정석</p>
                            </PossibleColor>
                            <PossibleColor color="#616161">
                              <p>일회권</p>
                            </PossibleColor>
                            <PossibleColor color="#FF8F00">
                              <p>기간권</p>
                            </PossibleColor>
                            <PossibleColor color="#00838F">
                              <p>시간정액권</p>
                            </PossibleColor>
                          </div>
                          {/* <PossibleColor color="#616161">
                            <p>세미나실</p>
                          </PossibleColor> */}
                          {/* <PossibleColor color="#AB84FF">
                            <p>휴식중</p>
                          </PossibleColor> */}
                        </SeatListS>
                      </div>
                    ) : (
                      <>
                        <div className="seatContainer">
                          <LockerView>
                            <LockersArray>
                              {lockerList?.map((locker) => (
                                <React.Fragment key={locker.id}>
                                  {/* 사물함 차단 */}
                                  {locker.status === "block" && (
                                    <LockerS
                                      isUse
                                      style={{ backgroundColor: "#979797" }}
                                    >
                                      <p>
                                        {locker.number}.{locker.name}
                                      </p>
                                    </LockerS>
                                  )}
                                  {/* 사물함 비어있음 */}
                                  {locker.status === "idle" && (
                                    <LockerS>
                                      <p>
                                        {locker.number}.{locker.name}
                                      </p>
                                    </LockerS>
                                  )}

                                  {/* 사물함 사용중 */}
                                  {locker.status === "using" &&
                                    +diffTimeToLocker(locker.endDate) > 2 && (
                                      <LockerS1 isUse>
                                        <p>
                                          {locker.number}.{locker.name}
                                        </p>
                                        <HoverInfo show={toggle} used="#888ea5">
                                          <p>
                                            {`${diffTimeToLocker(
                                              locker.endDate
                                            )}일
                                          남음`}
                                          </p>
                                        </HoverInfo>
                                      </LockerS1>
                                    )}
                                  {/* 사물함 사용중(기간 2일 이하로 남았을경우 */}
                                  {locker.status === "using" &&
                                    +diffTimeToLocker(locker.endDate) < 3 && (
                                      <LockerS1
                                        style={{ backgroundColor: "#ca3b3b" }}
                                        isUse
                                      >
                                        <p>
                                          {locker.number}.{locker.name}
                                        </p>
                                        <HoverInfo show={toggle} used="#ca3b3b">
                                          <p>
                                            {`${diffTimeToLocker(
                                              locker.endDate
                                            )}일
                                          남음`}
                                          </p>
                                        </HoverInfo>
                                      </LockerS1>
                                    )}
                                </React.Fragment>
                              ))}
                            </LockersArray>
                          </LockerView>

                          <SeatListS isSeat>
                            <div className="toggleBtnContainer">
                              <PossibleColorL isUse>
                                <p>점유된 사물함</p>
                              </PossibleColorL>
                              <PossibleColorL>
                                <p>빈 사물함</p>
                              </PossibleColorL>
                            </div>
                            {multiNum && (
                              <div className="floorBtn">
                                {[...Array(multiNum)].map((item, index) => (
                                  <TabButton
                                    selected={floorNum === index + 1}
                                    value={index + 1}
                                    onClick={() => setFloorNum(index + 1)}
                                  >
                                    {index + 1}층
                                  </TabButton>
                                ))}
                              </div>
                            )}
                          </SeatListS>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardContainer>
        </Container>
      </Fragment>
    </>
  );
};

export default SeatListInfo;
