import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  CardFooter,
} from "reactstrap";
import {
  deleteImage,
  getImages,
  merchantId_api,
  postAdImgs,
  postBannerImg,
} from "../lib/api";
import { useState } from "react";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { Label1 } from "./lockerChange";

const StyledContainer = styled.div`
  width: 100%;
  .top-object {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1vh 0;
    .datepicker {
      width: 100%;
      display: flex;
      align-items: center;
      h5 {
        margin: 0 8px;
        font-weight: 400;
      }
      > div > div > button {
        width: 100%;
      }
      select {
        background: #f8fcff;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        padding: 9px 12px;
        font-size: 14px;
        margin: 0 10px;
        height: 38px;
      }
    }
  }

  .phoneNum-click {
    color: #04c9db;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 375px) {
    .top-object {
      width: 100%;
      .datepicker {
        flex-direction: column;
        select {
          width: 100%;
          margin-top: 1vh;
          text-align: center;
        }
      }
    }
  }
`;

let merchantId;

const ImageSetting = () => {
  const [bannerImg, setBannerImg] = useState("");
  const [adImg, setAdImg] = useState("");

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      getImage(data.id);
    }
  };

  const handleOnChange = (e) => {
    let image_tag = document.querySelectorAll(".imageData");
    const reader = new FileReader();
    if (e.target.name === "banner") {
      if (bannerImg && typeof bannerImg !== "string") {
        image_tag[0].removeChild(image_tag[0].childNodes[2]);
      }

      reader.onload = function(event) {
        var img = document.createElement("img");
        img.setAttribute("src", event.target.result);
        img.setAttribute("style", "width:50%");
        image_tag[0].appendChild(img);
      };
      reader.readAsDataURL(e.target.files[0]);
      setBannerImg(e.target.files[0]);
    } else {
      if (adImg && typeof adImg !== "string") {
        image_tag[1].removeChild(image_tag[1].childNodes[2]);
      }

      reader.onload = function(event) {
        var img = document.createElement("img");
        img.setAttribute("src", event.target.result);
        img.setAttribute("style", "width:30%");
        image_tag[1].appendChild(img);
      };
      reader.readAsDataURL(e.target.files[0]);
      setAdImg(e.target.files[0]);
    }
  };

  const handleBannerImg = async () => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: "#04c9db",
      cancelButtonColor: "#F53C14",
      confirmButtonText: "등록",
      cancelButtonText: "취소",
      title: "이미지 등록",
      html: `이미지를 등록하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("bannerImg1", bannerImg);
        formData.append("merchantId", merchantId);
        const res = await postBannerImg(formData);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            timer: 3000,
            title: "이미지 등록이 완료됐습니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            timer: 3000,
            title: "이미지 등록에 실패했습니다. 다시 시도해주세요.",
          });
        }
      }
    });
  };
  const handleAdImg = async () => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: "#04c9db",
      cancelButtonColor: "#F53C14",
      confirmButtonText: "등록",
      cancelButtonText: "취소",
      title: "이미지 등록",
      html: `이미지를 등록하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("adImg1", adImg);
        formData.append("merchantId", merchantId);

        const res = await postAdImgs(formData);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            timer: 3000,
            title: "이미지 등록이 완료됐습니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            timer: 3000,
            title: "이미지 등록에 실패했습니다. 다시 시도해주세요.",
          });
        }
      }
    });
  };

  const getImage = async (mid) => {
    let imageData;
    if (mid) {
      imageData = await getImages(mid);
    } else {
      imageData = await getImages(merchantId);
    }
    setBannerImg(imageData.data.bannersMerchant.bannerImg1);
    setAdImg(imageData.data.adsMerchant.adImg1);
  };

  const deleteData = async (image) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: "#F53C14",
      cancelButtonColor: "#04c9db",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
      title: "이미지삭제",
      html: `이미지를 삭제하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resultData = await deleteImage(merchantId, image);
        if (resultData.status === 200) {
          Swal.fire({
            icon: "success",
            timer: 4000,
            title: "이미지를 삭제하였습니다.",
          }).then(() => window.location.reload());
        } else {
          Swal.fire({
            icon: "error",
            timer: 4000,
            title: "삭제중 오류가 발생했습니다. 다시 시도해주세요",
          });
        }
      }
    });
  };

  return (
    <Fragment>
      <Breadcrumbs parent="마일리지 쿠폰 관리" title="마일리지" />
      <Container fluid={true}>
        <StyledContainer>
          <Row>
            <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Card>
                <CardBody>
                  <h5 style={{ marginBottom: "20px" }}>가로 배너이미지 설정</h5>

                  <Label1 className="imageData">
                    <h5>이미지 등록</h5>
                    <Input
                      className="form-control m-input"
                      type="file"
                      accept="image/*"
                      name="banner"
                      placeholder="숫자만 입력해주세요"
                      // value={bannerImg || ""}
                      onChange={handleOnChange}
                    />
                    {typeof bannerImg === "string" && (
                      <img
                        style={{ width: "50%" }}
                        src={bannerImg || ""}
                        alt="가로이미지"
                      />
                    )}
                  </Label1>
                </CardBody>
                <CardFooter className="text-center">
                  <button className="btn btn-primary" onClick={handleBannerImg}>
                    등록하기
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => deleteData("bannerImg1")}
                  >
                    삭제하기
                  </button>
                </CardFooter>
              </Card>
              <Card>
                <CardBody>
                  <h5 style={{ marginBottom: "20px" }}>세로 광고이미지 설정</h5>

                  <Label1 className="imageData">
                    <h5>이미지 등록</h5>
                    <Input
                      className="form-control m-input"
                      type="file"
                      accept="image/*"
                      name="ad"
                      //   value={adImg || ""}
                      onChange={handleOnChange}
                    />
                    {typeof adImg === "string" && (
                      <img
                        style={{ width: "30%" }}
                        src={adImg || ""}
                        alt="세로이미지"
                      />
                    )}
                  </Label1>
                </CardBody>
                <CardFooter className="text-center">
                  <button className="btn btn-primary" onClick={handleAdImg}>
                    등록하기
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => deleteData("adImg1")}
                  >
                    삭제하기
                  </button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </StyledContainer>
      </Container>
    </Fragment>
  );
};

export default ImageSetting;
