import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  translate,
} from "react-switch-lang";

// import {
//   English,
//   Deutsch,
//   Español,
//   Français,
//   Português,
//   简体中文,
//   Notification,
//   DeliveryProcessing,
//   OrderComplete,
//   TicketsGenerated,
//   DeliveryComplete,
//   CheckAllNotification,
//   ViewAll,
//   MessageBox,
//   EricaHughes,
//   KoriThomas,
//   Admin,
//   Account,
//   Inbox,
//   Taskboard,
//   AinChavez,
//   CheckOut,
//   ShopingBag,
//   OrderTotal,
//   GoToShopingBag,
// } from "../../constant";

import en from "../../assets/i18n/en.json";
import es from "../../assets/i18n/es.json";
import pt from "../../assets/i18n/pt.json";
import fr from "../../assets/i18n/fr.json";
import du from "../../assets/i18n/du.json";
import cn from "../../assets/i18n/cn.json";
import ae from "../../assets/i18n/ae.json";
import {
  getMerchantInfo,
  getUserData,
  Logout_api,
  merchantId_api,
} from "../../lib/api";
import { openDoor } from "../../lib/socket";
import styled from "styled-components";
import { decryptFunc, levelDecode } from "../../components/common";
import { Cookies } from "react-cookie";
import OpenDoorModal from "../../components/modal/openDoorModal";
import Arrow from "../../assets/images/dashboard/arrow.svg";
import List from "../../assets/images/dashboard/list.svg";
import LogOut from "../../assets/images/dashboard/logout.svg";
import OpenDoor from "../../assets/images/dashboard/opendoor.svg";
import Menu from "../../assets/images/menuBlack.svg";
import LogoLetmeup from "../../assets/images/Logo_letmeupBlack.svg";
import User from "../../assets/images/UserCircle.svg";
import { useHistory } from "react-router-dom";
const cookies = new Cookies();

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage("en");
setLanguageCookie();

let mid;
const ResponseMerchantName = styled.div`
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 805px) {
    padding: 4.44vw 5vw;
    background-color: #f1f3f6;
    border-top-left-radius: 4.44vw;
    border-top-right-radius: 4.44vw;
    display: flex;
    color: #686c71;
    > div {
      &:first-child {
        margin-right: 2.77vw;
      }
    }
  }
`;

const Container = styled.div`
  .nav-right {
    margin-right: 0 !important;
  }
  .nav-menus {
    margin-right: 0 !important;
    .media-body {
      padding: 0.41vw;
      &:hover {
        background-color: #f1f3f6;
        border-radius: 0.41vw;
      }
    }
  }
  .profile-media img {
    width: 0.52vw !important;
    height: 0.31vw !important;
    margin-left: 0.2vw;
  }
  .profile-dropdown {
    width: 11.25vw !important;
    border-radius: 0.83vw !important;
    padding: 0.41vw 0.41vw !important;
    box-shadow: 0px 0px 24px 0px #00000029;
    top: 2.9vw !important;
    right: 0 !important;
    left: 1.53vw !important;
    li {
      justify-content: flex-start !important;
      padding: 0.62vw 1.45vw !important;
      img {
        width: 0.93vw;
        margin-right: 0.41vw;
      }
      span {
        font-size: 0.93vw;
        font-weight: 600;
        color: #686c71 !important;
      }
      &:first-child {
        border-top: none !important;
      }
      &:hover {
        background-color: #f1f3f6;
        border-radius: 0.41vw;
      }
    }
    hr {
      margin: 0;
      width: 80%;
      border-top: 1px solid #dde4eb;
      margin-left: 10%;
    }
  }
  @media screen and (max-width: 805px) {
    .profile-dropdown {
      right: 0;
      width: 60vw !important;
      border-radius: 4.44vw !important;
      padding: 0 !important;
      left: 36.5vw !important;
      top: 15.55vw !important;
      li {
        padding: 4.72vw 8.88vw !important;

        img {
          width: 5vw;
          margin-right: 2.22vw;
        }
        span {
          font-size: 5vw;
        }
        &:nth-child(2) {
          border-top: none !important;
        }
        &:hover {
          background-color: #ffffff;
          border-radius: 0.41vw;
        }
      }
      hr {
        margin-left: 6.66vw;
      }
    }
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .showMenu {
    width: 6.66vw;
    height: 6.66vw;
    margin-right: 5vw;
  }
  .logo {
    width: 27.77vw;
    height: 15.55vw;
  }
  .user {
    width: 7vw;
  }
`;

const Rightbar = (props) => {
  // 기존 언어관련
  // const [searchresponsive, setSearchresponsive] = useState(false);
  // const [langdropdown, setLangdropdown] = useState(false);
  // const [selected, setSelected] = useState("en");
  // const [cartDropdown, setCartDropDown] = useState(false);
  // const [notificationDropDown, setNotificationDropDown] = useState(false);
  // const [chatDropDown, setChatDropDown] = useState(false);

  const [moonlight, setMoonlight] = useState(false);
  const [isDoorModal, setIsDoorModal] = useState(false);
  const [name, setName] = useState("");
  const [merchantCode, setMerchantCode] = useState("");
  const isMobile = window.innerWidth < 806;
  const history = useHistory();
  let level = +levelDecode(
    localStorage.getItem("adminToken")
      ? localStorage.getItem("adminToken")
      : localStorage.getItem("Token")
  );

  const width = useWindowSize();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  const handleLogout = async () => {
    if (props) {
      await Logout_api(props.id, props.phoneNum);
      localStorage.clear();
    }
  };

  const handleClick = () => {
    document.querySelector(".onhover-show-div").classList.toggle("showing");
    localStorage.setItem("isClick", true);
  };

  const moveToDashBoard = () => {
    const path = level === 99 ? "/admin/dashboard" : "/dashboard";
    return history.push(path);
  };
  useEffect(() => {
    if (props?.data) {
      if (+levelDecode(localStorage.getItem("Token")) > 1) {
        setName(props?.data?.name);
        setMerchantCode(props.data.merchant_code);
      } else {
        if (props?.data.name) {
          if (!props?.data.userName) {
            setName(
              `${props.data.name} - ${props.data.phoneNum?.slice(7, 11)} 님` ||
                ""
            );
          } else {
            setName(`${props.data.name} - ${props.data.userName} 님` || "");
          }
        }
      }
    }
  }, [props?.data]);

  return (
    <Fragment>
      {isDoorModal && props && (
        <OpenDoorModal
          show={isDoorModal}
          onClose={setIsDoorModal}
          data={props.data.multiKioskFloor}
          id={props.mid}
        />
      )}
      {isMobile && (
        <MobileHeader>
          <div>
            <img
              className="showMenu"
              onClick={() => props.setIsShowSide(true)}
              src={Menu}
              alt="menu"
            />
            <img
              className="logo"
              src={LogoLetmeup}
              alt="LogoLetmeup"
              onClick={moveToDashBoard}
            />
          </div>
          <div>
            <img
              className="user"
              onClick={() => handleClick()}
              src={User}
              alt="user"
            />
          </div>
        </MobileHeader>
      )}
      {!isMobile && (
        <ResponseMerchantName
          style={
            window.innerWidth <= 991
              ? { display: "flex", alignItems: "center" }
              : { display: "none" }
          }
        >
          {name ? name : ""}
          {merchantCode && (
            <>
              <small>({merchantCode})</small>
            </>
          )}
        </ResponseMerchantName>
      )}
      <Container>
        <div className="nav-right col-8 pull-right right-header p-0">
          <ul className="nav-menus">
            <li className="profile-nav onhover-dropdown p-0">
              <div
                className="media profile-media"
                style={{ alignItems: "center", width: "max-content" }}
              >
                <div>
                  <div
                    style={
                      window.innerWidth <= 991
                        ? { display: "none" }
                        : { display: "flex" }
                    }
                    className="media-body"
                    onClick={() => handleClick()}
                  >
                    <span>
                      {name ? name : ""}{" "}
                      {merchantCode && (
                        <>
                          <small>({merchantCode})</small>
                        </>
                      )}
                    </span>
                    <span>
                      <img src={Arrow} alt="더보기" />
                      {/* <i style={{ marginLeft: "15px" }} className="middle fa fa-angle-down"></i> */}
                    </span>
                  </div>
                  <div
                    style={
                      window.innerWidth <= 991
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                    className="media-body"
                  >
                    <span>
                      {/* <i
                        onClick={() => handleClick()}
                        style={{ marginLeft: "15px" }}
                        className="middle fa fa-angle-down"
                      ></i> */}
                    </span>
                  </div>
                </div>
              </div>

              <ul className="profile-dropdown onhover-show-div">
                {isMobile && (
                  <ResponseMerchantName>
                    <div>
                      <img className="user" src={User} alt="user" />
                    </div>{" "}
                    <div>
                      {name ? name : ""}
                      {merchantCode && (
                        <>
                          <small>({merchantCode})</small>
                        </>
                      )}
                    </div>
                  </ResponseMerchantName>
                )}
                {level > 1 &&
                  (props && !props.data.multiKioskFloor ? (
                    <li onClick={() => openDoor(props?.mid)}>
                      <img src={OpenDoor} alt="OpenDoor" />
                      <span>문 열기</span>
                    </li>
                  ) : (
                    <li onClick={() => setIsDoorModal(true)}>
                      <img src={OpenDoor} alt="OpenDoor" />
                      <span>멀티 문열기</span>
                    </li>
                  ))}
                <hr />
                <li onClick={handleLogout}>
                  <img src={LogOut} alt="logout" />
                  <span>로그아웃</span>
                </li>
                <hr />
                {level === 99 && (
                  <li
                    onClick={() => {
                      window.location.href = `${process.env.PUBLIC_URL}/merchant-list`;
                    }}
                  >
                    <img src={List} alt="list" />
                    <span>지점 리스트</span>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </Container>
    </Fragment>
  );
};
export default translate(Rightbar);
